import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-etf-holdings.js";
import { useTranslation } from "react-i18next";
import { Button, CardActions, CardContent } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { KeyboardArrowUp } from "@material-ui/icons";

const useStyles = makeStyles(componentStyles);

export const data = {
  series: [
    {
      name: "2011",
      data: [
        {
          x: "Jan",
          y: -5,
        },
        {
          x: "Feb",
          y: -2,
        },
        {
          x: "Mar",
          y: 10,
        },
        {
          x: "Apr",
          y: 32,
        },
        {
          x: "May",
          y: 3,
        },
        {
          x: "Jun",
          y: -30,
        },
        {
          x: "Jul",
          y: 4,
        },
        {
          x: "Aug",
          y: -5,
        },
        {
          x: "Sep",
          y: -10,
        },
        {
          x: "Oct",
          y: 22,
        },
        {
          x: "Nov",
          y: 15,
        },
        {
          x: "Dec",
          y: -1,
        },
      ],
    },
    {
      name: "2012",
      data: [
        {
          x: "Jan",
          y: 22,
        },
        {
          x: "Feb",
          y: 10,
        },
        {
          x: "Mar",
          y: 5,
        },
        {
          x: "Apr",
          y: -3,
        },
        {
          x: "May",
          y: 3,
        },
        {
          x: "Jun",
          y: -1,
        },
        {
          x: "Jul",
          y: 4,
        },
        {
          x: "Aug",
          y: 1,
        },
        {
          x: "Sep",
          y: 2,
        },
        {
          x: "Oct",
          y: -10,
        },
        {
          x: "Nov",
          y: 3,
        },
        {
          x: "Dec",
          y: 29,
        },
      ],
    },
    {
      name: "2013",
      data: [
        {
          x: "Jan",
          y: 2,
        },
        {
          x: "Feb",
          y: 3,
        },
        {
          x: "Mar",
          y: 22,
        },
        {
          x: "Apr",
          y: 3,
        },
        {
          x: "May",
          y: -4,
        },
        {
          x: "Jun",
          y: -2,
        },
        {
          x: "Jul",
          y: 4,
        },
        {
          x: "Aug",
          y: 1,
        },
        {
          x: "Sep",
          y: 0,
        },
        {
          x: "Oct",
          y: 4,
        },
        {
          x: "Nov",
          y: 1,
        },
        {
          x: "Dec",
          y: -19,
        },
      ],
    },
    {
      name: "2014",
      data: [
        {
          x: "Jan",
          y: 0,
        },
        {
          x: "Feb",
          y: 2,
        },
        {
          x: "Mar",
          y: 5,
        },
        {
          x: "Apr",
          y: 1,
        },
        {
          x: "May",
          y: -2,
        },
        {
          x: "Jun",
          y: 1,
        },
        {
          x: "Jul",
          y: 5,
        },
        {
          x: "Aug",
          y: -3,
        },
        {
          x: "Sep",
          y: -2,
        },
        {
          x: "Oct",
          y: 21,
        },
        {
          x: "Nov",
          y: -3,
        },
        {
          x: "Dec",
          y: -5,
        },
      ],
    },
    {
      name: "2015",
      data: [
        {
          x: "Jan",
          y: -5,
        },
        {
          x: "Feb",
          y: -2,
        },
        {
          x: "Mar",
          y: -15,
        },
        {
          x: "Apr",
          y: -3,
        },
        {
          x: "May",
          y: 3,
        },
        {
          x: "Jun",
          y: -3,
        },
        {
          x: "Jul",
          y: 5,
        },
        {
          x: "Aug",
          y: 6,
        },
        {
          x: "Sep",
          y: 21,
        },
        {
          x: "Oct",
          y: 0,
        },
        {
          x: "Nov",
          y: -3,
        },
        {
          x: "Dec",
          y: 4,
        },
      ],
    },
    {
      name: "2016",
      data: [
        {
          x: "Jan",
          y: 1,
        },
        {
          x: "Feb",
          y: 5,
        },
        {
          x: "Mar",
          y: 7,
        },
        {
          x: "Apr",
          y: 22,
        },
        {
          x: "May",
          y: -3,
        },
        {
          x: "Jun",
          y: 44,
        },
        {
          x: "Jul",
          y: 3,
        },
        {
          x: "Aug",
          y: 6,
        },
        {
          x: "Sep",
          y: -3,
        },
        {
          x: "Oct",
          y: 5,
        },
        {
          x: "Nov",
          y: 2,
        },
        {
          x: "Dec",
          y: 4,
        },
      ],
    },
    {
      name: "2017",
      data: [
        {
          x: "Jan",
          y: -1,
        },
        {
          x: "Feb",
          y: 2,
        },
        {
          x: "Mar",
          y: 3,
        },
        {
          x: "Apr",
          y: 3,
        },
        {
          x: "May",
          y: 2,
        },
        {
          x: "Jun",
          y: 5,
        },
        {
          x: "Jul",
          y: 6,
        },
        {
          x: "Aug",
          y: 12,
        },
        {
          x: "Sep",
          y: 3,
        },
        {
          x: "Oct",
          y: 1,
        },
        {
          x: "Nov",
          y: 1,
        },
        {
          x: "Dec",
          y: 0,
        },
      ],
    },
    {
      name: "2018",
      data: [
        {
          x: "Jan",
          y: 2,
        },
        {
          x: "Feb",
          y: 5,
        },
        {
          x: "Mar",
          y: 4,
        },
        {
          x: "Apr",
          y: 4,
        },
        {
          x: "May",
          y: 5,
        },
        {
          x: "Jun",
          y: 0,
        },
        {
          x: "Jul",
          y: 4,
        },
        {
          x: "Aug",
          y: 2,
        },
        {
          x: "Sep",
          y: 2,
        },
        {
          x: "Oct",
          y: -1,
        },
        {
          x: "Nov",
          y: 2,
        },
        {
          x: "Dec",
          y: 1,
        },
      ],
    },
    {
      name: "2019",
      data: [
        {
          x: "Jan",
          y: 21,
        },
        {
          x: "Feb",
          y: 1,
        },
        {
          x: "Mar",
          y: 1,
        },
        {
          x: "Apr",
          y: 4,
        },
        {
          x: "May",
          y: 3,
        },
        {
          x: "Jun",
          y: 7,
        },
        {
          x: "Jul",
          y: -1,
        },
        {
          x: "Aug",
          y: -2,
        },
        {
          x: "Sep",
          y: -5,
        },
        {
          x: "Oct",
          y: 3,
        },
        {
          x: "Nov",
          y: 5,
        },
        {
          x: "Dec",
          y: 3,
        },
      ],
    },
    {
      name: "2020",
      data: [
        {
          x: "Jan",
          y: 5,
        },
        {
          x: "Feb",
          y: 11,
        },
        {
          x: "Mar",
          y: 2,
        },
        {
          x: "Apr",
          y: 20,
        },
        {
          x: "May",
          y: 3,
        },
        {
          x: "Jun",
          y: 1,
        },
        {
          x: "Jul",
          y: 2,
        },
        {
          x: "Aug",
          y: 2,
        },
        {
          x: "Sep",
          y: 7,
        },
        {
          x: "Oct",
          y: 7,
        },
        {
          x: "Nov",
          y: 3,
        },
        {
          x: "Dec",
          y: 7,
        },
      ],
    },
    {
      name: "2021",
      data: [
        {
          x: "Jan",
          y: 3,
        },
        {
          x: "Feb",
          y: -1,
        },
        {
          x: "Mar",
          y: 5,
        },
        {
          x: "Apr",
          y: -3,
        },
        {
          x: "May",
          y: 4,
        },
        {
          x: "Jun",
          y: 4,
        },
        {
          x: "Jul",
          y: 2,
        },
        {
          x: "Aug",
          y: 32,
        },
        {
          x: "Sep",
          y: 0,
        },
        {
          x: "Oct",
          y: 4,
        },
        {
          x: "Nov",
          y: 5,
        },
        {
          x: "Dec",
          y: 1,
        },
      ],
    },
    {
      name: "2022",
      data: [
        {
          x: "Jan",
          y: -1,
        },
        {
          x: "Feb",
          y: -3,
        },
        {
          x: "Mar",
          y: -24,
        },
        {
          x: "Apr",
          y: 10,
        },
        {
          x: "May",
          y: -2,
        },
        {
          x: "Jun",
          y: 1,
        },
        {
          x: "Jul",
          y: -30,
        },
        {
          x: "Aug",
          y: -1,
        },
        {
          x: "Sep",
          y: -3,
        },
      ],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        shadeIntensity: 0.5,
        radius: 0,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: [
            {
              from: -100,
              to: -20,
              name: "< -20%",
              color: "rgba(255, 0, 0, 0.85)",
            },
            {
              from: -20,
              to: 0,
              name: "< 0%",
              color: "rgba(255, 102, 102, 0.85)",
            },
            {
              from: 0,
              to: 0,
              name: "< 0%",
              color: "rgba(170, 170, 170, 0.65)",
            },
            {
              from: 0,
              to: 20,
              name: "> 0%",
              color: "rgba(102, 198, 102, 0.85)",
            },
            {
              from: 20,
              to: 300,
              name: "> 20%",
              color: "rgba(0, 161, 0, 0.85)",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (ticker, opt) {
        return opt.value ? Math.round(opt.value, 0) + "%" : "-";
      },
      style: {
        fontSize: "9px",
        fontFamily: "Open Sans",
        fontWeight: "400",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#0079B9",
          fontSize: "14px",
          fontFamily: "Open Sans",
          fontWeight: 600,
        },
      },
    },
    xaxis: {
      position: "top",
      labels: {
        style: {
          colors: "#888",
        },
      },
    },
    stroke: {
      width: 1,
    },
    legend: {
      show: false,
    },
  },
};

export default function CardPriceHeatMap({ stockData }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation("translation", { keyPrefix: "StockDetails" });
  const [chartData, serChartData] = React.useState(data);
  const [isExpanded, setIsExpanded] = React.useState(false);

  React.useEffect(() => {
    if (stockData) {
      serChartData((prev) => {
        return {
          ...prev,
          series: stockData.monthlyPrices,
        };
      });
    }
  }, [stockData]);

  React.useEffect(() => {}, [chartData]);

  return (
    <>
      <Card
        classes={{ root: classes.cardRoot }}
        style={{
          height: isExpanded ? "auto" : "320px",
        }}
      >
        <CardHeader
          subheader={
            <Grid container component={Box} alignItems="center" justifyContent="space-between">
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {"Performance"}
                  </Box>
                </Box>
                <Box component={Typography} variant="h3" marginBottom="0!important">
                  <Box component="span">{t("historical")}</Box>
                  <FeatureInfoWithBox description={t("generalInfoTooltip")} />
                </Box>
              </Grid>
              <Grid item xs="auto"></Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent
          style={{
            marginTop: "-20px",
            overflow: "hidden",
          }}
        >
          <ReactApexChart
            options={data.options}
            series={chartData.series}
            type="heatmap"
            height={chartData.series.length * 42}
          />
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            size="small"
            color="primary"
            style={{ color: theme.palette.bsColors.MainBlue }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <>
                {" "}
                {t("collapse")} <KeyboardArrowUp />
              </>
            ) : (
              <>
                {" "}
                {t("expand")} <KeyboardArrowDown />
              </>
            )}
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
