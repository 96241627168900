import React, {  } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";

import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import CircleLoader from "react-spinners/CircleLoader";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Person from "@material-ui/icons/Person";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// core components

import { Form } from "react-bootstrap";
import { useService } from "contexts/ServiceContext";
import styled from "styled-components";

// core components
import componentStyles from "assets/theme/views/auth/register.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { Alert } from "react-bootstrap"
import CardAboutMember from "components/Cards/Cards/CardAboutMember";


const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const dropdownOptions = [
  { value: 1, label: "Bugs" },
  { value: 2, label: "Feature Request" },
  { value: 3, label: "General Feedback" },
  { value: 4, label: "Pay with Crypto?" },
  { value: 5, label: "Other Reasons" },
];

const AutoStylingWrapper = styled.div`
  .MuiFilledInput-root {
    background-color: white!important;
    color: red!important;
  };
  .MuiInputBase-input {
    background-color: white!important;
    color: ${props => props.theme.palette.gray[600]}!important;
  };
  .MuiInputBase-root {
    background-color: white!important;
    color: ${props => props.theme.palette.gray[600]}!important;
  };
  .MuiInputAdornment-root {
    background-color: white!important;
    color: gray!important;
  };
  .MuiFormLabel-root {
    color: ${props => props.theme.palette.gray[600]}!important;
  };
  .Autocomplete-option {
    padding: 3em 1em;
    color: red!important;
  };
`;

function ContactUs() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = getTheme("light");
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const typeRef = React.useRef();
  const textRef = React.useRef();
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = React.useState(false);
  const { postFeedback } = useService();
  const maxCharacters = 1500;

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true);
    setError("")
    setSuccess("")
    setLoading(true)
    postFeedback(nameRef?.current?.value, emailRef?.current?.value, typeRef?.current?.value, textRef?.current?.value)
      .then(() => {
        setSuccess("Message received! Thanks :)");
      })
      .catch(() => {
        setError("Error sending message, please try again later or contact info@bullsheet.me");
      });
    setLoading(false);
  }

  function handleInputChange(e) {
    e.stopPropagation();

    setError("");
    if (textRef?.current?.value?.length >= maxCharacters) {
      setError("Limit of " + maxCharacters + " characters reached! Send an email to info@bullsheet.me instead.");
    }

    if (nameRef?.current?.value && emailRef?.current?.value && typeRef?.current?.value && textRef?.current?.value && !loading) {
      setSubmitButtonEnabled(true);
    } else {
      setSubmitButtonEnabled(false);
    }
  }

  return (
    <Box style={{ background: "linear-gradient(180.04deg, #FFFFFF 15.84%, #B3CEE8 240.04%)" }}>
      <Grid container>
        <Helmet>
          <title>Contact Us | Bullsheet</title>
          <meta name="description" content="Get in touch with the Bullsheet Team." />
        </Helmet>

        <Grid item xs={false} md={1} lg={2}></Grid>
        <Grid item xs={12} lg={4} md={5}>
          <Box
            className={classes.header}
            position="relative"
            paddingTop="8rem"
            paddingBottom="2rem"
          >
            <Box marginBottom="2rem" >
              <Box
                marginLeft="0rem"
                marginRight="2rem"
                justifyContent="center"
                color={theme.palette.bsColors.MainBlue}
              >
                <Box textAlign="left">
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.typographyH3}
                    color="inherit"
                  >
                    Works with:{"      "}
                    <img
                      alt="eToro"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Etoro_logo.svg/1200px-Etoro_logo.svg.png"
                      width="70rem"
                      style={{ marginLeft: "0.5rem", verticalAlign: "text-top", marginBottom: "0.5rem" }}

                    />
                  </Typography>

                </Box>
                <Typography component="h1" variant="h1" color="inherit" style={{ textAlign: "left", fontWeight: "600" }}>
                  Get in touch with the Bullsheet Team
                  </Typography>
                <Box classes={{ root: classes.checksBox }}>
                  We're here to help!
                  Write us a message and we'll try to get back to you as soon as possible
                  </Box>

                {/* <Box position="relative">
                  <OverlapDummyRegister/>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} md={6} classes={{ root: classes.gridItem }}>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
              className={classes.cardHeader}
              classes={{
                   title: classes.cardHeaderTitle,
                 }}
              title="Contact Form"
              titleTypographyProps={{
                component: Box,
                marginBottom: "0!important",
                variant: "h3",
              }}
            ></CardHeader>
            <CardContent>
              <AutoStylingWrapper theme={theme}>
              <Form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        placeholder="Your name"
                        inputRef={nameRef}
                        onChange={handleInputChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <OutlinedInput
                        fullWidth
                        type="email"
                        onChange={handleInputChange}
                        placeholder="Email address"
                        inputRef={emailRef}
                        startAdornment={
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        }
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="combo-box-bs"
                      options={dropdownOptions}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => <span style={{color: theme.palette.gray[700]}}>{option.label}</span>}
                      closeIcon={null}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                          style={{color: 'red !important'}}
                        />
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={typeRef}
                          label="Contact Reason"
                          variant="outlined"
                          style={{color: 'red !important'}}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      size="medium"
                      component={Box}
                      marginBottom="1rem!important"
                      style={{ marginTop: "15px" }}
                    >
                      <FilledInput disabled={false}
                        inputProps={{ maxLength: maxCharacters }}
                        type="text"
                        multiline={true}
                        rows={7}
                        inputRef={textRef}
                        onChange={handleInputChange}
                        placeholder="Write your message here"

                      />
                    </FormControl>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Button disabled={!submitButtonEnabled}
                      variant="contained"
                      type="submit"
                      classes={{
                        root: classes.buttonCTANormalUngated
                      }}
                    >  Send Message
                    <Box position="relative" style={{ marginRight: "27px", marginTop: "-20px" }}>
                        <CircleLoader color={theme.palette.bsColors.LogoLightBlue} loading={false} size={30} />
                      </Box>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              </AutoStylingWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box>
        <Typography variant="h2" style={{ color:theme.palette.bsColors.MainBlue, textAlign: "center" }}>Tag us on your eToro Posts</Typography>
        <Typography variant="h4" style={{ color:theme.palette.bsColors.MainBlue, textAlign: "center", paddingBottom: "1rem" }}>We'd love to hear what you have to say about Bullsheet</Typography>

        <Grid container style={{ paddingBottom: "2rem" }}>
          <Grid item xs={false} md={1} lg={2}></Grid>
          <Grid item xs={12} lg={4}>
            <CardAboutMember
              image="https://etoro-cdn.etorostatic.com/avatars/150X150/13589637/2.jpg"
              name="Filipe - Co-Founder"
              etoro="filsommer"
              linkedin="filipesommer"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardAboutMember
              image="https://etoro-cdn.etorostatic.com/avatars/150X150/17437715/2.jpg"
              name="Joao - Co-Founder"
              etoro="jdiogoc92"
              linkedin="joaoramalhocarlos"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ContactUs;
