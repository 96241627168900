import React from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "assets/theme/components/cards/sortable/card-coin-market-cap.js";
import { useTranslation } from "react-i18next";
import { useService } from "contexts/ServiceContext";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";

const useStyles = makeStyles(componentStyles);
export default function CardCryptoWhaleAlerts({ currentEtoroUserData, currentUser }) {
  const classes = useStyles();
  const { postCryptoWallet, deleteCryptoWallet } = useService();
  const [wallets, setWallets] = React.useState([
    {
      id: "Enter a wallet",
      amount: "Enter a wallet",
      coin: "ERG",
      ref: { current: { value: "" } },
    },
  ]);

  function handleDelete(index) {
    wallets.splice(index, 1);
    //walletRefs.current.splice(index, 1);
    setWallets([...wallets]);
    /*deleteCryptoWallet(currentUser, walletRefs.current[walletRefs.current.length-1].value).then( (response) => {
            wallets.splice(index, 1);
            setWallets([...wallets]);
        }).catch(err => {
            console.log("err", err);
        })*/
  }

  function handleAdd(index) {
    postCryptoWallet(currentUser.email, wallets[index].coin, wallets[index].ref.current.value)
      .then((response) => {
        wallets[index].id = wallets[index].ref.current.value;
        wallets[index].amount = response.data;
        wallets.push({
          id: "Enter a wallet",
          amount: "Enter a wallet",
          coin: "ERG",
          ref: { current: { value: "ERG" } },
        });
        setWallets([...wallets]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function handleCoinChange(option, index) {
    console.log("option", option);
    wallets[index].coin = option.props.value;
    setWallets([...wallets]);
  }

  React.useEffect(() => {
    console.log("wallets", wallets);
  }, [wallets]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        subheader={
          <Grid container component={Box} alignItems="center" justifyContent="space-between">
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h6"
                letterSpacing="2px"
                marginBottom="0!important"
                className={classes.textUppercase}
              >
                <Box component="span" color={"gray"}>
                  Crypto Wallets
                </Box>
              </Box>
              <Box component={Typography} variant="h3" marginBottom="0!important">
                <Box component="span">Personal Wallets</Box>
              </Box>
            </Grid>
            <Grid item xs="auto" className={classes.gridItemRoot}></Grid>
          </Grid>
        }
        classes={{ root: classes.cardHeaderRoot }}
      ></CardHeader>
      <Grid container style={{ padding: "15px", maxHeight: 360, overflow: "auto" }}>
        {wallets
          ? wallets.map((wallet, index) => (
              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={6} md={6}>
                    <OutlinedInput
                      fullWidth
                      inputRef={wallets[index].ref}
                      type="text"
                      placeholder="Wallet address"
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountBalanceWalletIcon />
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={6} style={{ marginLeft: "-25px" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={wallets[index].coin}
                      label="Coin"
                      disabled={wallets[index].amount !== "Enter a wallet"}
                      onChange={(e, option) => handleCoinChange(option, index)}
                    >
                      <MenuItem value={"ERG"}>ERG</MenuItem>
                      <MenuItem value={"ETH"}>ETH</MenuItem>
                    </Select>
                    <Button style={{ marginLeft: "-5px" }} onClick={() => handleAdd(index)}>
                      <AddIcon />
                    </Button>
                    <Button
                      style={{ marginLeft: "-15px" }}
                      onClick={() => handleDelete(index)}
                      disabled={wallets.length == 1}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Grid>

                <FormGroup style={{ marginLeft: "10px", marginTop: "5px" }}>
                  Amount: {wallet.amount}
                </FormGroup>
                <Divider style={{ marginBottom: "10px" }}></Divider>
              </Grid>
            ))
          : null}
      </Grid>
    </Card>
  );
}
