import React from "react";
import axios from "axios";
import clsx from "clsx";
import { firestore, fieldValue } from "../../firebase";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import themeColors from "assets/theme/colors.js";

// @material-ui/icons components
import Person from "@material-ui/icons/Person";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

import componentStyles from "assets/theme/views/auth/lock.js";
import componentStylesButtons from "assets/theme/components/button.js";
import boxShadows from "assets/theme/box-shadow.js";
import { useHistory } from "react-router-dom";
import { useService } from "contexts/ServiceContext";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useAuth } from "contexts/AuthContext";
import { Alert } from "react-bootstrap";
import CircleLoader from "react-spinners/CircleLoader";
import { useTranslation } from "react-i18next";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import getTheme from "assets/theme/theme";
import styled from "styled-components";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const CTANormalButton = {
  backgroundColor: themeColors.bsColors.LogoLightBlue,
  borderColor: themeColors.bsColors.LogoLightBlue,
  color: themeColors.white.main,
  "&:hover": {
    backgroundColor: themeColors.bsColors.MainBlue,
    borderColor: themeColors.bsColors.LogoLightBlue,
    color: themeColors.white.main,
  },
  "&:disabled": {
    backgroundColor: themeColors.gray[100],
    borderColor: themeColors.gray[100],
    color: themeColors.gray[300],
  },
};

const InputWrapper = styled.div`
  .MuiFilledInput-root {
    background-color: white !important;
  }
  .MuiInputBase-input {
    background-color: white !important;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      font-size: 16px;
    }
    color: ${(props) => props.theme.palette.gray[600]};
  }
  .MuiInputAdornment-root {
    background-color: white !important;
    color: ${(props) => props.theme.palette.gray[500]};
  }
`;

function LockPage() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = getTheme("light");
  const usernameRef = React.useRef();
  const [error, setError] = React.useState("");
  const [searchButtonEnabled, setSearchButtonButtonEnabled] = React.useState(false);
  const { checkUserExistence, checkMaintenance } = useService();
  const { currentUser } = useAuth();
  const { loadEtoroUser } = useEtoroUser();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation("translation", { keyPrefix: "General" });

  function handleSubmit(e) {
    e.preventDefault();
    setError("");
    if (currentUser && !currentUser.emailVerified) {
      setError("Please verify your email address and refresh this page. Check your spam!");
      setLoading(false);
      return;
    }
    setLoading(true);
    checkUserExistence(usernameRef.current.value)
      .then((resp) => {
        setLoading(true);
        const fireStoreDocName = currentUser.email ? currentUser.email : currentUser.displayName;
        let firestoreData = firestore.collection("users").doc(fireStoreDocName);
        firestoreData
          .get()
          .then((doc) => {
            if (doc.exists) {
              firestoreData.update({
                searchedEtoroUsernames: fieldValue.arrayUnion({
                  date: new Date(),
                  etoroUsername: usernameRef.current.value,
                }),
              });
            } else {
              firestoreData.set({
                searchedEtoroUsernames: [
                  { date: new Date(), etoroUsername: usernameRef.current.value },
                ],
              });
            }
          })
          .catch((error) => {
            // TODO handle error
          });
        loadEtoroUser(usernameRef.current.value, resp.data).then((resp) => {
          if (resp === "eToro_API_down") {
            setError("eToro's API is down. Try again later!");
          } else {
            setError("Unexpected Error. Please try again or contact us at info@bullsheet.me");
          }
          setLoading(false);
        });
      })
      .catch((e) => {
        setLoading(false);
        if (e?.response?.status == 404) {
          setError("Username is private or doesn't exist");
        } else if (e?.response?.status == 502) {
          setError("eToro's API is down. Try again later!");
        } else {
          setError("Server is under maintenance! Please try again in a few minutes.");
        }
      });
    setLoading(false);
  }
  function handleKeypress(e) {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      handleSubmit(e);
    }
  }

  function handleSearchInput(e) {
    e.preventDefault();
    // TODO email verification -> if (usernameRef?.current?.value && currentUser.emailVerified) {
    if (usernameRef?.current?.value) {
      setSearchButtonButtonEnabled(true);
    } else {
      setSearchButtonButtonEnabled(false);
    }
  }

  React.useEffect(() => {
    if (!currentUser) {
      history.push("/auth/register");
    }
    // else {
    //   checkMaintenance().catch(err => {
    //     history.push("/maintenance")
    //   });
    // }
    return () => {
      setLoading();
    };
  }, []);

  return (
    <>
      <Box className={classes.header}>
        <AuthHeader
          title="Welcome, bullsheeter"
          description="What's your eToro username?"
        ></AuthHeader>
        <Helmet>
          <title>User Selection | Bullsheet</title>
        </Helmet>
        <Container
          component={Box}
          maxWidth="xl"
          marginTop="-8rem"
          paddingBottom="3rem"
          position="relative"
          zIndex="101"
        >
          <Box component={Grid} container justifyContent="center">
            <Grid item xs={12} md={7} lg={5}>
              <Card classes={{ root: classes.cardRoot }}>
                <Box component={Grid} container justifyContent="center">
                  <Grid item xs={12} lg={3}>
                    <Box position="relative">
                      <Box
                        component="img"
                        src={require("assets/img/icons/common/eToro-icon.png").default}
                        alt="..."
                        maxWidth="90px"
                        borderRadius="50%"
                        position="absolute"
                        left="50%"
                        border={"3px solid " + theme.palette.white.main}
                        boxShadow={boxShadows.boxShadow + "!important"}
                        className={classes.profileImage}
                      />
                    </Box>
                  </Grid>
                </Box>
                <CardContent classes={{ root: classes.cardContent }}>
                  <Typography variant="h3" component="h3" className={classes.title}>
                    eToro username
                    <FeatureInfoWithBox
                      description={
                        "If you do not have an eToro account, try searching for Filsommer or Jdiogoc92.\n We are planning on adding support for more brokers in the future."
                      }
                    />
                  </Typography>
                  {error && <Alert variant="warning">{error}</Alert>}
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                    onKeyPress={handleKeypress}
                  >
                    <InputWrapper theme={theme}>
                      <FilledInput
                        disabled={loading}
                        inputRef={usernameRef}
                        onChange={handleSearchInput}
                        autoComplete="on"
                        type="text"
                        placeholder="Username"
                        startAdornment={
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        }
                      />
                    </InputWrapper>
                  </FormControl>
                  <Typography
                    style={{
                      marginBottom: "10px",
                      fontStyle: "italic",
                      fontSize: 13,
                      color: theme.palette.bsColors.MainBlue,
                    }}
                  >
                    {t("LoadingDetails")}
                  </Typography>
                  {/*<Box>
                  <i style={{fontSize: 15}}>Note: You need to verify your email to proceed.</i>
                </Box> */}
                  <Button
                    disabled={loading || !searchButtonEnabled}
                    onClick={handleSubmit}
                    variant="contained"
                    classes={{
                      root: clsx(classes.buttonCTANormalUngated, classes.buttonRoot),
                    }}
                    styles={CTANormalButton}
                  >
                    {" "}
                    {loading ? "" : "Let's go!"}
                    <Box
                      position="relative"
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <CircleLoader
                        color={theme.palette.bsColors.LogoLightBlue}
                        loading={loading}
                        size={15}
                      />
                    </Box>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default LockPage;
