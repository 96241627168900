import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import strings from "../../../strings/en.js";
// @material-ui/lab components
// @material-ui/icons components
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-coin-market-cap.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useService } from "contexts/ServiceContext";
import CustomCheckbox from "components/Custom/CustomCheckbox.js";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(componentStyles);

const tableHead = ["Coin", "Direction", "$ Amount", "Date"];

export default function CardCryptoWhaleAlerts({ currentEtoroUserData, cryptoData }) {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const [checked, setChecked] = React.useState(false);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CryptoDashboard' });

  function handleClick(e) {
    e.preventDefault();
    checked === true ? setChecked(false) : setChecked(true);
  }
  //We Need to change this to some crypto array coming from Backend
  React.useEffect(() => {
    if (currentEtoroUserData?.marketInfo) {
      new List(tableConatinerRef.current, {
        valueNames: ["coin", "direction", "$ amount", "date",],
        listClass: "list",
      });
    }
  }, [currentEtoroUserData]);

  function getLogoFromTicker(ticker) {
    const asset = cryptoData?.latestCryptoData?.latestCryptoFundamentals.find(entry => entry.ticker === ticker);
    if (ticker.includes("USD")) {
      return "https://etoro-cdn.etorostatic.com/market-avatars/usdollar/150x150.png";
    } else if (typeof asset == "undefined") {
      return
    } else {
      return asset.logo;
    }
  }
  function getTxLink(chain, hash) {
    if (chain.includes("bitcoin")) {
      return "https://mempool.space/tx/" + hash;
    } else if (chain.includes("ethereum")) {
      return "https://etherscan.io/tx/0x" + hash;
    }
    else if (chain.includes("ripple")) {
      return "https://xrpscan.com/tx/" + hash;
    }
    else if (chain.includes("tron")) {
      return "https://tronscan.org/#/transaction/" + hash;
    }
    else if (chain.includes("cardano")) {
      return "https://cardanoscan.io/transaction/" + hash;
    }
    else if (chain.includes("binance")) {
      return "https://binance.mintscan.io/txs/" + hash;
    }
    else if (chain.includes("stellar")) {
      return "https://stellarscan.io/transaction/" + hash;
    }
    else {
      return;
    }
  }
  function getRowColor(from, to) {
    if (from == "private") {
      if (to == "private") {
        return "Neutral"
      }
      else {
        return "Red"
      }
    }
    else {
      if (to == "private") {
        return "Green"
      }
      else {
        return "Neutral"
      }
    }
  }
  function MoneyFormat(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? ((Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? ((Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? ((Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

          : Math.abs(Number(labelValue));

  }
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t('cryptoTrades')}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t('whaleAlerts')} 🐳🐳</Box>
                  <FeatureInfoWithBox description={t("cryptoWhalesTooltip")} />
                </Box>
              </Grid>
              <Grid item xs="auto" className={classes.gridItemRoot}>
                <CustomCheckbox checked={checked} onClick={handleClick} />
                <Box color={theme.palette.bsColors.MainBlue} fontSize={theme.typography.h5.fontSize} fontWeight="600" component="small">
                  {t('myCoins')}
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        {cryptoData?.latestCryptoData?.latestCryptoWhales && cryptoData?.latestCryptoData?.latestCryptoWhales.length > 0 ?
          <TableContainer ref={tableConatinerRef}>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      scope="col"
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " sort " +
                          classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* Where we check if is checked or not to show all eToro coins or just user's */}
              <TableBody className="list">
                {(checked === true ? cryptoData?.latestCryptoData?.latestCryptoWhales?.filter((entry) => currentEtoroUserData?.userInstruments?.instruments.some(instrument => instrument.ticker == entry.ticker)) : cryptoData?.latestCryptoData?.latestCryptoWhales).slice(0, 25).map((prop, key) => (

                  <TableRow className={classes["tableRow" + getRowColor(prop.from, prop.to)]} key={key}>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                    >
                      <Box alignItems="center" display="flex">
                        <Box
                          component={Avatar}
                          marginRight="1rem"
                          alt="..."
                          classes={{ root: classes.avatarRoot }}
                          src={getLogoFromTicker(prop.ticker)}
                        />
                        <Box display="flex" alignItems="flex-start">
                          <Box
                            fontSize={theme.typography.h5.fontSize}
                            component="span"
                            className="ticker"
                          >
                            {prop.ticker}
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot + " budget" }}
                    >
                      <Box
                        component="span"
                        marginRight=".5rem"
                        className="name"
                        fontWeight="600"
                      >
                        <a href={getTxLink(prop.blockchain, prop.hash)} target="_blank">
                          {prop.from.includes("private") ? <><AccountBalanceWalletIcon /> Wallet </> : <><AttachMoneyIcon /> {prop.from} </>}
                      →
                      {prop.to.includes("private") ? <> Wallet <AccountBalanceWalletIcon /> </> : <> {prop.to} <AttachMoneyIcon /> </>}
                        </a>
                      </Box>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot + " budget" }}
                    >
                      <Box
                        component="span"
                        marginRight=".5rem"
                        className="position at company"
                      >
                        ${MoneyFormat(prop.amountUsd)}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes["tableCellRoot" + prop.transactionType] }}>
                      <Box paddingTop=".35rem" paddingBottom=".35rem" className="type">
                        {prop.timestamp}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </TableContainer>
          : <></>}
      </Card>
    </>
  );
}
