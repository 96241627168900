import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    border: "0!important",
    minHeight: "25rem",
  },
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  gridItemRoot: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: "100%",
  },
  listItemRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    padding:8,
    "&:not(:last-child)": {
      borderBottom: "1px solid " + theme.palette.gray[200],
    },
  },
  img: {
    verticalAlign: "middle",
    borderStyle: "none",
    maxWidth: "1.5rem",
    borderRadius: "50%",
    border: "1px solid black"
  },
  mb0: {
    marginBottom: "0!important",
  },
  dividendPercentageBox: {
    textAlign: "center",
    color: theme.palette.bsColors.LogoLightBlue,
    marginTop: "-15px!important",
  },
  dividendValueBox: {
    textAlign: "center",
    color: theme.palette.gray[600],
  }
});

export default componentStyles;
