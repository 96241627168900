import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components

// core components

import componentStyles from "assets/theme/components/cards/cards/card-rectangle-text.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);

function CardRectangleText({ title, subtitle, icon, onClick, color, image }) {
  const classes = { ...useStyles(), ...useStylesCardImg() };
  const theme = useTheme();

  return (
    <>
      <Card className={classes["cardRoot" + color]} onClick={onClick}>
        <CardContent style={{backgroundImage: `url(${image})`, backgroundSize:"cover"}}>
        <Box
            className={classes.titleTypography}      
            style={{
              maxWidth: "80%",
              color:theme.palette.white.main,
            }}
          >
            {title}
          </Box>
          <Box
            component="p"
            marginBottom="1rem"
            fontWeight="300"
            lineHeight="1.7"
            fontSize={theme.typography.h5.fontSize}
            style={{
              maxWidth: "80%",
              color:theme.palette.white.main,
            }}
          >
            {subtitle}
            { color === "Grey" ? <i> (Soon)</i> : null}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardRectangleText;
