import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useLocation, Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components
import Build from "@material-ui/icons/Build";
import Code from "@material-ui/icons/Code";
import Done from "@material-ui/icons/Done";
import Public from "@material-ui/icons/Public";
import EmojiEmotions from "@material-ui/icons/EmojiEmotions";
import Settings from "@material-ui/icons/Settings";
import Badge from "@material-ui/core/Badge";
import TrendingUp from "@material-ui/icons/TrendingUp";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Update from "@material-ui/icons/Update";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import EventIcon from '@material-ui/icons/Event';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import CardInfoBadges from "components/Cards/Index/CardInfoBadges.js";

import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import getTheme from 'assets/theme/theme';

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);

const AndMuchMoreSection = () => {
  const classes = { ...useStyles(), ...useStylesBadge() };
  const theme = getTheme("light");
  return (
      <>
        <Box
          paddingTop="4rem"
          paddingBottom="5rem"
          component="section"
          className={classes.bgWhite}
        >
          <Container maxWidth={false}>
            <Grid container style={{placeContent:"center"}}>
              <Grid item xs={12} md={12} className={classes.centerElements} style={{paddingBottom:"1rem"}}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.typographyH2Dark}
                  style={{color:theme.palette.bsColors.MainBlue}}
                  >
                  ...and much more
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <EventIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Dividend Calendar
                  </Typography>
                  <Box>        
                    Earnings, Dividend Payment and Ex-Dividend Dates for your eToro stocks
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <TrackChangesIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Real Time Trading
                  </Typography>
                  <Box>        
                    Check what eToro's Elite Popular Investors have been buying
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <PeopleAltIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Copy People Stats
                  </Typography>
                  <Box>        
                    How in sync are you with people you copy? What are their latest trades?
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <SaveAltIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Export Portfolio
                  </Typography>
                  <Box>        
                  Export your eToro portfolio quickly to .xlsx or .csv to view it offline
                  </Box>
                </Box>
              </Grid>
              <Box marginTop="2rem" textAlign="center" alignSelf="center">
                  <Button
                    component={Link}
                    variant="contained"
                    to="/auth/features"
                    classes={{ root: classes.buttonCTANormal }}
                    style={{marginRight:"1rem"}}
                  >
                    Check Features
                  </Button>
                  <Button
                    component={Link}
                    variant="contained"
                    to="/auth/pricing"
                    classes={{ root: classes.buttonCTANormalStroke }}
                    style={{marginRight:"1rem"}}
                  >
                    View Pricing
                  </Button>
              </Box>
            </Grid>
          </Container>
        </Box>
      </>
  )
}

export default AndMuchMoreSection;