import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "assets/theme/components/cards/widgets/card-quick-info.js";

const useStyles = makeStyles(componentStyles);

function CardQuickInfo({ subtitle, title, icon, color }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Card
      classes={{ root: clsx(classes.cardRoot, classes[color]) }}
      elevation={1}
      style={{ height: "auto", minHeight: "100px", overflow: "auto" }}
    >
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid container component={Box} justifyContent="space-between">
          <Grid item xs="auto">
            <Box
              component={Typography}
              variant="h5"
              color={theme.palette.bsColors.MainBlue + "!important"}
              marginBottom="0!important"
              className={classes.typographyH5Blue}
            >
              {subtitle}
            </Box>
          </Grid>
          <Grid item xs={"auto"} style={{ paddingTop: "0.15rem" }}>
            <Box
              component={Typography}
              variant="h4"
              fontWeight="600!important"
              marginBottom="20px!important"
              marginTop="0!important"
              color={theme.palette.bsColors.MainBlue + "!important"}
              className={classes.typographyH4Blue}
            >
              {title}
            </Box>
          </Grid>
          {/* <Grid item xs={"auto"} style={{paddingTop: "0.2rem", color: theme.palette.white.main}}>
              <Box
                component={icon}>
              </Box>
            </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}

CardQuickInfo.defaultProps = {
  color: "bgGradientError",
};

CardQuickInfo.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.oneOf([
    "bgGradientDefault",
    "bgGradientError",
    "bgGradientSuccess",
    "bgGradientPrimary",
    "bgGradientInfo",
    "bgGradientWarning",
    "bgGradientGlass",
    "bgGradientGlass2",
  ]),
};

export default CardQuickInfo;
