import boxShadows from "assets/theme/box-shadow.js";
import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    background: themeColors.background.default,
  },
  profileImage: {
    verticalAlign: "middle",
    transform: "translate(-50%,-50%) scale(1)",
    transition: "all .15s ease",
    "&:hover": {
      transform: "translate(-50%,-50%) scale(1.03)",
    },
  },
  cardContent: {
    paddingTop: "5rem !important",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  title: {
    marginBottom: "1.5rem",
    color: themeColors.bsColors.MainBlue
  },
  buttonRoot: {
    marginTop: "1rem",
  },
  header: {
    background: "linear-gradient(180.04deg, #FFFFFF 15.84%, #B3CEE8 240.04%)",
  },
});

export default componentStyles;
