import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components
import Code from "@material-ui/icons/Code";
import EmojiEmotions from "@material-ui/icons/EmojiEmotions";
import Settings from "@material-ui/icons/Settings";
import Badge from "@material-ui/core/Badge";
import { Fade } from 'react-awesome-reveal';

import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import getTheme from 'assets/theme/theme';

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const FeaturesSection = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = getTheme("light");
  return (
      <>
      <Box className={classes.bgDefault}>
        <Box paddingTop="4rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
            <Grid item xs={12} md={12} style={{textAlign:"center"}}>
                <Typography variant="h1" component="h1" className={classes.typographyH2}>
                  Bullsheet Features<br/> to track all your assets
                </Typography>
                <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1.25rem"
                    marginBottom="5rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Bullsheet's Portfolio Manager is designed for individuals who want<br/>
                    insightful information about their portfolio without all the <br/>manual labour of updating stocks everytime
                  </Box>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderMd2}>
                <Fade clear>
                  <Box
                    component="img"
                    alt="Automate your Etoro Portfolio"
                    maxWidth="100%"
                    height="auto"
                    className={classes.imgBox}
                    src={require("assets/img/theme/extendedHoursScreenshot.png").default}
                  ></Box>
                </Fade>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderMd1}>
                <div className={classes.prMd5}>
                  <Typography variant="h1" component="h1" className={classes.typographyH2}>
                    Manage your Portfolio
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Visualize your assets by sector, type, yield and much more.
                    
                    <b> Follow your stocks in extended hours.</b>
                    {" "}Check P/E, P/S and other ratios for your stocks and compare their financial data.
                  </Box>
                  <Box
                    component="ul"
                    marginTop="3rem"
                    paddingLeft="0"
                    className={classes.listStyleNone}
                  >
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<Settings />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            variant="h4"
                            component="h4"
                            className={classes.typographyH4White}
                          >
                            Sector, Dividend and Geographical Breakdowns
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<Code />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            variant="h4"
                            component="h4"
                            className={classes.typographyH4White}
                          >
                            Follow your stocks on extended hours
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box
                      component="li"
                      paddingTop=".5rem"
                      paddingBottom=".5rem"
                    >
                      <Box display="flex" alignItems="center">
                        <div>
                          <Badge
                            badgeContent={<EmojiEmotions />}
                            classes={{
                              badge: clsx(
                                classes.badgePositionRelative,
                                classes.badgeCircle,
                                classes.badgeSuccess
                              ),
                            }}
                          ></Badge>
                        </div>
                        <div>
                          <Typography
                            variant="h4"
                            component="h4"
                            className={classes.typographyH4White}
                          >
                            Load your new Buys automatically
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <Fade clear>
                  <Box
                    component="img"
                    alt="..."
                    maxWidth="100%"
                    height="auto"
                    className={classes.imgBoxPton}
                    src={require("assets/img/theme/newsElon.png").default}
                    // TODO: add easter egg on hover 
                    // onMouseOver={e => (e.currentTarget.src = require("assets/img/theme/newsElon.png").default)}
                    // onMouseOut={e => (e.currentTarget.src = require("assets/img/theme/newsScreenshot.png").default)}
                  ></Box>
                </Fade>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.prMd5}>
                  <Typography variant="h1" component="h1" className={classes.typographyH2}>
                    Latest News for <b>your</b> stocks
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Cut the noise. Get all the latest News for <b>your</b> stocks only and visualize
                    them easily in our News Dashboard. Find articles and
                    insights you might have otherwise missed. Bullsheet delivers you
                    the most recent articles about your stocks from the best online sources.
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} className={classes.orderMd2}>
                <Fade clear>
                  <Box
                    component="img"
                    alt="..."
                    maxWidth="100%"
                    height="auto"
                    className={classes.imgBox}
                    src={require("assets/img/theme/riskScreenshot.png").default}
                  ></Box>
                </Fade>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderMd1}>
                <div className={classes.prMd5}>
                  <Typography variant="h1" component="h1" className={classes.typographyH2}>
                    eToro Portfolio Risk Overview
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Find out which of your stocks might be increasing your
                    overall portfolio risk on eToro. Bullsheet provides you a risk breakdown and score of 0-9
                    for most of your stocks in our intuitive Risk Dashboard. Included in our premium package.
                  </Box>
                  <Box
                    component={Link}
                    to="/auth/pricing"
                    color={theme.palette.bsColors.LogoLightBlue}
                    marginTop="3rem"
                    fontWeight="600"
                    className={classes.infoAnchor}
                  >
                    Check our premium package ➡
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* <Box marginBottom="-5rem" marginTop="3rem">
          <Container maxWidth="xl">
            <Grid container className={classes.justifyContentCenter}>
              <Grid item xs={12}>
                  <Typography variant="h3" component="h3">
                    ... and many more features
                  </Typography>
                <Grid container>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={Event}
                      color="red"
                      title="Calendar"
                      subtitle="Earnings and Dividend Dates for your stocks"
                      badges={["Earnings", "Ex-Dividend"]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={Update}
                      color="green"
                      title="Real Time Trading"
                      subtitle="Check what Elite Popular Investors have been buying"
                      badges={["PI trades", "Insiders"]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={TrendingUp}
                      color="blue"
                      title="Overall Market"
                      subtitle="How is the market performing today?"
                      badges={["Winners", "Losers","News"]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={SaveAlt}
                      color="orange"
                      title="Export Portfolio"
                      subtitle="Export your portfolio quickly to view it offline"
                      badges={[".xlsx", ".csv"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box> */}
        </Box>
      </>
  )
}

export default FeaturesSection;