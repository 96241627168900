import App from "App";
import React from "react";
import ReactDOM from "react-dom";
import './i18n';

window.dataLayer.push({
  event: 'pageview'
});

ReactDOM.render(
  <App />,
  document.querySelector("#root")
);
