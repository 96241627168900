import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

import componentStyles from "assets/theme/components/cards/tabbed/card-sector-by-date.js";

const useStyles = makeStyles(componentStyles);

const sectorPerf = [
    [ //1D
      {
        sector: "SemiConductors",
        performance: 3.5,
      },
      {
        sector: "Oil & Gas",
        performance: 3.2,
      },
      {
        sector: "Commercial Real Estate",
        performance: 2.5,
      },
      {
        sector: "Gas Utilities",
        performance: 2.2,
      },
      {
        sector: "Technology - Yada",
        performance: 1.5,
      },
    ],
    [ //7D
        {
          sector: "Newspapers",
          performance: 3.5,
        },
        {
          sector: "Cookies",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
      ],
      [ //1M
        {
          sector: "Yolo Printers",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
      ],
      [ //3M
        {
          sector: "Rocket Launchers",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
      ],
      [ //6M
        {
          sector: "GigaByte",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
      ],
      [ //1Y
        {
          sector: "Qwerty Keyboards",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
        {
          sector: "Healthcare",
          performance: 3.5,
        },
      ],
  ];


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      classes={{ root: classes.tabsRoot }}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function CardSectorByDate() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
    <Card classes={{ root: classes.cardRoot }}>
    <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Market
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">Sector Performance</Box>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                  <Button 
                    variant="contained"
                    size="small"
                    classes={{
                      root: classes.buttonRootSelected,
                    }}
                  >
                    Best
                  </Button>
                  <Button 
                    variant="contained"
                    size="small"
                    style={{marginLeft:"3px"}}
                    classes={{
                      root: classes.buttonRoot,
                    }}
                  >
                    Worst
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
    <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{ root: classes.tabsRoot }}
        >
          <Tab classes={{ root: classes.tabsBodyRoot }} label="1D"/>
          <Tab classes={{ root: classes.tabsBodyRoot }} label="7D"/>
          <Tab classes={{ root: classes.tabsBodyRoot }} label="1M"/>
          <Tab classes={{ root: classes.tabsBodyRoot }} label="3M" />
          <Tab classes={{ root: classes.tabsBodyRoot }} label="6M" />
          <Tab classes={{ root: classes.tabsBodyRoot }} label="1Y" />
        </Tabs>
        {sectorPerf.map((prop,key) => (
        <TabPanel key={key} value={value} index={key} dir={theme.direction}>
        <Grid container component={Box} justifyContent="space-between" textAlign="center">
        {prop.map((dat,key) => (
            <>
            <Grid key={key} item xs="6">
              <Box
                component={Typography}
                variant="h4"
                color={theme.palette.bsColors.MainBlue + "!important"}
                marginBottom="1rem!important"
                textAlign="left"
                paddingLeft="3rem"
                className={classes.typographyRootH5}
              >
                {dat.sector}
              </Box>
              
            </Grid>
            <Grid item xs="6">
              <Box
                component={Typography}
                variant="h4"
                fontWeight="600!important"
                marginBottom="0!important"
                marginTop="0!important"
                color={theme.palette.bsColors.NumberGreen + "!important"}
              >
                {dat.performance}%
              </Box>
            </Grid>
            
            </>
            ))}
          </Grid>
        </TabPanel>
        ))}
    </div>
    </Card>
    </>
  );
}