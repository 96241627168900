import boxShadows from "assets/theme/box-shadow.js";
import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  cardRoot: {
    textAlign: "center",
    marginBottom: "1.5rem",
    borderRadius: ".375rem!important",
    background: themeColors.bsColors.MainBlue,

    [theme.breakpoints.down("sm")]: {
      marginTop: "8rem",
    },
  },
  cardContentRoot: {
    background: themeColors.bsColors.MainBlue,
    borderRadius: "0 0 .375rem .375rem",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
  },
  cardActionsRoot: {
    justifyContent: "center",
  },
  cardRootGradientSuccess: {
    background: themeColors.bsColors.MainBlue,

    boxShadow: boxShadows.boxShadowLg,
    [theme.breakpoints.up("md")]: {
      zIndex: 1,
      transform: "scale(1.1)",
    },
  },
  bgInherit: {
    background: themeColors.bsColors.MainBlue,
  },
  titleWhite: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.white.main,
    paddingTop: "0rem",
    paddingBottom: "1rem",
    marginBottom: "0",
  },
  subheaderWhite: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.white.main,
    marginBottom: "0",
  },
  whiteAnchor: {
    color: themeColors.white.main,
    textDecoration: "none",
    backgroundColor: "initial",
    "&:hover": {
      color: themeColors.gray[100],
    },
  },
  iconWhite: {
    padding: "12px",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1.25rem",
    height: "1.25rem",
    boxShadow: boxShadows.boxShadow,
    color: themeColors.gray[600],
    borderRadius: "50%",
    background: themeColors.white.main,
  },
  display1: {
    fontSize: "3rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  buttonRootWhite: {
    color: themeColors.dark.main,
    backgroundColor: themeColors.white.main,
    borderColor: themeColors.white.main,
    "&:hover": {
      backgroundColor: themeColors.white.main,
      borderColor: themeColors.white.main,
      color: themeColors.dark.dark,
    },
  },
  cardImgTop: {
    borderTopLeftRadius: "calc(.375rem - 1px)",
    borderTopRightRadius: "calc(.375rem - 1px)",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    alignSelf: "center",
    borderStyle: "none",
    maxHeight: "10rem",
    maxWidth: "6rem",
    objectFit: "cover",
    marginTop: "-6rem",
  },
});

export default componentStyles;
