import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-industry-breakdown.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Sector", "Industry", "Tickers", "Invested", "Profit", "Value"];
const originalMaxShowValue = 7;

// quick find: Industry Breakdown
export default function CardIndustryBreakdown({industries}) {
  const classes = useStyles();
  const theme = useTheme();
  const { pageLoading, currentEtoroUserData } = useEtoroUser();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Analysis' });
  const [sort, setSort] = React.useState({'attribute': 'invested', 'direction': 'desc'});
  const [sortedTable, setSortedTable] = React.useState();
  const [maxShow, setMaxShow] = React.useState({"value": originalMaxShowValue, "clicked": true});

  function handleSort(attribute) {
    setSort({'attribute': attribute, 'direction': sort.direction === 'asc' ? 'desc' : 'asc'});
  }

  function sortIndustryTable(sort) {
    if (sort.attribute === 'Sector') {
      const newArray = sortStrings(sortedTable, sort.direction, sort.attribute);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setSortedTable([...newArray]);
    } else if (sort.attribute === 'invested' || sort.attribute === 'Invested') {
      const newArray = sortedTable.sort(function(a, b){
        return sort.direction === 'asc' ? a.invested - b.invested : b.invested - a.invested;
      })
      setSortedTable([...newArray]);
    } else if (sort.attribute === 'Industry') {
      const newArray = sortStrings(sortedTable, sort.direction, sort.attribute);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setSortedTable([...newArray]);
    }  else if (sort.attribute === 'Profit') {
      const newArray = sortedTable.sort(function(a, b){
        return sort.direction === 'asc' ? a.profit - b.profit : b.profit - a.profit;
      })
      setSortedTable([...newArray]);
    } else if (sort.attribute === 'Value') {
      const newArray = sortedTable.sort(function(a, b){
        return sort.direction === 'asc' ? a.value - b.value : b.value - a.value;
      })
      setSortedTable([...newArray]);
    }
  }

  function sortStrings(array, direction, attribute) {
    const parsedAttribute = attribute === "Company" ? "ticker" : attribute
    return array.sort(function(a, b){
      const tickerA=a[parsedAttribute.toLowerCase()], tickerB=b[parsedAttribute.toLowerCase()];
      if (tickerA < tickerB) //sort string ascending
          return direction === 'asc' ? -1 : 1;
      if (tickerA > tickerB)
        return direction === 'asc' ? 1 : -1;
      return 0 //default return value (no sorting)
    })
  }

  function getLogo(ticker) {
    const asset = currentEtoroUserData?.userInstruments ? currentEtoroUserData.userInstruments.instruments.concat(currentEtoroUserData.userInstruments.parentInstruments).find(entry => entry.ticker === ticker) : undefined;
    if (asset) {
      return asset.media[asset.media.findIndex(x => x.width == 150)]?.uri;
    } else {
      return undefined;
    }
  }

  function getTicker(asset) {
    if (asset?.hodlers?.length > 5) { 
      if (asset.hodlers.includes("You")) {
        return  asset?.tickerName + " (You, 4+ copied investors)";
      } else {
        return  asset?.tickerName + " (5+ copied investors)";
      }
    }
    else if (asset?.hodlers?.length > 1) {
      let tickerString = asset.tickerName;
      tickerString += " ("  
      asset.hodlers.forEach(hodler => {
        tickerString += hodler + ", ";
      });
      return tickerString.substr(0, tickerString.length - 2) + ")";
    } else if (asset?.hodlers?.length == 1) {
      return asset.tickerName + " (" + (asset.hodlers[0] + ")");
    } else {
      return asset?.tickerName;
    }
  }

  function uniqueTickers(tickers) {
      // if you copy no people, no need to aggregate duplicate tickers
      if (currentEtoroUserData?.currentPortfolio?.summary?.socialTrades?.length == 0) {
        return tickers;
      } else {
        const uniqueTickers = [];
        const uniqueTickerNames = [];
        tickers.forEach(ticker => {
          if (uniqueTickerNames.includes(ticker.tickerName)) {
            let hodlers = uniqueTickers.find(entry => entry.tickerName === ticker.tickerName).hodlers;
            if (hodlers) {
              hodlers.push(ticker.parent ? ticker.parent : "You");
            }
          } else {
            uniqueTickerNames.push(ticker.tickerName);
            uniqueTickers.push({"tickerName": ticker.tickerName, "hodlers": ticker.parent ? [ticker.parent] : ["You"]});
          }
        });
        return uniqueTickers;
      }
  }

  React.useEffect(() => {
    setSortedTable(industries)
  }, [industries]);

  React.useEffect(() => {
    if(sortedTable) {
      sortIndustryTable(sort);
    }
  }, [sort]);

  return (
    <> {!pageLoading &&
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("segmentation")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("industryBreakdown")}</Box>
                  <FeatureInfoWithBox description={t("industryBreakdownTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto" className={classes.gridItemRoot}>
                {/* <CustomCheckbox defaultChecked />
                <Box fontSize={theme.typography.h5.fontSize} fontWeight="400" component="small">
                  Hide 0 balances
                </Box> */}
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        {sortedTable && sortedTable.length > 0 ?
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                  key={key}
                  {...(prop !== "Tickers") ? {
                    "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
                  } : null }
                  onClick={() => handleSort(prop)}
                  scope="col"
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " sort " +
                      classes.tableCellRootHead,
                  }}
                >
                  {t(prop)}
                </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {sortedTable ? sortedTable.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="body"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="sector"
                        >
                          {prop.sector}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " budget" }}>
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="industry"
                      >
                      {prop.industry}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                  { uniqueTickers(prop.tickers).slice(0, maxShow.value).map((asset, key) => (
                    <Box key={key} alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.avatarRoot }}
                        marginRight="0.5rem"
                        alt="..."
                        src={getLogo(asset.tickerName)}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box fontSize={theme.typography.h5.fontSize} component="span" className="ticker">
                          {getTicker(asset)}
                        </Box>
                      </Box>
                    </Box>
                  ))}

                  { uniqueTickers(prop.tickers)?.length > originalMaxShowValue ? <> { maxShow.clicked ? 
                        <Button variant="text" onClick={()=> setMaxShow({"value": 100, "clicked": false})} style={{height: "20px", marginTop: "5px", marginBottom: "-5px", marginLeft: "-8px"}}>{t("showMore")}</Button> 
                        : <Button variant="text" onClick={()=> setMaxShow({"value": originalMaxShowValue, "clicked": true})} style={{height: "20px", marginTop: "5px", marginBottom: "-5px", marginLeft: "-8px"}}>{t("showLess")}</Button> 
                        } </> : null }
                  
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " budget" }}>
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="invested"
                      >
                      {prop.invested.toFixed(2)}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes["tableCellRoot" + (prop.profit>0? "Positive" : "Negative")] }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="profit" profit-val={prop.profit + 1000}>
                      {prop.profit.toFixed(2)}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="value">
                      {prop.value.toFixed(2)}%
                    </Box>
                  </TableCell>
                  
                </TableRow>
              )) : <></> }
            </TableBody>
          </Table>
        </TableContainer>
          : <></> }
      </Card>}
    </>
  );
}
