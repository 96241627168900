import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// @material-ui/icons components
import CircleLoader from "react-spinners/CircleLoader";

// core components
import componentStyles from "assets/theme/components/cards/pricing/card-pricing-elite.js";
import buttonsStyles from "assets/theme/components/button.js";
import { useAuth } from "contexts/AuthContext";
import getTheme from "assets/theme/theme.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);

function CardPricingElite({handleSubscribe, loading, price, type}) {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const theme = getTheme("light");
  const { currentUser } = useAuth();
  const thisSubscriptionType = "sponsor";

  return (
    <>
      <Card className={clsx(classes.cardRoot, classes.cardRootGradientSuccess)}>
        <img
          alt="..."
          src={require("assets/img/theme/elite_bull.png").default}
          className={classes.cardImgTop}
        />
        <CardHeader
          title="Sponsor"
          className={classes.bgInherit}
          titleTypographyProps={{
            className: classes.titleInfo,
            component: "h4",
            variant: "h4",
          }}
          subheader="For early access to every feature"
          subheaderTypographyProps={{
            className: classes.subheaderInfo,
            component: "h6",
            variant: "h6",
          }}
        >
        </CardHeader>
        <CardContent className={classes.cardContentRoot}>
          <Box className={classes.display2} color={theme.palette.gray[700]}>
            €{price}
          </Box>
          <Box component="span" color={theme.palette.gray[600]} fontSize={theme.typography.h5.fontSize}>
            per month
          </Box>
          <Box
            component="ul"
            paddingLeft="0"
            className={classes.listStyleNone}
            margin="1.5rem 0"
          >
            
          </Box>
          <Box marginBottom="1rem" marginTop="3rem" textAlign="center">
            <Button
              variant="contained"
              classes={{
                root: classes.buttonCTANormalUngated,
              }}
              onClick={() => handleSubscribe({"plan": thisSubscriptionType, "type": type})}
              disabled={loading}
            >
              { loading ? "" : currentUser?.subscription === thisSubscriptionType ? "Current" : "Subscribe" }
                  <Box position="relative" style={{marginRight: "27px", marginTop: "-20px"}}>
                    <CircleLoader color={theme.palette.bsColors.LogoLightBlue} loading={loading} size={30} /> 
                  </Box>   
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardPricingElite;
