import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// @material-ui/lab components
// @material-ui/icons components

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader";
import componentStyles from "assets/theme/views/admin/export.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useService } from "../../../contexts/ServiceContext";
import excelIcon from "assets/img/icons/common/excel.png";
import csvIcon from "assets/img/icons/common/csv.png";
import tradingViewIcon from "assets/img/icons/common/tradingview.svg";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { useAuth } from "contexts/AuthContext";
import AdditionalSuperpowersSection from "views/auth/Features/AdditionalSuperpowersSection";
import FeatExportPortfolio from "views/auth/Features/FeatExportPortfolio";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "contexts/DarkModeContext";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const Export = () => {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const { downloadFile } = useService();
  const { activeTheme } = useDarkMode();
  const { currentEtoroUserData } = useEtoroUser();
  const { currentUser } = useAuth();
  const { t, i18n } = useTranslation("translation");

  function handleClick(e, extension) {
    e.preventDefault();
    downloadFile(currentEtoroUserData?.userInfo.userName, extension);
  }

  return (
    <>
      <AlternativeHeader section="Export" subsection="Portfolio" />
      {/* Page content */}
      <Helmet>
        <title>Export | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        {currentUser.subscription !== "freeTier" ? (
          <>
            <Grid container>
              <Grid item xs={8} lg={6}>
                <Card classes={{ root: classes.cardRoot }}>
                  <CardHeader
                    className={classes.cardHeader}
                    title={t("Export.title")}
                    titleTypographyProps={{
                      component: Box,
                      marginBottom: "0!important",
                      variant: "h3",
                    }}
                  ></CardHeader>
                  <CardContent>
                    <Box display="inline-block" marginRight="1rem" marginBottom="1rem">
                      <Button
                        classes={{ root: classes.buttonCTANormal }}
                        variant="contained"
                        onClick={(e) => handleClick(e, "xlsx")}
                      >
                        <img src={excelIcon} height="20px" /> .xlsx (Excel)
                      </Button>
                    </Box>
                    <Box display="inline-block" marginRight="1rem" marginBottom="1rem">
                      <Button
                        classes={{ root: classes.buttonCTANormal }}
                        variant="contained"
                        onClick={(e) => handleClick(e, "csv")}
                      >
                        <img
                          src={csvIcon}
                          style={{
                            filter: activeTheme === "light" ? "brightness(0) invert(1)" : "",
                          }}
                          height="20px"
                        />{" "}
                        .csv
                      </Button>
                    </Box>
                    <Box display="inline-block" marginRight="1rem" marginBottom="1rem">
                      <Button
                        classes={{ root: classes.buttonCTANormal }}
                        variant="contained"
                        onClick={(e) => handleClick(e, "tw")}
                      >
                        <img src={tradingViewIcon} height="20px" /> TradingView
                      </Button>
                    </Box>
                    <Box display="inline-block" marginRight="1rem" marginBottom="1rem">
                      <Button
                        classes={{ root: classes.buttonCTANormal }}
                        variant="contained"
                        onClick={(e) => handleClick(e, "xlsxxx")}
                      >
                        <img src={excelIcon} height="20px" /> .xlsx (Excel) agg. positions
                      </Button>
                    </Box>
                    <Box display="inline-block" marginRight="1rem" marginTop="1rem">
                      <i>{t("Export.disclaimer")}</i>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box component="section" paddingTop="1.5rem">
            <Box textAlign="center" paddingBottom="1.5rem">
              <Typography
                variant="h1"
                component="h1"
                className={classes.typographyH1Center}
                style={{
                  backgroundImage:
                    "linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(126, 38%, 48%, 1) 75%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  MozBackgroundClip: "text",
                  MozTextFillColor: "transparent",
                }}
              >
                <b>{t("PremiumContent.premiumContentTitle")}</b>
              </Typography>
              <Box marginTop="1rem" textAlign="center">
                {t("PremiumContent.premiumContentDescription")}
              </Box>
              <Box marginTop="2rem" textAlign="center">
                <Button
                  component={Link}
                  variant="contained"
                  to="/auth/pricing"
                  classes={{ root: classes.buttonCTANormal }}
                >
                  {t("PremiumContent.premiumCtA")}
                </Button>
              </Box>
            </Box>
            <FeatExportPortfolio />
          </Box>
        )}
      </Container>
    </>
  );
};

export default Export;
