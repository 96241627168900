import boxShadows from "assets/theme/box-shadow.js";
import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  iconInfo: {
    padding: "12px",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1.25rem",
    height: "1.25rem",
    boxShadow: boxShadows.boxShadow,
    color: themeColors.white.main,
    borderRadius: "50%",
    background:
      "linear-gradient(87deg," +
      themeColors.info.main +
      ",#1171ef)!important",
  },
  mutedAnchor: {
    color: themeColors.gray[400],
    textDecoration: "none",
    backgroundColor: "initial",
    "&:hover": {
      color: themeColors.gray[500],
    },
  },
  cardRoot: {
    textAlign: "center",
    marginBottom: "1.5rem",
    backgroundColor: "#ffffff",
  },
  cardContentRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
  },
  cardActionsRoot: {
    justifyContent: "center",
  },
  display2: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 600,
    lineHeight: 1.5,
    color: themeColors.gray[700],
  },
  titleInfo: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.bsColors.LogoLightBlue,
    paddingTop: "0rem",
    paddingBottom: "1rem",
    marginBottom: "0",
  },
  subheaderInfo: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.bsColors.MainBlue,
    marginBottom: "0",
  },
  bgInherit: {
    background: "inherit",
  },
  cardImgTop: {
    borderTopLeftRadius: "calc(.375rem - 1px)",
    borderTopRightRadius: "calc(.375rem - 1px)",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    alignSelf: "center",
    borderStyle: "none",
    maxHeight: "20rem",
    maxWidth: "12rem",
    objectFit: "cover",
    marginTop: "-5rem",
  },
});

export default componentStyles;
