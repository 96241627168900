import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AssessmentIcon from '@material-ui/icons/AssessmentOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import getTheme from "assets/theme/theme.js";

import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const FeaturesMetricsSection = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = getTheme("light");
  return (
      <>
        <Box
          paddingTop="2rem"
          paddingBottom="5rem"
          component="section"
          className={classes.bgWhite}
        >
          <Container maxWidth={false}>
            <Grid container>
            <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <ImportExportIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Import in one click
                  </Typography>
                  <Box>        
                    Automatically import and track your own stocks by just searching for your eToro username
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <AssessmentIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Portfolio Analysis
                  </Typography>
                  <Box>        
                    Analyze your portfolio diversification, risk, and see breakdowns by geographies and industries
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <QueryBuilderOutlinedIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Extended Hours Tracking
                  </Typography>
                  <Box>        
                    Follow your stocks before- and after-hours. Track the price change and volume of extended hours trading
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} lg={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <LocationSearchingIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Real Time Tracking
                  </Typography>
                  <Box>        
                    Track what other traders are buying and selling in real time. Follow Insider Trading as well
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
  )
}

export default FeaturesMetricsSection;