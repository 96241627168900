import React from "react";
// javascipt plugin for creating charts
// react plugin used to create charts
import { HorizontalBar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/icons components

// core components
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { chartRiskContribution} from "variables/charts.js";

import componentStyles from "assets/theme/components/cards/charts/card-portfolio-ratios";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "contexts/DarkModeContext";
const useStyles = makeStyles(componentStyles);

// quick find: Asset Risk Contribution
function CardRiskContribution() {
  const classes = useStyles();
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  const [chartData, setChartData] = React.useState();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Analysis' });
  const { activeTheme } = useDarkMode();

  React.useEffect(() => {
    if (currentEtoroUserData?.userInstruments && currentEtoroUserData?.riskContributers) {
      const riskContributersLabels = [];
      const riskContributersData = [];
      const riskContributersGraph = currentEtoroUserData.riskContributers.contributers.slice(0, 15);
      riskContributersGraph.forEach(riskContributer => {
        let instrument = currentEtoroUserData.userInstruments.instruments.find(entry => entry.instrumentId === riskContributer.instrumentId);
        if (!instrument) {
          // if it's not a child position, check if risk comes from parent
          instrument = currentEtoroUserData.userInstruments.parentInstruments.find(entry => entry.instrumentId === riskContributer.instrumentId);
        }
        if (instrument) {
          riskContributersLabels.push(instrument.ticker);
          riskContributersData.push(riskContributer.riskContributionPct);
        }
      })
    
      const colours = riskContributersData.map((value) => value < 0 ? theme.palette.bsColors.NumberRed : theme.palette.bsColors.MainBlue);
      chartRiskContribution.data.labels = riskContributersLabels;
      chartRiskContribution.data.datasets[0].data = riskContributersData;
      chartRiskContribution.data.datasets[0].backgroundColor = colours;
      chartRiskContribution.options.scales.xAxes[0].gridLines.color = theme.palette.bsColors.ChartLines;
      setChartData(chartRiskContribution)
    }
  }, [currentEtoroUserData, activeTheme]); 
  
  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                  {t("analytics")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("riskContribution")}</Box>
                  <FeatureInfoWithBox description={t("assetRiskContributionTooltip")}/>
                </Box>
              </Grid>
              
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          
              <Box position="relative" height="350px">
              { chartData ?
              <HorizontalBar
                    data={chartData.data}
                    options={chartData.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  /> : <></> }              
              </Box>
            
        </CardContent>
      </Card>
    </>
  );
}

export default CardRiskContribution;
