import boxShadows from "assets/theme/box-shadow.js";
import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    backgroundColor: themeColors.secondary.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1rem",
    },
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  cardHeaderTitle: {
    color: themeColors.bsColors.MainBlue,
  },
  cardContent: {
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  checksBox: {
    paddingLeft:"0.5rem",
    paddingTop:"2rem",
    fontWeight:"600",
    lineHeight:"2.4rem",
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
      textAlign: "left",
      lineHeight:"2rem",
      paddingTop:"1rem",
      fontSize: theme.typography.h5.fontSize,
    },
  },
  locksBox: {
    paddingLeft:"0.5rem",
    paddingTop:"0.5rem",
    fontWeight:"600",
    lineHeight:"2.4rem",
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
      textAlign: "left",
      lineHeight:"2rem",
      paddingTop:"0.5rem",
      fontSize: theme.typography.h5.fontSize,
    },
  },
  header: {
    
    [theme.breakpoints.up("md")]: {
      paddingTop: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: "3rem",
      marginRight: "3rem",
    },
  },
  gridItem: {
    paddingTop:"8rem", 
    paddingBottom: "4rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem",
      textAlign: "center",
      marginLeft: "2rem",
      marginRight: "2rem",
    },
  },
  imgRegister: {
    borderTopLeftRadius: "calc(.375rem - 1px)",
    borderTopRightRadius: "calc(.375rem - 1px)",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    maxWidth: "25rem",
    objectFit: "cover",
    paddingTop: "3rem",
  },
  buttonImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  buttonRoot: {
    backgroundColor: themeColors.white.main,
    maxWidth: "8.5rem",
    color: themeColors.primary.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    borderColor: themeColors.white.main + "!important",
    "&:hover": {
      color: themeColors.gray[900],
      borderColor: themeColors.white.main + "!important",
      backgroundColor: themeColors.white.main,
    },
  },
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: themeColors.gray[600],
  },
  footerLinks: {
    color: themeColors.bsColors.MainBlue,
    textDecoration: "none",
  },
  checkIcon: {
    color: themeColors.bsColors.NumberGreen,
    width: 25,
    height: 25,
  },
  lockIcon: {
    color: themeColors.bsColors.NumberGreen,
    width: 20,
    height: 25,
  }
});

export default componentStyles;
