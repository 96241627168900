import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  appBarRoot: {
    backgroundColor: "#fff",
  },
  buttonRootPurchase: {
    backgroundColor: themeColors.bsColors.LogoLightBlue,
    borderColor: themeColors.bsColors.LogoLightBlue,
    color: themeColors.white.main,
    minWidth: "10.5rem",
    [theme.breakpoints.down("md")]: {
      backgroundColor: themeColors.bsColors.LogoLightBlue,
      borderColor: themeColors.bsColors.LogoLightBlue,
      color: themeColors.white.main,
    },
  },
  buttonLabelPurchase: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listRootRight: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
    },
  },
  listItemRoot: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    paddingLeft: "1.25rem",
    paddingRight: "0.25rem",
    paddingTop: ".625rem",
    paddingBottom: ".625rem",
    transition: "all .15s linear",
    fontWeight: "600",
    "& i": {
      marginRight: "0.25rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: ".5rem",
      paddingLeft: "1.5rem",
      paddingRight: ".5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      color: themeColors.bsColors.MainBlue,
      fontWeight: "600",
      justifyContent: "center",
      "&:hover": {
        color: themeColors.bsColors.LogoLightBlue,
      },
    },
  },
  headerImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  menuPaper: {
    width: "calc(100% - 2rem)",
  },
  outlineNone: {
    outline: "none!important",
  },
  flexDirectionColumn: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  buttonRootOrange: {
    color: themeColors.white.main,
    backgroundColor: themeColors.bsColors.Orange,
    borderColor: themeColors.bsColors.Orange,
    //fontSize: theme.typography.h4.fontSize,
    "&:hover": {
      backgroundColor: themeColors.bsColors.Yellow,
      borderColor: themeColors.bsColors.Yellow,
      color: themeColors.white.main,
    },
  },
  buttonCTANormal: {
    backgroundColor: themeColors.bsColors.LogoLightBlue,
    borderColor: themeColors.bsColors.LogoLightBlue,
    color: themeColors.white.main,
    "&:hover": {
      backgroundColor: themeColors.bsColors.MainBlue,
      borderColor: themeColors.bsColors.MainBlue,
      color: themeColors.white.main,
    },
  },
});

export default componentStyles;
