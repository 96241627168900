import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import strings from "../../../strings/en.js";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-coin-market-cap.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useService } from "contexts/ServiceContext";
import CustomCheckbox from "components/Custom/CustomCheckbox.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Coin", "Market Cap", "Potential Price", "Implied Upside"];

export default function CardCoinPerspective({currentEtoroUserData, cryptoData}) {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const [checked, setChecked] = React.useState(false);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CryptoDashboard' });

  function handleClick(e) {
    e.preventDefault();
    checked === true ? setChecked(false) : setChecked(true);
  }

  React.useEffect(() => {
    if(currentEtoroUserData?.marketInfo && currentEtoroUserData?.userInstruments) {
      new List(tableConatinerRef.current, {
        valueNames: ["coin", "market cap", "potential price", "implied upside"],
        listClass: "list",
      });
    }
  }, [currentEtoroUserData]);

  function MoneyFormat(labelValue) 
  {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

       ? ((Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
       // Six Zeroes for Millions 
       : Math.abs(Number(labelValue)) >= 1.0e+6

       ? ((Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
       // Three Zeroes for Thousands
       : Math.abs(Number(labelValue)) >= 1.0e+3

       ? ((Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

       : Math.abs(Number(labelValue));

   }
   function getPrice(ticker) {
    const asset = cryptoData?.latestCryptoData?.latestCryptoPrices.find(entry => entry.ticker === ticker);  
    if (typeof asset == "undefined"){
      return
    }
    else {
      return asset.price.toFixed(2)  // or undefined
    }
  }
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Crypto Market
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">What if a coin reaches BTC's Market Cap?</Box>
                  <FeatureInfoWithBox description={t("cryptoPerspectiveTooltip")}/>
                </Box>
                <Box color={theme.palette.bsColors.MainBlue} marginTop="0rem" fontSize="1rem">Bitcoin Market Cap: ${MoneyFormat(cryptoData?.latestCryptoData?.latestCryptoFundamentals[0].marketCap)}</Box>
              </Grid>
              <Grid item xs="auto" className={classes.gridItemRoot}>
                <CustomCheckbox checked={checked}  onClick={handleClick}/>
                <Box color={theme.palette.bsColors.MainBlue} fontSize={theme.typography.h5.fontSize} fontWeight="600" component="small">
                  My Coins
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        {cryptoData?.latestCryptoData?.latestCryptoFundamentals && cryptoData?.latestCryptoData?.latestCryptoFundamentals.length > 0 ?
        <TableContainer ref={tableConatinerRef}>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
            {(checked === true ? cryptoData?.latestCryptoData?.latestCryptoFundamentals?.filter((entry) => currentEtoroUserData?.userInstruments?.instruments.some(instrument => instrument.instrumentId == entry.instrumentId)) : cryptoData?.latestCryptoData?.latestCryptoFundamentals).map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        marginRight="1rem"
                        alt="..."
                        classes={{ root: classes.avatarRoot}}
                        src={prop.logo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="coin"
                        >
                          {prop.ticker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="market cap"
                      >
                      ${MoneyFormat(prop.marketCap)}
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes["tableCellRoot" + (prop.ticker == "BTC" ? "" : "Buy") ]}}
                  >
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="potential price"
                      >
                      {/* (BTC Market Cap / This coin Market cap) * this coin price */}
                      ${MoneyFormat(((getPrice(prop.ticker))*(cryptoData?.latestCryptoData?.latestCryptoFundamentals[0].marketCap / prop.marketCap)).toFixed(2))}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes["tableCellRoot" + (prop.ticker == "BTC" ? "" : "Buy")] }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="implied upside">
                      {/* (This coin potential price / This coin current price) - 1 (in percent) */}
                      +{(((cryptoData?.latestCryptoData?.latestCryptoFundamentals[0].marketCap / prop.marketCap)-1)*100).toFixed(1)}%
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
        </TableContainer>
        : <></> }
      </Card>
    </>
  );
}
