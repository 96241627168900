const componentStyles = (theme) => ({
  header: {
    background:
      "transparent",
      // "linear-gradient(87deg," + theme.palette.background.default + ",#e0eaf5)",
    [theme.breakpoints.up("md")]: {
      paddingTop: "10rem",
    },
  },
});

export default componentStyles;
