import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-pi-trades.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useService } from "contexts/ServiceContext";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Parent", "Ticker", "Type", "% of Portfolio", "Leverage", "Date (UTC)"];

// quick find: Latest Parent Trades
export default function CardParentTrades() {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const { currentEtoroUserData } = useEtoroUser();
  const { getCopySync } = useService();
  const [checked, setChecked] = React.useState(false);
  const [latestTrades, setLatestTrades] = React.useState();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CopySync' });

  React.useEffect(() => {
    if(currentEtoroUserData?.userInfo?.userName) {
      getCopySync(currentEtoroUserData.userInfo.userName).then(response => {
        setLatestTrades(response.data.latestParentTrades);
      });
    }
    return () => {
        setLatestTrades();
    };
  }, [currentEtoroUserData]);

  React.useEffect(() => {
    setLatestTrades(currentEtoroUserData?.copySyncData?.latestParentTrades);
    return () => {
        setLatestTrades();
    };
  }, []);

  function handleClick(e) {
    e.preventDefault();
    checked === true ? setChecked(false) : setChecked(true);
  }

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("realTimeTracking")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("latestParentTrades")}</Box>
                  <FeatureInfoWithBox description={t("parentTradesTooltip")}/>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer classes={{ root: classes.tableRoot }} ref={tableConatinerRef}>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              { latestTrades ? latestTrades.slice(0, 50).map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.avatarRoot}}
                        marginRight="1rem"
                        alt="..."
                        src={currentEtoroUserData?.userInstruments?.copyParentUsernames[prop?.username]?.media[0]?.uri}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="name"
                        >
                          {prop.username}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.avatarCompanyRoot}}
                        marginRight="0.5rem"
                        alt="..."
                        src={prop.logo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker"
                        >
                          {prop.ticker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="total position">
                      {prop.isBuy == true ? <span style={{color: "green"}}>Buy</span> : <span style={{color: "red"}}>Sell</span>}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " budget" }}>
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="invested"
                      >
                      {prop.investmentPct.toFixed(2)}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="leverage">
                      {prop.leverage}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="date">
                      {prop.openTradeDatetime}
                    </Box>
                  </TableCell>

                </TableRow>
              )) : <></> }
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}
