import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";

/**
 * @param {number} DEFAULT_PAGINATION Defines the number of items per page
 * @param {Object} showMore Defines how many pages are supposed to show at the given moment
 * @param {Function} setShowMore Sets the number of pages the is supposed to show after pressing Show More or Show Less
 * @param {number} tableSize Given to define when to show the buttons
 * @returns Component that shows Show More and Show less buttons
 */

export default function ShowMoreButton({DEFAULT_PAGINATION, showMore, setShowMore, tableSize}) {
    return (
        <TableCell>
            {showMore && showMore<tableSize ?
                    <Button onClick={() => setShowMore(showMore + DEFAULT_PAGINATION)}>
                        Show more
                    </Button> 
            : 
                <></>
            }
            {showMore !== DEFAULT_PAGINATION ? 
                    <Button onClick={()=> setShowMore(DEFAULT_PAGINATION)}>
                        Show less
                    </Button> 
            : 
                <></>
            }
        </TableCell>
    )
}