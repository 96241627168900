import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components


import componentStyles from "assets/theme/components/headers/features-header.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import FeatAssetRisk from './FeatAssetRisk';
import FeatIndustryBreakdown from './FeatIndustryBreakdown';
import FeatDividendFocus from './FeatDividendFocus';
import FeatPortfolioRatios from './FeatPortfolioRatios';

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const PortfolioInsightsSection = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = getTheme("light");
  return (
      <>
      <Box className={classes.header}>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={12} style={{textAlign:"center"}}>
                <Typography variant="h1" component="h1" className={classes.typographyH1Center}>
                  Our Approach to Portfolio Analysis
                </Typography>
                <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1.25rem"
                    marginBottom="3rem"
                    marginTop="0"
                    color={theme.palette.bsColors.MainBlue}
                  >
                    Bullsheet helps you analyse your Portfolio with advanced insights about<br/>
                    your risk and diversification across sectors and industries <br/>without having to manually update your stocks
                  </Box>
              </Grid>
              <FeatAssetRisk/>
              <FeatIndustryBreakdown/>
            </Grid>
          </Container>
        </Box>
        <FeatDividendFocus/>
        <FeatPortfolioRatios/>
        </Box>
      </>
  )
}

export default PortfolioInsightsSection;