import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components

// core components
import componentStyles from "assets/theme/components/headers/alternative-header.js";
import { Switch } from "@material-ui/core";
import CircleLoader from "react-spinners/CircleLoader";

const useStyles = makeStyles(componentStyles);

const Header = ({ section, subsection, type, parseChecked, checked}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div className={classes.header}>
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <Grid
            container
            component={Box}
            alignItems="center"
            paddingTop="1.5rem"
            paddingBottom="1.5rem"
          >
            <Grid item xs={7} lg={6}>
              <Typography
                variant="h2"
                component="h6"
                className={clsx(classes.displayInlineBlock, classes.mb0)}
              >
                {section}
              </Typography>
              
            </Grid>
            { type === 'AfterHours' ? 
            (<Grid item xs={12} lg={6} component={Box} textAlign="right">
              {/* use for buttons on right side if needed */}
              {"Portfolio "}<Switch checked={checked} onClick={parseChecked}/>{" Market Movers"} 
            </Grid>) : null }
            { type === 'Refresh' ? 
            (<Grid item xs={12} lg={6} component={Box} textAlign="right">
              <Box position="relative" style={{marginRight: "30px", marginTop: "-10px"}}>
                <CircleLoader color={theme.palette.bsColors.LogoLightBlue} loading={true} size={30} /> 
              </Box> 
            </Grid>) : null }
          </Grid>
        </Container>
      </div>
    </>
  );
};

Header.propTypes = {
  section: PropTypes.string,
  subsection: PropTypes.string,
};

export default Header;
