import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import { useService } from "contexts/ServiceContext";

// @material-ui/icons components

// core components

import componentStyles from "assets/theme/components/cards/dashboard/card-opening-hours.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const tableBody = [
  {
    country: "United States",
    marketName: "US Markets",
    schedule: "M-F, 9:30 - 16:00 (ET)",
  },
  {
    country: "United Kingdom",
    marketName: "London SE.",
    schedule: "M-F, 8:00 - 16:30 (GMT)",
  },
  {
    country: "France",
    marketName: "ENX Paris SE.",
    schedule: "M-F, 9:00 - 17:30 (CET)",
  },
  {
    country: "Denmark",
    marketName: "Copenhagen SE.",
    schedule: "M-F, 9:00 - 16:30 (CET)",
  },
  {
    country: "Germany",
    marketName: "Frankfurt SE.",
    schedule: "M-F, 9:00 - 17:30 (CET)",
  },
  {
    country: "Hong Kong",
    marketName: "Hong Kong SE.",
    schedule: "M-F, 9:30 - 16:00 (HKT)",
  },
  {
    country: "Saudi Arabia",
    marketName: "Saudi Arabia SE.",
    schedule: "Su-Th, 10:00 - 15:00 (AST)",
  },
  {
    country: "Bitcoin",
    marketName: "Crypto Market",
    schedule: "Always Open",
  },
];

function CardOpeningHours() {
  const classes = useStyles();
  const theme = useTheme();
  const {flags} = useService();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Markets' });

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("Market")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("openingHours")}</Box>
                  <FeatureInfoWithBox description={t("infoOpeningHoursTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
              
              </Grid>
            </Grid>
              }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer style={{overflowX: "hidden"}}>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {t("Market")}
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {t("Schedule")}
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {tableBody.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.flagRoot }}
                        marginRight="0.5rem"
                        alt="..."
                        src={flags[prop.country]}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box fontSize={theme.typography.h5.fontSize} component="span">
                          {prop.marketName}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontSize={theme.typography.h5.fontSize} component="span">
                      {prop.schedule}
                    </Box>
                  </TableCell>                  
                </TableRow>
              ))}
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}

export default CardOpeningHours;
