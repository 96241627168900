import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// @material-ui/icons components


import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);

const ReadyBottomCTA = () => {
  const classes = { ...useStyles(), ...useStylesBadge() };
  const theme = useTheme();
  return (
      <>
        <Box
          paddingTop="5rem"
          paddingBottom="6rem"
          component="section"
          className={classes.bgDefault}
        >
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12} md={6} style={{alignSelf:"center"}}>
                <Box
                  color={theme.palette.white.main}
                  fontWeight="300"
                  lineHeight="1.7"
                  marginBottom="1rem"
                >
                  <Typography
                    component="h2"
                    variant="h2"
                    className={classes.typographyH2}
                  >
                    Ready to superpower your portfolio?
                  </Typography>
                </Box>
                <Button
                      component={Link}
                      variant="contained"
                      to="/auth/register"
                      classes={{ root: classes.buttonCTANormal }}
                      color="default"
                      style={{marginTop:"1rem", fontSize: theme.typography.h3.fontSize}}
                    >
                      Sign Up for Free
                    </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                
              </Grid>
            </Grid>
          </Container>
          </Box>
      </>
  )
}

export default ReadyBottomCTA;