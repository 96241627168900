import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "assets/theme/components/cards/widgets/card-news-text-only.js";
import boxShadows from "assets/theme/box-shadow.js";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import ArrowForward from "@material-ui/icons/ArrowForward"

const useStyles = makeStyles(componentStyles);

function CardNewsTextOnly({ ticker, title, source, color, url}) {
  const classes = useStyles();
  const theme = useTheme();
  let utm = "?utm_source=bullsheet&utm_medium=referral";

  const { currentEtoroUserData } = useEtoroUser();
  let companyLogo = ""; 
  let asset = currentEtoroUserData?.userInstruments ? currentEtoroUserData.userInstruments.instruments.find(entry => entry.ticker === ticker) : undefined;
  if (!asset) {
    asset = currentEtoroUserData?.userInstruments.parentInstruments ? currentEtoroUserData.userInstruments.parentInstruments.find(entry => entry.ticker === ticker) : undefined;
  }
  function handleClick(){
    window.open(url + utm, "_blank");
  }
  if (asset) {
    companyLogo = asset.media[asset.media.findIndex(x => x.width == 150)].uri;
  }
  return (
    <>
      <Card
        classes={{ root: clsx(classes.cardRoot, classes[color]) }}
        elevation={0}
      >
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <Grid container component={Box} justifyContent="space-between">
            <Grid item xs={10} lg={9}>
              <Box
                component={Typography}
                variant="h6"
                color={theme.palette.bsColors.GlobalWhite + "!important"}
                marginBottom="0!important"
                marginTop="0!important"
                className={classes.typographyRootH5}
              >
                {ticker} - {source}
              </Box>
              {title.length < 66 ?
              <Box
                component={Typography}
                variant="h5"
                fontWeight="600!important"
                marginBottom="0!important"
                marginTop="0!important"
                color={theme.palette.bsColors.GlobalWhite + "!important"}
              >
                  {title}
              </Box>
              :
              <Box
                component={Typography}
                variant="h5"
                fontWeight="600!important"
                marginBottom="0!important"
                marginTop="0!important"
                color={theme.palette.bsColors.GlobalWhite + "!important"}
              >
                  {title.substring(0,62)+"..."}
                  </Box>

                }
            </Grid>
            <Grid item xs={2} lg={3}>
              
                  <Box
                    component={Avatar}
                    classes={{ root: classes.tableLogo }}
                    alt="..."
                    src={companyLogo}
                  />
                  <Button onClick={handleClick}
                    variant="text"
                    disableRipple
                    component={Box}
                    padding="0!important"
                    style={{float:"right", color: theme.palette.bsColors.GlobalWhite, minWidth:"40px", right:"3px", bottom:"10px", position:"absolute"}}
                  >
                    <ArrowForward />
                  </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

CardNewsTextOnly.defaultProps = {
  color: "bgGradientError",
};

CardNewsTextOnly.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.oneOf([
    "bgGradientDefault",
    "bgGradientError",
    "bgGradientSuccess",
    "bgGradientPrimary",
    "bgGradientInfo",
    "bgGradientWarning",
    "bgGradientLightBlue",
    "bgGradientLogoMainBlue",
  ]),
};

export default CardNewsTextOnly;
