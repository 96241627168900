import boxShadows from "assets/theme/box-shadow.js";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const componentStyles = (theme) => ({
  appBarRoot: {
    borderBottom:
      "1px solid rgba(" + hexToRgb(theme.palette.dark.main) + ", 0.08)",
    backgroundColor: theme.palette.transparent.main,
  },
  brandTitle: {
    textTransform: "uppercase",
    margin: "0",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  searchBoxShow: {
    display: "flex!important",
    marginRight: "1rem",
    "& div:first-of-type": {
      flex: 1,
    },
  },
  searchBox: {
    borderColor: theme.palette.bsColors.MainBlue,
    borderRadius: "0.75rem",
    height: "2.5rem",
    border: "0px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.bsColors.MainBlue) + ",0.9)",
    //backgroundColor: "#F8F9FE",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    color: theme.palette.gray[400],
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchBoxAlt: {
    borderColor: theme.palette.bsColors.LogoLightBlue,
    borderRadius: "0.75rem",
    height: "2.5rem",
    border: "0px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.bsColors.LogoLightBlue) + ",0.9)",
    //backgroundColor: "#F8F9FE",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    color: theme.palette.gray[400],
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "&:hover": { 
      transform: "scale(1.02)",
      transition: "0!important",
      boxShadows:"none"
    },
  },
  searchIcon: {
    color: theme.palette.gray[400],
    marginRight: "0.5rem",
    marginLeft: "1rem",
  },
  searchClose: {
    color: theme.palette.gray[200],
    marginRight: "1rem",
    marginLeft: "-.5rem",
  },
  searchInput: {
    color: theme.palette.gray[200],
    '@supports (-webkit-touch-callout: none)': {
      /* CSS specific to iOS devices */ 
      fontSize: '16px',
    },
    width: "9rem",
    backgroundColor: "initial",
    border: 0,
    boxShadow: "none",
    padding: "0",
    marginLeft: "1.5rem",
    height: "2.5rem"
  },
  searchInputButton: {
    border: 0, 
    borderRadius: "0.75rem", 
    height: "2.5rem",
    width: "2rem",
    backgroundPosition: "center", 
    marginRight: "0rem",
    marginLeft: "0.5rem",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80px", 
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
    },
    "&:hover": { 
      transform: "scale(1.05)"
    },
  },
  searchInputButtonDisabled: {
    border: 0, 
    borderRadius: "0.75rem", 
    height: "2.5rem",
    width: "2rem",
    backgroundPosition: "center", 
    marginRight: "0rem",
    marginLeft: "0.5rem",
    cursor: "pointer",
    backgroundSize: "70px", 
    backgroundRepeat: "no-repeat",
    backgroundImage: require("assets/img/icons/common/eToro-icon-stretch.png").default,
    backgroundColor: "#989898 !important", //Same color as grey filter on disabled eToro logo
    backgroundBlendMode: "luminosity",

    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
    },
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  marginLeftAuto: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  marginLeftNone: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
    },
  },
  displayNone: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  alertSuccess: {
    background: theme.palette.bsColors.NumberGreen+ " !important",
    color: "white !important",
  },
  alertWarning: {
    background: theme.palette.bsColors.Orange+ " !important",
    color: "white !important",
  },
  alertDanger: {
    background: theme.palette.bsColors.NumberRed+ " !important",
    color: "white !important",
  },
});

export default componentStyles;
