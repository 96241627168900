import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import getTheme from "assets/theme/theme.js";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components


import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const AutoUpdateSection = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = getTheme("light");
  return (
      <>
      <Box className={classes.bgDefault}>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={12} style={{textAlign:"center"}}>
                <Typography variant="h1" component="h1" className={classes.typographyH2}>
                  One-Click<br/> Portfolio Manager
                </Typography>
                <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1.25rem"
                    marginBottom="3rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Bullsheet's Portfolio Manager is designed for individuals who want<br/>
                    insightful information about their portfolio without all the <br/>manual labour of updating stocks everytime
                  </Box>
              </Grid>
              <Box paddingTop="1rem" paddingBottom="4.5rem" component="section">
                <Container maxWidth="xl">
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Box
                        component="img"
                        alt="..."
                        maxWidth="100%"
                        height="auto"
                        className={classes.imgBox}
                        src={require("assets/img/theme/extendedHoursScreenshot.png").default}
                      ></Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.prMd5}>
                        <Typography variant="h1" component="h1" className={classes.typographyH2}>
                          Track your stocks on extended hours
                        </Typography>
                        <Box
                          component="p"
                          fontWeight="300"
                          lineHeight="1.7"
                          fontSize="1rem"
                          marginBottom="1rem"
                          marginTop="0"
                          color={theme.palette.white.main}
                        >
                          Track <b>your</b> stocks before and after hours
                          with our Extended Hours Screen. <br/><br/>
                          See which of your stocks are jumping up or down the most before or after market.
                          <br/><br/>
                          Use the volume column to check for high trading volumes for your stocks on extended hours.
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
              <Grid item xs={12} md={6} className={classes.orderMd2}>
                <Box
                  component="img"
                  alt="Automate your Etoro Portfolio"
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/portfolioOverview_screenshot.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderMd1}>
                <div className={classes.prMd5}>
                  <Typography variant="h2" component="h2" className={classes.typographyH2}>
                    Insightful Portfolio Overview
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Visualize your assets by sector, type, dividend yield and much more.
                    <br/><br/>
                    Check which of your stocks are going up or down the most today.
                    <br/><br/>
                    Toggle between invested and value percentages and keep track of the differences
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  alt="..."
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/newsElon.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.prMd5}>
                  <Typography variant="h1" component="h1" className={classes.typographyH2}>
                    Latest News
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Get all the latest News for <b>your</b> stocks and visualize
                    them easily with our News Dashboard. 
                    <br/><br/>
                    Find articles and insights you might have otherwise missed.
                    <br/><br/>
                    Bullsheet delivers you the most recent articles about your stocks from the best online sources.
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} className={classes.orderMd2}>
                <Box
                  component="img"
                  alt="..."
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/calendar_screenshot.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderMd1}>
                <div className={classes.prMd5}>
                  <Typography variant="h1" component="h1" className={classes.typographyH2}>
                    A Calendar that tracks your stocks
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    Keep track of your stocks' most important dates.
                    <br/><br/>
                    Bullsheet's calendar lets you track earnings, ex-dividend and dividend payment dates.
                    <br/><br/>
                    The calendar auto-updates with new information from an updating global database.
                  </Box>
                  <Box
                    component={Link}
                    to="/auth/pricing"
                    color={theme.palette.bsColors.LogoLightBlue}
                    marginTop="3rem"
                    fontWeight="600"
                    className={classes.infoAnchor}
                  >
                    Check our premium package ➡
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  alt="..."
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/copySync_screenshot.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.prMd5}>
                  <Typography variant="h1" component="h1" className={classes.typographyH2}>
                    Get more insights about who you copy
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.white.main}
                  >
                    How does your portfolio look like if you breakdown the assets of all the people that you copy? 
                    <br/><br/>
                    Find out if you are you in sync with the people you copy.
                    <br/><br/>
                    Check the latest trades from the people you copy.
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* <Box marginBottom="-5rem" marginTop="3rem">
          <Container maxWidth="xl">
            <Grid container className={classes.justifyContentCenter}>
              <Grid item xs={12}>
                  <Typography variant="h3" component="h3">
                    ... and many more features
                  </Typography>
                <Grid container>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={Event}
                      color="red"
                      title="Calendar"
                      subtitle="Earnings and Dividend Dates for your stocks"
                      badges={["Earnings", "Ex-Dividend"]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={Update}
                      color="green"
                      title="Real Time Trading"
                      subtitle="Check what Elite Popular Investors have been buying"
                      badges={["PI trades", "Insiders"]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={TrendingUp}
                      color="blue"
                      title="Overall Market"
                      subtitle="How is the market performing today?"
                      badges={["Winners", "Losers","News"]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <CardInfoBadges
                      icon={SaveAlt}
                      color="orange"
                      title="Export Portfolio"
                      subtitle="Export your portfolio quickly to view it offline"
                      badges={[".xlsx", ".csv"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box> */}
        </Box>
      </>
  )
}

export default AutoUpdateSection;