import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import hexToRgb from "assets/theme/hex-to-rgb.js";
import componentStyles from "assets/theme/components/headers/pricing-header.js";

const useStyles = makeStyles(componentStyles);

const PricingHeader = ({ title, description }) => {
  const classes = useStyles();
  const theme = getTheme("light");
  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="8rem"
        paddingBottom="8rem"
      >
        <Container maxWidth="xl">
          <Box marginBottom="2rem" textAlign="left">
            <Box
              component={Grid}
              container
              justifyContent="left"
              color={theme.palette.bsColors.MainBlue}
            >
              <Grid item lg={6} md={6} xs={12}>
                {title && (
                  <Typography component="h1" variant="h1" color="inherit">
                    {title}
                  </Typography>
                )}
                {description && (
                  <Box
                    component="p"
                    fontWeight="400"
                    color={
                      "rgba(" + hexToRgb(theme.palette.bsColors.MainBlue) + ",0.9)"
                    }
                    lineHeight="1.7"
                    fontSize={theme.typography.h3.fontSize}
                  >
                    {description}
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        </Container>
        
      </Box>
    </>
  );
};

PricingHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default PricingHeader;
