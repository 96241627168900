import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// @material-ui/icons components

// core components
import componentStyles from "assets/theme/components/cards/pricing/card-pricing.js";
import buttonsStyles from "assets/theme/components/button.js";
import { Link } from "react-router-dom";
import CircleLoader from "react-spinners/CircleLoader";
import getTheme from "assets/theme/theme";


const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);

function CardPricingFree({handleSubscribe, loading}) {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const theme = getTheme("light");

  return (
    <>
      <Card className={classes.cardRoot}>
        <img
          alt="..."
          src={require("assets/img/theme/weak_bull.png").default}
          className={classes.cardImgTop}
        />
        <CardHeader
          title="Free"
          className={classes.bgInherit}
          titleTypographyProps={{
            className: classes.titleInfo,
            component: "h4",
            variant: "h4",
          }}
          subheader="I get all of this for free?!"
          subheaderTypographyProps={{
            className: classes.subheaderInfo,
            component: "h6",
            variant: "h6",
          }}
        ></CardHeader>
        
        <CardContent className={classes.cardContentRoot}>
          <div className={classes.display2}>€0</div>
          <Box component="span" color={theme.palette.gray[600]} fontSize={theme.typography.h5.fontSize}>
            per month
          </Box>
          <Box
            component="ul"
            paddingLeft="0"
            className={classes.listStyleNone}
            margin="1.5rem 0"
            textAlign="left"
          >
            
          </Box>
          <Box marginBottom="1rem" marginTop="3rem" textAlign="center">
            <Button
              variant="contained"
              classes={{
                root: classes.buttonCTANormalUngated,
              }}
            >
              <Link
              to="/auth/register"
              style={{color: "white"}}
              >
                { loading ? "" : "Get Started" }
                  <Box position="relative" style={{marginRight: "27px", marginTop: "-20px"}}>
                    <CircleLoader color={theme.palette.bsColors.MainBlue} loading={loading} size={30} /> 
                  </Box>   
              </Link>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardPricingFree;
