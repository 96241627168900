const componentStyles = (theme) => ({
  cardRoot: {
    height: "6rem",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      height: "5rem",
    },

  },
  cardContentRoot: {
    marginTop: "0.3rem",
    padding: "1rem 1.5rem !important",
  },
  typographyRootH5: {
    display: "flex",
    marginTop: "0.3rem",
  },
  typographyH4White: {
    color: theme.palette.white.main,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: theme.typography.h4.fontSize,
    textAlign: "left",
    lineHeight: 1.5,
  },
  typographyH5White: {
    color: theme.palette.white.main,
    fontWeight: "600!important",
    fontSize: theme.typography.h5.fontSize,
    textAlign: "left",
    lineHeight: 1.5,
  },
  typographyH4Blue: {
    color: theme.palette.bsColors.MainBlue,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: theme.typography.h4.fontSize,
    textAlign: "left",
    lineHeight: 1.5,
  },
  typographyH5Blue: {
    color: theme.palette.bsColors.MainBlue,
    fontWeight: "600!important",
    fontSize: theme.typography.h5.fontSize,
    textAlign: "left",
    lineHeight: 1.5,
  },
  bgWhite: {
    backgroundColor: theme.palette.white.main,
  },
  bgGradientDefault: {
    backgroundImage:
    "linear-gradient(135deg, "+ hexToRGB(theme.palette.background.default, 0.6) + " 0%, " + hexToRGB(theme.palette.background.default, 0.40) + " 95%)",
    backdropFilter: "blur(8px) !important",
    color: theme.palette.bsColors.MainBlue,
  },
  bgGradientError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      ",#f56036)!important",
  },
  bgGradientSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      ",#2dcecc)!important",
  },
  bgGradientPrimary: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ",#825ee4)!important",
  },
  bgGradientInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ",#1171ef)!important",
  },
  bgGradientWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      ",#fbb140)!important",
  },
  bgGradientGlass: {
    backgroundImage:
    "linear-gradient(127.43deg, "+ hexToRGB(theme.palette.bsColors.LogoLightBlue, 0.7) + " 15.1%, " + hexToRGB(theme.palette.bsColors.LogoLightBlue, 0.50) + " 69.51%)",
    backdropFilter: "blur(8px) !important",
  },
  bgGradientGlass2: {
    backgroundImage:
    "linear-gradient(135deg, "+ hexToRGB(theme.palette.background.default, 0.6) + " 0%, " + hexToRGB(theme.palette.background.default, 0.40) + " 95%)",
    backdropFilter: "blur(8px) !important",
    color: theme.palette.bsColors.MainBlue,
  },
  bgGradientDefaultTransparent: {
    background:
      "linear-gradient(87deg," +
      hexToRGB(theme.palette.bsColors.MainBlue,0.8) +
      "," + hexToRGB(theme.palette.bsColors.LogoLightBlue,0.9) + ")!important",
  },
});

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export default componentStyles;
