import { useContext, useEffect, useState, createContext } from 'react';

const DarkModeContext = createContext();

export function useDarkMode() {
  return useContext(DarkModeContext);
}

export function DarkModeProvider({children}) {
  const [activeTheme, setTheme] = useState('light');
  const [loading, setLoading] = useState(false);

  const setMode = (mode) => {
    try {
      window.localStorage.setItem('activeTheme', mode);
    } catch {
      /* do nothing */
    }
    setTheme(mode);
  };

  const toggleTheme = () => {
    setLoading(true);
    activeTheme === 'light' ? setMode('dark') : setMode('light');
  };

  useEffect(() => {
    if (loading) { 
      setLoading(false);
      return;
    }
    try {
      const localTheme = window.localStorage.getItem('activeTheme');
      localTheme ? setTheme(localTheme) : setMode('light');
    } catch {
      setMode('light');
    }
    setLoading(false);
  }, [activeTheme]);

  const value = {
    activeTheme,
    toggleTheme
  }

  return (
      <DarkModeContext.Provider value={value}>
          {!loading && children}
      </DarkModeContext.Provider>
  )
};