/* eslint-disable react/jsx-no-target-blank*/
import React from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// @material-ui/icons components
// import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
// core components

import FeaturesHeader from "components/Headers/FeaturesHeader.js";

import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import ReadyBottomCTA from "./auth/Homepage/ReadyBottomCTA";
import FeaturesMetricsSection from "./auth/Features/FeaturesMetrics";
import { Helmet } from "react-helmet";
import AutoUpdateSection from "./auth/Features/AutoUpdateSection";
import PortfolioInsightsSection from "./auth/Features/PortfolioInsightsSection";
import AdditionalSuperpowersSection from "./auth/Features/AdditionalSuperpowersSection";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);


export default function Features() {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = useTheme();
  const location = useLocation();
  React.useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
  }, [location]);
  return (
    <>
      <Helmet>
        <title>Extended Hours, News, Portfolio Breakdowns and much more | Bullsheet</title>
        <meta name="description" content="Bullsheet tracks your stocks in Extended Hours and offers you several different analyses and real time information for your stocks." />
      </Helmet>
      <Box position="relative">
        <FeaturesHeader />
        <FeaturesMetricsSection/>
        <AutoUpdateSection/>
        <PortfolioInsightsSection/>
        <AdditionalSuperpowersSection/>
        {/* <AutomaticPortfolioImportSection/>
        <PricingHomepageSection/>
        <JoinNewsletterSection/> */}
        <ReadyBottomCTA/>
      </Box>
    </>
  );
}
