import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from '@material-ui/core/Switch';
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';

// @material-ui/icons components
import Check from "@material-ui/icons/Check";
import strings from "../../strings/en.js";

// core components
import componentStyles from "assets/theme/views/auth/pricing.js";
import cardGroupStyles from "assets/theme/components/cards/card-group.js";
import CardPricingElite from "components/Cards/Pricing/CardPricingElite";
import CardPricingChampion from "components/Cards/Pricing/CardPricingChampion";
import CardPricingFree from "components/Cards/Pricing/CardPricingFree";
import TrendingUp from "@material-ui/icons/TrendingUp";
import { Clear } from "@material-ui/icons";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import { stripePromise } from "contexts/AuthContext";
import { useAuth } from "contexts/AuthContext.js";
import { useHistory } from "react-router-dom"
import getTheme from "assets/theme/theme.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardGroup = makeStyles(cardGroupStyles);

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 56,
    height: 32,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.bsColors.LogoLightBlue,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.bsColors.LogoLightBlue,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.bsColors.LogoLightBlue,
        borderColor: theme.palette.bsColors.LogoLightBlue,
        color: theme.palette.bsColors.LogoLightBlue,
      },
    },
  },
  thumb: {
    width: 28,
    height: 28,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.bsColors.LogoLightBlue}`,
    borderRadius: 32 / 2,
    opacity: 0.6,
    backgroundColor: theme.palette.bsColors.LogoLightBlue,
  },
  checked: {},
}))(Switch);

function Pricing() {
  const classes = {
    ...useStyles(),
    ...useStylesCardGroup(),
  };
  const theme = getTheme("light");
  const { handleSubscribe } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const tableRows = [
    {
      FIELD1: "Asset Breakdown",
      description: strings.assetBreakdown,
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Daily Performance",
      description: strings.dailyPerformance,
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Assets by Geography",
      description: strings.stocksByGeography,
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Your Stocks' News",
      description: strings.stockNews,
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Holdings Analysis",
      description: strings.cardHoldings,
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Earnings and Dividend Calendar",
      description: strings.infoCalendar,
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Markets Overview",
      description: strings.cardBiggestWinners + " " + strings.cardBiggestLosers,
      FIELD2: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "PI Trades Tracking",
      description: strings.popularInvestorsTrades,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Insider Trading for your stocks",
      description: strings.infoInsiderTrading,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Copy Portfolio Breakdown",
      description: strings.effectivePortfolio,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Latest Parent Trades",
      description: strings.parentTradesTooltip,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Crypto Dashboard & Whales",
      description: strings.cryptoDashboardInfo,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Extended Hours",
      description: strings.porfolioExtendedHours,
      FIELD2: "All Stocks (for now)",
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Estimated Dividend Yield",
      description: strings.estimatedDividendYield,
      FIELD2: "Estimated Total + Top 5 Stocks",
      FIELD3: "Full Breakdown",
      FIELD4: "Full Breakdown",
    },
    {
      FIELD1: "Basic Sector Breakdown",
      description: strings.sectorBreakdown,
      FIELD2: "Portfolio Sector Breakdown",
      FIELD3: "Breakdown vs Market",
      FIELD4: "Breakdown vs Market",
    },
    {
      FIELD1: "Portfolio Risk Overview",
      description: strings.portfolioRiskOverview,
      FIELD2: "Portfolio Risk in 6 Clusters",
      FIELD3: "Risk Score of Individual Stocks",
      FIELD4: "Risk Score of Individual Stocks",
    },
    // {
    //   FIELD1: "Portfolio Dividend Analysis",
    //   description: strings.portfolioDividends,
    //   FIELD2: 
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.error.main}
    //       component={Clear}
    //     />,
    //   FIELD3: (
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.success.main}
    //       component={Check}
    //     />
    //   ),
    //   FIELD4: (
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.success.main}
    //       component={Check}
    //     />
    //   ),
    // },
    // {
    //   FIELD1: "Portfolio Sectors vs Market",
    //   description: strings.sectorDiversification,
    //   FIELD2: 
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.error.main}
    //       component={Clear}
    //     />,
    //   FIELD3: (
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.success.main}
    //       component={Check}
    //     />
    //   ),
    //   FIELD4: (
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.success.main}
    //       component={Check}
    //     />
    //   ),
    // },
    {
      FIELD1: "Portfolio Ratios vs Market",
      description: strings.portfolioRatiosVsMarket,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Asset Risk Contribution",
      description: strings.assetRiskContribution,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    // {
    //   FIELD1: "Risk Score for your Stocks",
    //   description: strings.assetRisk,
    //   FIELD2: 
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.error.main}
    //       component={Clear}
    //     />,
    //   FIELD3: (
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.success.main}
    //       component={Check}
    //     />
    //   ),
    //   FIELD4: (
    //     <Box
    //       width="1.25rem!important"
    //       height="1.25rem!important"
    //       color={theme.palette.success.main}
    //       component={Check}
    //     />
    //   ),
    // },
    {
      FIELD1: "Industry Breakdown",
      description: strings.industryBreakdown,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Export Your Portfolio",
      description: strings.exportPortfolio,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Search different usernames",
      description: strings.searchUsernames,
      FIELD2: 
        "Unlimited searches",
      FIELD3: "Unlimited searches",
      FIELD4: "Unlimited searches",
    },
    {
      FIELD1: "ETF Details",
      description: strings.etfInfo,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Stock Screener",
      description: strings.screenerInfo,
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
    {
      FIELD1: "Early Access to Features",
      description: "We love you Sponsors.",
      FIELD2: 
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />,
      FIELD3: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.error.main}
          component={Clear}
        />
      ),
      FIELD4: (
        <Box
          width="1.25rem!important"
          height="1.25rem!important"
          color={theme.palette.success.main}
          component={Check}
        />
      ),
    },
  ];

  const [isChecked, setChecked] = React.useState({isChecked:true});
  const ChangeCheckedState = () => {
    setChecked(prevState => ({
        isChecked: !prevState.isChecked,
    }));
  }

  function handleSubscribeWithLoader(pricing) {
    setLoading(true);
    handleSubscribe(pricing);
    setLoading(true);
  }

  return (
    <>
      {/* Page content */}
      <Helmet>
        <title>Pricing Plans | Bullsheet</title>
        <meta name="description" content="Bullsheet has a freemium pricing model. Track your eToro portfolio's stocks, cryptos & other assets. Pro plan offers you more tools to analyze your portfolio." />
      </Helmet>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="3rem"
        paddingBottom="2rem"
      >
        <Container maxWidth="xl">
          <Box>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12}>
                <Box marginTop="6rem">
                  <Typography
                    variant="h1"
                    component="h1"
                    className={classes.typographyH1}
                  >
                    Bullsheet Pricing Plans
                  </Typography>
                  <Box marginTop="1.5rem" textAlign="-webkit-center">
                    <Typography
                      variant="h3"
                      component="h2"
                      className={classes.heroSubheader}
                    >
                      Load up on affordable superpowers for your portfolio management
                    </Typography>
                  </Box>
                  <Box marginTop="3rem" textAlign="center" marginBottom="5rem">
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.typographyH3}
                    >
                      Works with:{"      "}
                    <img
                      alt="eToro"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Etoro_logo.svg/1200px-Etoro_logo.svg.png"
                      width="80rem"
                      style={{verticalAlign:"text-bottom", marginLeft:"0.2rem"}}
                    />
                    </Typography>
                      <Typography component="h3" variant="h3" style={{marginTop:"3rem", marginBottom:"18rem"}}>
                        <Grid component="label" container style={{justifyContent:"center"}} alignItems="center" spacing={2}>
                          <Grid item><Typography className={classes.typographyH3}>Monthly</Typography></Grid>
                          <Grid item>
                            <AntSwitch 
                              // classes={{
                              //   switchBase: classes.switchStyled.switchBase,
                              //   checked: classes.switchStyled.checked,
                              //   track: classes.switchStyled.track,
                              // }} 
                              checked={isChecked.isChecked} 
                              onChange={() => ChangeCheckedState()} 
                              name="checkedYearly" 
                            />
                          </Grid>
                          <Grid item><Typography className={classes.typographyH3}>Yearly </Typography>
                          <Box 
                            alignSelf="center"
                            paddingLeft="0.5rem"
                            paddingRight="0.5rem"
                            marginTop="0.25rem"
                            fontSize="0.625rem"
                            fontWeight="600"
                            style={{ borderRadius:"0.2rem", maxWidth:"7rem", color:theme.palette.white.main ,backgroundColor:theme.palette.bsColors.LogoLightBlue}}
                            >
                              3 Months Free
                          </Box>
                          </Grid>
                          
                        </Grid>
                      </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        className={classes.pricingBackground}
        position="relative"
        paddingTop="3rem"
        paddingBottom="2rem"
      >
      <Container
        component={Box}
        maxWidth="xl"
        marginTop="3rem"
        paddingBottom="3rem"
        position="relative"
        zIndex="101"
      >
        <Box component={Grid} container justifyContent="center">
          <Grid item xs={12} lg={11}>
            <Box
              marginBottom="3rem"
              marginTop="-15rem"
              className={clsx(classes.cardGroup, classes.flexMdRow)}
            >
              <CardPricingFree handleSubscribe={handleSubscribeWithLoader} loading={loading} />
              <CardPricingChampion handleSubscribe={handleSubscribeWithLoader} loading={loading} price={isChecked.isChecked? 5.99 : 7.99} type={isChecked.isChecked? "yearly" : "monthly"}/>
              <CardPricingElite handleSubscribe={handleSubscribeWithLoader} loading={loading} price={isChecked.isChecked? 14.99 : 19.99} type={isChecked.isChecked? "yearly" : "monthly"}/>
              
            </Box>
          </Grid>
        </Box>
        <Box
          display="flex"
          paddingLeft="1rem"
          paddingRight="1rem"
          marginTop="3rem"
          className={classes.justifyContentLgCenter}
          color={theme.palette.bsColors.MainBlue}
          alignItems="center"
        >
          <div>
            <Avatar className={classes.avatarRoot}>
              <Box
                component={TrendingUp}
                color={theme.palette.white.main}
                width="1.5rem!important"
                height="1.5rem!important"
              />
            </Avatar>
          </div>
          <Grid item xs={12} lg={10}>
            <Box
              component="p"
              lineHeight="1.7"
              marginBottom="1rem"
              marginTop="1rem"
              fontWeight="600"
            >
              Don't be surprised if you frequently see new features being added here.
              We are data lovers and try to design insightful cards every month. Paying yearly
              protects you against potential price increases.
            </Box>
          </Grid>
        </Box>
        </Container>
      </Box>
        <Grid component={Box} justifyContent="center" textAlign="-webkit-center">
          <Grid item xs={12} lg={10}>
            <Box 
              textAlign="center"
              color={theme.palette.white.main}
              fontSize={theme.typography.h2.fontSize}
              marginTop="4rem"
            >
              Bullsheet Features Compared
            </Box>
            <TableContainer component={Box} marginTop="3rem">
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell algin="left" className={classes.tableCellHead}>
                      Features
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Free Version
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Premium Version
                    </TableCell>
                    <TableCell align="center" className={classes.tableCellHead}>
                      Sponsor Version
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((prop, key) => (
                    <TableRow key={key} className={classes.tableRowBody}>
                      <TableCell align="left" className={classes.tableCellBody}>
                        {prop.FIELD1}
                        <FeatureInfoWithBox description={prop.description}/>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellBody}
                      >
                        {prop.FIELD2}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellBody}
                      >
                        {prop.FIELD3}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCellBody}
                      >
                        {prop.FIELD4}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      
    </>
  );
}

export default Pricing;
