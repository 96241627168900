import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
// core components

import componentStyles from "assets/theme/components/cards/cards/card-team-member-info.js";
import componentStylesButtons from "assets/theme/components/button.js";
import boxShadows from "assets/theme/box-shadow.js";

const useStylesButtons = makeStyles(componentStylesButtons);
const useStyles = makeStyles(componentStyles);

const socialButtonColors = [
  {
    icon: "fab fa-twitter",
    text: "Twitter",
    color: "buttonContainedEtoro",
  },
  {
    icon: "fab fa-facebook",
    text: "Facebook",
    color: "buttonContainedFacebook",
  },
  {
    icon: "fab fa-dribbble",
    text: "Dribbble",
    color: "buttonContainedDribbble",
  },
];

function CardAboutMember({image, name, etoro, linkedin}) {
  const classes = { ...useStyles(), ...useStylesButtons() };
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardContent>
          <Box
            component="img"
            src={image}
            alt="..."
            maxWidth="140px"
            borderRadius="50%"
            marginRight="auto"
            marginLeft="auto"
            display="block"
            boxShadow={boxShadows.boxShadow + "!important"}
            className={classes.profileImage}
          />
          <Box textAlign="center" paddingTop="1.5rem">
            <Typography variant="h3">@{etoro}</Typography>
            <Box
              component={Typography}
              variant="h5"
              fontWeight="300!important"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {name}
              <a href={"https://www.linkedin.com/in/"+ linkedin} target="_blank">
                <img style={{marginLeft:"0.5rem",maxWidth:"1.5rem"}} src="https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Bug.svg.original.svg"/>
              </a>
            </Box>
            <Box marginTop="1rem">
            <Button
                      component={Link}
                      variant="contained"
                      to={{ pathname: "https://www.etoro.com/people/" + etoro }}
                      target="_blank"
                      rel="noreferrer"
                      classes={{ root: classes.buttonContainedEtoro }}
                    >
                      View eToro Profile
                    </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardAboutMember;
