import React, { useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Done from "@material-ui/icons/Done";
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import Typography from "@material-ui/core/Typography";
import getTheme from "assets/theme/theme.js";

import styled from "styled-components";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import componentStyles from "assets/theme/views/auth/register.js";
import componentStylesButtons from "assets/theme/components/button.js";
import {
  useAuth,
  gmailProvider,
  facebookProvider,
  microsoftProvider,
} from "../../contexts/AuthContext";
import { Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const InputWrapper = styled.div`
  .MuiFilledInput-root {
    background-color: white !important;
  }
  .MuiInputBase-input {
    background-color: white !important;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      font-size: 16px;
    }
    color: ${(props) => props.theme.palette.gray[600]};
  }
  .MuiInputAdornment-root {
    background-color: white !important;
    color: ${(props) => props.theme.palette.gray[500]};
  }
`;

function Register() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = getTheme("light");
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const privacyPolicyRef = useRef();
  const { signInWithSocialMedia, signup, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [createAccountButtonEnabled, setCreateAccountButtonEnabled] = useState(false);
  const history = useHistory();

  function passwordsMatch(pw1, pw2) {
    return pw1 && pw2 && passwordRef.current.value === passwordConfirmRef.current.value;
  }

  async function handleOnClick(e, provider) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await signInWithSocialMedia(provider);
    } catch (e) {
      setError("Failed to create an account. " + e);
    }
    setLoading(false);
  }

  function handleInputChange(e) {
    e.stopPropagation();

    setError("");

    if (emailRef.current.value && privacyPolicyRef.current.checked) {
      setCreateAccountButtonEnabled(true);
    } else {
      setCreateAccountButtonEnabled(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      const newUser = await signup(emailRef.current.value, passwordRef.current.value);
      if (!newUser.user.emailVerified) {
        newUser.user.sendEmailVerification();
      }
    } catch (e) {
      if (e.code === "auth/weak-password") {
        setError("Passwords should be at least 6 characters!");
      } else if (e.code === "auth/invalid-email") {
        setError("Invalid email adress!");
      } else if (e.code === "auth/email-already-in-use") {
        setError("Email already exists!");
      } else {
        setError("Failed to create an account. " + e);
      }
    }
    setLoading(false);
  }

  React.useEffect(() => {
    // if user is already logged in, redirect to dashboard
    if (currentUser) {
      localStorage.getItem("cachedEtoroUserData")
        ? history.push("/app/dashboard")
        : history.push("/auth/lock");
    }
  }, []);

  return (
    <Grid
      container
      style={{ background: "linear-gradient(180.04deg, #FFFFFF 15.84%, #B3CEE8 240.04%)" }}
    >
      <Helmet>
        <title>Register | Bullsheet</title>
        <meta
          name="description"
          content="Create your Bullsheet Account to track your eToro portfolio. Follow and analyze your portfolio with ease."
        />
      </Helmet>

      <Grid item xs={false} md={1} lg={2}></Grid>
      <Grid item xs={12} lg={4} md={5}>
        <Box className={classes.header} position="relative" paddingTop="8rem" paddingBottom="2rem">
          <Box marginBottom="2rem">
            <Box
              marginLeft="0rem"
              marginRight="2rem"
              justifyContent="center"
              color={theme.palette.bsColors.MainBlue}
            >
              <Box textAlign="left">
                <Typography
                  variant="h3"
                  component="h3"
                  className={classes.typographyH3}
                  color="inherit"
                >
                  Works with:{"      "}
                  <img
                    alt="eToro"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Etoro_logo.svg/1200px-Etoro_logo.svg.png"
                    width="70rem"
                    style={{
                      marginLeft: "0.5rem",
                      verticalAlign: "text-top",
                      marginBottom: "0.5rem",
                    }}
                  />
                </Typography>
              </Box>
              <Typography
                component="h1"
                variant="h1"
                color="inherit"
                style={{ textAlign: "left", fontWeight: "600" }}
              >
                Automate
                <br /> your portfolio management
              </Typography>
              <Box classes={{ root: classes.checksBox }}>
                <Done classes={{ root: classes.checkIcon }} /> Sector, Dividend and Geographical
                Breakdowns
                <br />
                <Done classes={{ root: classes.checkIcon }} /> Follow your stocks on extended hours
                <br />
                <Done classes={{ root: classes.checkIcon }} /> Load your new Buys automatically
                <br />
                <Done classes={{ root: classes.checkIcon }} /> Analyze your best performance stocks
                daily
                <br />
                <Done classes={{ root: classes.checkIcon }} /> Earnings & Dividends Calendar
                <br />
                <Done classes={{ root: classes.checkIcon }} /> Export your Portfolio in .csv or
                .xlsx
                <br />
                <Done classes={{ root: classes.checkIcon }} /> Find your next big winners with our
                stock screener
                <br />
                and much more...
              </Box>
              <Typography
                component="h2"
                variant="h2"
                color="inherit"
                style={{ textAlign: "left", fontWeight: "600", marginTop: "2rem" }}
              >
                Safe. No Bullsheet connectors required
              </Typography>
              <Box classes={{ root: classes.locksBox }}>
                <Lock classes={{ root: classes.lockIcon }} /> Search your eToro username. That's it.
                <br />
                <Lock classes={{ root: classes.lockIcon }} /> Bullsheet only gets your data if your
                profile is public
                <br />
                <Lock classes={{ root: classes.lockIcon }} /> No extension or connection required
                <br />
              </Box>

              {/* <Box position="relative">
                  <OverlapDummyRegister/>
                  </Box> */}
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* Page content */}
      <Grid item xs={12} lg={4} md={6} classes={{ root: classes.gridItem }}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title={
              <Box
                fontSize="80%"
                fontWeight="400"
                component="small"
                color={theme.palette.gray[600]}
              >
                Register with
              </Box>
            }
            titleTypographyProps={{
              component: Box,
              textAlign: "center",
              marginBottom: "1rem!important",
              marginTop: ".5rem!important",
              fontSize: "1rem!important",
            }}
            subheader={
              <Box textAlign="center">
                {/* <Box onClick={(e) => handleOnClick(e, facebookProvider)}
                      component={Button}
                      variant="contained"
                      marginRight="1rem!important"
                      marginTop="0.2rem!important"
                      classes={{ root: classes.buttonRoot }}
                    >
                      <Box component="span" marginRight="2px">
                        <Box
                          alt="..."
                          component="img"
                          width="15px"
                          className={classes.buttonImg}
                          src={
                            require("assets/img/icons/common/facebook.png")
                              .default
                          }
                        ></Box>
                      </Box>
                      <Box component="span" marginLeft=".5rem">
                        Facebook
                      </Box>
                    </Box> */}
                <Box
                  onClick={(e) => handleOnClick(e, gmailProvider)}
                  component={Button}
                  variant="contained"
                  marginRight="1rem!important"
                  marginTop="0.2rem!important"
                  classes={{ root: classes.buttonRoot }}
                >
                  <Box component="span" marginRight="2px">
                    <Box
                      alt="..."
                      component="img"
                      width="15px"
                      className={classes.buttonImg}
                      src={require("assets/img/icons/common/google.svg").default}
                    ></Box>
                  </Box>
                  <Box component="span" marginLeft=".5rem">
                    Google
                  </Box>
                </Box>
                <Box
                  onClick={(e) => handleOnClick(e, microsoftProvider)}
                  component={Button}
                  variant="contained"
                  marginRight="1rem!important"
                  marginTop="0.2rem!important"
                  classes={{ root: classes.buttonRoot }}
                >
                  <Box component="span" marginRight="2px">
                    <Box
                      alt="..."
                      component="img"
                      width="15px"
                      className={classes.buttonImg}
                      src={require("assets/img/icons/common/microsoft.svg").default}
                    ></Box>
                  </Box>
                  <Box component="span" marginLeft=".5rem">
                    Microsoft
                  </Box>
                </Box>
                {/* <Box onClick={(e) => handleOnClick(e, facebookProvider)}
                      component={Button}
                      variant="contained"
                      marginRight="1rem!important"
                      marginTop="0.2rem!important"
                      classes={{ root: classes.buttonRoot }}
                    >
                      <Box component="span" marginRight="2px">
                        <Box
                          alt="..."
                          component="img"
                          width="15px"
                          className={classes.buttonImg}
                          src={
                            require("assets/img/icons/common/facebook.png")
                              .default
                          }
                        ></Box>
                      </Box>
                      <Box component="span" marginLeft=".5rem">
                        Facebook
                      </Box>
                    </Box> */}
              </Box>
            }
          ></CardHeader>
          <CardContent classes={{ root: classes.cardContent }}>
            <form onSubmit={handleSubmit}>
              <InputWrapper theme={theme}>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="center"
                  marginBottom="1.5rem"
                  marginTop=".5rem"
                  fontSize="1rem"
                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    Or register with your e-mail
                  </Box>
                </Box>
                {error && <Alert variant="danger">{error}</Alert>}
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1.5rem!important"
                >
                  <FilledInput
                    inputRef={emailRef}
                    onChange={handleInputChange}
                    autoComplete="off"
                    type="email"
                    placeholder="Email"
                    startAdornment={
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1.5rem!important"
                >
                  <FilledInput
                    inputRef={passwordRef}
                    onChange={handleInputChange}
                    autoComplete="off"
                    type="password"
                    placeholder="Password"
                    startAdornment={
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1.5rem!important"
                >
                  <FilledInput
                    inputRef={passwordConfirmRef}
                    onChange={handleInputChange}
                    autoComplete="off"
                    type="password"
                    placeholder="Confirm Password"
                    startAdornment={
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={privacyPolicyRef}
                      onChange={handleInputChange}
                      color="primary"
                    />
                  }
                  label={
                    <>
                      By registering my account, I accept Bullsheet's{" "}
                      <Link
                        to="/auth/terms-and-conditions"
                        color={theme.palette.primary.main}
                        textDecoration="none"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>{" "}
                      and agree with the{" "}
                      <Link
                        to="/auth/privacy-policy"
                        color={theme.palette.primary.main}
                        textDecoration="none"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                    </>
                  }
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlLabelRoot,
                    label: classes.formControlLabelLabel,
                  }}
                />
                <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                  <Button
                    type="submit"
                    disabled={!createAccountButtonEnabled || loading}
                    variant="contained"
                    classes={{ root: classes.buttonCTANormal }}
                  >
                    Create account
                  </Button>
                </Box>
              </InputWrapper>
            </form>
          </CardContent>
        </Card>
        <Grid container component={Box} marginTop="-0.5rem">
          <Grid
            item
            xs={12}
            component={Box}
            textAlign="center"
            fontSize={theme.typography.h4fontSize}
          >
            <div className={classes.footerLinks}>
              Already registered?{" "}
              <Link style={{ color: theme.palette.bsColors.LogoLightBlue }} to="/auth/login">
                Login here
              </Link>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Register;
