import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/lab components
// @material-ui/icons components

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader";

import componentStyles from "assets/theme/views/admin/sortable.js";
import CardAfterHours from "components/Cards/Sortable/CardAfterHours.js";
import CardAfterHoursChart from "components/Cards/Sortable/CardAfterHoursChart.js";
import { Grid } from "@material-ui/core";
import { useService } from "contexts/ServiceContext";
import { useEtoroUser } from "contexts/EtoroUserContext";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const AfterHours = () => {
  const classes = useStyles();
  const { currentEtoroUserData } = useEtoroUser();
  const { getLatestAssetPrices } = useService();
  const [currentTime, setCurrentTime] = React.useState();
  const [updates, setUpdates] = React.useState(0);
  const [updatedPrices, setUpdatedPrices] = React.useState();
  const [updatedPricesChart, setUpdatedPricesChart] = React.useState();
  const [extPerformance, setExtPerformance] = React.useState(0);
  const [backupData, setBackupData] = React.useState(); // we backup data to toggle between market movers and user stonks, so we don't have to wait for next backend fetch
  const [sort, setSort] = React.useState({ attribute: "% Difference", direction: "desc" });
  const [checked, setChecked] = React.useState(false);
  const [checkedRenderCount, setCheckedRenderCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { t, i18n } = useTranslation("translation", { keyPrefix: "CardAfterHours" });

  function parseChecked() {
    setChecked(!checked);
  }

  function sortRows(updatedPrices) {
    if (!updatedPrices) {
      return;
    }
    if (sort.attribute === "Company") {
      const newArray = sortStrings(updatedPrices, sort.direction);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setUpdatedPrices([...newArray]);
    } else if (sort.attribute === "Previous Price") {
      const newArray = updatedPrices.sort(function (a, b) {
        return sort.direction === "asc"
          ? a.extendedPrice - a.extendedPriceChange - (b.extendedPrice - b.extendedPriceChange)
          : b.extendedPrice - b.extendedPriceChange - (a.extendedPrice - a.extendedPriceChange);
      });
      setUpdatedPrices([...newArray]);
    } else if (sort.attribute === "Price Difference") {
      const newArray = updatedPrices.sort(function (a, b) {
        return sort.direction === "asc"
          ? a.extendedPriceChange - b.extendedPriceChange
          : b.extendedPriceChange - a.extendedPriceChange;
      });
      setUpdatedPrices([...newArray]);
    } else if (sort.attribute === "% Difference") {
      const newArray = updatedPrices.sort(function (a, b) {
        return sort.direction === "asc"
          ? a.extendedPriceChangePercentage - b.extendedPriceChangePercentage
          : b.extendedPriceChangePercentage - a.extendedPriceChangePercentage;
      });
      setUpdatedPrices([...newArray]);
    } else if (sort.attribute === "Volume") {
      const newArray = sortByVolume(updatedPrices, sort.direction);
      setUpdatedPrices([...newArray]);
    } else if (sort.attribute === "Last Update (UTC)") {
      const newArray = updatedPrices.sort(function (a, b) {
        return sort.direction === "asc"
          ? new Date(a.lastUpdate) - new Date(b.lastUpdate)
          : new Date(b.lastUpdate) - new Date(a.lastUpdate);
      });
      setUpdatedPrices([...newArray]);
    } else if (sort.attribute === "Current Price") {
      const newArray = updatedPrices.sort(function (a, b) {
        return sort.direction === "asc"
          ? a.extendedPrice - b.extendedPrice
          : b.extendedPrice - a.extendedPrice;
      });
      setUpdatedPrices([...newArray]);
    }
  }

  function sortByVolume(array, direction) {
    return array.sort(function (a, b) {
      const volume1 = parseVolume(a.volume);
      const volume2 = parseVolume(b.volume);
      return direction === "asc" ? volume1 - volume2 : volume2 - volume1;
    });
  }

  function parseVolume(volume) {
    const volumeString = volume.toLowerCase();
    if (volumeString.includes("k")) {
      return volumeString.split("k")[0] * 1000;
    } else if (volumeString.includes("m")) {
      return volumeString.split("m")[0] * 1000000;
    } else {
      return volumeString * 1;
    }
  }

  function sortStrings(array, direction) {
    return array.sort(function (a, b) {
      const tickerA = a.ticker.toLowerCase(),
        tickerB = b.ticker.toLowerCase();
      if (tickerA < tickerB)
        //sort string ascending
        return direction === "asc" ? -1 : 1;
      if (tickerA > tickerB) return direction === "asc" ? 1 : -1;
      return 0; //default return value (no sorting)
    });
  }

  React.useEffect(() => {
    // we only need to force fetch data after initial check render is completed (after count is more than 1, no need to keep updating counter state)
    setCheckedRenderCount((count) => (count > 1 ? count : count + 1));
    const data = backupData ? backupData : currentEtoroUserData.extendedPricesPlusMarket;
    if (checked) {
      setUpdatedPrices(data.extendedPricesMarket ? [...data.extendedPricesMarket] : []);
      setUpdatedPricesChart(data.extendedPricesMarket ? [...data.extendedPricesMarket] : []);
    } else {
      sortRows(data.extendedPrices ? [...data.extendedPrices] : []);
      setUpdatedPricesChart(data.extendedPrices ? [...data.extendedPrices] : data.extendedPrices);
      setExtPerformance(data ? data.extPerformance : 0);
    }
  }, [checked]);

  const TODAY = new Date();
  function isDST() {
    const year = TODAY.getFullYear();
    const march = new Date(year, 2, 1); // March 1st
    const november = new Date(year, 10, 1); // November 1st

    // Find the second Sunday of March
    const startDst = new Date(march.setDate(1));
    startDst.setDate(1 + ((7 - march.getDay() + 7) % 7) + 7); // Move to first Sunday and then to second Sunday
    startDst.setHours(2, 0, 0, 0); // Set to 2:00 AM

    // Find the first Sunday of November
    const endDst = new Date(november.setDate(1));
    endDst.setDate(1 + ((7 - november.getDay() + 7) % 7)); // Move to first Sunday
    endDst.setHours(2, 0, 0, 0); // Set to 2:00 AM

    // Check if the date is within the DST period
    return TODAY >= startDst && TODAY < endDst;
  }

  React.useEffect(() => {
    let interval;
    // we only need to force fetch data after initial check render is completed (defaults to market movers if user has no stonks)
    if (checkedRenderCount >= 1) {
      const weekDay = new Date().toUTCString().split(",");
      const now = weekDay[1].split(" ")[4].split(":");
      const hour = now[0];
      const minutes = now[1];
      setCurrentTime({
        hour: hour,
        minutes: minutes,
        isWeekend: weekDay[0] === "Sat" || weekDay[0] === "Sun",
      });
      interval = setInterval(() => {
        setUpdates((updates) => updates + 1);
      }, 10000);
      if (!document.hidden && currentEtoroUserData?.userInstruments?.instruments && !loading) {
        setLoading(true);
        getLatestAssetPrices(currentEtoroUserData.userInstruments.instruments)
          .then((response) => {
            setUpdatedPricesChart(
              checked ? [...response.data.extendedPricesMarket] : [...response.data.extendedPrices]
            );
            sortRows(
              checked ? [...response.data.extendedPricesMarket] : [...response.data.extendedPrices]
            );
            const backUp = response.data;
            backUp["extPerformance"] = extPerformance;
            setBackupData(backUp);
          })
          .catch(() => {
            setUpdatedPricesChart([]);
            setUpdatedPrices([]);
            setBackupData([]);
          });
      }
    }
    return () => {
      clearInterval(interval);
      // remove this setUpdates line to only render update once per interval and avoid having to use checkedRenderCount
      setUpdates();
    };
  }, [updates, checkedRenderCount]);

  React.useEffect(() => {
    if (checked) {
      setLoading(false);
      return;
    } // if market movers is toggled, there's no need to calculate user performance
    let newExtPerformance = 0.0;
    if (updatedPrices && currentEtoroUserData?.currentPortfolio?.summary?.positions) {
      updatedPrices.forEach((price) => {
        const asset = currentEtoroUserData.currentPortfolio.summary.positions.find(
          (entry) => entry.instrumentId === price["instrumentId"]
        );
        if (!asset) {
          newExtPerformance += 0;
        } else {
          const value =
            asset.isBuy === false ? -asset.valuePctUnrealized : asset.valuePctUnrealized; // if it's a SELL position, subtract instead
          newExtPerformance += (price.extendedPriceChangePercentage * value) / 100;
        }
      });
    }
    setExtPerformance(newExtPerformance?.toFixed(2));
    setLoading(false);
  }, [updatedPrices]);

  React.useEffect(() => {
    if (
      currentEtoroUserData &&
      currentEtoroUserData.extendedPricesPlusMarket?.extendedPrices.length > 0
    ) {
      setChecked(false);
    } else {
      setChecked(true);
    }
    return () => {
      setUpdatedPrices();
    };
  }, [currentEtoroUserData]);

  React.useEffect(() => {
    if (updatedPrices) {
      sortRows(updatedPrices);
    }
  }, [sort]);

  function isMarketOpen() {
    return (
      currentTime &&
      !currentTime.isWeekend &&
      currentTime.hour < 21 &&
      ((currentTime.hour == 14 && currentTime.minutes >= 30) || currentTime.hour > 14)
    );
  }

  return (
    <>
      <AlternativeHeader
        section={t("header")}
        subsection="Portfolio"
        type={loading ? "Refresh" : currentTime && isMarketOpen() ? "" : "AfterHours"}
        parseChecked={parseChecked}
        checked={checked}
      />
      {/* Page content */}
      <Helmet>
        <title>Extended Hours | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        {isMarketOpen() ? (
          <Grid item xs={12} xl={6}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                className={classes.cardHeader}
                title={t("closedTitle")}
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
              <CardContent>
                <Typography
                  variant="h4"
                  component="h4"
                  classes={{ root: classes.menuTitle }}
                ></Typography>
                {t("closedDescription")}
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <>
            <Grid container>
              <Grid item xl={6} xs={12}>
                <CardAfterHoursChart
                  updatedPrices={updatedPricesChart?.slice(0, 11)}
                  type={"Rising"}
                  asset={"Stocks"}
                />
              </Grid>
              <Grid item xl={6} xs={12}>
                <CardAfterHoursChart
                  updatedPrices={updatedPricesChart?.slice(-10)}
                  type={"Falling"}
                  asset={"Stocks"}
                />
              </Grid>
              <Grid item xl={12} xs={12}>
                <CardAfterHours
                  updatedPrices={updatedPrices}
                  extPerformance={extPerformance}
                  setSort={setSort}
                  sort={sort}
                  checked={checked}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};

export default AfterHours;
