import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-holdings.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const tableHeadPerformance = ["Company", "Invested", "P/L%",];

export default function CardEffectivePortfolio({effectivePortfolio}) {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const [sort, setSort] = React.useState({'attribute': 'invested', 'direction': 'desc'});
  const [sortedPortfolio, setSortedPortfolio] = React.useState();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CopySync' });

  function handleSort(attribute) {
    setSort({'attribute': attribute, 'direction': sort.direction === 'asc' ? 'desc' : 'asc'});
  }

  function sortEffectivePortfolio(sort) {
    if (sort.attribute === 'Company') {
      const newArray = sortStrings(sortedPortfolio, sort.direction, sort.attribute);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setSortedPortfolio([...newArray]);
    } else if (sort.attribute === 'invested' || sort.attribute === 'Invested') {
      const newArray = sortedPortfolio.sort(function(a, b){
        return sort.direction === 'asc' ? a.investedPct - b.investedPct : b.investedPct - a.investedPct;
      })
      setSortedPortfolio([...newArray]);
    } else {
      const newArray = sortedPortfolio.sort(function(a, b){
        return sort.direction === 'asc' ? a.profit - b.profit : b.profit - a.profit;
      })

      setSortedPortfolio([...newArray]);
    }
  }

  function sortStrings(array, direction, attribute) {
    const parsedAttribute = attribute === "Company" ? "ticker" : ""
    return array.sort(function(a, b){
      const tickerA=a[parsedAttribute].toLowerCase(), tickerB=b[parsedAttribute].toLowerCase();
      if (tickerA < tickerB) //sort string ascending
          return direction === 'asc' ? -1 : 1;
      if (tickerA > tickerB)
        return direction === 'asc' ? 1 : -1;
      return 0 //default return value (no sorting)
    })
  }

  React.useEffect(() => {
    setSortedPortfolio(effectivePortfolio)
  }, [effectivePortfolio]);

  React.useEffect(() => { 
    // updates table when state is updated (re-renders component)
  }, [sortedPortfolio]);

  React.useEffect(() => {
    if(sortedPortfolio) {
      sortEffectivePortfolio(sort);
    }
  }, [sort]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }} style={{maxHeight: "38rem"}}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("effectivePortfolio")}</Box>
                  <FeatureInfoWithBox description={t("effectivePortfolioTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer classes={{ root: classes.tableRoot }} ref={tableConatinerRef}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead classes={{ root: classes.tableCellRootHead }}>
              <TableRow>
                { tableHeadPerformance ? tableHeadPerformance.map((prop, key) => (
                  <TableCell
                    key={key}
                    {...{
                      "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
                    }}
                    onClick={() => handleSort(prop)}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                )) : <></> }
              </TableRow>
            </TableHead>
              { sortedPortfolio ? sortedPortfolio.map((position, key) => (
              <TableBody key={key} className="list">
                <TableRow>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="body"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={position.logo ? Avatar : MonetizationOnIcon}
                        marginRight="0.8rem"
                        alt="..."
                        classes={{ root: classes.companyLogo }}
                        src={position.logo ? position.logo : ""}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker"
                        >
                          {position.ticker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="invested"
                      >
                      {position.investedPct.toFixed(2)}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes["tableCellRoot" + (position.profit>0? "Positive" : "Negative")]}}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="p/l%">
                      {position.profit ? position.profit.toFixed(2) + "%" : ""}
                    </Box>
                  </TableCell>
                  </TableRow>
               </TableBody>
               )) : <></> }
            </Table>
        </TableContainer>
      </Card>
    </>
  );
}
