import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    //boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  buttonRootUnselected: {
    background: theme.palette.white.main + "!important",
    color: theme.palette.primary.main + "!important",
  },
  buttonRoot: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  buttonRootSelected: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.dark.main,
    borderColor: theme.palette.dark.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  tableBodyRoot: {
    fontSize: theme.typography.h6.fontSize,
  },
  boxButtonsHeader:{
    justifyContent:"flex-end",
    display:"flex",
    flexWrap:"wrap",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.3rem",
      justifyContent:"flex-start",
    },
  },
});

export default componentStyles;
