import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// @material-ui/lab components
// @material-ui/icons components
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-after-hours.js";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(componentStyles);

const tableHead = ["Company", "Previous Price", "Current Price", "Price Difference", "% Difference", "Last Update (UTC)",];

export default function CardHoldings({updatedPrices, extPerformance, setSort, sort, checked}) {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardAfterHours' });

  function handleSort(attribute) {
    setSort({'attribute': attribute, 'direction': sort.direction === 'asc' ? 'desc' : 'asc'});
  }

  return (
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("performance")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("beforeAndAfterMarket")}</Box>
                  <FeatureInfoWithBox description={t("afterHoursTooltip")}/>
                </Box>
              </Grid>
              { !checked ? 
              (<Grid item xs="auto" classes={extPerformance < 0 ? { root: classes.tableCellRootNegative } : { root: classes.tableCellRootPositive }}>
                <Box paddingTop=".35rem" paddingBottom=".35rem" className="price difference">
                      <Box
                        component={extPerformance < 0 ? ArrowDropDown : ArrowDropUp}
                        width="1.5rem!important"
                        height="1.5rem!important"
                        marginRight="-0.5rem"
                      />{" "}
                       {extPerformance}%
                </Box>
              </Grid>) : null}
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        { updatedPrices && updatedPrices.length > 0 ? 
        <TableContainer ref={tableConatinerRef} classes={{ root: classes.tableRoot }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    {...{
                      "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
                    }}
                    onClick={() => handleSort(prop)}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              { updatedPrices.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        marginRight="0.8rem"
                        alt="..."
                        classes={{ root: classes.companyLogo }}
                        src={prop.logo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          fontWeight= "600"
                          component="span"
                          className="ticker"
                        >
                          {prop.ticker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot}}>
                  <Box paddingTop=".35rem" paddingBottom=".35rem" className="previous price">
                      {(prop.extendedPrice - prop.extendedPriceChange).toFixed(2)}
                    </Box>
                  </TableCell>
                  {prop.extendedPriceChangePercentage < 0 ? 
                  <><TableCell classes={{ root: classes.tableCellRootNegative }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="current price">
                      {prop.extendedPrice.toFixed(2)}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRootNegative }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="price difference">
                      <Box
                        component={ArrowDropDown}
                        width="1.5rem!important"
                        height="1.5rem!important"
                        marginRight="-0.5rem"
                      />{" "}
                      {prop.extendedPriceChange.toFixed(2)}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRootNegative }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="% difference">
                      <Box
                        component={ArrowDropDown}
                        width="1.5rem!important"
                        height="1.5rem!important"
                        marginRight="-0.5rem"
                      />{" "}
                      {prop.extendedPriceChangePercentage.toFixed(2)}%
                    </Box>
                  </TableCell>
                  </>
                  :
                  <>
                  <TableCell classes={{ root: classes.tableCellRootPositive }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="current price">
                      {prop.extendedPrice.toFixed(2)}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRootPositive }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="price difference">
                      <Box
                        component={ArrowDropUp}
                        width="1.5rem!important"
                        height="1.5rem!important"
                        marginRight="-0.5rem"
                      />{" "}
                      {prop.extendedPriceChange.toFixed(2)}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRootPositive }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="% difference">
                      <Box
                        component={ArrowDropUp}
                        width="1.5rem!important"
                        height="1.5rem!important"
                        marginRight="-0.5rem"
                      />{" "}
                      {prop.extendedPriceChangePercentage.toFixed(2)}%
                    </Box>
                  </TableCell>
                  </>
                  }
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="last update (utc)">
                        {prop.lastUpdate}
                    </Box>
                  </TableCell>
              
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        : <></> }
      </Card>
  );
}
