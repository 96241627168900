import React, { useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import Done from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";

// core components
import styled from "styled-components";
import componentStyles from "assets/theme/views/auth/login.js";
import componentStylesButtons from "assets/theme/components/button.js";
import {
  useAuth,
  gmailProvider,
  facebookProvider,
  microsoftProvider,
} from "../../contexts/AuthContext";
import { Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import getTheme from "assets/theme/theme.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const InputWrapper = styled.div`
  .MuiFilledInput-root {
    background-color: white !important;
  }
  .MuiInputBase-input {
    background-color: white !important;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      font-size: 16px;
    }
    color: ${(props) => props.theme.palette.gray[600]};
  }
  .MuiInputAdornment-root {
    background-color: white !important;
    color: ${(props) => props.theme.palette.gray[500]};
  }
`;

function Login() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = getTheme("light");
  const emailRef = useRef();
  const passwordRef = useRef();
  const { signInWithSocialMedia, login, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [signInButtonEnabled, setSignInButtonEnabled] = useState(false);
  const history = useHistory();

  function handleInputChange(e) {
    e.stopPropagation();

    setError("");

    if (emailRef.current.value && passwordRef.current.value) {
      setSignInButtonEnabled(true);
    } else {
      setSignInButtonEnabled(false);
    }
  }

  async function handleOnClick(e, provider) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await signInWithSocialMedia(provider);
    } catch (e) {
      setError("Failed to create an account. " + e);
    }
    setLoading(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
    } catch (e) {
      if (e.code === "auth/user-not-found") {
        setError("User doesn't exist, please register!");
      } else if (e.code === "auth/invalid-email") {
        setError("Invalid email adress!");
      } else if (e.code === "auth/wrong-password") {
        setError("Incorrect password, please try again!");
      } else {
        setError("Failed to create an account. " + e);
      }
    }
    setLoading(false);
  }

  React.useEffect(() => {
    // if user is already logged in, redirect to dashboard
    if (currentUser) {
      localStorage.getItem("cachedEtoroUserData")
        ? history.push("/app/dashboard")
        : history.push("/auth/lock");
    }
  }, []);

  return (
    <>
      <Grid
        container
        style={{ background: "linear-gradient(180.04deg, #FFFFFF 15.84%, #B3CEE8 240.04%)" }}
      >
        <Helmet>
          <title>Login | Bullsheet</title>
          <meta
            name="description"
            content="Login - Your Bullsheet Account to track your eToro portfolio is one click away."
          />
        </Helmet>
        {/* Page content */}
        <Grid item xs={false} md={1} lg={2}></Grid>
        <Grid item xs={12} lg={4} md={5}>
          <Box
            className={classes.header}
            position="relative"
            paddingTop="8rem"
            paddingBottom="2rem"
          >
            <Box marginBottom="2rem">
              <Box
                marginLeft="0rem"
                marginRight="2rem"
                justifyContent="center"
                color={theme.palette.bsColors.MainBlue}
              >
                <Box textAlign="left">
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.typographyH3}
                    color="inherit"
                  >
                    Works with:{"      "}
                    <img
                      alt="eToro"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Etoro_logo.svg/1200px-Etoro_logo.svg.png"
                      width="70rem"
                      style={{
                        marginLeft: "0.5rem",
                        verticalAlign: "text-top",
                        marginBottom: "0.5rem",
                      }}
                    />
                  </Typography>
                </Box>
                <Typography
                  component="h1"
                  variant="h1"
                  color="inherit"
                  style={{ textAlign: "left", fontWeight: "600" }}
                >
                  Welcome back, Bullsheeter 🚀
                </Typography>
                <Box
                  component="img"
                  alt="..."
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/loginScreenshot.png").default}
                ></Box>

                {/* <Box position="relative">
                  <OverlapDummyRegister/>
                  </Box> */}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} md={6} classes={{ root: classes.gridItem }}>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
              className={classes.cardHeader}
              title={
                <Box
                  fontSize="80%"
                  fontWeight="400"
                  component="small"
                  color={theme.palette.gray[600]}
                >
                  Sign in with
                </Box>
              }
              titleTypographyProps={{
                component: Box,
                textAlign: "center",
                marginBottom: "1rem!important",
                marginTop: ".5rem!important",
                fontSize: "1rem!important",
              }}
              subheader={
                <Box textAlign="center">
                  <Box
                    onClick={(e) => handleOnClick(e, gmailProvider)}
                    component={Button}
                    variant="contained"
                    marginRight="1rem!important"
                    marginTop="0.5rem!important"
                    classes={{ root: classes.buttonRoot }}
                  >
                    <Box component="span" marginBottom="2px">
                      <Box
                        alt="..."
                        component="img"
                        width="20px"
                        className={classes.buttonImg}
                        src={require("assets/img/icons/common/google.svg").default}
                      ></Box>
                    </Box>
                    <Box component="span" marginLeft=".75rem">
                      Google
                    </Box>
                  </Box>
                  <Box
                    onClick={(e) => handleOnClick(e, microsoftProvider)}
                    component={Button}
                    variant="contained"
                    marginRight="1rem!important"
                    marginTop="0.5rem!important"
                    classes={{ root: classes.buttonRoot }}
                  >
                    <Box component="span" marginBottom="4px">
                      <Box
                        alt="..."
                        component="img"
                        width="20px"
                        className={classes.buttonImg}
                        src={require("assets/img/icons/common/microsoft.svg").default}
                      ></Box>
                    </Box>
                    <Box component="span" marginLeft=".75rem">
                      Microsoft
                    </Box>
                  </Box>
                  {/* <Box onClick={(e) => handleOnClick(e, facebookProvider)}
                      component={Button}
                      variant="contained"
                      marginRight="1rem!important"
                      marginTop="0.5rem!important"
                      classes={{ root: classes.buttonRoot }}
                    >
                      <Box component="span" marginBottom="2px">
                        <Box
                          alt="..."
                          component="img"
                          width="20px"
                          className={classes.buttonImg}
                          src={
                            require("assets/img/icons/common/facebook.png")
                              .default
                          }
                        ></Box>
                      </Box>
                      <Box component="span" marginLeft=".75rem">
                        Facebook
                      </Box>
                    </Box> */}
                </Box>
              }
            ></CardHeader>
            <CardContent classes={{ root: classes.cardContent }}>
              <form onSubmit={handleSubmit}>
                <InputWrapper theme={theme}>
                  <Box
                    color={theme.palette.gray[600]}
                    textAlign="center"
                    marginBottom="1rem"
                    marginTop=".5rem"
                    fontSize="1rem"
                  >
                    <Box fontSize="80%" fontWeight="400" component="small">
                      Or sign in with credentials
                    </Box>
                  </Box>
                  {error && <Alert variant="danger">{error}</Alert>}
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FilledInput
                      inputRef={emailRef}
                      onChange={handleInputChange}
                      autoComplete="on"
                      type="email"
                      placeholder="Email"
                      startAdornment={
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl
                    variant="filled"
                    component={Box}
                    width="100%"
                    marginBottom="1rem!important"
                  >
                    <FilledInput
                      inputRef={passwordRef}
                      onChange={handleInputChange}
                      autoComplete="off"
                      type="password"
                      placeholder="Password"
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" />}
                    label="Remember me"
                    labelPlacement="end"
                    classes={{
                      root: classes.formControlLabelRoot,
                      label: classes.formControlLabelLabel,
                    }}
                  />
                  <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                    <Button
                      type="submit"
                      disabled={!signInButtonEnabled || loading}
                      variant="contained"
                      classes={{ root: classes.buttonCTANormal }}
                    >
                      Sign in
                    </Button>
                  </Box>
                </InputWrapper>
              </form>
            </CardContent>
          </Card>
          <Grid container component={Box} marginTop="-0.5rem">
            <Grid item xs={6} component={Box} textAlign="left">
              <div className={classes.footerLinks}>
                <Link
                  style={{ color: theme.palette.bsColors.LogoLightBlue }}
                  to="/auth/forgot-password"
                >
                  Forgot password?
                </Link>
              </div>
            </Grid>
            <Grid item xs={6} component={Box} textAlign="right">
              <div className={classes.footerLinks}>
                No account?{" "}
                <Link style={{ color: theme.palette.bsColors.LogoLightBlue }} to="/auth/register">
                  Register here
                </Link>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
