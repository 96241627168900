import React from "react";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";

import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import NoBreadcrumbsHeader from "components/Headers/NoBreadcrumbsHeader";
import { Typography } from "@material-ui/core";
import PricingHeader from "components/Headers/PricingHeader";

function PrivacyPolicyPage() {
  const theme = getTheme("light");

  function disableCookies() {
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    window.location.href = "/";
  }

  return (
    <>
      <PricingHeader title="Privacy Policy" description="Bullsheet's Privacy Policy"/>
      <Helmet>
        <title>Privacy Policy | Bullsheet</title>
      </Helmet>
      <Container
        component={Box}
        maxWidth="xl"
        marginTop="-7rem"
        paddingBottom="3rem"
        position="relative"
        zIndex="101"
        style={{backgroundColor:"#F8F9FD", paddingTop:"10px"}}
      >
        <Box>
          <Typography style={{color:theme.palette.bsColors.MainBlue}}>
            <h2 id="-effective-date-04-february-2021"><strong>Effective Date:</strong> 04 February 2021</h2>
            <p>At Bullsheet. (“Bullsheet,” “we,” “us” or “our”), putting our users (“you” or “your”) first means exceeding global privacy and data protection standards in order to keep sensitive data and communications private, safe and secure. </p>
            <h2 id="1-important-information">1. Important Information</h2>
            <p>This policy explains what information we gather when you use Bullsheet&#39;s websites, services, mobile applications, products and content. Here you also find information about how we store, use, transfer and delete this information. Our goal is not just to comply with the privacy law. It is to earn your trust. </p>
            <h3 id="1-1-general-terms">1.1. General terms</h3>
            <p>Our Privacy Promise:</p>
            <p>· We do not sell any sensitive, personal information</p>
            <p>· You own your data. </p>
            <p>· Your personal data will be kept private according to this policy and to the greatest extent allowed by the law.</p>
            <p>Additional questions? Don’t hesitate to send us an email at <a href="&#x6d;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#x6e;&#x66;&#x6f;&#x40;&#98;&#117;&#108;&#108;&#x73;&#x68;&#101;&#x65;&#116;&#x2e;&#x6d;&#x65;">&#105;&#x6e;&#x66;&#x6f;&#x40;&#98;&#117;&#108;&#108;&#x73;&#x68;&#101;&#x65;&#116;&#x2e;&#x6d;&#x65;</a> </p>
            <p>Bullsheet is committed to maintaining the privacy and security of any of your personal information on the website or in our applications or information received in any other manner. This Privacy Policy (“Agreement” or “Policy”) defines how Bullsheet uses and protects any personal information that it collects and stores. </p>
            <p>For the purpose of this Policy, “Personal Information” means information about an identifiable individual but does not include aggregated information that cannot be associated with a specific individual. </p>
            <p>For the purpose of this Agreement, “Services” refers to the Company’s website which can be viewed or accessed at <a href="https://bullsheet.me">https://bullsheet.me</a> (“Website”), our assistant or any other services that we may supply to you which are further described in our Terms of Service.</p>
            <p>By accepting our Policy and our Terms of Service, you expressly consent to our collection, storage, processing, use and disclosure of your Personal information as described in this Policy. </p>
            <p>In case your organization acts as a data controller under ‘General Data Protection Regulation (EU) 2016/679’ of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data you will need to sign or agree to an additional Data Processing Agreement with Bullsheet in order to process data in our Services. </p>
            <h3 id="1-2-revision-of-the-privacy-policy">1.2. Revision of the Privacy Policy</h3>
            <p>Bullsheet reserves the right to revise, modify or update this Policy at any time. Your continued use of the Services after any such revisions, modifications or updates constitutes your acceptance of any such change. </p>
            <p>If we make any material changes, we will notify you by posting the revised Privacy Policy on our Website or conspicuously in the Services and, providing notice through email.</p>
            <h2 id="2-use-of-our-services">2. Use of our services</h2>
            <h3 id="2-1-our-customers-use">2.1. Our customers use</h3>
            <p>You can use our Services to get a detailed overview of your portfolio, or other public portfolios and available market data. Bullsheet does not control your investment choices, nor does it extract any of your data from any broker or offline source without your own initiative and permission. That information belongs to you and is used, disclosed and protected by your brokers according to their privacy policies and is not subject to this Privacy Policy. </p>
            <p>Bullsheet acknowledges that you have the right to access your Personal Information. You control and are responsible for correcting, deleting or updating information we have collected like your e-mail, name, broker username or other applicable personal information. If requested to remove data we will respond within a reasonable timeframe. </p>
            <p>We are not responsible for your use of information on the Services. Bullsheet collects information under the direction of its customers.</p>
            <p>We may transfer Personal Information to companies that help us provide our Services, like CRM systems. Transfers to subsequent third parties are covered by the processing agreements with our customers. </p>
            <h3 id="2-2-sensitive-information-">2.2. “Sensitive Information”</h3>
            <p>This refers to credit or debit card numbers, personal financial account information, Social Security numbers, passport numbers, driver’s license numbers or similar personal identifiers, racial or ethnic origin, physical or mental health condition or information, or other employment, financial or health information. </p>
            <h2 id="3-information-we-collect">3. Information We Collect</h2>
            <p>When you provide Bullsheet with your Personal Information you consent to our providing your information to any third parties that are necessary to offer, support and improve the Services. </p>
            <p>Bullsheet also collects Personal Information from you when you place an order including your name, physical address, email address. Bullsheet only uses this data for the purpose of fulfilling orders or communicating with you and our partners regarding the Services. </p>
            <h3 id="3-1-when-you-visit-our-website">3.1. When You Visit our Website</h3>
            <p>You are free to explore our Website without providing any Personal Information about yourself. Bullsheet collects your Personal Information when you register and open an account with Bullsheet to help us operate and support the Services and to contact you in connection with your use of the Services. Also, we collect navigational information using third parties such as Google Analytics (“GA”) and other similar analytics tools collecting the same type of data. </p>
            <h3 id="3-2-personal-information-">3.2. “Personal Information”</h3>
            <p>This refers to any information that you voluntarily submit to us and that identifies you personally, including contact information, such as your name, e-mail address, company name, address, phone number, and other information about yourself or your business. </p>
            <p>Personal Information can also include information about any transactions, both free and paid, that you enter into on the Websites, and information about you that is available on the internet, such as from Facebook, LinkedIn, Twitter, Google, Microsoft or publicly available information that we acquire from service providers. </p>
            <h3 id="3-3-navigational-information-">3.3. “Navigational Information”</h3>
            <p>This refers to information about your computer and your visits to and use of the Services such as your IP address, geographical location, browser type, referral source, length of visit and pages viewed. </p>
            <p>We use this information for the above purposes and to monitor and analyze use of the Services, for the Services’ technical administration, to increase our Services’ functionality and user-friendliness, to verify users have the authorization needed for the Services to process their requests, and for advertising purposes. </p>
            <h3 id="3-4-payment-information">3.4. Payment Information</h3>
            <p>We collect and process payment information from you when you subscribe to the Service, including credit cards numbers and billing information, using third party PCI-compliant service providers. Except for this, we do not collect Sensitive Information from you. </p>
            <h3 id="3-5-information-about-children">3.5. Information About Children</h3>
            <p>The Websites and our Services are not intended for or targeted at children under 13, and we do not knowingly or intentionally collect information about children under 13. If you believe that we have collected information about a child under 13, please contact us at <a href="&#109;&#97;&#x69;&#108;&#x74;&#x6f;&#x3a;&#105;&#110;&#102;&#111;&#64;&#98;&#x75;&#108;&#108;&#115;&#x68;&#101;&#101;&#116;&#x2e;&#x6d;&#x65;">&#105;&#110;&#102;&#111;&#64;&#98;&#x75;&#108;&#108;&#115;&#x68;&#101;&#101;&#116;&#x2e;&#x6d;&#x65;</a> so that we may delete the information. </p>
            <h3 id="3-6-user-login-data">3.6 User Login Data</h3>
            <p>If you choose to register a Google account to Bullsheet, we will store your Google Account's email as the email associated to your Bullsheet account.</p>
            <p>This data will be securely stored to be used by Bullsheet to provide you with the Services (including but not limited to logging into your account). Under no circumstances will this data be voluntarily shared with any third parties; Bullsheet could only provide this information to legal authorities upon their lawful request. Should you choose to disconnect your Gmail account or decide to close your account, any information from Google that is stored on our servers will be removed permanently. </p>
            <p>If you choose to connect a Twitter account to Bullsheet, we will store your handle and account email address. </p>
            <p>Also, we will store your handle and account email address. This data will be securely stored to be used by Bullsheet to provide you with the Services (including but not limited to logging into your account). Under no circumstances will this data be voluntarily shared with any third parties; Bullsheet could only provide this information to legal authorities upon their lawful request. Should you choose to disconnect your Twitter account or decide to close your account, any information from Twitter that is stored on our servers will be removed permanently. </p>
            <p>At Bullsheet, we may use data obtained from our customers (from their Twitter accounts) for advertising purposes; this includes personalized, re-targeted, and interest-based advertising. </p>
            <p>To obtain more information, please reach out to our Data Protection Officer <a href="&#x6d;&#x61;&#x69;&#x6c;&#116;&#x6f;&#58;&#x69;&#110;&#x66;&#x6f;&#64;&#98;&#x75;&#108;&#x6c;&#115;&#104;&#101;&#101;&#x74;&#x2e;&#109;&#101;">&#x69;&#110;&#x66;&#x6f;&#64;&#98;&#x75;&#108;&#x6c;&#115;&#104;&#101;&#101;&#x74;&#x2e;&#109;&#101;</a>. </p>
            <h3 id="3-7-web-beacons">3.7 Web beacons</h3>
            <p>We may also collect information using web beacons (also known as “tracking pixels”). </p>
            <h2 id="4-use-of-information-we-collect">4. Use of Information We Collect</h2>
            <h3 id="4-1-we-do-not-sell-personal-information">4.1. We do not sell Personal Information</h3>
            <p>We do not sell your Personal Information to any third party. </p>
            <h3 id="4-2-navigational-data-cookies-and-web-beacons-">4.2 Navigational data, cookies and web beacons.</h3>
            <p>A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Bullsheet uses cookies to identify and track visitors, and your website access preferences. </p>
            <p>You can accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Bullsheet Services. </p>
            <p>Web beacons are electronic images (also called “gifs”) that may be used in Bullsheet’s Services or in emails that help us to deliver cookies, count visits, understand usage and campaign effectiveness and determine whether an email has been opened and acted upon. </p>
            <p>You can decline to show gifs or pictures in your email clients to avoid being tracked by email web beacons. </p>
            <p>Also, we use Navigational Information to operate and improve the Services. </p>
            <h3 id="4-3-use-of-personal-information">4.3 Use of Personal Information</h3>
            <p>In addition to the uses identified elsewhere in this Privacy Policy, we may use your Personal Information to: (a) improve your browsing experience by personalizing and improving the Services; (b) send information to you which we think may be of interest to you by post, email, or other means; (c) send you marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you; </p>
            <p>We may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, we do not transfer your unique Personal Information to the third party. </p>
            <p>In addition, we may share data with trusted partners to contact you based on your request to receive such communications, help us perform statistical analysis, or provide customer support. Such third parties are prohibited from using your Personal Information except for these purposes, and they are required to maintain the confidentiality of your information. If you do not want us to share your personal information with these companies, contact us at <a href="&#109;&#97;&#105;&#x6c;&#x74;&#111;&#58;&#x69;&#x6e;&#x66;&#111;&#64;&#98;&#x75;&#108;&#x6c;&#x73;&#104;&#101;&#x65;&#116;&#x2e;&#x6d;&#x65;">&#x69;&#x6e;&#x66;&#111;&#64;&#98;&#x75;&#108;&#x6c;&#x73;&#104;&#101;&#x65;&#116;&#x2e;&#x6d;&#x65;</a>. </p>
            <p>We use the information collected/stored via our Services by our customers for the following purposes:(a) to provide the Services (which may include the detection, prevention and resolution of security and technical issues); (b) to respond to customer support requests; (c) otherwise to fulfill the obligations under Bullsheet’s Terms of Service. </p>
            <h3 id="4-4-customer-testimonials-and-comments">4.4. Customer Testimonials and Comments</h3>
            <p>We post customer testimonials and comments in our Services, which may contain Personal Information. We obtain each customer’s consent via email prior to posting the customer’s name and testimonial. </p>
            <h3 id="4-5-use-of-credit-card-information">4.5. Use of Credit Card Information</h3>
            <p>If you give us credit card information, we use it solely to check your financial qualifications and collect payments from you. We use Stripe, a third-party service provider, to manage credit card processing and as a subscription management service. This service provider are not permitted to store, retain, or use information you provide except for the sole purpose of subscription management credit card processing on our behalf. </p>
            <h3 id="4-6-service-providers">4.6. Service Providers</h3>
            <p>We employ other companies and people to provide services to the visitors and users of the Services and we may need to share your information with them to provide information, products or services to you. Examples may include removing repetitive or invalid information from prospect lists, analyzing data, providing marketing assistance, processing credit card payments, supplementing the information you provide us in order to provide you with better service, and providing customer service. In all cases where we share your information with such third parties, we expect the agent to acknowledge and adhere to our privacy and customer data handling policies. </p>
            <h3 id="4-7-confidentiality-and-security-of-personal-information">4.7. Confidentiality and Security of personal information</h3>
            <p>We use a variety of security technologies and procedures to help protect your Personal Information from unauthorized access, use or disclosure. We secure the Personal Information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. </p>
            <p>All the Personal Information we have where Bullsheet acts as a processor for a customer’s data (e.g., use of the Bullsheet Services) is encrypted. All connections to our Website or Services are protected via the use of encrypted connections, such as the Secure Socket Layer (SSL) protocol. </p>
            <p>Please note that your account in Bullsheet service is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and keep it long and complicated enough and by logging out of your account after each use. </p>
            <p>If you have any questions about the security of your Personal Information, you can contact us at <a href="&#x6d;&#97;&#105;&#108;&#116;&#x6f;&#x3a;&#105;&#x6e;&#102;&#111;&#x40;&#x62;&#117;&#108;&#108;&#115;&#x68;&#x65;&#x65;&#116;&#x2e;&#x6d;&#101;">&#105;&#x6e;&#102;&#111;&#x40;&#x62;&#117;&#108;&#108;&#115;&#x68;&#x65;&#x65;&#116;&#x2e;&#x6d;&#101;</a>. </p>
            <h3 id="4-8-external-websites">4.8. External Websites</h3>
            <p>Our Websites provide links to other websites. We do not control, and are not responsible for, the content or practices of these other websites. Our provision of such links does not constitute our endorsement of these other websites, their content, their owners, or their practices. This Privacy Policy does not apply to these other websites, which are subject to any privacy and other policies they may have. </p>
            <h3 id="4-9-retention-of-personal-information">4.9. Retention of Personal Information</h3>
            <p>We retain Personal Information that you provide us as long as we consider it potentially useful in contacting you about the Service or our other services, or as needed to comply with our legal obligations, resolve disputes and enforce our agreements, and then we securely delete the information. </p>
            <p>We will delete this information from the servers at an earlier date if you so request, as described in “Opting Out and Unsubscribing” below. </p>
            <p>If you provide information to our customers as part of their use of the Bullsheet Service, our customers decide how long to retain the personal information they collect from you. If a customer terminates its use of the Service, then we will provide customer with access to all information stored for the customer by the Service, including any Personal Information provided by you, for export by the customer according to our agreement with our customer. After termination, we may, unless legally prohibited, delete all customer information, including your Personal Information, from the Bullsheet Service. </p>
            <h3 id="4-10-information-storage">4.10. Information storage</h3>
            <p>Bullsheet uses third-party vendors and partners such as Amazon and Google for hardware, software, networking, storage and related technology to get Bullsheet up and running. We maintain two types of logs: server logs and event logs. By using our services, you consent to our transfer, storage and use of your information in Portugal and any other country in which we operate. </p>
            <h3 id="4-11-sharing-with-third-party-applications">4.11. Sharing with Third party applications</h3>
            <p>Certain aspects of the Bullsheet’s Service allow or require you to connect with third party applications or services, such as but not limited to, Firebase, Twitter and Google. In connection with your use of any such third party applications or services, such third parties may receive information about you including your username and any content you choose to use in connection with those applications and services, and such third parties may contact you directly as necessary. </p>
            <p>Our Service may automatically collect and upload certain data from such applications via direct integrations or proxy services. </p>
            <p>This Privacy Policy does not apply to your use of such third -party applications and services, and we are not responsible for how those third parties collect, use and disclose your information and Content. We encourage you to review the privacy policies of those third parties before connecting to or using their applications or services to learn more about their information and privacy practices. </p>
            <h3 id="4-12-compelled-disclosure">4.12. Compelled Disclosure</h3>
            <p>Bullsheet reserves the right to use or disclose your Personal Information if required by law or if we reasonably believe that use or disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud and security breaches, or comply with a law, court order, or legal process. </p>
            <h3 id="4-13-data-retention">4.13. Data retention</h3>
            <p>Bullsheet retains personal data associated with your account for the life of your account. If you want to delete your personal information, you can delete your profile at any time by sending us a request at info@bullsheet.me. Deleted account profiles will become unrecoverable on our system upon deletion. </p>
            <p>To delete your payment or billing information, you may need to contact your payment service provider, as Bullsheet only has minimal secure access to these records as needed to provide the services. </p>
            <h2 id="5-opting-out-and-unsubscribing">5. Opting Out and Unsubscribing</h2>
            <h3 id="5-1-reviewing-correcting-and-removing-your-personal-information">5.1. Reviewing, Correcting and Removing Your Personal Information</h3>
            <p>Upon request Bullsheet will provide you with information about whether we hold any of your Personal Information. If you provide us with your Personal Information, you have the following rights with respect to that information: </p>
            <ul>
            <li><p>To review the user information that you have supplied to us </p>
            </li>
            <li><p>To request that we correct any errors, outdated information, or omissions in user information that you have supplied to us </p>
            </li>
            <li><p>To request that your user information not be used to contact you </p>
            </li>
            <li><p>To request that your user information be removed from any solicitation list that we use </p>
            </li>
            <li><p>To request that your user information be deleted from our records </p>
            </li>
            <li><p>To opt out of being solicited by Bullsheet or third parties </p>
            </li>
            <li><p>To exercise any of these rights, please contact us at <a href="&#x6d;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#x6f;&#x40;&#98;&#x75;&#108;&#x6c;&#115;&#x68;&#x65;&#x65;&#x74;&#46;&#109;&#x65;">&#105;&#110;&#102;&#x6f;&#x40;&#98;&#x75;&#108;&#x6c;&#115;&#x68;&#x65;&#x65;&#x74;&#46;&#109;&#x65;</a>. We will respond to your request to change, correct, or delete your information within a reasonable timeframe and notify you of the action we have taken. </p>
            </li>
            </ul>
            <h3 id="5-2-anti-spam-policy">5.2. Anti-Spam Policy</h3>
            <p>We at Bullsheet committed to provide customers with tools and expertise to curate relevant communications via our platform. </p>
            <p>We require all of our customers to agree to adhere to the Terms of Service Policy at all times, and any violations of the Terms of Service Policy by a customer can result in suspension or termination of the Bullsheet Service. </p>
            <h3 id="5-3-to-unsubscribe-from-our-communications">5.3. To Unsubscribe From Our Communications</h3>
            <p>You may unsubscribe from our marketing communications by clicking on the “unsubscribe” link located on the bottom of our e-mails, or by sending us email us at <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#x3a;&#105;&#x6e;&#x66;&#111;&#x40;&#98;&#x75;&#108;&#x6c;&#115;&#x68;&#101;&#x65;&#116;&#x2e;&#x6d;&#101;">&#105;&#x6e;&#x66;&#111;&#x40;&#98;&#x75;&#108;&#x6c;&#115;&#x68;&#101;&#x65;&#116;&#x2e;&#x6d;&#101;</a>. Customers cannot opt out of receiving transactional emails or notifications related to their account with us or the Bullsheet Service. </p>
            <p>Contact Us </p>
            <p>Bullsheet</p>
            <p>Contact Person: <a href="&#109;&#97;&#105;&#x6c;&#x74;&#111;&#x3a;&#105;&#x6e;&#102;&#111;&#64;&#x62;&#117;&#108;&#108;&#115;&#x68;&#x65;&#x65;&#x74;&#46;&#109;&#x65;">&#105;&#x6e;&#102;&#111;&#64;&#x62;&#117;&#108;&#108;&#115;&#x68;&#x65;&#x65;&#x74;&#46;&#109;&#x65;</a></p>
            <h3 id="5-4-to-delete-our-cookies-and-tracking">5.4. To Delete Cookies And Tracking</h3>
            <button style={{height: "20px", width: "60px", fontSize: "10px", marginBottom: "30px"}} onClick={disableCookies}>click here</button>        

          
            <p>All rights reserved</p>

          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default PrivacyPolicyPage;
