import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons components
import MoreHoriz from "@material-ui/icons/MoreHoriz";

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import CardNews from "components/Cards/Cards/CardNews";

import componentStyles from "assets/theme/views/admin/alternative-dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import CardOpeningHours from "components/Cards/Dashboard/CardOpeningHours";
import CardBiggestWinners from "components/Cards/Alternative/CardBiggestWinners";
import CardBiggestLosers from "components/Cards/Alternative/CardBiggestLosers";
import CardMiniMarketPerformance from "components/Cards/Cards/CardMiniMarketPerformance";
import CardNewsBgImage from "components/Cards/Cards/CardNewsBgImage";
import CardSectorByDate from "components/Cards/Tabbed/CardSectorByDate";
import CardCountryByDate from "components/Cards/Tabbed/CardCountryByDate";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useService } from "contexts/ServiceContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function Markets() {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  const { getMarketInfo } = useService();
  const { currentEtoroUserData } = useEtoroUser();
  const [marketInfo, setMarketInfo] = React.useState();
  const { t, i18n } = useTranslation("translation", { keyPrefix: "Markets" });

  React.useEffect(() => {
    let unsubscribe;
    if (currentEtoroUserData?.marketInfo) {
      unsubscribe = getMarketInfo().then((response) => {
        setMarketInfo(response.data);
      });
    }
    return () => {
      setMarketInfo({});
    };
  }, [currentEtoroUserData]);

  React.useEffect(() => {
    setMarketInfo(currentEtoroUserData?.marketInfo);
    return () => {
      setMarketInfo({});
    };
  }, []);

  return (
    <>
      <AlternativeHeader section={t("header")} />
      {/* Page content */}
      <Helmet>
        <title>Markets | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} md={6} xl={5}>
            <CardBiggestWinners marketInfo={marketInfo} />
          </Grid>
          <Grid item xs={12} md={6} xl={5}>
            <CardBiggestLosers marketInfo={marketInfo} />
          </Grid>

          {marketInfo?.latestMarketPrices ? (
            <Grid item xs={12} md={12} xl={2}>
              <Box marginBottom="1rem">
                <CardMiniMarketPerformance
                  direction={
                    marketInfo?.latestMarketPrices[1]?.priceChangePercentage < 0 ? "Down" : "Up"
                  }
                  title="S&P 500"
                  change={marketInfo?.latestMarketPrices[1]?.priceChangePercentage}
                />
              </Box>
              <Box marginBottom="1rem">
                <CardMiniMarketPerformance
                  direction={
                    marketInfo?.latestMarketPrices[3]?.priceChangePercentage < 0 ? "Down" : "Up"
                  }
                  title="DJ 30"
                  change={marketInfo?.latestMarketPrices[3]?.priceChangePercentage}
                />
              </Box>
              <Box marginBottom="1rem">
                <CardMiniMarketPerformance
                  direction={
                    marketInfo?.latestMarketPrices[2]?.priceChangePercentage < 0 ? "Down" : "Up"
                  }
                  title="Nasdaq 100"
                  change={marketInfo?.latestMarketPrices[2]?.priceChangePercentage}
                />
              </Box>
              <Box marginBottom="1rem">
                <CardMiniMarketPerformance
                  direction={
                    marketInfo?.latestMarketPrices[6]?.priceChangePercentage < 0 ? "Down" : "Up"
                  }
                  title="BTC"
                  change={marketInfo?.latestMarketPrices[6]?.priceChangePercentage}
                />
              </Box>
              <Box marginBottom="1rem">
                <CardMiniMarketPerformance
                  direction={
                    marketInfo?.latestMarketPrices[7]?.priceChangePercentage < 0 ? "Down" : "Up"
                  }
                  title="Ether"
                  change={marketInfo?.latestMarketPrices[7]?.priceChangePercentage}
                />
              </Box>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={3}>
            <CardNewsBgImage
              title={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[3].headline
                  : "U.S. Covid cases fall further as Pfizer's vaccine is cleared for 12- to 15-year-olds"
              } //headline
              date={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[3].newsDatetime
                  : "2021-02-02 14:53"
              } //datetime to human date
              image={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[3].image
                  : "https://image.cnbcfm.com/api/v1/image/106882607-16209169612021-05-12t235015z_1155055109_rc2qdn9ruxyj_rtrmadp_0_health-coronavirus-usa-schools.jpeg?v=1620917015"
              } //image
              source={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[3].source : "CNBC"} //source
              url={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[3].url : ""} //url
            />
            <CardNewsBgImage
              title={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[2].headline
                  : "U.S. Covid cases fall further as Pfizer's vaccine is cleared for 12- to 15-year-olds"
              } //headline
              date={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[2].newsDatetime
                  : "2021-02-02 14:53"
              } //datetime to human date
              image={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[2].image
                  : "https://image.cnbcfm.com/api/v1/image/106882607-16209169612021-05-12t235015z_1155055109_rc2qdn9ruxyj_rtrmadp_0_health-coronavirus-usa-schools.jpeg?v=1620917015"
              } //image
              source={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[2].source : "CNBC"} //source
              url={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[2].url : ""} //url
            />
            <CardNewsBgImage
              title={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[1].headline
                  : "U.S. Covid cases fall further as Pfizer's vaccine is cleared for 12- to 15-year-olds"
              } //headline
              date={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[1].newsDatetime
                  : "2021-02-02 14:53"
              } //datetime to human date
              image={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[1].image
                  : "https://image.cnbcfm.com/api/v1/image/106882607-16209169612021-05-12t235015z_1155055109_rc2qdn9ruxyj_rtrmadp_0_health-coronavirus-usa-schools.jpeg?v=1620917015"
              } //image
              source={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[1].source : "CNBC"} //source
              url={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[1].url : "CNBC"} //url
            />
          </Grid>
          <Grid item xs={12} xl={5}>
            <CardNews
              ticker={t("header")}
              title={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[0].headline
                  : "U.S. Covid cases fall further as Pfizer's vaccine is cleared for 12- to 15-year-olds"
              } //headline
              date={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[0].newsDatetime
                  : "2021-02-02 14:53"
              } //datetime to human date
              image={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[0].image
                  : "https://image.cnbcfm.com/api/v1/image/106882607-16209169612021-05-12t235015z_1155055109_rc2qdn9ruxyj_rtrmadp_0_health-coronavirus-usa-schools.jpeg?v=1620917015"
              } //image
              source={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[0].source : "CNBC"} //source
              description={
                marketInfo?.latestMarketNews
                  ? marketInfo.latestMarketNews[0].summary
                  : "Technology stocks could see another leg lower amid concerns around inflation and interest rates, says portfolio manager Paul Meeks."
              }
              url={marketInfo?.latestMarketNews ? marketInfo.latestMarketNews[0].url : ""} //url
            />
          </Grid>
          <Grid item xs={12} xl={4}>
            <CardOpeningHours />
          </Grid>
        </Grid>
        {/* <Box className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
          <CardSectorByDate />
          <CardCountryByDate />
        </Box> */}
      </Container>
    </>
  );
}

export default Markets;
