import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import componentStyles from "assets/theme/components/headers/features-header.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import { useTranslation } from 'react-i18next';
import themeColors from 'assets/theme/colors';

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const FeatDividendFocus = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Analysis' });
  const location = useLocation();
  const ungated = location.pathname.includes("auth/");

  return (
      <>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  alt="..."
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/dividendFocus_screenshot.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.prMd5}>
                  <Typography variant="h1" component="h1" className={classes.typographyH2Strong} style={{color: (ungated ? themeColors.bsColors.MainBlue : theme.palette.bsColors.MainBlue)}}>
                    {t("premiumTitleC")}
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={(ungated ? themeColors.bsColors.MainBlue : theme.palette.bsColors.MainBlue)}
                    >
                    {t("premiumDescription1C")}
                    <br/><br/>
                    {t("premiumDescription2C")}
                    <br/><br/>
                    {t("premiumDescription3C")}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
  )
}

export default FeatDividendFocus;