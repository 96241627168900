import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "assets/theme/components/cards/widgets/card-simple-rectangle.js";

const useStyles = makeStyles(componentStyles);

function CardSimpleRectangle({ ticker, imageUrl, title, color}) {
  const classes = useStyles();
  const theme = useTheme();
  let utm = "?utm_source=bullsheet&utm_medium=referral";

  let companyLogo = ""; 
  companyLogo = imageUrl;
  return (
    <>
      <Card
        classes={{ root: clsx(classes.cardRoot, classes[color]) }}
        elevation={0}
      >
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <Grid container component={Box} justifyContent="space-between">
            <Grid item xs={9} lg={9}>
              <Box
                component={Typography}
                variant="h4"
                color={theme.palette.white.main + "!important"}
                marginBottom="0!important"
                marginTop="0!important"
                className={classes.typographyRootH5}
              >
                {ticker}
              </Box>
              {title.length < 20 ?
              <Box
                component={Typography}
                variant="h5"
                fontWeight="600!important"
                marginBottom="0!important"
                marginTop="0!important"
                color={theme.palette.white.main + "!important"}
              >
                  {title}
              </Box>
              :
              <Box
                component={Typography}
                variant="h5"
                fontWeight="600!important"
                marginBottom="0!important"
                marginTop="0!important"
                color={theme.palette.white.main + "!important"}
              >
                  {title}
                  </Box>

                }
            </Grid>
            <Grid item xs={3} lg={3}>
              
                  <Box
                    component={Avatar}
                    classes={{ root: classes.tableLogo }}
                    alt="..."
                    src={companyLogo}
                  />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

CardSimpleRectangle.defaultProps = {
  color: "bgGradientError",
};

CardSimpleRectangle.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.oneOf([
    "bgGradientDefault",
    "bgGradientError",
    "bgGradientSuccess",
    "bgGradientPrimary",
    "bgGradientInfo",
    "bgGradientWarning",
    "bgGradientLightBlue",
    "bgGradientLogoMainBlue",
  ]),
};

export default CardSimpleRectangle;
