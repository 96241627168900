import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CircleLoader from "react-spinners/CircleLoader";

// core components
import componentStyles from "assets/theme/components/cards/pricing/card-pricing-gradient.js";
import buttonsStyles from "assets/theme/components/button.js";
import { useAuth } from "contexts/AuthContext";
import getTheme from "assets/theme/theme.js";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(buttonsStyles);

function CardPricingChampion({handleSubscribe, loading, price, type}) {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const theme = getTheme("light");
  const { currentUser } = useAuth();
  const thisSubscriptionType = "premium";
  return (
    <>
      <Card className={clsx(classes.cardRoot, classes.cardRootGradientSuccess)}>
        <img
          alt="Pro Bullsheet Subscriber"
          src={require("assets/img/theme/pro_bull.png").default}
          className={classes.cardImgTop}
        />
        <Box 
          alignSelf="center"
          paddingLeft="1rem"
          paddingRight="1rem"
          marginTop="0.5rem"
          fontSize="0.8rem"
          fontWeight="600"
          style={{ borderRadius:"0.5rem", maxWidth:"9rem", color:theme.palette.white.main ,backgroundColor:theme.palette.bsColors.LogoLightBlue}}
          >
            Best Value
        </Box>
        <CardHeader
          title="Premium"
          className={classes.bgInherit}
          titleTypographyProps={{
            className: classes.titleWhite,
            component: "h4",
            variant: "h4",
          }}
          subheader="For the active bull investor"
          subheaderTypographyProps={{
            className: classes.subheaderWhite,
            component: "h6",
            variant: "h6",
          }}
        ></CardHeader>
        <CardContent className={classes.cardContentRoot}>
          <Box className={classes.display1} color={theme.palette.white.main}>
            €{price}
          </Box>
          <Box component="span" color={theme.palette.white.main} fontSize={theme.typography.h5.fontSize}>
            per month
          </Box>
          <Box
            component="ul"
            paddingLeft="0"
            className={classes.listStyleNone}
            margin="1.5rem 0"
          >
            
          </Box>
          <Box marginBottom="1rem" marginTop="3rem" textAlign="center">
            <Button
              variant="contained"
              classes={{
                root: classes.buttonCTANormalUngated,
              }}
              onClick={() => handleSubscribe({"plan": thisSubscriptionType, "type": type})}
              disabled={loading}
            >
              { loading ? "" : currentUser?.subscription === thisSubscriptionType ? "Current" : "Subscribe" }
                  <Box position="relative" style={{marginRight: "27px", marginTop: "-20px"}}>
                    <CircleLoader color={theme.palette.bsColors.LogoLightBlue} loading={loading} size={30} /> 
                  </Box>   
                </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardPricingChampion;
