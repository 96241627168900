import boxShadows from "assets/theme/box-shadow.js";
import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    backgroundColor: themeColors.secondary.main,
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  cardContent: {
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  imgBox: {
    verticalAlign: "middle",
    borderStyle: "none",
    paddingTop: "2rem",
  },
  header: {
    
    [theme.breakpoints.up("md")]: {
      paddingTop: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: "3rem",
      marginRight: "3rem",
    },
  },
  gridItem: {
    paddingTop:"8rem", 
    paddingBottom: "4rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem",
      textAlign: "center",
      marginLeft: "2rem",
      marginRight: "2rem",
    },
  },
  buttonImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  buttonRoot: {
    backgroundColor: themeColors.white.main,
    color: themeColors.primary.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    borderColor: themeColors.white.main + "!important",
    "&:hover": {
      color: themeColors.gray[900],
      borderColor: themeColors.white.main + "!important",
      backgroundColor: themeColors.white.main,
    },
  },
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: themeColors.gray[600],
  },
  footerLinks: {
    color: themeColors.bsColors.MainBlue,
    textDecoration: "none",
    fontSize: theme.typography.h4.fontSize,
  },
});

export default componentStyles;
