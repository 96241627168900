import React from "react";
// javascript plugin that creates a sortable object from a dom object
// react plugin for creating vector maps
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@material-ui/core/styles";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";

// core components

import componentStyles from "assets/theme/components/cards/alternative/card-countries.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Country", "Invested", ];

function CardCountries({geography}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'ETFDetails' });
  const flags = {'North America': 'https://hatscripts.github.io/circle-flags/flags/us.svg', 'Europe Developed': 'https://hatscripts.github.io/circle-flags/flags/european_union.svg',
                'United Kingdom': 'https://hatscripts.github.io/circle-flags/flags/gb.svg','Europe Emerging': 'https://hatscripts.github.io/circle-flags/flags/ua.svg',
                'Africa/Middle East': 'https://hatscripts.github.io/circle-flags/flags/sa.svg', 'Japan': 'https://hatscripts.github.io/circle-flags/flags/jp.svg',
                'Australasia': 'https://hatscripts.github.io/circle-flags/flags/au.svg', 'Asia Developed': 'https://hatscripts.github.io/circle-flags/flags/kr.svg',
                'Asia Emerging': 'https://hatscripts.github.io/circle-flags/flags/in.svg', 'Latin America': 'https://hatscripts.github.io/circle-flags/flags/br.svg',};

  return (
    <>
      {alert}
      <Card classes={{ root: classes.cardRoot }} elevation={0}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("etfDiversification")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("stocksByGeography")}</Box>
                  <FeatureInfoWithBox description={t("etfGeografiaTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
          <Grid container>
            <Grid item lg={12} md={12} sm={12}>
              <PerfectScrollbar>
              <TableContainer classes={{ root: classes.tableRoot }} >
                <Box component={Table} alignItems="center" marginBottom="0!important">
                  <TableHead>
                    <TableRow>
                      {tableHead.map((prop, key) => (
                        <TableCell
                          key={key}
                          scope="col"
                          classes={{
                            root:
                              classes.tableCellRoot + " sort " + classes.tableCellRootHead,
                          }}
                        >
                          {t(prop)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="list">
                  {geography ? geography.map((prop, key) => (
                      <TableRow key={key}>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          component="th"
                          variant="head"
                          scope="row"
                          style={{cursor:"pointer"}}
                        >
                          <Box alignItems="center" display="flex">
                            <Box
                              component={Avatar}
                              classes={{ root: classes.flagRoot }}
                              marginRight="0.5rem"
                              alt="Country Flag"
                              src={flags[prop.region]}
                            />
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize={theme.typography.h5.fontSize} component="span" className="ticker">
                                {prop.region}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box fontSize={theme.typography.h5.fontSize} component="span" className="invested">
                            {parseFloat(prop.pct).toFixed(2)}%
                          </Box>
                        </TableCell>               
                      </TableRow>
                    )) : <></> }
                  </TableBody>
                </Box>
              </TableContainer>
              </PerfectScrollbar>
            </Grid>
          </Grid>
      </Card>
    </>
  );
}

export default CardCountries;
