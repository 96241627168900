import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/icons components

// core components

import { chartOptions, parseOptions, chartRiskClusters, chartRiskClustersDark } from "variables/charts.js";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { useTranslation } from 'react-i18next';

import componentStyles from "assets/theme/components/cards/charts/card-portfolio-ratios";
import { useDarkMode } from "contexts/DarkModeContext";

const useStyles = makeStyles(componentStyles);

function CardRiskStockClusters() {
  const { currentEtoroUserData } = useEtoroUser();
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardRiskStockClusters' });
  const { activeTheme } = useDarkMode();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  React.useEffect(() => {
    if(theme && currentEtoroUserData) {
      //Ensures chart is using current theme colors
      currentEtoroUserData.riskClusters.datasets[0].backgroundColor = ["#cacaca",
      theme.palette.bsColors.Risk1,
      theme.palette.bsColors.Risk3,
      theme.palette.bsColors.Risk5,
      theme.palette.bsColors.Risk7,
      theme.palette.bsColors.Risk9,
      "#eeeeee",
      ];
    }
  }, []);
  
  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t('risk')}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t('title')}</Box>
                  <FeatureInfoWithBox description={t('riskClustersTooltip')}/>
                </Box>
              </Grid>
              <Grid item xs="auto" className={classes.gridItemRoot}>
                <Box fontSize={theme.typography.h5.fontSize} fontWeight="400" component="small" style={{color:theme.palette.bsColors.MainBlue}}>
                  {t('description')}
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          { currentEtoroUserData?.riskClusters ? 
              <Box position="relative" height="250px">
                  <Bar
                    data={currentEtoroUserData.riskClusters}
                    options={activeTheme === "light" ? chartRiskClusters.options : chartRiskClustersDark.options}
                  />
                </Box>
            : <></> }
        </CardContent>
      </Card>
    </>
  );
}

export default CardRiskStockClusters;
