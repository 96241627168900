import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import strings from "../../../strings/en.js";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-screener-results.js";
import { useTranslation } from 'react-i18next';
import ShowMoreButton from '../Charts/ShowMoreButton';

const useStyles = makeStyles(componentStyles);

const tableHead = ["Company", "Market Cap", "Revenue TTM", "P/E ratio TTM", "PEG ratio", "Price/Sales TTM", "Div Yield", "Quarterly Revenue Growth", "Quarterly Earnings Growth",  "Operating Margin", "Profit Margin", "Risk"];
const tableHeadBasicInfo = ["Company", "Name", "Sector", "Industry", "Country", "Market Cap (USD)", "Dividend Yield", "Risk"];
const tableHeadPerformance = ["Company", "1 Week", "MTD", "1 Month", "YTD", "1 Year", "3 Years", "5 Years", "10 Years", /*"20 Years", "Max", */"First Price Date"];
const tableHeadRatios = ["Company", "P/E ratio TTM", "5 Yr avg. P/E", "PEG ratio", "Price/Sales TTM", "RoA"];
const tableHeadFinancials = ["Company", "Revenue TTM", "EBITDA", "Gross Profit", "EBIT Margin", "Profit Margin", "Quarterly Revenue Growth", "Quarterly Earnings Growth"];
const tableHeadRisk = ["Company", "Risk", "Beta", "52 Week High to Low", "Institutional Ownership %"];

const tableHeads = {"basicInfo": tableHeadBasicInfo, "performance": tableHeadPerformance, "ratios": tableHeadRatios, 
                    "financials": tableHeadFinancials, "risk": tableHeadRisk}
const DEFAULT_PAGINATION = 30;

export default function ScreenerResults({screenerResults}) {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const [selectedTable, setSelectedTable] = React.useState("basicInfo");
  const [resultArray, setResultArray] = React.useState();
  const [sort, setSort] = React.useState({'attribute': 'priceChangePercentage', 'direction': 'desc'});
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Screener' });
  const [showMore, setShowMore] = React.useState(DEFAULT_PAGINATION);
  
  function handleClick(option) {
    setSelectedTable(option);
  }

  function handleSort(attribute) {
    setSort({'attribute': attribute, 'direction': sort.direction === 'asc' ? 'desc' : 'asc'});
  }

  function updateResultArray(newArray) {
    setResultArray([...newArray]);
  }

  function sortBasicInfoArray(sort) {
    if (['Company', 'Name', 'Sector', 'Industry', 'Country'].includes(sort.attribute) ) {
      const newArray = sortStrings(resultArray, sort.direction, sort.attribute);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't executed
      updateResultArray(newArray);
    }  else if (sort.attribute === 'Market Cap (USD)') {
      const newArray = resultArray.sort(function(a, b){
        return sort.direction === 'asc' ? a.marketCap - b.marketCap : b.marketCap - a.marketCap;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Risk') {
      const newArray = resultArray.sort(function(a, b){
        const risk1 = parseNonExistingValues(a.risk);
        const risk2 = parseNonExistingValues(b.risk);
        return sort.direction === 'asc' ? risk1 - risk2 : risk2 - risk1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Dividend Yield') {
      const newArray = resultArray.sort(function(a, b){
        return sort.direction === 'asc' ? a.forwardAnnualDividendYield - b.forwardAnnualDividendYield : b.forwardAnnualDividendYield - a.forwardAnnualDividendYield;
      })
      updateResultArray(newArray);
    }
  }


  function sortPerformanceArray(sort) {
    if (sort.attribute === 'Company') {
      const newArray = sortStrings(resultArray, sort.direction, sort.attribute);
      updateResultArray(newArray);
    } else if (sort.attribute === '1 Week') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.oneWeek : -Infinity;
        const bb = b.performance ? b.performance.oneWeek : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'MTD') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.mtd : -Infinity;
        const bb = b.performance ? b.performance.mtd : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '1 Month') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.oneMonth : -Infinity;
        const bb = b.performance ? b.performance.oneMonth : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;
      }) 
      updateResultArray(newArray);
    } else if (sort.attribute === 'YTD') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.ytd : -Infinity;
        const bb = b.performance ? b.performance.ytd : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '1 Year') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.oneYear : -Infinity;
        const bb = b.performance ? b.performance.oneYear : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;      
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '3 Years') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.threeYears : -Infinity;
        const bb = b.performance ? b.performance.threeYears : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;      
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '5 Years') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.fiveYears : -Infinity;
        const bb = b.performance ? b.performance.fiveYears : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;      
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '10 Years') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.tenYears : -Infinity;
        const bb = b.performance ? b.performance.tenYears : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;      
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '20 Years') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.twentyYears : -Infinity;
        const bb = b.performance ? b.performance.twentyYears : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;      
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Max') {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? a.performance.maximum : -Infinity;
        const bb = b.performance ? b.performance.maximum : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;      
      })
      updateResultArray(newArray);
    } else {
      const newArray = resultArray.sort(function(a, b){
        const aa = a.performance ? new Date(a.performance.firstPriceDate) : -Infinity;
        const bb = b.performance ? new Date(b.performance.firstPriceDate) : -Infinity;
        return sort.direction === 'asc' ? aa - bb : bb - aa;      
      })
      updateResultArray(newArray);
    }
  }

  function sortRatiosArray(sort) {
    if (sort.attribute === 'Company') {
      const newArray = sortStrings(resultArray, sort.direction, sort.attribute);
      updateResultArray(newArray);
    }
    else if (sort.attribute === 'Price/Sales TTM') {
      const newArray = resultArray.sort(function(a, b){
        const priceToSales1 = parseNonExistingValues(a.priceToSales);
        const priceToSales2 = parseNonExistingValues(b.priceToSales);
        return sort.direction === 'asc' ? priceToSales1 - priceToSales2 : priceToSales2 - priceToSales1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'P/E ratio TTM') {
      const newArray = resultArray.sort(function(a, b){
        const peRatio1 = parseNonExistingValues(a.peRatio);
        const peRatio2 = parseNonExistingValues(b.peRatio);
        return sort.direction === 'asc' ? peRatio1 - peRatio2 : peRatio2 - peRatio1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '5 Yr avg. P/E') {
      const newArray = resultArray.sort(function(a, b){
        const fiveYearAveragePERatio1 = parseNonExistingValues(a.fiveYearAveragePERatio);
        const fiveYearAveragePERatio2 = parseNonExistingValues(b.fiveYearAveragePERatio);
        return sort.direction === 'asc' ? fiveYearAveragePERatio1 - fiveYearAveragePERatio2 : fiveYearAveragePERatio2 - fiveYearAveragePERatio1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'PEG ratio') {
      const newArray = resultArray.sort(function(a, b){
        const pegRatio1 = parseNonExistingValues(a.pegRatio);
        const pegRatio2 = parseNonExistingValues(b.pegRatio);
        return sort.direction === 'asc' ? pegRatio1 - pegRatio2 : pegRatio2 - pegRatio1;
      })
      updateResultArray(newArray);
    } else {
      const newArray = resultArray.sort(function(a, b){
        const returnOnAssets1 = parseNonExistingValues(a.returnOnAssets);
        const returnOnAssets2 = parseNonExistingValues(b.returnOnAssets);
        return sort.direction === 'asc' ? returnOnAssets1 - returnOnAssets2 : returnOnAssets2 - returnOnAssets1;
      })
      updateResultArray(newArray);
    }
  }

  function sortRiskArray(sort) {
    if (sort.attribute === 'Company') {
      const newArray = sortStrings(resultArray, sort.direction, sort.attribute);
      updateResultArray(newArray);
    }
    else if (sort.attribute === 'Risk') {
      const newArray = resultArray.sort(function(a, b){
        const risk1 = parseNonExistingValues(a.risk);
        const risk2 = parseNonExistingValues(b.risk);
        return sort.direction === 'asc' ? risk1 - risk2 : risk2 - risk1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Beta') {
      const newArray = resultArray.sort(function(a, b){
        const beta1 = parseNonExistingValues(a.beta);
        const beta2 = parseNonExistingValues(b.beta);
        return sort.direction === 'asc' ? beta1 - beta2 : beta2 - beta1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === '52 Week High to Low') {
      const newArray = resultArray.sort(function(a, b){
        const highToLow52Week1 = parseNonExistingValues(a.highToLow52Week);
        const highToLow52Week2 = parseNonExistingValues(b.highToLow52Week);
        return sort.direction === 'asc' ? highToLow52Week1 - highToLow52Week2 : highToLow52Week2 - highToLow52Week1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Institutional Ownership %') {
      const newArray = resultArray.sort(function(a, b){
        const institutionalOwnership1 = parseNonExistingValues(a.institutionalOwnership);
        const institutionalOwnership2 = parseNonExistingValues(b.institutionalOwnership);
        return sort.direction === 'asc' ? institutionalOwnership1 - institutionalOwnership2 : institutionalOwnership2 - institutionalOwnership1;
      })
      updateResultArray(newArray);
    }
  }


  function sortFinancialsArray(sort) {
    if (sort.attribute === 'Market Cap (USD)') {
      const newArray = resultArray.sort(function(a, b){
        const marketCap1 = parseNonExistingValues(a.marketCap);
        const marketCap2 = parseNonExistingValues(b.marketCap);
        return sort.direction === 'asc' ? marketCap1 - marketCap2 : marketCap2 - marketCap1;
      })
      updateResultArray(newArray);
    }
    else if (sort.attribute === 'Revenue TTM') {
      const newArray = resultArray.sort(function(a, b){
        const revenueTTM1 = parseNonExistingValues(a.revenueTTM);
        const revenueTTM2 = parseNonExistingValues(b.revenueTTM);
        return sort.direction === 'asc' ? revenueTTM1 - revenueTTM2 : revenueTTM2 - revenueTTM1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'EBITDA') {
      const newArray = resultArray.sort(function(a, b){
        const ebitda1 = parseNonExistingValues(a.ebitda);
        const ebitda2 = parseNonExistingValues(b.ebitda);
        return sort.direction === 'asc' ? ebitda1 - ebitda2 : ebitda2 - ebitda1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Gross Profit') {
      const newArray = resultArray.sort(function(a, b){
        const grossProfit1 = parseNonExistingValues(a.grossProfit);
        const grossProfit2 = parseNonExistingValues(b.grossProfit);
        return sort.direction === 'asc' ? grossProfit1 - grossProfit2 : grossProfit2 - grossProfit1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Profit Margin') {
      const newArray = resultArray.sort(function(a, b){
        const profitMargin1 = parseNonExistingValues(a.profitMargin);
        const profitMargin2 = parseNonExistingValues(b.profitMargin);
        return sort.direction === 'asc' ? profitMargin1 - profitMargin2 : profitMargin2 - profitMargin1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'EBIT Margin') {
      const newArray = resultArray.sort(function(a, b){
        const operationMargin1 = parseNonExistingValues(a.operationMargin);
        const operationMargin2 = parseNonExistingValues(b.operationMargin);
        return sort.direction === 'asc' ? operationMargin1 - operationMargin2 : operationMargin2 - operationMargin1;
      })
      updateResultArray(newArray);
    } else if (sort.attribute === 'Quarterly Revenue Growth') {
      const newArray = resultArray.sort(function(a, b){
        const quarterlyRevenueGrowth1 = parseNonExistingValues(a.quarterlyRevenueGrowth);
        const quarterlyRevenueGrowth2 = parseNonExistingValues(b.quarterlyRevenueGrowth);
        return sort.direction === 'asc' ? quarterlyRevenueGrowth1 - quarterlyRevenueGrowth2 : quarterlyRevenueGrowth2 - quarterlyRevenueGrowth1;
      })
      updateResultArray(newArray);
    } else {
      const newArray = resultArray.sort(function(a, b){
        const quarterlyEarningsGrowth1 = parseNonExistingValues(a.quarterlyEarningsGrowth);
        const quarterlyEarningsGrowth2 = parseNonExistingValues(b.quarterlyEarningsGrowth);
        return sort.direction === 'asc' ? quarterlyEarningsGrowth1 - quarterlyEarningsGrowth2 : quarterlyEarningsGrowth2 - quarterlyEarningsGrowth1;
      })
      updateResultArray(newArray);
    }
  }


  function parseNonExistingValues(value) {
    return value === "-" ? -Infinity : value;
  }


  React.useEffect(() => { 
    if (screenerResults) {
      setResultArray([...screenerResults])
    }
    // updates table when state is updated (re-renders component)
  }, [screenerResults]);


  React.useEffect(() => {
    if (!resultArray || resultArray.length == 0) {
      return;
    }
    if (selectedTable === "basicInfo") {
      sortBasicInfoArray(sort)
    } else if (selectedTable === "financials") {
      sortFinancialsArray(sort)
    } else if (selectedTable === "ratios") {
      sortRatiosArray(sort)
    } else if (selectedTable === "performance"){
      sortPerformanceArray(sort)
    } else if (selectedTable === "risk"){
      sortRiskArray(sort)
    }
  }, [sort]);

  React.useEffect(() => {
    const newArray = []
    screenerResults.forEach(entry => {
      newArray.push({"companyLogo": entry.companyLogo, "companyTicker": entry.companyTicker, "name": entry.name ? entry.name : "-",
      "sector": entry.sector ? entry.sector : "-", "industry": entry.industry ? entry.industry : "-", "country": entry["country"] ? entry["country"] : "-",
      "marketCap": entry["marketCap"] ? entry["marketCap"] : "-", "risk": entry["risk"] ? entry["risk"] : "-", 
      "forwardAnnualDividendYield": entry["forwardAnnualDividendYield"] ? entry["forwardAnnualDividendYield"] : 0,
      "peRatio": entry.peRatio ? entry.peRatio : "-",
      "pegRatio": entry["pegRatio"] ? entry["pegRatio"] : "-", "priceToSales": entry["priceToSales"] ? entry["priceToSales"] : "-",
      "fiveYearAveragePERatio": entry["fiveYearAveragePERatio"] ? entry["fiveYearAveragePERatio"] : "-", 
      "returnOnAssets": entry["returnOnAssets"] ? entry["returnOnAssets"] : "-",
      "revenueTTM": entry.revenueTTM ? entry.revenueTTM : "-",
          "ebitda": entry["ebitda"] ? entry["ebitda"] : "-", "grossProfit": entry["grossProfit"] ? entry["grossProfit"] : "-",
          "operationMargin": entry["operationMargin"] ? entry["operationMargin"] : "-", "profitMargin": entry["profitMargin"] ? entry["profitMargin"] : "-",
          "quarterlyEarningsGrowth": entry["quarterlyEarningsGrowth"] ? entry["quarterlyEarningsGrowth"] : "-",
          "quarterlyRevenueGrowth": entry["quarterlyRevenueGrowth"] ? entry["quarterlyRevenueGrowth"] : "-",
          "risk": entry.risk ? entry.risk : "-",
          "beta": entry["beta"] ? entry["beta"] : "-", "highToLow52Week": entry["highToLow52Week"] ? entry["highToLow52Week"] : "-",
          "institutionalOwnership": entry["institutionalOwnership"] ? entry["institutionalOwnership"] : 0, "performance": entry["performance"]
    });
    })
    setResultArray(newArray)
  }, [screenerResults]);

  function lowercaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  

  function sortStrings(array, direction, attribute) {
    const parsedAttribute = attribute === "Company" ? "companyTicker" : attribute;
    return array.sort(function(a, b){
      const tickerA=a[lowercaseFirstLetter(parsedAttribute)]?.toLowerCase(), tickerB=b[lowercaseFirstLetter(parsedAttribute)]?.toLowerCase();
      if (tickerA < tickerB) //sort string ascending
          return direction === 'asc' ? -1 : 1;
      if (tickerA > tickerB)
        return direction === 'asc' ? 1 : -1;
      return 0 //default return value (no sorting)
    })
  }
  
  function moneyFormat(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

       ? ((Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
       // Six Zeroes for Millions 
       : Math.abs(Number(labelValue)) >= 1.0e+6

       ? ((Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
       // Three Zeroes for Thousands
       : Math.abs(Number(labelValue)) >= 1.0e+3

       ? ((Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

       : Math.abs(Number(labelValue));

  }

  return (
    <>
      {resultArray && selectedTable && 
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Screener
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{"Screener Results"}</Box>
                  <FeatureInfoWithBox description={strings.cardHoldings}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box classes={{root: classes.boxButtonsHeader}}>
                <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "basicInfo" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    onClick={() => handleClick("basicInfo")}
                  >
                    {t("basicInfo")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "performance" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    onClick={() => handleClick("performance")}
                  >
                    {t("performance")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "ratios" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    onClick={() => handleClick("ratios")}
                  >
                    {t("ratios")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "financials" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    onClick={() => handleClick("financials")}
                  >
                    {t("financials")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "risk" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    onClick={() => handleClick("risk")}
                  >
                    {t("risk")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        { resultArray && resultArray.length > 0 ?
        <TableContainer classes={{ root: classes.tableRoot }} ref={tableConatinerRef}>
          <Box alignItems="center" marginBottom="0!important">
          <Table stickyHeader aria-label="sticky table">
            <TableHead classes={{ root: classes.tableCellRootHead }}>
              <TableRow>
                <CustomTableHeader header={tableHeads[selectedTable]} classes={classes} handleSort={handleSort} t={t} title={selectedTable}></CustomTableHeader>
              </TableRow>
            </TableHead>
            <TableBody className="list">
              <CustomTableBody resultArray={resultArray} showMore={showMore} classes={classes} 
                                moneyFormat={moneyFormat} theme={theme} selectedTable={selectedTable}>
              </CustomTableBody>
              <ShowMoreButton
                  showMore={showMore}
                  setShowMore={setShowMore}
                  DEFAULT_PAGINATION={DEFAULT_PAGINATION}
                  tableSize={resultArray.length}
                />
            </TableBody>
            </Table>
          </Box>
        </TableContainer>
        : <></> }
      </Card>
     }
    </>
  );
}


function CustomTableHeader({header, classes, handleSort, t}) { 
  return (
    <>
      { header.map((prop, key) => (
        <TableCell
          key={key}
          {...{
            "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
          }}
          onClick={() => handleSort(prop)}
          scope="col"
          classes={{
            root:
              classes.tableCellRoot +
              " sort " +
              classes.tableCellRootHead,
          }}
        >
          {t(prop)}
        </TableCell>
      )) }
    </>
  )
}

function parsePercentage(value) {
  const color = !value || value == 1 ? "black" : (parseFloat(value) < 1 ? "red" : "green");
  return (
  <Box display="flex" alignItems="center" color={color}>
      {value ? ((parseFloat(value - 1)) * 100).toFixed(2) + "%" : "-"}
  </Box>
  )
}

function CustomTableBody({resultArray, showMore, classes, moneyFormat, theme, selectedTable}) { 

  return (
    <>
      { resultArray ? resultArray.slice(0, showMore).map((prop, key) => (
                <TableRow key={key} classes={{ root: classes.tableRow }}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <a href={"https://www.etoro.com/markets/" + prop.companyTicker} target="_blank">                    
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        marginRight="0.8rem"
                        alt="..."
                        classes={{ root: classes.companyLogo }}
                        src={prop.companyLogo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker2"
                        >
                          {prop.companyTicker}
                        </Box>
                      </Box>
                    </Box>
                    </a>
                  </TableCell>
                  {/* TODO refactor the columns like this: columns.map((column, key) => (
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className={column}>
                        {prop[column] !== "-" ? moneyFormat(prop[column]) : "-"}
                    </Box>
                  </TableCell>
                  ))*/}
                  { selectedTable === "basicInfo" ? <>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="name">
                      {prop.name !== "-" ? prop.name : "-"}
                    </Box>
                  </TableCell> 
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="sector">
                      {prop.sector !== "-" ? prop.sector : "-"}
                    </Box>
                  </TableCell> 
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="industry">
                      {prop.industry !== "-" ? prop.industry : "-"}
                    </Box>
                  </TableCell> 
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="country">
                      {prop.country !== "-" ? prop.country : "-"}
                    </Box>
                  </TableCell> 
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                  <Box
                        component="span"
                        marginRight=".5rem"
                        className="marketCap"
                      >
                      {prop.marketCap !== "-" ? moneyFormat(prop.marketCap) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="div yield">
                        {prop.forwardAnnualDividendYield !== "-" ? (prop.forwardAnnualDividendYield*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="risk">
                      <Avatar variant="rounded" className="risk" classes={{ root: classes["avatarRootRisk" + (prop.risk != "-" ? prop.risk : "")] }}>
                        {prop.risk !== "-" ? prop.risk : "-"}
                      </Avatar>
                    </Box>
                  </TableCell>
                  </> : null }
                  { selectedTable === "performance" ? <>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                        {prop.performance && parsePercentage(parseFloat(prop.performance.oneWeek))}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="mtd">
                        {prop.performance?.mtd ? parsePercentage(parseFloat(prop.performance.mtd)) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="oneMonth">
                        {prop.performance?.oneMonth ? parsePercentage(parseFloat(prop.performance.oneMonth)) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="ytd">
                        {prop.performance?.ytd ? parsePercentage(parseFloat(prop.performance.ytd)) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="pegRatio">
                        {prop.performance?.oneYear ? parsePercentage(parseFloat(prop.performance.oneYear)) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="price/sales ttm">
                        {prop.performance?.threeYears ? parsePercentage(parseFloat(prop.performance.threeYears)) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="returnOnAssets">
                        {prop.performance?.fiveYears ? parsePercentage(parseFloat(prop.performance.fiveYears)) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="returnOnAssets">
                        {prop.performance?.tenYears ? parsePercentage(parseFloat(prop.performance.tenYears)) : "-"}
                    </Box>
                  </TableCell>
                  {/* <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="returnOnAssets">
                        {prop.performance?.twentyYears ? parsePercentage(parseFloat(prop.performance.twentyYears)) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="returnOnAssets">
                        {prop.performance?.maximum ? parsePercentage(parseFloat(prop.performance.maximum)) : "-"}
                    </Box>
                  </TableCell> */}
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="returnOnAssets">
                        {prop.performance?.firstPriceDate ? prop.performance.firstPriceDate : "-"}
                    </Box>
                  </TableCell>
                  </> : null }
                  { selectedTable === "ratios" ? <>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                  >
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="p/e ratio ttm"
                      >
                      {prop.peRatio !== "-" ? parseFloat(prop.peRatio).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="fiveYearAveragePERatio">
                        {prop.fiveYearAveragePERatio !== "-" ? parseFloat(prop.fiveYearAveragePERatio).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="pegRatio">
                        {prop.pegRatio !== "-" ? parseFloat(prop.pegRatio).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="price/sales ttm">
                        {prop.priceToSales !== "-" ? parseFloat(prop.priceToSales).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="returnOnAssets">
                        {prop.returnOnAssets !== "-" ? parseFloat(prop.returnOnAssets).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  </> : null }
                  { selectedTable === "financials" ? <>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    scope="row"
                  >
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="revenue ttm"
                      >
                      {prop.revenueTTM !== "-" ? moneyFormat(prop.revenueTTM) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="ebitda">
                        {prop.ebitda !== "-" ? moneyFormat(prop.ebitda) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="gross profit">
                        {prop.grossProfit !== "-" ? moneyFormat(prop.grossProfit) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="gross profit">
                      {prop.operationMargin !== "-" ? (prop.operationMargin*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="ebit margin">
                      {prop.profitMargin !== "-" ? (prop.profitMargin*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="roa">
                      {prop.quarterlyRevenueGrowth !== "-" ? (prop.quarterlyRevenueGrowth*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="ebitda">
                      {prop.quarterlyEarningsGrowth !== "-" ? (prop.quarterlyEarningsGrowth*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                  </> : null }
                  { selectedTable === "risk" ? <>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box className="risk">
                      <Avatar variant="rounded" className="risk" classes={{ root: classes["avatarRootRisk" + (prop.risk != "-" ? prop.risk : "")] }}>
                        {prop.risk !== "-" ? prop.risk : "-"}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="beta">
                      {prop.beta !== "-" ? prop.beta.toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="highToLow52Week">
                      {prop.highToLow52Week !== "-" ? ((prop.highToLow52Week-1) * 100).toFixed(2) + "%": "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="institutionalOwnership">
                      {prop.institutionalOwnership !== "-" ? (prop.institutionalOwnership*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                  </> : null }
                  </TableRow> 
              )) : <></> }
    </>
  )
}