import React, { useContext, useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import "../assets/css/loader.css";
import axios from "axios";
import { currentServer } from "./ServiceContext";

const AuthContext = React.createContext();

export const gmailProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");

export const stripePromise = loadStripe(
  "pk_live_51JieC0Irh1eJ8aV0wpZhzkjr71G9I4VnsHECeu7WtXTdtU1dOEJWRiXJSPX4pmjsFY8cOHHIcxUmLg7L6ZHZUe5u006ougZacV"
);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  // TODO decide how sub tiers are called and move this to the backend for security reasons
  const subscriptionBlockedServices = {
    freeTier: ["Analysis", "Export", "Screener", "ETF Details", "Stock Details"],
    premium: [""],
    sponsor: [""],
  };

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function signInWithSocialMedia(provider) {
    return auth.signInWithPopup(provider);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function reauthenticateWithCredential(currentPassword) {
    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    return currentUser.reauthenticateWithCredential(credential);
  }

  function updatePassword(newPassword) {
    return currentUser.updatePassword(newPassword);
  }

  function redirectToLockScreen(user) {
    setCurrentUser(user);
    const cachedData = localStorage.getItem("cachedEtoroUserData");
    setLoading(false);
    //if (window.location.href in ["/auth/contact",
    if (user && !cachedData) {
      history.push("/auth/lock");
    }
  }

  async function handleSubscribe(pricing) {
    let stripePricing = "";
    switch (pricing.plan) {
      case "premium":
        stripePricing =
          pricing.type === "monthly"
            ? "price_1JycO6Irh1eJ8aV0P7c1W6Gi"
            : "price_1JycO6Irh1eJ8aV0UbKZZ0k7";
        break;
      case "sponsor":
        stripePricing =
          pricing.type === "monthly"
            ? "price_1JycPKIrh1eJ8aV0nRuQxJRG"
            : "price_1JycPKIrh1eJ8aV0GDybBQxj";
        break;
      default:
      // code block
    }
    // only starts checkout session if user is logged in
    if (!currentUser) {
      history.push("/auth/register");
    } else {
      firestore
        .collection("customers")
        .doc(currentUser.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active"])
        .onSnapshot(async (snapshot) => {
          // In this implementation we only expect one active or trialing subscription to exist.
          const doc = snapshot.docs[0];
          if (doc && doc.id) {
            const functionRef = firebase
              .app()
              .functions("europe-west3")
              .httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
            const { data } = await functionRef({
              returnUrl: window.location.origin + "/auth/pricing",
            });
            window.location.assign(data.url);
          } else {
            const docRef = await firestore
              .collection("customers")
              .doc(currentUser.uid)
              .collection("checkout_sessions")
              .add({
                price: stripePricing,
                payment_behavior: "error_if_incomplete",
                allow_promotion_codes: true,
                success_url: window.location.origin + "/app/dashboard",
                cancel_url: window.location.origin + "/auth/pricing",
              });
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot((snap) => {
              const { error, url } = snap.data();
              if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
              }
              if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
              }
            });
          }
        });
    }
  }

  async function checkPremiumSub(idToken) {
    try {
      const response = await axios.get(currentServer + "/checkSub", {
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data === "ok";
    } catch (e) {
      // new user, doesn't have an idToken yet so can't be premium anyway
      return false;
    }
  }

  useEffect(() => {
    /* TODO: change this to onIdTokenChanged -> it has the same behavior as onAuthStateChanged but also includes token expiry 
      -> then we can remove the "await currentUser.getIdToken();" snippet in the ServiceContext on each request, as we can just update the currentUser's idToken
    */
    const unsubscribe = auth.onAuthStateChanged((user) => {
      localStorage.removeItem("cacheStructureUpdated");
      if (!localStorage.getItem("cacheStructureUpdated2")) {
        localStorage.removeItem("cachedEtoroUserData");
        localStorage.setItem("cacheStructureUpdated2", "yep");
      }
      if (user) {
        if (!user.emailVerified) {
          // TODO: remove this in 2023. Email sending will happen on register only.
          user.sendEmailVerification();
        }
        setLoading(true);
        user
          .getIdToken(/* forceRefresh */ true)
          .then(async function (idToken) {
            user.idToken = idToken;
            var docRef = firestore.collection("customers").doc(user.uid);
            user.subscription = "freeTier";
            const isPremium = await checkPremiumSub(idToken);
            if (isPremium) {
              user.subscription = "premium";
              redirectToLockScreen(user);
            } else {
              docRef
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    docRef
                      .collection("subscriptions")
                      .orderBy("created", "desc")
                      .limit(1)
                      .get()
                      .then((querySnapshot) => {
                        if (!querySnapshot.docs[0]) {
                          // no such document
                          redirectToLockScreen(user);
                        } else {
                          const subscription = querySnapshot.docs[0].data();
                          // if most recent subscription hasn't ended, give access to premium content
                          if (subscription.status === "active") {
                            user.subscription = subscription.role;
                          }
                          redirectToLockScreen(user);
                        }
                      });
                  } else {
                    // no such document
                    redirectToLockScreen(user);
                  }
                })
                .catch((error) => {
                  // user has no subscriptions
                  redirectToLockScreen(user);
                });
            }
          })
          .catch(function (error) {
            // TODO Handle idToken error
          });
      } else {
        setCurrentUser(user);
        setLoading(false);
      }
    });
    return () => {
      unsubscribe && setCurrentUser();
    };
  }, []);

  const value = {
    currentUser,
    subscriptionBlockedServices,
    login,
    signup,
    signInWithSocialMedia,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    handleSubscribe,
    reauthenticateWithCredential,
  };

  return (
    <>
      {loading ? (
        <div className="divLoader">
          <img className="pulsatingLogo" src="/favicon.ico" alt="auth" height="30em" />
        </div>
      ) : (
        <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>
      )}
    </>
  );
}
