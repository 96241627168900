// core components
// admin pages
// // // // // admin dashboards
import AlternativeDashboard from "views/admin/Dashboards/AlternativeDashboard.js";
import Calendar from "views/admin/Calendar.js";
// rtl pages
// @material-ui/icons components
import Event from "@material-ui/icons/Event";
import House from "@material-ui/icons/House";
import Book from "@material-ui/icons/Book";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import TrendingUp from "@material-ui/icons/TrendingUp";
import BarChart from "@material-ui/icons/BarChart";
import SaveAlt from "@material-ui/icons/SaveAlt";
import MenuBook from "@material-ui/icons/MenuBook";
import Update from "@material-ui/icons/Update";
import DonutLarge from "@material-ui/icons/DonutLarge";
import FeedbackIcon from '@material-ui/icons/Feedback';
import SearchIcon from '@material-ui/icons/Search';
import BlurCircular from '@material-ui/icons/BlurCircular';

import Holdings from "views/admin/Portfolio/Holdings";
import Export from "views/admin/Components/Export";
import AfterHours from "views/admin/Portfolio/AfterHours";
import RealTimeTrades from "views/admin/Portfolio/RealTimeTrades";
import Analysis from "views/admin/Portfolio/Analysis";
import News from "views/admin/Portfolio/News";
import Markets from "views/admin/Markets";
import CopyPeople from "views/admin/Portfolio/CopyPeople";
import FeedbackPage from "views/admin/Components/Feedback";
import Fantasy from "views/admin/Fantasy";
import Screener from "views/admin/Components/Screener";
import ETFDetails from "views/admin/Components/ETFDetails";
import CryptoDashboard from "views/admin/Portfolio/CryptoDashboard";
import StockDetails from "views/admin/Components/StockDetails";
import BallotIcon from '@material-ui/icons/Ballot';
import AppleIcon from '@material-ui/icons/Apple';

var sidebarRoutes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: House,
    iconColor: "Primary",
    component: AlternativeDashboard,
    layout: "/app",
  },
  {
    divider: true,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    icon: Book,
    component: Holdings,
    iconColor: "Primary",
    layout: "/app",
  },
  {
    path: "/after-hours",
    name: "Extended Hours",
    icon: BarChart,
    iconColor: "Primary",
    component: AfterHours,
    layout: "/app",
  },
  {
    path: "/stats",
    name: "Analysis",
    icon: DonutLarge,
    iconColor: "Primary",
    component: Analysis,
    layout: "/app",
  },
  {
    path: "/crypto-dashboard",
    name: "Crypto",
    icon: BlurCircular,
    iconColor: "Primary",
    component: CryptoDashboard,
    layout: "/app",
  },
  {
    path: "/news",
    name: "News",
    icon: MenuBook,
    iconColor: "Primary",
    component: News,
    layout: "/app",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: Event,
    iconColor: "Primary",
    component: Calendar,
    layout: "/app",
  },
  /*{
    path: "/watchlists",
    name: "Watchlists",
    icon: LocationSearchingIcon,
    iconColor: "Primary",
    component: null,
    layout: "/app",
  },*/
  {
    divider: true,
  },
  {
    path: "/copy-people",
    name: "Copy Sync",
    icon: PeopleAlt,
    iconColor: "Primary",
    component: CopyPeople,
    layout: "/app",
  },
  {
    divider: true,
  },
  {
    path: "/markets",
    name: "Markets",
    icon: TrendingUp,
    iconColor: "Primary",
    component: Markets,
    layout: "/app",
  },
  {
    path: "/real-time-trades",
    name: "Real Time Trades",
    icon: Update,
    iconColor: "Primary",
    component: RealTimeTrades,
    layout: "/app",
  },
  {
    path: "/screener",
    name: "Screener",
    icon: SearchIcon,
    iconColor: "Primary",
    component: Screener,
    layout: "/app",
  },
  {
    path: "/etf-details",
    name: "ETF Details",
    icon: BallotIcon,
    iconColor: "Primary",
    component: ETFDetails,
    layout: "/app",
  },
  {
    path: "/stock-details",
    name: "Stock Details",
    icon: AppleIcon,
    iconColor: "Primary",
    component: StockDetails,
    layout: "/app",
  },
  /*{
    path: "/fantasy",
    name: "Fantasy League",
    icon: EmojiEvents,
    iconColor: "Error",
    component: Fantasy,
    layout: "/app",
  },*/
  {
    divider: true,
  },
  
  {
    path: "/export",
    name: "Export",
    icon: SaveAlt,
    iconColor: "Primary",
    component: Export,
    layout: "/app",
  },
  {
    path: "/feedback",
    name: "Give Feedback",
    icon: FeedbackIcon,
    iconColor: "Primary",
    component: FeedbackPage,
    layout: "/app",
  }
];
export default sidebarRoutes;
