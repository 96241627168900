import React, { Suspense, lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const AdminLayout = lazy(() => import('layouts/Admin.js'));

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        return currentUser ? 
        <Suspense fallback={<></>}>
          <AdminLayout {...props} />
        </Suspense> 
        : 
        <Redirect to="/auth/login" />
      }}
    ></Route>
  )
}