import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { toast } from "react-toastify";

const ServiceContext = React.createContext();

export function useService() {
  return useContext(ServiceContext);
}

const server = {
  LOCAL: "http://127.0.0.1:5000/",
  DEVELOP: "https://server-bullsheet.koyeb.app/",
  PROD: "https://server.bullsheet.me/",
};
const flags = {
  "Not Classified": "https://hatscripts.github.io/circle-flags/flags/xx.svg",
  Other: "https://hatscripts.github.io/circle-flags/flags/aq.svg",
  "United States": "https://hatscripts.github.io/circle-flags/flags/us.svg",
  Japan: "https://hatscripts.github.io/circle-flags/flags/jp.svg",
  "United Kingdom": "https://hatscripts.github.io/circle-flags/flags/gb.svg",
  Germany: "https://hatscripts.github.io/circle-flags/flags/de.svg",
  Netherlands: "https://hatscripts.github.io/circle-flags/flags/nl.svg",
  Cyprus: "https://hatscripts.github.io/circle-flags/flags/cy.svg",
  "Hong Kong": "https://hatscripts.github.io/circle-flags/flags/hk.svg",
  China: "https://hatscripts.github.io/circle-flags/flags/cn.svg",
  Argentina: "https://hatscripts.github.io/circle-flags/flags/ar.svg",
  Luxembourg: "https://hatscripts.github.io/circle-flags/flags/lu.svg",
  Brazil: "https://hatscripts.github.io/circle-flags/flags/br.svg",
  Spain: "https://hatscripts.github.io/circle-flags/flags/es.svg",
  Mexico: "https://hatscripts.github.io/circle-flags/flags/mx.svg",
  Finland: "https://hatscripts.github.io/circle-flags/flags/fi.svg",
  Israel: "https://hatscripts.github.io/circle-flags/flags/il.svg",
  Ireland: "https://hatscripts.github.io/circle-flags/flags/ie.svg",
  Canada: "https://hatscripts.github.io/circle-flags/flags/ca.svg",
  France: "https://hatscripts.github.io/circle-flags/flags/fr.svg",
  Belgium: "https://hatscripts.github.io/circle-flags/flags/be.svg",
  Italy: "https://hatscripts.github.io/circle-flags/flags/it.svg",
  Switzerland: "https://hatscripts.github.io/circle-flags/flags/ch.svg",
  Russia: "https://hatscripts.github.io/circle-flags/flags/ru.svg",
  India: "https://hatscripts.github.io/circle-flags/flags/in.svg",
  Bermuda: "https://hatscripts.github.io/circle-flags/flags/bm.svg",
  Australia: "https://hatscripts.github.io/circle-flags/flags/au.svg",
  Jersey: "https://hatscripts.github.io/circle-flags/flags/je.svg",
  "South Africa": "https://hatscripts.github.io/circle-flags/flags/za.svg",
  "Czech Republic": "https://hatscripts.github.io/circle-flags/flags/cz.svg",
  Norway: "https://hatscripts.github.io/circle-flags/flags/no.svg",
  Singapore: "https://hatscripts.github.io/circle-flags/flags/sg.svg",
  Sweden: "https://hatscripts.github.io/circle-flags/flags/se.svg",
  Austria: "https://hatscripts.github.io/circle-flags/flags/at.svg",
  Denmark: "https://hatscripts.github.io/circle-flags/flags/dk.svg",
  Macau: "https://hatscripts.github.io/circle-flags/flags/mo.svg",
  "Isle of Man": "https://hatscripts.github.io/circle-flags/flags/im.svg",
  Guernsey: "https://hatscripts.github.io/circle-flags/flags/gg.svg",
  Georgia: "https://hatscripts.github.io/circle-flags/flags/ge.svg",
  Vietnam: "https://hatscripts.github.io/circle-flags/flags/vn.svg",
  Gibraltar: "https://hatscripts.github.io/circle-flags/flags/gi.svg",
  UK: "https://hatscripts.github.io/circle-flags/flags/uk.svg",
  USA: "https://hatscripts.github.io/circle-flags/flags/us.svg",
  Chile: "https://hatscripts.github.io/circle-flags/flags/cl.svg",
  "Cayman Islands": "https://hatscripts.github.io/circle-flags/flags/ky.svg",
  Taiwan: "https://hatscripts.github.io/circle-flags/flags/tw.svg",
  Portugal: "https://hatscripts.github.io/circle-flags/flags/pt.svg",
  Malta: "https://hatscripts.github.io/circle-flags/flags/mt.svg",
  "South Korea": "https://hatscripts.github.io/circle-flags/flags/kr.svg",
  Greece: "https://hatscripts.github.io/circle-flags/flags/gr.svg",
  Uruguay: "https://hatscripts.github.io/circle-flags/flags/uy.svg",
  Monaco: "https://hatscripts.github.io/circle-flags/flags/mc.svg",
  Panama: "https://hatscripts.github.io/circle-flags/flags/pa.svg",
  Colombia: "https://hatscripts.github.io/circle-flags/flags/co.svg",
  "Saudi Arabia": "https://hatscripts.github.io/circle-flags/flags/sa.svg",
  Bitcoin: require("assets/img/theme/BTC_Logo.png").default,
};
const countryCodes = {
  "Not Classified": "XX",
  Other: "XX",
  "United States": "US",
  Japan: "JP",
  "United Kingdom": "GB",
  Germany: "DE",
  Netherlands: "NL",
  Cyprus: "CY",
  "Hong Kong": "HK",
  China: "CN",
  Argentina: "AR",
  Luxembourg: "LU",
  Brazil: "BR",
  Spain: "ES",
  Mexico: "MX",
  Finland: "FI",
  Israel: "IL",
  Ireland: "IE",
  Canada: "CA",
  France: "FR",
  Belgium: "BE",
  Italy: "IT",
  Switzerland: "CH",
  Russia: "RU",
  India: "IN",
  Bermuda: "BM",
  Australia: "AU",
  Jersey: "JE",
  "South Africa": "ZA",
  "Czech Republic": "CZ",
  Norway: "NO",
  Singapore: "SG",
  Sweden: "SE",
  Austria: "AT",
  Denmark: "DK",
  Macau: "MO",
  "Isle of Man": "IM",
  Guernsey: "GG",
  Georgia: "GE",
  Vietnam: "VN",
  Gibraltar: "GI",
  USA: "US",
  Chile: "CL",
  "Cayman Islands": "KY",
  Taiwan: "TW",
  Portugal: "PT",
  Malta: "MT",
  "South Korea": "KR",
  Greece: "GR",
  Uruguay: "UY",
  Monaco: "MC",
  Panama: "PA",
  Colombia: "CO",
  "Saudi Arabia": "SA",
};
const countryCodesNew = {
  XX: "Other",
  US: "United States",
  JP: "Japan",
  GB: "United Kingdom",
  DE: "Germany",
  NL: "Netherlands",
  CY: "Cyprus",
  HK: "Hong Kong",
  CN: "China",
  AR: "Argentina",
  LU: "Luxembourg",
  BR: "Brazil",
  ES: "Spain",
  MX: "Mexico",
  FI: "Finland",
  IL: "Israel",
  IE: "Ireland",
  CA: "Canada",
  FR: "France",
  BE: "Belgium",
  IT: "Italy",
  CH: "Switzerland",
  RU: "Russia",
  IN: "India",
  BM: "Bermuda",
  AU: "Australia",
  JE: "Jersey",
  ZA: "South Africa",
  CZ: "Czech Republic",
  NO: "Norway",
  SG: "Singapore",
  SE: "Sweden",
  AT: "Austria",
  DK: "Denmark",
  MO: "Macau",
  IM: "Isle of Man",
  GG: "Guernsey",
  GE: "Georgia",
  VN: "Vietnam",
  GI: "Gibraltar",
  CL: "Chile",
  KY: "Cayman Islands",
  TW: "Taiwan",
  PT: "Portugal",
  MT: "Malta",
  KR: "South Korea",
  GR: "Greece",
  UY: "Uruguay",
  MC: "Monaco",
  PA: "Panama",
  CO: "Colombia",
  SA: "Saudi Arabia",
};

export const currentServer = server.DEVELOP;

export function ServiceProvider({ children }) {
  const { currentUser } = useAuth();

  async function getRequest(url) {
    const idToken = await currentUser.getIdToken();
    return axios.get(url, {
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  async function postRequest(url, data) {
    const idToken = await currentUser.getIdToken();
    return axios.post(url, data, { headers: { Authorization: "Bearer " + idToken } });
  }

  function checkMaintenance() {
    return getRequest(currentServer + "checkMaintenance");
  }

  function getUserPortfolio(username) {
    return getRequest(currentServer + "getPortfolio/" + username);
  }

  function validatePortfolio(username) {
    return getRequest(currentServer + "validatePortfolio/" + username);
  }

  function checkUserExistence(username) {
    return getRequest(currentServer + "getUserInfo/" + username);
  }

  function getUserInfo(username) {
    return getRequest(currentServer + "getUserInfo/" + username);
  }

  function getStripeInvoices() {
    return getRequest(currentServer + "getStripeInvoices");
  }

  function getUserInstruments(username) {
    return getRequest(currentServer + "getUserInstruments/" + username);
  }

  function getAllEtfs() {
    return getRequest(currentServer + "getAllEtfs");
  }

  function getEtf(ticker) {
    return getRequest(currentServer + "getEtf/" + ticker);
  }

  function getAllStocks() {
    return getRequest(currentServer + "getAllStocks");
  }

  function getStock(instrumentId) {
    return getRequest(currentServer + "getStock/" + instrumentId);
  }

  function getTrPin() {
    return getRequest(currentServer + "getTrPin");
  }

  function loginTr(id, pin) {
    //return axios.post("https://api.traderepublic.com/api/v1/auth/web/login/" + id + "/" + pin, {}, { headers: { 'Content-Type': 'application/json', 'origin': 'https://app.traderepublic.com', 'referer': 'https://app.traderepublic.com' }})
    return postRequest(currentServer + "loginTr", { id: id, pin: pin });
  }

  function getAssetsByFilter(filter) {
    return postRequest(currentServer + "postScreener", filter);
  }

  function getAssetCountByFilter(filter, getIndustries) {
    return postRequest(currentServer + "getScreenerCount", {
      filter: filter,
      getIndustries: getIndustries,
    });
  }

  function postCryptoWallet(username, blockchain, address) {
    return postRequest(currentServer + "postCryptoWallet", {
      username: username,
      blockchain: blockchain,
      address: address,
    });
  }

  function deleteCryptoWallet(username, address) {
    return postRequest(currentServer + "deleteCryptoWallet", {
      username: username,
      address: address,
    });
  }

  function downloadFile(username, extension) {
    let config = {
      headers: { Authorization: "Bearer " + currentUser.idToken },
    };
    const url =
      currentServer + "download/" + username + "/" + extension + "/" + currentUser.idToken;
    getRequest(url)
      .then((response) => {
        window.open(url, "_blank");
      })
      .catch((err) => {
        if (err.message.includes("403")) {
          toast("🔒 Unauthorized");
        } else {
          toast("🔒 An error occured. Please contact info@bullsheet.me");
        }
        return -1;
      });
  }

  function getNews(instruments) {
    let tickerList = [];
    instruments
      .filter((instr) => instr.assetClassId != 10)
      .forEach((instrument) => {
        tickerList.push(instrument.ticker);
      });
    if (tickerList.length > 0) {
      return getRequest(currentServer + "getNews/" + tickerList);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getNewsForInstrument(instrument) {
    if (instrument.ticker) {
      return getRequest(currentServer + "getNews/" + instrument.ticker);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getCryptoNews(instruments) {
    let tickerList = [];
    instruments.forEach((instrument) => {
      tickerList.push(instrument.ticker);
    });
    if (tickerList.length > 0) {
      return getRequest(
        currentServer +
          "getCryptoNews/" +
          (instruments ? instruments.length : 15) +
          "/" +
          tickerList
      );
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getMarketInfo() {
    return getRequest(currentServer + "getMarketInfo");
  }

  function getCryptoData() {
    return getRequest(currentServer + "getCryptoData");
  }

  function getLatestAssetPrices(instruments) {
    let instrumentIdList = [];
    instruments.forEach((instrument) => {
      instrumentIdList.push(instrument.instrumentId);
    });
    if (instrumentIdList?.length > 0) {
      return getRequest(currentServer + "getLatestAssetPrices/" + instrumentIdList);
    } else {
      return getRequest(currentServer + "getLatestExtendedMarketPrices");
    }
  }

  function getLatestInsiderTradesByInstrument(instruments) {
    let instrumentIdList = [];
    instruments.forEach((instrument) => {
      instrumentIdList.push(instrument.instrumentId);
    });
    if (instrumentIdList?.length > 0) {
      return getRequest(currentServer + "getLatestInsiderTradesByInstrument/" + instrumentIdList);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getSingleLatestInsiderTrades(instrument) {
    if (instrument) {
      return getRequest(currentServer + "getLatestInsiderTradesByInstrument/" + instrument);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getAssetFundamentals(instruments) {
    let instrumentIdList = [];
    instruments.forEach((instrument) => {
      instrumentIdList.push(instrument.instrumentId);
    });
    if (instrumentIdList?.length > 0) {
      return getRequest(currentServer + "getFundamentals/" + instrumentIdList);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getInstrumentRisks(instruments) {
    let instrumentIdList = [];
    instruments.forEach((instrument) => {
      instrumentIdList.push(instrument.instrumentId);
    });
    if (instrumentIdList?.length > 0) {
      return getRequest(currentServer + "getInstrumentRisks/" + instrumentIdList);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function parseInstrumentsToFetch(copySyncData, instruments) {
    const instrumentIdList = [];
    if (copySyncData?.copySync?.effectivePortfolio?.length > 0) {
      copySyncData?.copySync?.effectivePortfolio.slice(0, 50).forEach((instrument) => {
        if (instrument.instrumentId > 0) {
          instrumentIdList.push(instrument.instrumentId);
        }
      });
    } else if (instruments?.length > 0) {
      instruments.forEach((instrument) => {
        if (instrument.instrumentId > 0) {
          instrumentIdList.push(instrument.instrumentId);
        }
      });
    }
    return instrumentIdList;
  }

  function getEarnings(copySyncData, instruments) {
    const instrumentIdList = [];
    //const instrumentIdList = parseInstrumentsToFetch(copySyncData, instruments);
    instruments.forEach((instrument) => {
      if (instrument.instrumentId > 0) {
        instrumentIdList.push(instrument.instrumentId);
      }
    });
    if (instrumentIdList?.length > 0) {
      return getRequest(currentServer + "getEarnings/" + instrumentIdList);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getDividends(instruments) {
    let instrumentIdList = [];
    instruments.forEach((instrument) => {
      instrumentIdList.push(instrument.instrumentId);
    });
    if (instrumentIdList?.length > 0) {
      return getRequest(currentServer + "getDividends/" + instrumentIdList);
    } else {
      return Promise.resolve({ data: [] });
    }
  }

  function getCopySync(username) {
    return getRequest(currentServer + "getCopySync/" + username);
  }

  function getParentPerformances(username) {
    return getRequest(currentServer + "getParentPerformances/" + username);
  }

  function getPortfolioCountries(username) {
    return getRequest(currentServer + "getPortfolioCountries/" + username);
  }

  function getRiskContributers(username) {
    return getRequest(currentServer + "getRiskContributers/" + username);
  }

  function logScreenerSearch(filter) {
    return postRequest(currentServer + "logScreenerSearch", {
      filter: filter,
      email: currentUser?.email,
    });
  }

  function logDarkModeToggle(type) {
    return postRequest(currentServer + "logDarkModeToggle", {
      email: currentUser?.email,
      type: type,
    });
  }

  function postFeedback(name, email, type, text) {
    return postRequest(currentServer + "postFeedback", {
      name: name,
      email: email,
      type: type,
      text: text,
    });
  }

  const value = {
    checkMaintenance,
    validatePortfolio,
    getUserPortfolio,
    getUserInfo,
    getStripeInvoices,
    checkUserExistence,
    getUserInstruments,
    downloadFile,
    getNews,
    getNewsForInstrument,
    getCryptoNews,
    getAssetFundamentals,
    getCopySync,
    getMarketInfo,
    getCryptoData,
    getInstrumentRisks,
    getRiskContributers,
    getEarnings,
    getLatestAssetPrices,
    getDividends,
    postFeedback,
    getLatestInsiderTradesByInstrument,
    getSingleLatestInsiderTrades,
    getAllEtfs,
    getEtf,
    getAllStocks,
    getStock,
    getParentPerformances,
    getPortfolioCountries,
    getAssetsByFilter,
    getAssetCountByFilter,
    logScreenerSearch,
    logDarkModeToggle,
    postCryptoWallet,
    deleteCryptoWallet,
    getTrPin,
    loginTr,
    flags,
    countryCodes,
    countryCodesNew,
  };

  return <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>;
}
