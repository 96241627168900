import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/tables/card-dividend-focus.js";
import { useTranslation } from "react-i18next";
import { CardContent } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Company", "Yield", "Value", "Ex-Div Date",];

// Quick find: Portfolio Dividends
export default function CardDividendFocus({portfolioDividends}) {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Analysis' });

  React.useEffect( () => {
    new List(tableConatinerRef.current, {
      valueNames: ["ticker", "yield", "value", "ex-div date", ],
      listClass: "list",
    });
    return;
  }, [])

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("dividends")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("portfolioDividends")}</Box>
                  <FeatureInfoWithBox description={t("portfolioDividendsTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto" className={classes.gridItemRoot}>
                {/* <CustomCheckbox defaultChecked={false} />
                <Box fontSize={theme.typography.h5.fontSize} fontWeight="400" component="small">
                  Hide 0 balances
                </Box> */}
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        { portfolioDividends && portfolioDividends.assets.length > 0 ?
        <TableContainer classes={{ root: classes.tableRoot }} ref={tableConatinerRef}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                  key={key}
                  {...{
                    "data-sort":
                      prop !== "" 
                        ? (prop === "Company" ? "ticker" : prop).toLowerCase()
                        : undefined,
                  }}
                  scope="col"
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " sort " +
                      classes.tableCellRootHead,
                  }}
                >
                  {t(prop)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {portfolioDividends ?
              portfolioDividends.assets.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="body"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.tableLogo }}
                        marginRight="0.5rem"
                        alt="..."
                        src={prop.logo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box fontSize=".75rem" component="span" className="ticker">
                          {prop.ticker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }} >
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="yield">
                      { prop.yield == "Suspended" ? prop.yield : (prop.yield * 100).toFixed(2) + "%" }
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="value">
                      {parseFloat(prop.value.toFixed(2))}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center">
                      <Box component="span" marginRight=".5rem" className="ex-div date">
                        { prop.exDividendDate ? prop.exDividendDate : "-" }
                      </Box>
                    </Box>
                  </TableCell>
                  
                </TableRow>
              )) : <></> }
            </TableBody>
          </Table>
        </TableContainer>
        : 
        <CardContent>
          <Typography
              component="h5"
              variant="h5"
              style={{marginTop: "150px", marginLeft: "50px"}}
            >
              You've got no dividend assets :(
          </Typography>
          <img
          alt="..."
          src={require("assets/img/theme/weak_bull.png").default}
          style={{width:"200px", marginTop: "10px", marginLeft: "50px"}}
          /> 
        </CardContent> }
      </Card>
    </>
  );
}
