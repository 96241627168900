const componentStyles = () => ({
  cardImgTop: {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    maxHeight: "22rem",
    objectFit: "cover", 
  },
  cardImgTopArticle: {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    maxHeight: "13rem",
    objectFit: "cover", 
  },
  cardImgTopBackground: {
    borderRadius: "1rem",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    maxHeight: "20rem",
    objectFit: "cover", 
    opacity: 0.15,
  },
  cardImgOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: "1.25rem",
    borderRadius: "1rem",
  },
  cardImgTopSmall: {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    maxHeight: "7rem",
    objectFit: "cover", 
  },
});

export default componentStyles;
