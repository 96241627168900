import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles({
    featureInfo: {
        fontSize: "0.8em !important",
        color: "#B2B2B2",
        paddingLeft: "0.2em",

        "&:hover": {
            cursor: "pointer",
            color: "#1f1f1f",
        }
    },
    tooltipText: {
        fontSize: "0.8rem",
        lineHeight: "1.2em",
        fontWeight: "normal",
        textAlign: "left",
        padding: "0.5em 0em 1em 0em",
    }
})

const FeatureInfoTooltip = withStyles((theme) => ({
tooltip: {
    backgroundColor: theme.palette.bsColors.GlobalWhite,
    borderColor: theme.palette.bsColors.MainBlue,
    border: "1px solid",
    color: "#333",
    maxWidth: 250,
    borderRadius: '1em',
    textAlign: "left",
    whiteSpace: "normal",
},
arrow: {
    color: theme.palette.bsColors.MainBlue,
}
}))(Tooltip);


function FeatureInfoWithBox(description) {
        const classes = useStyles();
        const tooltip = (description) => {
            return <Box px={2} flexDirection='column'>
                <Box className={classes.tooltipText}>
                    {description.toString()}
                </Box>
            </Box>
        }
        return <FeatureInfoTooltip
            title={
                tooltip(description["description"])
            }
            PopperProps={{
                disablePortal: true,
            }}
            arrow
            placement="right"
            enterDelay={1}
            enterTouchDelay={1}
        >
            <InfoOutlined style={{marginLeft:"0.3rem"}} />
        </FeatureInfoTooltip>

}

export default FeatureInfoWithBox;