import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReactRotatingText from "react-rotating-text";
import styled from "styled-components";

// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/headers/index-header.js";

const BSRotatingText = styled(ReactRotatingText)`
.react-rotating-text-cursor {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
  @keyframes blinking-cursor {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 100%;
      background-color: #4daa57; 
    }
    100% {
      opacity: 0;
    }
  }
}
`;

const useStyles = makeStyles(componentStyles);

const IndexHeader = () => {

  const classes = useStyles();
  const newFeatures = ['Superpower', 'Automate', 'Improve', 'Amplify', 'Revamp',]
  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="3rem"
        paddingBottom="2rem"
      >
        <Container maxWidth="xl">
          <Box>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12}>
                <Box marginTop="2rem">
                    <Typography
                      variant="h1"
                      component="h1"
                      className={classes.typographyH1}
                    >
                      <b><BSRotatingText 
                      style={{backgroundImage:"linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(126, 38%, 48%, 1) 75%)", 
                      "WebkitBackgroundClip": "text",
                      "WebkitTextFillColor": "transparent",
                      "MozBackgroundClip": "text",
                      "MozTextFillColor": "transparent",}} 
                      color="#6A1EC0"
                      deletingInterval={30} items={newFeatures} fontFamily="Open Sans, sans-serif"/></b>
                      <br/>your portfolio management
                    </Typography>
                  <Box marginTop="1.5rem" textAlign="-webkit-center">
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.heroSubheader}
                    >
                      Make better investment decisions by automatically importing and tracking your stocks across several verticals
                    </Typography>
                  </Box>
                  <Box marginTop="2rem" textAlign="center">
                    <Button
                      component={Link}
                      variant="contained"
                      to="/auth/register"
                      classes={{ root: classes.buttonRoot }}
                    >
                      Sign Up for Free
                    </Button>
                  </Box>
                  <Box marginTop="3rem" textAlign="center" marginBottom="-3rem">
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.typographyH3}
                    >
                      Works with:{"      "}
                    <img
                      alt="eToro"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Etoro_logo.svg/1200px-Etoro_logo.svg.png"
                      width="70rem" 
                      style={{verticalAlign:"top"}}
                    />
                    </Typography>

                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={12} style={{textAlign:"center", marginBottom:"-7rem"}}>
                <Box
                  component="img"
                  alt="Automate your Etoro Portfolio"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/bullsheet_for_etoro.png").default}
                ></Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default IndexHeader;
