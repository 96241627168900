export const themeColors = {
  white: {
    main: "#FFFFFF", //Used for whites and card backgrounds. If it's white on both modes, use bsColors.GlobalWhite
  },
  black: {
    light: "#12263F",
    main: "#000000",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  inputText: {
    main: "#8898aa",
  },
  primary: {
    main: "#5e72e4",
    dark: "#233dd2",
    snackbar: "#7889e8",
    badgeBg: "#eaecfb",
    badgeBgHover: "#2a44db",
    badge: "#2643e9",
    inputCredit: "#324cdd",
  },
  secondary: {
    main: "#f7fafc",
    snackbar: "#f8fbfc",
    badgeBgHover: "#cadeeb",
    btnOutline: "#4385b1",
    btnActive: "#d2e3ee",
  },
  warning: {
    tableLight: "#fed3ca",
    tableLightHover: "#febeb1",
    light: "#ffd600",
    main: "#fb6340",
    snackbar: "#fc7c5f",
    badgeBg: "#fee6e0",
    badgeBgHover: "#f93305",
    badge: "#ff3709",
  },
  error: {
    light: "#f3a4b5",
    main: "#f5365c",
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
  },
  info: {
    main: "#11cdef",
    snackbar: "#37d5f2",
    badgeBg: "#aaedf9",
    badgeBgHover: "#0c9cb7",
    badge: "#03acca",
  },
  background: {
    default: "#f8f9fe", //Used for background (behind Cards)
  },
  text: {
    primary: "#1B1B4F",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#1b1b4f",
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBg: "#4172c6",
    badgeBgHover: "#09111e",
  },
  success: {
    tableLightHover: "#afecd2",
    tableLight: "#c4f1de",
    main: "#2dce89",
    snackbar: "#4fd69c",
    badgeBg: "#b0eed3",
    badgeBgHover: "#229c68",
    badge: "#1aae6f",
  },
  facebook: {
    main: "#3b5999",
    dark: "#2d4474",
  },
  twitter: {
    main: "#1da1f2",
    dark: "#0c85d0",
  },
  google: {
    main: "#dd4b39",
    dark: "#c23321",
  },
  instagram: {
    main: "#e4405f",
    dark: "#d31e40",
  },
  pinterest: {
    main: "#bd081c",
    dark: "#8c0615",
  },
  youtube: {
    main: "#cd201f",
    dark: "#a11918",
  },
  vimeo: {
    main: "#04A0F0",
    dark: "#037fbe",
  },
  slack: {
    main: "#3aaf85",
    dark: "#2d8968",
  },
  dribbble: {
    main: "#ea4c89",
    dark: "#e51e6b",
  },
  github: {
    main: "#222222",
    dark: "#090909",
  },
  bsColors: {
    //Main Palette
    MainBlue: '#1B1B4F', //Main Color. For texts, buttons
    LogoLightBlue: '#4D86C5', //Main Secondary color
    Orange: "#FF570A", //Orange for Buttons and Labels
    Yellow: '#FCBF49', //Used in Pie Charts and on Orange Button Hover
    GlobalWhite: "#FAFAFA", //For when it's supposed to be white on both themes. Otherwise use white.main
    
    //Colors for Positive/Negative Numbers
    NumberRed: "#E52527",
    NumberGreen: "#74B82F",

    //Navigation Buttons and Text
    SideBarBlue: "#dddddd", //Sidebar links color
    SidebarHoverGray: "#EEEEEE", //Used for hover of sidebar Links.
    StarBlue: "#00AEFF", //Star Color
    NavBarMobileIcons: "#808080", //Icon Color for mobile navigation icons
    ChartLines: "#EEE", // Chart Scale lines (e.g. Sector breakdown horizontal)
    LightGray: '#F1F1F1', //For footer links on ungated pages before hover
    SidebarDivider: "#777777", //For Section Dividers on Sidebar

    BackgroundLightestBlue: "#F0F4FA", //Not in use, but possibly to use for background
    
    //Used for Market Performance Cards
    RedGradientTop: "#B44143",
    RedGradientBot: "#C45A5C",
    GreenGradientTop: "#9EC346",
    GreenGradientBot: "#AECD65",
    
    MainBlueDarkGradient: '#1a174d', //Used for gradients with MainBlue
    BlueGradientBot: "#3D78BB", //Blue Gradient to use with LogoLightBlue
    GreenPantone: "#4DAA57", //Used for Rectangles on ETF Quick Pick

    //Risk Colors
    Risk0: "#7D6D61",
    Risk1: "#1B1B4F",
    Risk2: "#4D86C5",
    Risk3: "#9EC346",
    Risk4: "#D6C047",
    Risk5: "#FCBF49",
    Risk6: "#FF9C33",
    Risk7: "#FA8334",
    Risk8: "#EE6055",
    Risk9: "#C45A5C",

    //Pie Colors
    Pie0: "#1b1b4f",
    Pie1: "#4e2160",
    Pie2: "#7e2367",
    Pie3: "#ab2864",
    Pie4: "#d13a59",
    Pie5: "#ed5747",
    Pie6: "#fd7d2f",
    Pie7: "#FE9719",
    Pie8: "#ffa600",
    Pie9: "#ca9d00",
    Pie10: "#6f800b",
    Pie11: "#2b5a22",
    //Pie11: "#013220",
    //Pie11: "#DBE7F3",
    Pie12: "#D62828",
    Pie13: "#D62828",

    //Placeholder to try out new Pie Colors Quickly
    Pie0B: "#F0F4FA",
    Pie1B: "#E0EAF5",
    Pie2B: "#D1DFF0",
    Pie3B: "#C1D4EB",
    Pie4B: "#B2CAE6",
    Pie5B: "#A3C0E1",
    Pie6B: "#93B5DC",
    Pie7B: "#84ABD7",
    Pie8B: "#74A0D2",
    Pie9B: "#6596CD",
    Pie10B: "#568BC8",
    Pie11B: "#4680C3",
    Pie12B: "#3C76B9",

    //Unused Colors that may be re-added in the future

    // main: '#4D86C5',
    // BlackText: '#1f1f1f',

    // BackgroundLight: '#fafafa',
    // BackgroundDashboardLight: "#F5F5FB",
    // BackgroundDashboardDark: "#001233",

    // BackgroundLightBlue: "#DBE7F3",
    // BackgroundLighterBlue: "#E0EAF5",

    // Purple: '#6A1EC0',
    // SapphireBlue: "#0F52BA",
    // Blue: "#4D13D1",
    // Red: "#D72638",
    // Green: "#12F885",
    // PetrolBlue: '#2A9D8F',
    // QueenBlue: "#376CA9",
    // PrincetonOrange: "#FA8334",

    // SideBarDark: "#001845",
  }
};

export const darkThemeColors = {
  white: {
    main: "#171731",
  },
  black: {
    light: "#12263F",
    main: "#ffffff",
  },
  transparent: {
    main: "transparent",
  },
  gray: {
    100: "#0F0F25",
    200: "#171731",
    300: "#ccc",
    400: "#e1e1e1",
    500: "#efefef",
    600: "#f3f3f3",
    700: "#f7f7f7",
    800: "#fafafa",
    900: "#fefefe",
  },
  inputText: {
    main: "#888",
  },
  primary: {
    main: "#B7C2FF",
    dark: "#233dd2",
    snackbar: "#7889e8",
    badgeBg: "#eaecfb",
    badgeBgHover: "#2a44db",
    badge: "#2643e9",
    inputCredit: "#324cdd",
  },
  secondary: {
    main: "#f7fafc",
    snackbar: "#f8fbfc",
    badgeBgHover: "#cadeeb",
    btnOutline: "#4385b1",
    btnActive: "#d2e3ee",
  },
  warning: {
    tableLight: "#fed3ca",
    tableLightHover: "#febeb1",
    light: "#ffd600",
    main: "#fb6340",
    snackbar: "#fc7c5f",
    badgeBg: "#fee6e0",
    badgeBgHover: "#f93305",
    badge: "#ff3709",
  },
  error: {
    light: "#f3a4b5",
    main: "#f5365c",
    snackbar: "#f75676",
    badgeBg: "#fdd1da",
    badgeBgHover: "#e30b36",
    badge: "#f80031",
    dialogNotification: "#f56036",
  },
  info: {
    main: "#11cdef",
    snackbar: "#37d5f2",
    badgeBg: "#aaedf9",
    badgeBgHover: "#0c9cb7",
    badge: "#03acca",
  },
  background: {
    default: "#0B0B1E",
  },
  text: {
    primary: "#f1f1f1",
  },
  dark: {
    tableBorder: "#1f3a68",
    tableHeadColor: "#4d7bca",
    tableHeadBgColor: "#1c345d",
    main: "#1b1b4f",
    dark: "#0b1526",
    snackbar: "#3c4d69",
    badgeBg: "#4172c6",
    badgeBgHover: "#09111e",
  },
  success: {
    tableLightHover: "#afecd2",
    tableLight: "#c4f1de",
    main: "#2dce89",
    snackbar: "#4fd69c",
    badgeBg: "#b0eed3",
    badgeBgHover: "#229c68",
    badge: "#1aae6f",
  },
  facebook: {
    main: "#3b5999",
    dark: "#2d4474",
  },
  twitter: {
    main: "#1da1f2",
    dark: "#0c85d0",
  },
  google: {
    main: "#dd4b39",
    dark: "#c23321",
  },
  instagram: {
    main: "#e4405f",
    dark: "#d31e40",
  },
  pinterest: {
    main: "#bd081c",
    dark: "#8c0615",
  },
  youtube: {
    main: "#cd201f",
    dark: "#a11918",
  },
  vimeo: {
    main: "#04A0F0",
    dark: "#037fbe",
  },
  slack: {
    main: "#3aaf85",
    dark: "#2d8968",
  },
  dribbble: {
    main: "#ea4c89",
    dark: "#e51e6b",
  },
  github: {
    main: "#222222",
    dark: "#090909",
  },
  bsColors: {
    main: '#4949C1',
    BlackText: '#f1f1f1',

    BackgroundLight: '#fafafa',
    BackgroundDashboardLight: "#F5F5FB",
    BackgroundDashboardDark: "#001233",

    MainBlue: '#f1f1f1',
    MainBlueDarkGradient: '#fafafa',
    LogoLightBlue: '#78B6FD',
    LightGray: '#1B1B4F',
    BackgroundLightBlue: "#DBE7F3",
    SidebarHoverGray: "#EEEEEE",
    Purple: '#6A1EC0',
    StarBlue: "#00AEFF",

    SideBarDark: "#001845",
    NavBarMobileIcons: "#f1f1f1",
    ChartLines: "#333", // Chart Scale lines (e.g. Sector breakdown horizontal)
    SidebarDivider: "#777777",

    NumberRed: "#EC5B5D",
    NumberGreen: "#A3DA6C",

    //trying out new colors
    SapphireBlue: "#0F52BA",
    Blue: "#4D13D1",
    Red: "#D72638",
    //Orange: "#4D86C5", //Change letter back to commented
    Orange: "#FF570A",
    Green: "#12F885",
    PetrolBlue: '#2A9D8F',
    Yellow: '#FCBF49',
    QueenBlue: "#376CA9",
    PrincetonOrange: "#FA8334",
    GreenPantone: "#4DAA57",
    GlobalWhite: "#FAFAFA",

    RedGradientTop: "#B44143",
    RedGradientBot: "#C45A5C",
    GreenGradientTop: "#9EC346",
    GreenGradientBot: "#AECD65",
    BlueGradientBot: "#3D78BB",

    Risk0: "#958275",
    Risk1: "#2A2A7A",
    Risk2: "#4D86C5",
    Risk3: "#2A9D8F",
    Risk4: "#FED486",
    Risk5: "#FCBF49",
    Risk6: "#FF9C33",
    Risk7: "#F77F00",
    Risk8: "#EE6055",
    Risk9: "#D62828",

    Pie0: "#2A2A7A",
    Pie1: "#4e2160",
    Pie2: "#7e2367",
    Pie3: "#ab2864",
    Pie4: "#d13a59",
    Pie5: "#ed5747",
    Pie6: "#fd7d2f",
    Pie7: "#ffa600",
    Pie8: "#ca9d00",
    Pie9: "#6f800b",
    Pie10: "#2b5a22",
    Pie11: "#013220",
    Pie12: "#D62828",
    Pie13: "#D62828",

    Pie0B: "#F0F4FA",
    Pie1B: "#E0EAF5",
    Pie2B: "#D1DFF0",
    Pie3B: "#C1D4EB",
    Pie4B: "#B2CAE6",
    Pie5B: "#A3C0E1",
    Pie6B: "#93B5DC",
    Pie7B: "#84ABD7",
    Pie8B: "#74A0D2",
    Pie9B: "#6596CD",
    Pie10B: "#568BC8",
    Pie11B: "#4680C3",
    Pie12B: "#3C76B9",

  }
};

export default themeColors;
