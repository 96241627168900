import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-holdings.js";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(componentStyles);

const tableHeadPerformance = ["Company", "Invested", "P/L%", "Value", "Type", "MTD", "YTD"];
const tableHeadRatios = ["Company", "P/E ratio TTM", "5 Yr avg. P/E", "PEG ratio", "Price/Sales TTM", "RoA"];
const tableHeadFinancials = ["Company", "Market Cap (Local Currency)", "Revenue TTM", "EBITDA", "Gross Profit", "EBIT Margin", "Quarterly EPS Growth"];

export default function CardHoldings({currentEtoroUserData}) {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const [selectedTable, setSelectedTable] = React.useState("performance");
  const [portfolioPerformanceArray, setPortfolioPerformanceArray] = React.useState();
  const [portfolioRatiosArray, setPortfolioRatiosArray] = React.useState();
  const [portfolioFinancialsArray, setPortfolioFinancialsArray] = React.useState();
  const [sort, setSort] = React.useState({'attribute': 'priceChangePercentage', 'direction': 'desc'});
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardHoldings' });

  function handleClick(option) {
    setSelectedTable(option);
  }

  function handleSort(attribute) {
    setSort({'attribute': attribute, 'direction': sort.direction === 'asc' ? 'desc' : 'asc'});
  }

  function sortPortfolioPerformanceArray(sort) {
    if (sort.attribute === 'Company') {
      const newArray = sortStrings(portfolioPerformanceArray, sort.direction, sort.attribute);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setPortfolioPerformanceArray([...newArray]);
    } else if (sort.attribute === 'Invested') {
      const newArray = portfolioPerformanceArray.sort(function(a, b){
        return sort.direction === 'asc' ? a.invested - b.invested : b.invested - a.invested;
      })
      setPortfolioPerformanceArray([...newArray]);
    } else if (sort.attribute === 'Value') {
      const newArray = portfolioPerformanceArray.sort(function(a, b){
        return sort.direction === 'asc' ? a.value - b.value : b.value - a.value;
      })
      setPortfolioPerformanceArray([...newArray]);
    } else if (sort.attribute === 'Type') {
      const newArray = sortStrings(portfolioPerformanceArray, sort.direction, sort.attribute);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setPortfolioPerformanceArray([...newArray]);
    } else if (sort.attribute === 'MTD') {
      const newArray = portfolioPerformanceArray.sort(function(a, b){
        return sort.direction === 'asc' ? a.mtd - b.mtd : b.mtd - a.mtd;
      })
      setPortfolioPerformanceArray([...newArray]);
    } else if (sort.attribute === 'YTD') {
      const newArray = portfolioPerformanceArray.sort(function(a, b){
        return sort.direction === 'asc' ? a.ytd - b.ytd : b.ytd - a.ytd;
      })
      setPortfolioPerformanceArray([...newArray]);
    } else {
      const newArray = portfolioPerformanceArray.sort(function(a, b){
        return sort.direction === 'asc' ? a.PLper - b.PLper : b.PLper - a.PLper;
      })
      setPortfolioPerformanceArray([...newArray]);
    }
  }

  function sortPortfolioRatiosArray(sort) {
    if (sort.attribute === 'Company') {
      const newArray = sortStrings(portfolioRatiosArray, sort.direction, sort.attribute);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setPortfolioRatiosArray([...newArray]);
    }
    else if (sort.attribute === 'Price/Sales TTM') {
      const newArray = portfolioRatiosArray.sort(function(a, b){
        const priceToSales1 = parseNonExistingValues(a.priceToSales);
        const priceToSales2 = parseNonExistingValues(b.priceToSales);
        return sort.direction === 'asc' ? priceToSales1 - priceToSales2 : priceToSales2 - priceToSales1;
      })
      setPortfolioRatiosArray([...newArray]);
    } else if (sort.attribute === 'P/E ratio TTM') {
      const newArray = portfolioRatiosArray.sort(function(a, b){
        const peRatio1 = parseNonExistingValues(a.peRatio);
        const peRatio2 = parseNonExistingValues(b.peRatio);
        return sort.direction === 'asc' ? peRatio1 - peRatio2 : peRatio2 - peRatio1;
      })
      setPortfolioRatiosArray([...newArray]);
    } else if (sort.attribute === 'Forward P/E') {
      const newArray = portfolioRatiosArray.sort(function(a, b){
        const fiveYearAveragePERatio1 = parseNonExistingValues(a.fiveYearAveragePERatio);
        const fiveYearAveragePERatio2 = parseNonExistingValues(b.fiveYearAveragePERatio);
        return sort.direction === 'asc' ? fiveYearAveragePERatio1 - fiveYearAveragePERatio2 : fiveYearAveragePERatio2 - fiveYearAveragePERatio1;
      })
      setPortfolioRatiosArray([...newArray]);
    } else if (sort.attribute === 'PEG ratio') {
      const newArray = portfolioRatiosArray.sort(function(a, b){
        const pegRatio1 = parseNonExistingValues(a.pegRatio);
        const pegRatio2 = parseNonExistingValues(b.pegRatio);
        return sort.direction === 'asc' ? pegRatio1 - pegRatio2 : pegRatio2 - pegRatio1;
      })
      setPortfolioRatiosArray([...newArray]);
    } else {
      const newArray = portfolioRatiosArray.sort(function(a, b){
        const returnOnAssets1 = parseNonExistingValues(a.returnOnAssets);
        const returnOnAssets2 = parseNonExistingValues(b.returnOnAssets);
        return sort.direction === 'asc' ? returnOnAssets1 - returnOnAssets2 : returnOnAssets2 - returnOnAssets1;
      })
      setPortfolioRatiosArray([...newArray]);
    }
  }

  function parseNonExistingValues(value) {
    return value === "-" ? -Infinity : value;
  }

  function sortPortfolioFinancialsArray(sort) {
    if (sort.attribute === 'Market Cap (Local Currency)') {
      const newArray = portfolioFinancialsArray.sort(function(a, b){
        const marketCap1 = parseNonExistingValues(a.marketCap);
        const marketCap2 = parseNonExistingValues(b.marketCap);
        return sort.direction === 'asc' ? marketCap1 - marketCap2 : marketCap2 - marketCap1;
      })
      setPortfolioFinancialsArray([...newArray]);
    }
    else if (sort.attribute === 'Revenue TTM') {
      const newArray = portfolioFinancialsArray.sort(function(a, b){
        const revenueTTM1 = parseNonExistingValues(a.revenueTTM);
        const revenueTTM2 = parseNonExistingValues(b.revenueTTM);
        return sort.direction === 'asc' ? revenueTTM1 - revenueTTM2 : revenueTTM2 - revenueTTM1;
      })
      setPortfolioFinancialsArray([...newArray]);
    } else if (sort.attribute === 'EBITDA') {
      const newArray = portfolioFinancialsArray.sort(function(a, b){
        const ebitda1 = parseNonExistingValues(a.ebitda);
        const ebitda2 = parseNonExistingValues(b.ebitda);
        return sort.direction === 'asc' ? ebitda1 - ebitda2 : ebitda2 - ebitda1;
      })
      setPortfolioFinancialsArray([...newArray]);
    } else if (sort.attribute === 'Gross Profit') {
      const newArray = portfolioFinancialsArray.sort(function(a, b){
        const grossProfit1 = parseNonExistingValues(a.grossProfit);
        const grossProfit2 = parseNonExistingValues(b.grossProfit);
        return sort.direction === 'asc' ? grossProfit1 - grossProfit2 : grossProfit2 - grossProfit1;
      })
      setPortfolioFinancialsArray([...newArray]);
    } else if (sort.attribute === 'EBIT Margin') {
      const newArray = portfolioFinancialsArray.sort(function(a, b){
        const operationMargin1 = parseNonExistingValues(a.operationMargin);
        const operationMargin2 = parseNonExistingValues(b.operationMargin);
        return sort.direction === 'asc' ? operationMargin1 - operationMargin2 : operationMargin2 - operationMargin1;
      })
      setPortfolioFinancialsArray([...newArray]);
    } else {
      const newArray = portfolioFinancialsArray.sort(function(a, b){
        const quarterlyEarningsGrowth1 = parseNonExistingValues(a.quarterlyEarningsGrowth);
        const quarterlyEarningsGrowth2 = parseNonExistingValues(b.quarterlyEarningsGrowth);
        return sort.direction === 'asc' ? quarterlyEarningsGrowth1 - quarterlyEarningsGrowth2 : quarterlyEarningsGrowth2 - quarterlyEarningsGrowth1;
      })
      setPortfolioFinancialsArray([...newArray]);
    }
  }


  React.useEffect(() => {
    if (selectedTable === "financials" && portfolioFinancialsArray) {
      sortPortfolioFinancialsArray(sort)
    } else if (selectedTable === "ratios" && portfolioRatiosArray) {
      sortPortfolioRatiosArray(sort)
    } else if (selectedTable === "performance" && portfolioPerformanceArray){
      sortPortfolioPerformanceArray(sort)
    }
  }, [sort]);
  

  React.useEffect(() => {
    const newPortfolioPerformanceArray = []; 
    const newPortfolioRatiosArray = []; 
    const newPortfolioFinancialsArray = []; 
    if (currentEtoroUserData?.currentPortfolio && currentEtoroUserData?.userInstruments && currentEtoroUserData?.assetFundamentals) {
      currentEtoroUserData.currentPortfolio.summary.positions.forEach((position) => {
        const asset = currentEtoroUserData.userInstruments.instruments.find(entry => entry.instrumentId === position["instrumentId"]);
        let historicalPerformance;
        if (currentEtoroUserData.currentPortfolio.historicalAssetPerformances) {
           historicalPerformance = currentEtoroUserData.currentPortfolio.historicalAssetPerformances.find(entry => entry.instrumentId === position["instrumentId"]);
        }
        let companyLogo = "";
        let ticker = ""
        let logoColor = "#F7F7F7"
        if(asset) {
          companyLogo = asset.media[asset.media.findIndex(x => x.width == 0)]?.uri;
          if (!companyLogo) {
            companyLogo = asset.media.slice(-1)[0]?.uri;
          }
          logoColor = companyLogo?.substr(companyLogo.indexOf("_") + 1, 6)

          ticker = asset.ticker;
        } else {
          // if asset is a copy position, company logo becomes parent photo
          const parent = currentEtoroUserData.userInstruments?.copyParentUsernames ? currentEtoroUserData.userInstruments.copyParentUsernames[position.instrumentId] : undefined;
          companyLogo = parent ? parent.media[parent.media.length - 1].uri : undefined;
          ticker = position.instrumentId;
        }
        newPortfolioPerformanceArray.push({"companyLogo": companyLogo, "logoColor": logoColor, "companyTicker": ticker, "invested": position["investmentPctRealized"],
        "PLper": position["profitPct"], "value": position["valuePctUnrealized"], "isBuy": position["isBuy"] ? "Buy" : "Sell", 
        "mtd": historicalPerformance ? historicalPerformance["mtd"] : null , "ytd": historicalPerformance ? historicalPerformance["ytd"] : null});
      });
      currentEtoroUserData.assetFundamentals.forEach((position) => {
        const asset = currentEtoroUserData.userInstruments.instruments.find(entry => entry.instrumentId === position["instrumentId"]);
        const companyLogo = asset.media.slice(-1)[0]?.uri;
        const logoColor = companyLogo.substr(companyLogo.indexOf("_") + 1, 6)

        // TODO add ratios for ETFs? For now, only stonks have them
        if (asset.assetClassId === 5) {
          newPortfolioRatiosArray.push({"companyLogo": companyLogo, "logoColor": logoColor, "companyTicker": asset.ticker, "peRatio": position["peRatio"] ? position["peRatio"] : "-",
          "pegRatio": position["pegRatio"] ? position["pegRatio"] : "-", "priceToSales": position["priceToSales"] ? position["priceToSales"] : "-",
          "fiveYearAveragePERatio": position["fiveYearAveragePERatio"] ? position["fiveYearAveragePERatio"] : "-", 
          "returnOnAssets": position["returnOnAssets"] ? position["returnOnAssets"] : "-" });
          newPortfolioFinancialsArray.push({"companyLogo": companyLogo, "logoColor": logoColor, "companyTicker": asset.ticker, "revenueTTM": position["revenueTTM"] ? position["revenueTTM"] : "-",
          "ebitda": position["ebitda"] ? position["ebitda"] : "-", "grossProfit": position["grossProfit"] ? position["grossProfit"] : "-",
          "marketCap": position["marketCap"] ? position["marketCap"] : "-", "quarterlyEarningsGrowth": position["quarterlyEarningsGrowth"] ? position["quarterlyEarningsGrowth"] : "-", 
          "operationMargin": position["operationMargin"] ? position["operationMargin"] : "-" });
        }
      });
      setPortfolioPerformanceArray(newPortfolioPerformanceArray);
      setPortfolioRatiosArray(newPortfolioRatiosArray);
      setPortfolioFinancialsArray(newPortfolioFinancialsArray);
    }
  }, []);

  function sortStrings(array, direction, attribute) {
    const parsedAttribute = attribute === "Company" ? "companyTicker" : "isBuy"
    return array.sort(function(a, b){
      const tickerA=a[parsedAttribute].toLowerCase(), tickerB=b[parsedAttribute].toLowerCase();
      if (tickerA < tickerB) //sort string ascending
          return direction === 'asc' ? -1 : 1;
      if (tickerA > tickerB)
        return direction === 'asc' ? 1 : -1;
      return 0 //default return value (no sorting)
    })
  }
  
  function MoneyFormat(labelValue) 
  {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

       ? ((Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
       // Six Zeroes for Millions 
       : Math.abs(Number(labelValue)) >= 1.0e+6

       ? ((Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
       // Three Zeroes for Thousands
       : Math.abs(Number(labelValue)) >= 1.0e+3

       ? ((Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

       : Math.abs(Number(labelValue));
  }

  function parsePercentage(value) {
    const color = !value || value == 1 ? "" : (parseFloat(value) < 1 ? "Negative" : "Positive");
    return (
    <TableCell classes={{ root: classes["tableCellRoot" + color]}}>
      <Box display="flex" alignItems="center" className="value">
        {value ? ((parseFloat(value - 1)) * 100).toFixed(2) + "%" : "-"}
      </Box>
    </TableCell>
    )
  }

  return (
    <>
      {currentEtoroUserData && <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("title")}</Box>
                  <FeatureInfoWithBox description={t("holdingsTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box classes={{root: classes.boxButtonsHeader}}>
                  <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "performance" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    onClick={() => handleClick("performance")}
                  >
                    {t("Performance")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "ratios" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    disabled={portfolioRatiosArray && portfolioRatiosArray.length === 0}
                    onClick={() => handleClick("ratios")}
                  >
                    {t("Ratios")}
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={selectedTable === "financials" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRoot,
                    }}
                    disabled={portfolioFinancialsArray && portfolioFinancialsArray.length === 0}
                    onClick={() => handleClick("financials")}
                  >
                    {t("Financials")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        { portfolioPerformanceArray && portfolioPerformanceArray.length > 0 ?
        <TableContainer classes={{ root: classes.tableRoot }} ref={tableConatinerRef}>
          <Box alignItems="center" marginBottom="0!important">
          <Table stickyHeader aria-label="sticky table">
            <TableHead classes={{ root: classes.tableCellRootHead }}>
              <TableRow>
                { selectedTable === "performance" ? tableHeadPerformance.map((prop, key) => (
                  <TableCell
                    key={key}
                    {...{
                      "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
                    }}
                    onClick={() => handleSort(prop)}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                )) : <></> }
                { selectedTable === "ratios" ? tableHeadRatios.map((prop, key) => (
                  <TableCell
                    key={key}
                    {...{
                      "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
                    }}
                    onClick={() => handleSort(prop)}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                )) : <></> }
                { selectedTable === "financials" ? tableHeadFinancials.map((prop, key) => (
                  <TableCell
                    key={key}
                    {...{
                      "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
                    }}
                    onClick={() => handleSort(prop)}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                )) : <></> }
              </TableRow>
            </TableHead>
            <TableBody className="list">
              { selectedTable === "performance" && portfolioPerformanceArray ? portfolioPerformanceArray.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                    zindex={1000}
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        marginRight="0.8rem"
                        alt="..."
                        classes={{ root: classes.companyLogo }}
                        style={{backgroundColor: "#" + prop.logoColor}}
                        src={prop.companyLogo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker1"
                        >
                          {prop.companyTicker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                  >                  
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="invested"
                      >
                      {prop.invested.toFixed(2)}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes["tableCellRoot" + (prop.PLper>0? "Positive" : "Negative")]}}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="p/l%">
                      {prop.PLper.toFixed(2)}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="value">
                      {prop.value.toFixed(2)}%
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes["tableCellRoot" + (prop.isBuy == "BUY" ? "Positive" : "Negative")] }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="type">
                      {prop.isBuy}
                    </Box>
                  </TableCell>
                  {parsePercentage(prop.mtd)}
                  {parsePercentage(prop.ytd)}
                  </TableRow>
               )) : <></> }
              { selectedTable === "ratios" && portfolioRatiosArray ? portfolioRatiosArray.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        marginRight="0.8rem"
                        alt="..."
                        classes={{ root: classes.companyLogo }}
                        style={{backgroundColor: "#" + prop.logoColor}}
                        src={prop.companyLogo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker2"
                        >
                          {prop.companyTicker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="p/e ratio ttm"
                      >
                      {prop.peRatio !== "-" ? parseFloat(prop.peRatio).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="fiveYearAveragePERatio">
                        {prop.fiveYearAveragePERatio !== "-" ? parseFloat(prop.fiveYearAveragePERatio).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="pegRatio">
                        {prop.pegRatio !== "-" ? parseFloat(prop.pegRatio).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="price/sales ttm">
                        {prop.priceToSales !== "-" ? parseFloat(prop.priceToSales).toFixed(2) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="roa">
                      {prop.returnOnAssets !== "-" ? (prop.returnOnAssets*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                </TableRow>
              )) : <></> }
              { selectedTable === "financials" && portfolioFinancialsArray ? portfolioFinancialsArray.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        marginRight="0.8rem"
                        alt="..."
                        classes={{ root: classes.companyLogo }}
                        style={{backgroundColor: "#" + prop.logoColor}}
                        src={prop.companyLogo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker3"
                        >
                          {prop.companyTicker}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="market cap (local currency)">
                        {prop.marketCap !== "-" ? MoneyFormat(prop.marketCap) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot + " budget" }}
                  >
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="revenue ttm"
                      >
                      {prop.revenueTTM !== "-" ? MoneyFormat(prop.revenueTTM) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="ebitda">
                        {prop.ebitda !== "-" ? MoneyFormat(prop.ebitda) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box display="flex" alignItems="center" className="gross profit">
                        {prop.grossProfit !== "-" ? MoneyFormat(prop.grossProfit) : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="ebit margin">
                      {prop.operationMargin !== "-" ? (prop.operationMargin*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="quarterly eps growth">
                      {prop.quarterlyEarningsGrowth !== "-" ? (prop.quarterlyEarningsGrowth*100).toFixed(2) + "%" : "-"}
                    </Box>
                  </TableCell>
                </TableRow>
               )) : <></> }
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
        : <></> }
      </Card>
    </> }
    </>
  );
}
