import boxShadows from "assets/theme/box-shadow.js";
import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  iconInfo: {
    padding: "12px",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1.25rem",
    height: "1.25rem",
    boxShadow: boxShadows.boxShadow,
    color: themeColors.white.main,
    borderRadius: "50%",
    background:"#fffffff",
  },
  mutedAnchor: {
    color: themeColors.gray[400],
    textDecoration: "none",
    backgroundColor: "initial",
    "&:hover": {
      color: themeColors.gray[500],
    },
  },
  cardRoot: {
    textAlign: "center",
    marginBottom: "1.5rem", 
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16rem",
    },
  },
  cardRootGradientSuccess: {
    background:"#fffffff",
    boxShadow: boxShadows.boxShadowLg,
    [theme.breakpoints.up("md")]: {
      zIndex: 0.9,
      transform: "scale(1.05)",
    },
  },
  bgInherit: {
    background: "inherit",
  },
  whiteAnchor: {
    color: themeColors.white.main,
    textDecoration: "none",
    backgroundColor: "initial",
    "&:hover": {
      color: themeColors.gray[100],
    },
  },
  display1: {
    fontSize: "3rem",
    fontWeight: 600,
    lineHeight: 1.5,
  },
  cardContentRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
  },
  cardActionsRoot: {
    justifyContent: "center",
  },
  display2: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  titleInfo: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.bsColors.LogoLightBlue,
    paddingTop: "1rem",
    paddingBottom: "1rem",
    marginBottom: "0",
  },
  titleWhite: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.white.main,
    paddingTop: "1rem",
    paddingBottom: "1rem",
    marginBottom: "0",
  },
  subheaderWhite: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.white.main,
    marginBottom: "0",
  },
  subheaderInfo: {
    letterSpacing: ".0625rem",
    textTransform: "uppercase",
    color: themeColors.bsColors.MainBlue,
    marginBottom: "0",
  },
  buttonRootWhite: {
    color: themeColors.dark.main,
    backgroundColor: themeColors.white.main,
    borderColor: themeColors.white.main,
    "&:hover": {
      backgroundColor: themeColors.white.main,
      borderColor: themeColors.white.main,
      color: themeColors.dark.dark,
    },
  },
  cardImgTop: {
    borderTopLeftRadius: "calc(.375rem - 1px)",
    borderTopRightRadius: "calc(.375rem - 1px)",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    alignSelf: "center",
    borderStyle: "none",
    maxHeight: "30rem",
    maxWidth: "15rem",
    objectFit: "cover",
    marginTop: "-17rem",
  },
});

export default componentStyles;
