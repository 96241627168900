import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    //boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  cardContentRoot:{

  },
  gridItemRoot: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: "100%",
  },
  listItemRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    "&:not(:last-child)": {
      borderBottom: "1px solid " + theme.palette.gray[200],
    },
  },
  buttonRoot: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  img: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  mb0: {
    marginBottom: "0!important",
  },
  vectorMap: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: 0,
      overflowY: "hidden",
    },
    "&:not(.active)" : { 
      display: "block",
      height: 0,
      overflowY: "hidden",
    },
  },
  tableRoot: {
    marginBottom: "0!important",
    maxHeight: "26rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "21rem",
      overflow: "auto",
    },
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1rem",
    borderTop: "0",
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.gray[600],
  },
  tableCellRootBodyHead: {
    textTransform: "unset!important",
    fontSize: ".8125rem",
  },
  flagRoot: {
    height: "20px",
    width: "20px",
  },
  tableCellRootPositive: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    color: theme.palette.bsColors.NumberGreen,
  },
  tableCellRootNegative: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    color: theme.palette.bsColors.NumberRed,
  },
});

export default componentStyles;
