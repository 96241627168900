import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components


// core components

import componentStyles from "assets/theme/views/admin/alternative-dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import CardCryptoPortfolioOverview from "components/Cards/Widgets/CardCryptoPortfolioOverview";
import CardCoinBasicInfo from "components/Cards/Cards/CardCoinBasicInfo";
import CardCryptoPortfolioBreakdown from "components/Cards/Charts/CardCryptoPortfolioBreakdown";
import CardStaking from "components/Cards/Cards/CardStaking";
import CardQuickInfo from "components/Cards/Widgets/CardQuickInfo";
import { Warning } from "@material-ui/icons";
import { useService } from "contexts/ServiceContext";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";

import CardCoinMarketCap from "components/Cards/Sortable/CardCoinMarketCap";
import CardCoinPerspective from "components/Cards/Sortable/CardCoinPerspective";
import CardPersonalWallets from "components/Cards/Sortable/CardPersonalWallets";
import CardNews from "components/Cards/Cards/CardNews";
import CardCryptoWhaleAlerts from "components/Cards/Sortable/CardCryptoWhaleAlerts";
import CardNewsBgImage from "components/Cards/Cards/CardNewsBgImage";
import CardAfterHoursChart from "components/Cards/Sortable/CardAfterHoursChart";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function CryptoDashboard() {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  const { getCryptoNews, getCryptoData } = useService();
  const { currentEtoroUserData } = useEtoroUser();
  const { currentUser } = useAuth();
  const [cryptoData, setCryptoData] = React.useState();
  const [updatedNews, setUpdatedNews] = React.useState();
  const [updates, setUpdates] = React.useState(0);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CryptoDashboard' });
  const hasCrypto = currentEtoroUserData?.userInstruments.instruments.some((instrument) => instrument.instrumentId >= 100000);
  const isFelOrJoao = ["filsommer@gmail.com", "jdiogoc@gmail.com"].includes(currentUser.email);
  
  //Alterar isto para simplesmente ir buscar de todas as cryptos (assetclass 10)
  function parseTickersToFetchNewsFor() {
    let instrumentsToSend = [
      { "instrumentId": 100000, "ticker": "BTC" },
      { "instrumentId": 100017, "ticker": "ADA" },
      { "instrumentId": 100001, "ticker": "ETH" },
      { "instrumentId": 100030, "ticker": "BNB" },
      { "instrumentId": 100037, "ticker": "DOT" },
      { "instrumentId": 100080, "ticker": "SHIB" },
      { "instrumentId": 100043, "ticker": "DOGE" },
      { "instrumentId": 100063, "ticker": "SOL" },
      { "instrumentId": 100018, "ticker": "IOTA" },
      { "instrumentId": 100020, "ticker": "XLM" },
      { "instrumentId": 100022, "ticker": "EOS" },
      { "instrumentId": 100026, "ticker": "TRON" },
    ]
    return instrumentsToSend
  }

  React.useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setUpdates(updates => updates + 1);
    }, 15000);
    if (!document.hidden && currentEtoroUserData?.userInstruments) {
      if(currentEtoroUserData.userInstruments) {
        getCryptoData().then(response => {
          setCryptoData(response.data)
        });
      }
      const instrumentsToSend = parseTickersToFetchNewsFor();
      if (instrumentsToSend.length > 0) {
        getCryptoNews(instrumentsToSend).then(response => {
          if (!response.length) {
            setUpdatedNews(response.data);
          }
        });
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [updates]);

  React.useEffect(() => {
    setCryptoData(currentEtoroUserData?.cryptoData);
    return () => {
      setCryptoData();
    };
  }, []);

  function getPrice(ticker) {
    const asset = cryptoData?.latestCryptoData?.latestCryptoPrices.find(entry => entry.ticker === ticker);  
    if (typeof asset == "undefined"){
      return
    }
    else {
      return asset.price.toFixed(2)  // or undefined
    }
  }

  function getChangeP(ticker) {
    const asset = cryptoData?.latestCryptoData?.latestCryptoPrices.find(entry => entry.ticker === ticker);  
    if (typeof asset == "undefined"){
      return
    }
    else {
      return asset.priceChangePercentage.toFixed(2)  // or undefined
    }
  }

  //Get Crypto News
  //forEach loop to create cryptoOnly array
  //Send these cryptoOnlyarrays as input parameters for most components
  //Get BTC,ETH etc live prices array (with change %) to send to components
  return (
    <>
      <AlternativeHeader section={t("header")} />
      {/* Page content */}
      <Helmet>
        <title>Crypto Dashboard | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >

        <Grid container>
            {cryptoData?.latestCryptoData?.latestCryptoFundamentals.slice(0,6).map((prop, key) => (
              <Grid item xs={6} xl={2} key={key}>
                <CardCoinBasicInfo coin={prop.ticker} logo={prop.logo} price={getPrice(prop.ticker)} change={getChangeP(prop.ticker)} />
              </Grid>
            ))}
              {/* Maybe one day we will use this for multi brokers
              <Grid item xs={12} md={6} xl={4}>
                <CardCryptoPortfolioOverview />
                <CardQuickInfo style={{ fontWeight: "400" }}
                  subtitle="Total Crypto Market Cap"
                  title="2.1T"
                  icon={Warning}
                  color="bgGradientDefault"
                />
              </Grid> */}
              {hasCrypto ? <>
                <Grid item xs={12} md={6} xl={4}>
                  <CardCryptoPortfolioBreakdown currentEtoroUserData={currentEtoroUserData}/>
                </Grid> 
                <Grid item xs={12} md={6} xl={4}>
                  <CardStaking portfolioDividends={currentEtoroUserData?.portfolioDividends} />
                </Grid>
                <Grid item xs={12} md={12} xl={4}>
                  <CardAfterHoursChart type={"Mixed"} asset={"Crypto"} updatedPrices={cryptoData?.latestCryptoData?.latestCryptoPrices.sort((b,a) => (a.priceChangePercentage > b.priceChangePercentage) ? 1 : ((b.priceChangePercentage > a.priceChangePercentage) ? -1 : 0))}/>
                </Grid> 
              <Grid item xs={12} md={12} xl={/*isFelOrJoao ? 7 : */12}>
                <CardCoinMarketCap currentEtoroUserData={currentEtoroUserData} cryptoData={cryptoData}/>
              </Grid>
              {isFelOrJoao ?
              <Grid item xs={12} md={12} xl={false}>
                {/* <CardPersonalWallets currentEtoroUserData={currentEtoroUserData} currentUser={currentUser}/> */}
              </Grid> : null }
              </>
              :
              <>
                <Grid item xs={12} md={12} xl={8}>
                  <CardCoinMarketCap currentEtoroUserData={currentEtoroUserData} cryptoData={cryptoData}/>
                </Grid>
                <Grid item xs={12} md={12} xl={4}>
                  <CardAfterHoursChart type={"Mixed"} asset={"Crypto"} updatedPrices={cryptoData?.latestCryptoData?.latestCryptoPrices.sort((b,a) => (a.priceChangePercentage > b.priceChangePercentage) ? 1 : ((b.priceChangePercentage > a.priceChangePercentage) ? -1 : 0))}/>
                </Grid>
              </>
              }
              
              <Grid item xs={12} xl={6}>
                <CardCryptoWhaleAlerts currentEtoroUserData={currentEtoroUserData} cryptoData={cryptoData}/>
              </Grid>
              <Grid item xs={12} xl={6}>
                <CardCoinPerspective currentEtoroUserData={currentEtoroUserData} cryptoData={cryptoData}/>
              </Grid>
              {updatedNews && updatedNews.length > 0 ?
                <Grid container>
                {updatedNews.slice(0,9).map( (news,key) =>(
                  <Grid item xs={12} xl={4} key={key}>
                    <CardNews
                      ticker={news.symbol}
                      date={news.publishedDate + " UTC"}
                      image={news.image}
                      title={news.title}
                      description={news.text}
                      source={news.site}
                      url={news.url}
                    />
                  </Grid>
                  ))
                }
                </Grid>
                :
                <></>}
        </Grid>
      </Container>
    </>
  );
}

export default CryptoDashboard;
