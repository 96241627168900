import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components

// core components
import componentStyles from "assets/theme/views/auth/resources.js";
import cardDeckStyles from "assets/theme/components/cards/card-deck.js";
import buttonsStyles from "assets/theme/components/button.js";
import HeaderArticlePreview from "./Blog/HeaderArticlePreview";
import NormalArticlePreview from "./Blog/NormalArticlePreview";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(cardDeckStyles);
const useStylesButtons = makeStyles(buttonsStyles);


const articleList= [
  {
    type:"eToro",
    title:"Dividends on eToro",
    description:"How to check your eToro dividends and how to know when to expect them",
    date:"2021-10-30",
    image:"https://cdn.snapi.dev/images/v1/u/r/urlhttps3a2f2fgfoolcdncom2feditorial2fimages2f6435822fwind-turbines-on-rocky-hilljpgw700opresize-1022625-1078077.jpg",
    source:"Bullsheet Team",
    url:"dividends-etoro"
  },
  {
    type:"eToro",
    title:"ADA and TRX Staking in eToro",
    description:"How does staking in eToro work? How do you opt-in?",
    date:"2021-10-30",
    image:"https://images.pexels.com/photos/2228570/pexels-photo-2228570.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    source:"Bullsheet Team",
    url:"staking-ada-etoro",
  },
  {
    type:"eToro",
    title:"Earnings & Dividends Calendar for eToro",
    description:"How to use Bullsheet to track your eToro earnings and dividends on a calendar",
    date:"2021-10-30",
    image:"https://images.pexels.com/photos/5386754/pexels-photo-5386754.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    source:"Bullsheet Team",
    url:"earnings-dividends-calendar-etoro",
  },
  {
    type:"eToro",
    title:"How to Export eToro Portfolio to csv (and other formats)",
    description:"While eToro does not have a native tool to do it, Bullsheet allows you to export your eToro portfolio to csv, xls and even Tradingview",
    date:"2021-10-30",
    image:"https://images.pexels.com/photos/5668765/pexels-photo-5668765.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    source:"Bullsheet Team",
    url:"export-etoro-portfolio-csv",
  },
];

function Blog() {
  const classes = {
    ...useStyles(),
    ...useStylesCardDeck(),
    ...useStylesButtons(),
  };
  const theme = getTheme("light");

  return (
    <>
      {/* Page content */}
      <Helmet>
        <title>Get Help - Answers for your eToro questions | Bullsheet</title>
        <meta name="description" content="How can you see eToro dividends? Export eToro Portfolio to csv? eToro staking rewards? Check our blog for answers" />
      </Helmet>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="3rem"
        paddingBottom="2rem"
      >
        <Container maxWidth="xl">
          <Box>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12}>
                <Box marginTop="4rem">
                  <Typography
                    className={classes.heroTitle}
                    component="h1"
                  >
                    Bullsheet Resources - Learn about eToro dividends, staking, risk, diversification, and more
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box maxHeight="20rem">
        <HeaderArticlePreview type={articleList[0].type} title={articleList[0].title} description={articleList[0].description} url={articleList[0].url}/>
      </Box>
      
      <Box className={classes.whiteBackground}>
      <Container
        maxWidth="xl"
        >
      <Box 
        className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow, classes.whiteBackground)}
        paddingTop="12rem"
        paddingBottom="5rem"
        //paddingX="15rem"
      >
        { articleList.slice(1, 4).map( (article, key) => (
        <NormalArticlePreview key={key}
          date={article.date}
          image={article.image}
          title={article.title}
          description={article.description}
          source={article.source}
          type={article.type}
          url={article.url}
        />
        ))}
      </Box>
      </Container>
      </Box>
    </>
  );
}

export default Blog;
