import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import bsIconStretch from "assets/img/brand/bs-icon-stretch.png";
import Avatar from "@material-ui/core/Avatar";
import CircleLoader from "react-spinners/CircleLoader";

// @material-ui/lab components
// @material-ui/icons components
import GraphicEq from "@material-ui/icons/GraphicEq";
import SearchIcon from '@material-ui/icons/Search';

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader";
import componentStyles from "assets/theme/views/admin/etf-screener.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useService } from "../../../contexts/ServiceContext";
import excelIcon from "assets/img/icons/common/excel.png"
import csvIcon from "assets/img/icons/common/csv.png"
import tradingViewIcon from "assets/img/icons/common/tradingview.svg"
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { useAuth } from "contexts/AuthContext";
import AdditionalSuperpowersSection from "views/auth/Features/AdditionalSuperpowersSection";
import FeatExportPortfolio from "views/auth/Features/FeatExportPortfolio";
import FeatETFDetails from "views/auth/Features/FeatETFDetails";
import { IconButton, InputBase, TextField } from "@material-ui/core";
import CardHoldings from "components/Cards/Sortable/CardAfterHours";
import CardSectorBreakdownHorizontal from "components/Cards/Charts/CardSectorBreakdownHorizontal";
import CardMapPerformance from "components/Cards/Alternative/CardMapPerformance";
import CardCountries from "components/Cards/Alternative/CardCountries";
import CardETFSectors from "components/Cards/Charts/CardETFSectors";
import CardETFHoldings from "components/Cards/Sortable/CardETFHoldings";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import CardQuickInfo from "components/Cards/Widgets/CardQuickInfo";
import CardETFValuations from "components/Cards/Sortable/CardETFValuations";
import CardETFGrowthRatios from "components/Cards/Sortable/CardETFGrowthRatios";
import CardETFReturns from "components/Cards/Sortable/CardETFReturns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Fragment } from "react";
import { useTheme } from "@material-ui/core/styles";
import Globe from "@material-ui/icons/Public";
import styled from "styled-components";
import themeColors from "assets/theme/colors.js";
import { useTranslation } from "react-i18next";
import CardSimpleRectangle from "components/Cards/Widgets/CardSimpleRectangle";

const AutoStylingWrapper = styled.div`
  .MuiInputBase-input {
    background: fixed;
    //background-clip: padding-box;
    border-radius: .375rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: .625rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${props => props.theme.palette.white.main};
    box-shadow: none;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
    font-size: .875rem;
    box-sizing: border-box;
  };
  .MuiSvgIcon-root {
    fill: white;
    width: 14px;
    height: 14px;
    display: inline-block;
    font-size: 1.7142857142857142rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  
  };
  .MuiFormLabel-root {
    color: ${props => props.theme.palette.inputText.main};
    display: inline-block;
    padding: 0;
    font-size: .875rem;
    font-family: Open Sans,sans-serif;
    font-weight: 600;
    line-height: 0.3;
    margin-bottom: 1rem;
  };
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 18px) scale(1);
    pointer-events: none;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;


const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

const ETFDetails = () => {
  const classes = { ...useStyles(), ...useStylesCardDeck(), ...useStylesButtons() };
  const { getAllEtfs, getEtf } = useService();
  const { notify } = useEtoroUser();
  const { currentUser } = useAuth();
  const theme = useTheme();
  const etfRef = React.useRef();
  const [searchButtonEnabled, setSearchButtonEnabled] = React.useState(false);
  const [etfs, setEtfs] = React.useState([{ instrumentId: "0", "ticker": "", "logo": "" }]);
  const [fetchedEtf, setFetchedEtf] = React.useState();
  const [loadingEtf, setLoadingEtf] = React.useState(false);
  const { t, i18n } = useTranslation('translation');

  function handleSubmit(e) {
    e.preventDefault();
    getEtf(etfRef?.current?.value).then((result) => {
      setFetchedEtf(result.data);
      setLoadingEtf(false);
    }).catch(err => {
      notify("etf_error");
      setLoadingEtf(false);
    })
  }

  function handleKeypress(e, ticker) {
    //it triggers by pressing the enter key
    if (e.charCode === 13 && ticker) {
      handleSubmit(e);
    }
  };

  function handleSearchInput(e, ticker) {
    e.preventDefault();
    if (ticker) {
      etfRef.current.value = ticker;
      setSearchButtonEnabled(true);
    } else if (etfRef?.current?.value) {
      setSearchButtonEnabled(true);
    } else {
      setSearchButtonEnabled(false);
    }
  }
  function handleSuggestionClick(e, ticker) {
    e.preventDefault();
    setLoadingEtf(true);
    if (ticker) {
      etfRef.current.value = ticker;
      setSearchButtonEnabled(true);
      handleSubmit(e);
    }
  }
  function handleEtoroSearch(e, ticker) {
    e.preventDefault();
    const url="https://www.etoro.com/markets/"
    window.open(url + ticker, "_blank");
  }

  React.useEffect(() => {
    getAllEtfs().then(result => {
      setEtfs(result.data);
    })
  }, []);

  return (
    <>
      <AlternativeHeader section={t("ETFDetails.header")} subsection="Portfolio" />
      {/* Page content */}
      <Helmet>
        <title>ETF Details | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        {currentUser.subscription !== "freeTier" ?
          <>
            <Grid container>
              <Grid item xs={8} lg={6}>
                <Box
                  display="flex"
                  //justifyContent="center"
                  alignItems="center"
                  width="15rem"
                  marginBottom="2rem"
                  className={classes.searchBox}
                >
                  <AutoStylingWrapper theme={theme}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={etfs}
                      getOptionLabel={(option) => option.ticker}
                      onChange={(event, value) => handleSearchInput(event, value?.ticker)}
                      renderOption={option => {
                        return (
                          <Fragment>
                            <img style={{ width: "25px", marginRight: "10px", borderRadius: "15px" }} src={option.logo} />
                            <Box style={{color: "#1b1b4f"}}>{option.ticker}</Box>
                          </Fragment>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} inputRef={etfRef} label={t("ETFDetails.searchEtf")} variant="outlined" style={{ border: "none" }} />}
                    />
                  </AutoStylingWrapper>
                  <Button disabled={!searchButtonEnabled} style={{ backgroundColor: "#1b1b4f", backgroundImage: `url(${bsIconStretch})`, cursor: "pointer" }} onClick={handleSubmit}
                    className={searchButtonEnabled ? classes.searchInputButton : classes.searchInputButtonDisabled}
                  ></Button>
                </Box>
              </Grid>
            </Grid>
            <Box display="flex">
              <Box
                component={fetchedEtf ? Avatar : Globe}
                classes={{ root: classes.avatarRoot }}
                marginRight="0.875rem"
                alt="..."
                src={fetchedEtf ? etfs.find(etf => etf.ticker === fetchedEtf.ticker)?.logo : ""}
              />
              <Typography variant="h3" style={{ marginBottom: "1.5rem" }}>
                {fetchedEtf ? fetchedEtf.name + " (" + fetchedEtf.ticker + ")" : t("ETFDetails.selectEtf")}
              </Typography>
            </Box>
            {fetchedEtf ?
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="auto"
                  marginBottom="1rem"
                  className={classes.etoroBox}
                >
                  <IconButton onClick={(event) => handleEtoroSearch(event, fetchedEtf?.ticker)}>
                    <Box
                      component={SearchIcon}
                      width="1.5rem!important"
                      height="1.5rem!important"
                      style={{ color: "white" }}
                    />
                    <Typography variant="h6" style={{ color: theme.palette.bsColors.GlobalWhite, marginLeft: "0.2rem", marginTop: "0.2rem", paddingRight: "0.3rem", textAlign: "left", lineHeight: "0.8rem" }}>Check <br />on eToro</Typography>
                  </IconButton>
                </Box>
                <Box container className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
                  <CardETFHoldings holdings={fetchedEtf.holdings} />
                  <CardETFSectors sectorWeights={fetchedEtf.sectorWeights} />
                  <CardCountries geography={fetchedEtf.geography} />
                </Box>
                <Box container className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
                  <CardQuickInfo
                    subtitle={t("ETFDetails.nrOfHoldings")}
                    title={fetchedEtf.nrHoldings.toString()}
                    icon={GraphicEq}
                    color="bgGradientDefault"
                  />
                  <CardQuickInfo
                    subtitle={t("ETFDetails.top10Holdings%")}
                    title={Math.min(fetchedEtf.top10pct, 100) + "%"}
                    icon={GraphicEq}
                    color="bgGradientDefault"
                  />
                  <CardQuickInfo
                    subtitle={t("ETFDetails.divYield")}
                    title={fetchedEtf.divYield + "%"}
                    icon={GraphicEq}
                    color="bgGradientDefault"
                  />
                  <CardQuickInfo
                    subtitle={t("ETFDetails.expenseRatio")}
                    title={fetchedEtf.expenseRatio + "%"}
                    icon={GraphicEq}
                    color="bgGradientDefault"
                  />
                </Box>
                <Box container className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
                  <CardETFReturns performance={fetchedEtf.performance} />
                  <CardETFValuations valuations={fetchedEtf.valuations} />
                  <CardETFGrowthRatios growthRates={fetchedEtf.growthRates} />
                </Box>
              </> :
              <Grid container style={{ marginTop: "1rem" }}>
                {loadingEtf ? <><CircleLoader color={theme.palette.bsColors.LogoLightBlue} loading={loadingEtf} size={30} style={{ marginLeft: "50px" }} /></> : <>
                  <Grid item xl={4} md={4} xs={6}>
                    <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                      US Market ETFs
                </Typography>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "VOO")}>
                      <CardSimpleRectangle ticker="VOO" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/4238/150x150.png" title="Vanguard S&P 500" color="bgGradientError" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "QQQ")}>
                      <CardSimpleRectangle ticker="QQQ" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/qqq/150x150.png" title="Invesco NASDAQ100" color="bgGradientWarning" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "DIA")}>
                      <CardSimpleRectangle ticker="DIA" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/dia/150x150.png" title="SPDR Dow Jones" color="bgGradientSuccess" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "VTI")}>
                      <CardSimpleRectangle ticker="VTI" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/4237/150x150.png" title="Vanguard Total Market" color="bgGradientError" />
                    </Box>
                  </Grid>
                  <Grid item xl={4} md={4} xs={6}>
                    <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                      Most Searched
                </Typography>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "ARKK")}>
                      <CardSimpleRectangle ticker="ARKK" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/3166/150x150.png" title="ARK Innovation ETF" color="bgGradientDefault" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "DGRO")}>
                      <CardSimpleRectangle ticker="DGRO" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/3149/150x150.png" title="iShares Dividend Growth" color="bgGradientSuccess" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "ARKF")}>
                      <CardSimpleRectangle ticker="ARKF" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/3165/150x150.png" title="ARK Fintech ETF" color="bgGradientDefault" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "TAN")}>
                      <CardSimpleRectangle ticker="TAN" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/3237/150x150.png" title="Invesco Solar ETF" color="bgGradientWarning" />
                    </Box>
                  </Grid>
                  <Grid item xl={4} md={4} xs={6}>
                    <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                      International ETFs
                </Typography>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "VXUS")}>
                      <CardSimpleRectangle ticker="VXUS" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/6368/150x150.png" title="Vanguard Total Intern. Stock" color="bgGradientError" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "EWJ")}>
                      <CardSimpleRectangle ticker="EWJ" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/ewj/150x150.png" title="iShares Japan" color="bgGradientSuccess" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "VWO")}>
                      <CardSimpleRectangle ticker="VWO" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/4252/150x150.png" title="Vanguard Emerging Markets" color="bgGradientError" />
                    </Box>
                    <Box style={{ cursor: "pointer" }} onClick={(event) => handleSuggestionClick(event, "SWDA.L")}>
                      <CardSimpleRectangle ticker="SWDA.L" imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/3040/150x150.png" title="iShares Core MSCI World" color="bgGradientSuccess" />
                    </Box>
                  </Grid>
                </>}
              </Grid>
            }
          </>
          :
          <Box component="section" paddingTop="1.5rem" >
            <Box
              textAlign="center"
              paddingBottom="1.5rem"
            >
              <Typography variant="h1" component="h1" className={classes.typographyH1Center}
                style={{
                  backgroundImage: "linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(126, 38%, 48%, 1) 75%)",
                  "WebkitBackgroundClip": "text",
                  "WebkitTextFillColor": "transparent",
                  "MozBackgroundClip": "text",
                  "MozTextFillColor": "transparent",
                }}
              >
                <b>{t("PremiumContent.premiumContentTitle")}</b>
              </Typography>
              <Box marginTop="1rem" textAlign="center">
                {t("PremiumContent.premiumContentDescription")}
              </Box>
              <Box marginTop="2rem" textAlign="center">
                <Button
                  component={Link}
                  variant="contained"
                  to="/auth/pricing"
                  classes={{ root: classes.buttonCTANormal }}
                >
                  {t("PremiumContent.premiumCtA")}
                </Button>
              </Box>
            </Box>
            <FeatETFDetails />
          </Box>}
      </Container>
    </>
  );
};

export default ETFDetails;