import React from "react";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// @material-ui/lab components
// @material-ui/icons components
import Notifications from "@material-ui/icons/Notifications";
import ThumbUp from "@material-ui/icons/ThumbUp";

import componentStyles from "assets/theme/views/admin/timeline.js";
import componentStylesBadge from "assets/theme/components/badge.js";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(componentStylesBadge);

const CardTimeline = () => {
  const classes = { ...useStyles(), ...useStylesBadge() };
  const { t, i18n } = useTranslation("translation", { keyPrefix: "Contact" });

  const items = [
    {
      icon: Notifications,
      title: t("Upcoming Features"),
      color: "Info",
      date: "2023",
      text: [
        "1 - Quadrant Analysis",
        "2 - Historical performance on Portfolio assets",
        "3 - Social Tab",
        "Note: As eToro acquired Bullsheet recently, expect the feature release speed to be lower until the merger is fully complete.",
      ],
      types: ["Features", "Bugfixing", "Design"],
    },
    {
      icon: ThumbUp,
      title: "New feature",
      color: "Success",
      date: "2023/04/05",
      text: [
        "Stock Details menu is here for sponsors! Upon successful testing it will be open to all premium users.",
      ],
      types: ["Features"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2022/07/06",
      text: [
        "1. Dark mode is here!",
        "2. Screener results and Portfolio show asset performance",
        "3. Improved visual/color consistency across the application",
        "4. Calendar is now responsive on mobile",
      ],
      types: ["Features"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2022/04/14",
      text: [
        "1. Added following info to the calendar:",
        "-> Dividend amounts",
        "-> If earnings are announced before or after market close",
        "-> Eps estimates and actual values.",
      ],
      types: ["Features"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2022/03/18",
      text: [
        "1. Improved Export functionality",
        "-> Added open rate",
        "-> Added copy positions",
        "-> Added several new columns such as stop loss, take profit, etc.",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/02/14",
      text: [
        "1 - Password change functionalty",
        "2 - Invoice downloads",
        "3 - Small fixes to charts",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2022/01/20",
      text: [
        "1 - Updated UI",
        "2 - Bitcoin whales only show movements above 100M$",
        "3 - Screener Filters are now more visible",
        "4 - Real-time prices are now updated consistently",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/12/15",
      text: [
        "1 - eToro Stock Screener",
        "2 - Look up risk score for any asset, even if you don't own it",
        "3 - Improved design of real-time price cards.",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/12/13",
      text: [
        "1 - Crypto Dashboard is now Live!",
        "2 - Fixed some bugs on the screener.",
        "3 - Improved design of real-time price cards.",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/12/05",
      text: [
        "1 - Screener Alpha version released to our Sponsors. Premium Users can start using it in some weeks.",
        "2 - Implemented Extended Hours Market Movers graphs on the Extended Hours tab.",
        "3 - Fixed a bug on our DipSheet that caused stocks that were recently unlisted to appear there for several days as -100%",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/11/15",
      text: [
        "1 - We have a new blog with some eToro help articles! Check it out through our Homepage",
        "2 - Improved UI of Sector/Country Breakdown popups.",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/11/01",
      text: [
        "1 - Copy Positions are now broken down on the Dashboard and Analysis tabs!",
        "2 - Daily Performance and Extended Hours prices now update even faster.",
        "3 - Implemented Quick ETF Search on ETF Details",
        "4 - Small bugfixes and UI Improvements",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/10/13",
      text: [
        "1 - New Bullsheet Languages! German, Italian and Spanish are now Available. 🇩🇪 🇮🇹 🇪🇸",
        "1b - You may change your language on the bottom part of the sidebar. Let us know if you find some wrong/inaccurate translations",
        "2 - Decreased the update time of our extended hours tab data",
        "3 - Fixed a bug that made some table sorts work incorrectly",
        "4 - Fixed/Changed some URL paths",
        "5 - ETFs Sectors and Countries broken down on the Sector/Geo Breakdown Graphs",
        "6 - Calendar now shows ETFs!",
      ],

      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/09/13",
      text: [
        "1 - New Summary Charts on After Hours Tab",
        "2 - Improved Portfolio Breakdown Card Design with Asset Number inside",
        "3 - Changed Some Graphs' Colors for a better UI",
        "4 - Fixed Table Headers that weren't working properly",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/08/29",
      text: [
        "1 - New ETF Details tool!",
        "2 - News tab now shows crypto news",
        "3 - Staking now included in dividend yield",
        "4 - Reload portfolio button is now clearer",
        "5 - Country names are now clickable in the Dashboard",
        "6 - Fixed calendar earnings dates",
        "7 - Fixed Copy Sync percentages",
        "8 - Fixed loading time for people copying a million portfolios *cof cof Yoni*",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/08/06",
      text: [
        "1 - Early Access is over. Thanks for your amazing feedback!",
        "2 - Latest trades for people you copy only",
        "3 - Pages can now be access directly without redirecting to the Dashboard first",
        "4 - Export tab now supports TradingView export",
        "5 - Market Movers are now updated upon entering tab",
        "6 - Effective Portfolio sorting works correctly now",
        "7 - Extended hours now show extended performance",
      ],
      types: ["Features", "Bugfixing"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/07/12",
      text: [
        "1 - Fixed bug on some Profit Calculations",
        "2 - Fixed Bug on After Hours showing Pre Market Prices",
        "3 - Improved Card Header designs for Mobile on cards with buttons",
        "4 - Increased Character Limit on Feedback Form to 1500 because we have the best early access users in the world and sometimes 1000 characters were not enough (Thanks!!)",
        "5 - Reduced time needed to click on 'i' icon to show info tooltip on mobile",
        "6 - Switched Sector and Industry order on Industry Breakdown card (Analysis tab)",
      ],
      types: ["Features", "Bugfixing", "Design"],
    },
    {
      icon: ThumbUp,
      title: "Implemented Features & Bugfixes",
      color: "Success",
      date: "2021/07/04",
      text: [
        "1 - Implemented Cache",
        "2 - Fixed Bug on Extended Hours, causing app to crash",
        "3 - Added Info Tooltips to every card to provide info about the card",
        "4 - Added popup when you click on Sector or Map Breakdowns showing the respective stocks",
        "5 - Fixed sorting problems",
        "6 - Made some design changes across several components",
      ],
      types: ["Features", "Bugfixing", "Design"],
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Card elevation={0}>
            <CardHeader
              className={classes.cardHeader}
              title="Timeline"
              titleTypographyProps={{
                component: Box,
                marginBottom: "0!important",
                variant: "h3",
              }}
            ></CardHeader>
            <CardContent>
              <div className={clsx(classes.timeline, classes.timelineOneSide)}>
                {items.map((prop, key) => (
                  <div key={key} className={classes.timelineBlock}>
                    <Badge
                      badgeContent={<prop.icon />}
                      color={prop.color === "Error" ? "error" : undefined}
                      classes={{
                        root: classes.timelineBadgeRoot,
                        badge: clsx(classes.timelineBadge, classes.badgePositionRelative, {
                          [classes["badge" + prop.color]]: prop.color !== "Error",
                        }),
                      }}
                    ></Badge>
                    <div className={classes.timelineContent}>
                      <Box component="small" fontSize="80%" fontWeight="600">
                        {prop.date}
                      </Box>
                      <Typography component="h5" variant="h5" className={classes.typographyH5}>
                        {prop.title}
                      </Typography>
                      {prop.text.map((prop, key) => (
                        <Box
                          key={key}
                          component="p"
                          marginBottom="0"
                          fontWeight="300"
                          lineHeight="1.7"
                          fontSize=".875rem"
                          marginTop=".5rem"
                        >
                          {prop}
                        </Box>
                      ))}
                      <Box marginTop="1rem">
                        {prop.types.map((type, key) => (
                          <Badge
                            key={key}
                            badgeContent={type}
                            color={prop.color === "Error" ? "error" : undefined}
                            classes={{
                              root: classes.badgeMargin,
                              badge: clsx(classes.badgePositionRelative, classes.badgeRound, {
                                [classes["badge" + prop.color]]: prop.color !== "Error",
                              }),
                            }}
                          ></Badge>
                        ))}
                      </Box>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CardTimeline;
