import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  tabsRoot:{
    border: "0",
    [theme.breakpoints.down("md")]: {
      maxWidth: "50rem",
      overflow: "auto",
    },
    //borderBottom: '1px solid #e8e8e8',
  },
  tabsBodyRoot: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.bsColors.MainBlue,
    minWidth: '2rem',
    width: '2rem',
    fontWeight: "600",
    fontSize: theme.typography.h5.fontSize,
  },
  textUppercase: {
      textTransform: "uppercase",
  },
  buttonRoot: {
    color: theme.palette.bsColors.MainBlue,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.gray[100],
      borderColor: theme.palette.gray[100],
      color: theme.palette.dark.main,
    },
  },
  buttonRootSelected: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.bsColors.MainBlue,
    borderColor: theme.palette.bsColors.MainBlue,
    "&:hover": {
      backgroundColor: theme.palette.dark.main,
      borderColor: theme.palette.dark.main,
      color: theme.palette.white.main,
    },
  },
});

export default componentStyles;
