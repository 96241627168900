import React, {  } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";

import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
import Typography from "@material-ui/core/Typography";

// core components
import componentStyles from "assets/theme/views/auth/aboutus.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useHistory } from "react-router-dom";
import CardAboutMember from "components/Cards/Cards/CardAboutMember";
import ReadyBottomCTA from "./Homepage/ReadyBottomCTA";


const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

function AboutUs() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = getTheme("light");
  const history = useHistory()

  return (
    <Box style={{ background: "linear-gradient(180.04deg, #FFFFFF 15.84%, #B3CEE8 240.04%)" }}>
      <Grid container>
        <Helmet>
          <title>About Us | Bullsheet</title>
          <meta name="description" content="Our Bullsheet Team and History." />
        </Helmet>
        <Grid item xs={12} lg={12} md={12}>
          <Box
            className={classes.header}
            position="relative"
            paddingTop="8rem"
            paddingBottom="2rem"
          >
            <Container maxWidth="xl">
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={12}>
                    <Box marginTop="2rem">
                      <Typography
                        variant="h4"
                        component="h4"
                        className={classes.typographyH4}
                        style={{color:theme.palette.bsColors.MainBlue}}
                      >
                        About the Bullsheet Team and Story
                    </Typography>
                      <Box marginBottom="5rem" marginTop="1.5rem" textAlign="-webkit-center">
                        <Typography
                          variant="h2"
                          component="h2"
                          className={classes.typographyH2}
                          style={{color:theme.palette.bsColors.MainBlue}}
                        >
                          We wanted to improve how we tracked our Portfolios. So we made it available for you, too.
                    </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
      <Box style={{ backgroundColor: theme.palette.bsColors.MainBlue }}>
        <Typography variant="h2" style={{ color: "white", paddingTop: "3rem", paddingBottom: "2rem", textAlign: "center" }}>The Bullsheet Team</Typography>
        <Typography style={{ color: "white", textAlign: "center", paddingBottom: "2rem" }}>
          We are two cousins and eToro Popular Investors devoting their free time to Bullsheet 🚀
        </Typography>
        <Grid container style={{ paddingBottom: "2rem" }}>
          <Grid item xs={false} md={1} lg={2}></Grid>
          <Grid item xs={12} lg={4}>
            <CardAboutMember
              image="https://etoro-cdn.etorostatic.com/avatars/150X150/13589637/2.jpg"
              name="Filipe - Co-Founder"
              etoro="filsommer"
              linkedin="filipesommer"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <CardAboutMember
              image="https://etoro-cdn.etorostatic.com/avatars/150X150/17437715/2.jpg"
              name="Joao - Co-Founder"
              etoro="jdiogoc92"
              linkedin="joaoramalhocarlos"
            />
          </Grid>
        </Grid>
        <Box maxWidth="75%" textAlign="center" style={{margin: "0 auto"}}>
        <Typography style={{ color: "white", textAlign: "left", paddingBottom: "2rem", paddingTop: "1rem" }}>
          We both have Engineering Bachelors and professional experience in management consultancies. We love data.
          As we are both Popular Investors on eToro and devote much of our free time keeping track of companies we invest in and looking for new opportunities, 
          we needed to track our investments efficiently. Other tools just weren't convenient enough as we had to input all of our stocks manually, which often change.
          <br/>
          With Bullsheet, we can now manage everything by importing our stocks with just one click. We are power users of our own product and are always looking for ways to improve it.
        </Typography>
        </Box>
      </Box>
      <Box style={{ backgroundColor: theme.palette.white.main }}>
        <Typography variant="h2" style={{ color: theme.palette.bsColors.MainBlue, paddingTop: "3rem", paddingBottom: "1rem", textAlign: "center" }}>The Bullsheet Roadmap</Typography>
        <Grid container style={{ paddingBottom: "2rem" }}>
          <Grid item xs={false} md={1} lg={2}></Grid>
          <Grid item xs={12} lg={4}>
            
          </Grid>
        </Grid>
        <Box maxWidth="75%" textAlign="center" style={{margin: "0 auto"}}>
        <Typography style={{ color: "black", textAlign: "center", paddingBottom: "2rem", paddingTop: "0rem" }}>
          Check the Feedback tab inside the app :)
        </Typography>
        </Box>
      </Box>
      <ReadyBottomCTA/>
    </Box>
  );
}

export default AboutUs;
