import React, { useEffect } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { HorizontalBar } from "react-chartjs-2";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import { useTranslation } from 'react-i18next';
import ChartDetailModal from "./ChartDetailModal";
import Typography from "@material-ui/core/Typography";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStyles from "assets/theme/components/cards/charts/card-portfolio-breakdown.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// @material-ui/icons components

// core components
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { chartOptions, parseOptions, chartSectorBreakdownHorizontal } from "variables/charts.js";

const useStylesButtons = makeStyles(componentStylesButtons);
const useStyles = makeStyles(componentStyles);

// quick find: Sector Breakdown
function CardSectorBreakdownHorizontal() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const { currentEtoroUserData } = useEtoroUser();
  const theme = useTheme();
  const [selectedButton, setSelectedButton] = React.useState("byValue");
  const [translatedSectors, setTranslatedSectors] = React.useState();
  const [originalSectors, setOriginalSectors] = React.useState();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardSectorBreakdownHorizontal' });
  const [sectorModal, setSectorModal] = React.useState({isOpen: false});
  const [chartStyling, setChartStyling] = React.useState(chartSectorBreakdownHorizontal.options);
  const backgroundColor = [theme.palette.bsColors.Pie0, theme.palette.bsColors.Pie1, theme.palette.bsColors.Pie2, theme.palette.bsColors.Pie3,
    theme.palette.bsColors.Pie4, theme.palette.bsColors.Pie5, theme.palette.bsColors.Pie6, theme.palette.bsColors.Pie7, 
    theme.palette.bsColors.Pie8, theme.palette.bsColors.Pie9, theme.palette.bsColors.Pie10, theme.palette.bsColors.Pie11, theme.palette.bsColors.Yellow, "#70F", "#000", "#70F", "#000", "#70F", "#000", "#70F", "#000",
  ];

  function handleClick(byValue) {
    setSelectedButton(byValue ? "byValue" : "byInvested");
  }

  function translateSectors(sectorsToTranslate) {
    for (let i = 0; i < sectorsToTranslate[0]?.labels?.length; i++) {
      sectorsToTranslate[0].labels[i] = t(originalSectors.byValue[i]);
      sectorsToTranslate[0].datasets[0].label = i18n.language;
    }
    for (let i = 0; i < sectorsToTranslate[1]?.labels?.length; i++) {
      // translate each chart label to current language
      sectorsToTranslate[1].labels[i] = t(originalSectors.byInvested[i]);
      sectorsToTranslate[1].datasets[0].label = i18n.language;
    }
  }

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }, []);

  useEffect(() => {
    if(translatedSectors && currentEtoroUserData) {
      // translate value and invested chart labels to current language
      translateSectors(translatedSectors);
      setTranslatedSectors([...translatedSectors]);
    }
  }, [t]);

  useEffect(() => {
    if (currentEtoroUserData) {
      // if any soul is able to deep copy an object without this jibberjabber from stackoverflow please do so
      setOriginalSectors({"byValue": [...currentEtoroUserData.sectorBreakdown[0].labels], 
                  "byInvested": [...currentEtoroUserData.sectorBreakdown[1].labels]});
      //setTranslatedSectors(currentEtoroUserData.sectorBreakdown);
    }
  }, [currentEtoroUserData]); 

  useEffect(() => {
    if(theme) {
      // Ensures chart is using current theme colors
      chartStyling.scales.yAxes[0].ticks.fontColor = theme.palette.bsColors.MainBlue;
      chartStyling.scales.xAxes[0].ticks.fontColor = theme.palette.bsColors.MainBlue;
      chartStyling.scales.xAxes[0].gridLines.color = theme.palette.bsColors.ChartLines;
      setChartStyling(chartStyling);
    }
    // Reloads data since chart will only redraw on data change, not options :(
    if (originalSectors) {
      // after originalSectors (english) are set, translate them
      const sectorsToTranslate = [{"datasets": [...currentEtoroUserData.sectorBreakdown[0].datasets],
      "labels": [...originalSectors.byValue]}, {"datasets": [...currentEtoroUserData.sectorBreakdown[1].datasets],
       "labels": [...originalSectors.byInvested]}];
      sectorsToTranslate[0].datasets[0].backgroundColor = backgroundColor;
      sectorsToTranslate[1].datasets[0].backgroundColor = backgroundColor;
      translateSectors(sectorsToTranslate);
      setTranslatedSectors(sectorsToTranslate);
    }
  }, [originalSectors]);

  const handleClickOpen = (e) => {
    if (!e[0]) { 
      return; 
    }
    const index = selectedButton === "byValue" ? 0 : 1;
    const sectorBreakdown = index === 0 ? 
                        currentEtoroUserData.currentPortfolio.sortedByValueSectorBreakdown : 
                          currentEtoroUserData.currentPortfolio.sortedByInvestedSectorBreakdown;
    const sectorLabel = e[0]._model.label === "Comm. Services" ? "Communication Services" : Object.keys(sectorBreakdown)[e[0]._index];

    if (sectorLabel in sectorBreakdown) {
      const sectorWeight = sectorBreakdown[sectorLabel]?.weight;
      const sectorData = sectorBreakdown[sectorLabel]?.tickers.filter(entry => entry.weight >= 0.01);
      setSectorModal({ label: sectorLabel, weight: sectorWeight, data: sectorData, isOpen: true, dataType: "SECTOR_BREAKDOWN" });
    }
  };

  return (
    <>
      <ChartDetailModal
        modalData = {sectorModal}
        setModalData = {setSectorModal}
        tableHead = {["ticker", "weight", "source"]}
      />
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("title")}</Box>
                  <FeatureInfoWithBox description={t("sectorBreakdownTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box classes={{root: classes.boxButtonsHeader}}>
                  <Button onClick={() => handleClick(true)}
                    variant="contained"
                    size="small"
                    classes={selectedButton === "byValue" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRootDefault,
                    }}
                  >
                    {t("value")}
                  </Button>
                  <Button onClick={() => handleClick(false)}
                    variant="contained"
                    size="small"
                    style={{marginLeft:"3px"}}
                    classes={selectedButton === "byInvested" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRootDefault,
                    }}
                  >
                    {t("invested")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          <Box key={i18n?.language} position="relative" height="350px">
          { translatedSectors && chartStyling && selectedButton === "byValue" ?
            <HorizontalBar
              data={translatedSectors[0]}
              options={chartStyling}
              getElementAtEvent={(e) => handleClickOpen(e)}
              redraw={true}
          /> : <></> }
          { translatedSectors && chartStyling && selectedButton === "byInvested" ?
          <HorizontalBar
              data={translatedSectors[1]}
              options={chartStyling}
              getElementAtEvent={(e) => handleClickOpen(e)}
              redraw={true}
          /> : <></> }
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardSectorBreakdownHorizontal;
