import React, { Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";

import componentStyles from "assets/theme/components/cards/tabbed/card-sector-by-date.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(componentStyles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();


  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      classes={{ root: classes.tabsRoot }}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} style={{paddingBottom: 0}}>
          <Typography variant="h3">{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

// quick find: Recent Performance
export default function CardPerformanceByDate() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { currentEtoroUserData } = useEtoroUser();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardPerformancebyDate' });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
    <Card classes={{ root: classes.cardRoot }}>
    <CardHeader
          subheader={
            <Grid
              container
              component={Box}
            >
              <Grid item xs={6} lg={6}>
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("title")}</Box>
                  <FeatureInfoWithBox description={t("performanceByDateTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs={6} lg={6}>
              {currentEtoroUserData?.currentPortfolio?.performance ? currentEtoroUserData.currentPortfolio.performance.slice(1).map((prop,key) => (
                <TabPanel key={key} value={value} index={key} dir={theme.direction} >
                { prop.map((dat, key) => (
                  <Fragment key={key}>
                      <Box
                        component={Typography}
                        variant="h3"
                        color={theme.palette.bsColors.MainBlue + "!important"}
                        textAlign="right"
                        fontSize="1rem"
                        className={classes.typographyRootH3}
                        padding="0px !important"
                      >
                        {t(dat.timeframe)}
                      </Box>
                        <Box
                          component={Typography}
                          variant="h3"
                          fontWeight="600!important"
                          fontSize="1rem"
                          textAlign="right"
                          className={classes.typographyRootH3}
                          color={(dat.performance > 0 ? theme.palette.bsColors.NumberGreen : theme.palette.bsColors.NumberRed) + "!important"}
                        >
                          {dat.performance}%
                        </Box>
                    </Fragment>
                  ))}
                </TabPanel>
                )) : <></> }
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
    <div>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                classes={{ root: classes.tabsRoot }}
                style={{padding: "10px"}}
              >
                <Tab classes={{ root: classes.tabsBodyRoot }} label={t("7D")}/>
                <Tab classes={{ root: classes.tabsBodyRoot }} label={t("1M")}/>
                <Tab classes={{ root: classes.tabsBodyRoot }} label={t("MTD")}/>
                <Tab classes={{ root: classes.tabsBodyRoot }} label={t("3M")}/>
                <Tab classes={{ root: classes.tabsBodyRoot }} label={t("6M")}/>
                <Tab classes={{ root: classes.tabsBodyRoot }} label={t("1Y")}/>
                <Tab classes={{ root: classes.tabsBodyRoot }} label={t("YTD")}/>
              </Tabs>
      
    </div>
    </Card>
    </>
  );
}