// core components
// admin pages
// // // // // admin dashboards
// // // // // admin examples
// // // // // admin components
// // // // // admin forms
// // // // // admin tables
// // // // // admin maps
// // // // // admin rest
// rtl pages
// auth pages
import Lock from "views/auth/Lock.js";
import Login from "views/auth/Login.js";
import Pricing from "views/auth/Pricing.js";
import Register from "views/auth/Register.js";
import sidebarRoutes from "sidebarRoutes.js";
import ForgotPassword from "views/auth/ForgotPassword";
import PrivacyPolicyPage from "views/auth/Privacy Policy";
import TermsConditionsPage from "views/auth/TermsConditions";
import Features from "views/Features";
import ContactUs from "views/auth/ContactUs";
import AboutUs from "views/auth/AboutUs";
import Blog from "views/auth/Blog";
import BlogArticle from "views/auth/Blog/BlogArticle";

var landingPageRoutes = [
  
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/lock",
    name: "Lock",
    component: Lock,
    layout: "/auth",
  },
  {
    path: "/features",
    name: "Features",
    component: Features,
    layout: "/auth",
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    layout: "/auth",
  },
  {
    path: "/resources/:url",
    name: "Blog Article",
    component: BlogArticle,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyPage,
    layout: "/auth",
  },
  {
    path: "/terms-and-conditions",
    name: "TermsConditions",
    component: TermsConditionsPage,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: ContactUs,
    layout: "/auth",
  },
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
    layout: "/auth",
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: Pricing,
    layout: "/auth",
  },
]

var routes = [...sidebarRoutes, ...landingPageRoutes]

export default routes;
