import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@material-ui/core/styles";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";

// core components
import componentStyles from "assets/theme/components/cards/alternative/card-map-performance.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useService } from "contexts/ServiceContext";
import { useTranslation } from 'react-i18next';
import ChartDetailModal from "../Charts/ChartDetailModal";
import Public from "@material-ui/icons/Public";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Country", "Invested", "Profit",];

// quick find: Stocks by Geography
function CardMapPerformance() {
  const classes = useStyles();
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  const { countryCodesNew, flags } = useService();
  const tableConatinerRef = React.useRef(null);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardMapPerformance' });
  const [modalData, setModalData] = React.useState({ isOpen: false })


  const handleClickOpen = (event, code) => {
    let countryName = Object.keys(countryCodesNew).find(k=>countryCodesNew[k]===code);
    let countryData = currentEtoroUserData.assetsByCountry.assetsByCountryList.find(entry => entry.country === countryName)
    if(countryData) {
      setModalData({label: countryData.country, weight: countryData.invested, data: countryData.tickers, isOpen: true})
    }
  };

  React.useEffect( () => {
      new List(tableConatinerRef.current, {
        valueNames: ["ticker", "invested", {name: "profit", attr:"profit-val"},],
        listClass: "list",
      });
      return;
  }, [])

  return (
    <div >
      <ChartDetailModal
        setModalData = {setModalData}
        modalData = {modalData}
        tableHead = {["ticker", "weight", "parent"]}
        dataType = "MAP_PERFORMANCE"
      />
      <Card classes={{ root: classes.cardRoot }} elevation={0}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span"><Public style={{verticalAlign: "baseline"}}/>{"  "}{t("title")}</Box>
                  <FeatureInfoWithBox description={t("mapPerformanceTooltip")}/>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              { currentEtoroUserData?.assetsByCountry?.assetsByCountryList && currentEtoroUserData.assetsByCountry.assetsByCountryList.length > 0 ?
              <PerfectScrollbar>
              <TableContainer ref={tableConatinerRef} classes={{ root: classes.tableRoot }} >
                <Box component={Table} alignItems="center" marginBottom="0!important">
                  <TableHead>
                    <TableRow>
                      {tableHead.map((prop, key) => (
                        <TableCell
                          key={key}
                          {...{
                            "data-sort":
                              prop !== "" 
                                ? (prop === "Country" ? "ticker" : prop).toLowerCase()
                                : undefined,
                          }}
                          scope="col"
                          classes={{
                            root:
                              classes.tableCellRoot + " sort " + classes.tableCellRootHead,
                          }}
                        >
                          {t(prop)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="list">
                  {currentEtoroUserData?.assetsByCountry?.assetsByCountryList ? currentEtoroUserData.assetsByCountry.assetsByCountryList.map((prop, key) => (
                      <TableRow key={key}>
                        <TableCell
                          classes={{
                            root:
                              classes.tableCellRoot +
                              " " +
                              classes.tableCellRootBodyHead,
                          }}
                          component="th"
                          variant="head"
                          scope="row"
                          style={{cursor:"pointer"}}
                          onClick={(event) => handleClickOpen(event, countryCodesNew[prop.country])}
                        >
                          <Box alignItems="center" display="flex">
                            <Box
                              component={Avatar}
                              classes={{ root: classes.flagRoot }}
                              marginRight="0.5rem"
                              alt="Country Flag"
                              src={flags[countryCodesNew[prop.country]]}
                            />
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize={theme.typography.h5.fontSize} component="span" className="ticker">
                                {countryCodesNew[prop.country]}
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Box fontSize={theme.typography.h5.fontSize} component="span" className="invested">
                            {parseFloat(prop.invested.toFixed(2))}%
                          </Box>
                        </TableCell>
                        <TableCell classes={{ root: classes["tableCellRoot" + (prop.profit>0? "Positive" : "Negative")] }} >
                          <Box fontSize={theme.typography.h5.fontSize} component="span" className="profit" profit-val={prop.profit+1000}>
                            {prop.profit?.toFixed(2)}%
                          </Box>
                        </TableCell>               
                      </TableRow>
                    )) : <></> }
                  </TableBody>
                </Box>
              </TableContainer>
              </PerfectScrollbar>
              : <></> }
            </Grid>
          </Grid>
      </Card>
    </div>
  );
}

export default CardMapPerformance;
