import boxShadows from "assets/theme/box-shadow.js";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const componentStyles = (theme) => ({
  cardRoot: {
    //boxShadow: boxShadows.boxShadow + "!important",
    height: "30rem",
  },
  cardRootTranslucent: {
    backgroundColor: theme.palette.transparent.main,
  },
  cardRootStripped: {
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.gray[100]) + ", 0.3)",
    },
  },
  cardRootCheckboxColors: {
    "& tbody tr:nth-child(1)": {
      backgroundColor: theme.palette.success.tableLight,
      "&:hover": {
        backgroundColor: theme.palette.success.tableLightHover,
      },
    },
    "& tbody tr:nth-child(3)": {
      backgroundColor: theme.palette.warning.tableLight,
      "&:hover": {
        backgroundColor: theme.palette.warning.tableLightHover,
      },
    },
  },
  cardRootDark: {
    backgroundColor: theme.palette.dark.main,
    "& *": {
      color: theme.palette.white.main,
    },
    "& $tableCellRoot, & $tableRoot": {
      color: theme.palette.white.main,
      borderColor: theme.palette.dark.tableBorder,
    },
    "& $tableCellRootHead": {
      color: theme.palette.dark.tableHeadColor,
      backgroundColor: theme.palette.dark.tableHeadBgColor,
    },
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  cardActionsRoot: {
    paddingBottom: "1.5rem!important",
    paddingTop: "1.5rem!important",
    borderTop: "0!important",
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  tableRoot: {
    marginBottom: "0!important",
  },
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    fontSize: theme.typography.h5.fontSize,
  },
  tableCellRootBuy: {
    fontWeight: "600",
    color: theme.palette.bsColors.NumberGreen,
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    fontSize: theme.typography.h5.fontSize,
  },
  tableCellRootSell: {
    fontWeight: "600",
    color: theme.palette.bsColors.NumberRed,
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    fontSize: theme.typography.h5.fontSize,
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.gray[600],
    fontSize: theme.typography.h6.fontSize,
  },
  tableCellRootBodyHead: {
    textTransform: "unset!important",
    fontSize: ".8125rem",
  },
  linearProgressRoot: {
    height: "3px!important",
    width: "120px!important",
    margin: "0!important",
  },
  bgGradientError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      ",#f56036)!important",
  },
  bgGradientSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      ",#2dcecc)!important",
  },
  bgGradientPrimary: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ",#825ee4)!important",
  },
  bgGradientInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ",#1171ef)!important",
  },
  bgGradientWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      ",#fbb140)!important",
  },
  bgPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bgPrimaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  bgError: {
    backgroundColor: theme.palette.error.main,
  },
  bgErrorLight: {
    backgroundColor: theme.palette.error.light,
  },
  bgWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  bgWarningLight: {
    backgroundColor: theme.palette.warning.light,
  },
  bgInfo: {
    backgroundColor: theme.palette.info.main,
  },
  bgInfoLight: {
    backgroundColor: theme.palette.info.light,
  },
  bgSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  verticalAlignMiddle: {
    verticalAlign: "middle",
  },
  avatarRoot: {
    width: "15px",
    height: "15px",
    fontSize: ".875rem",
  },
  avatarCompanyRoot: {
    width: "10px",
    height: "10px",
    fontSize: ".5rem",
  },
  anchorStyles: {
    fontWeight: "600",
  },
  buttonRootWhite: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  buttonRoot: {
    marginLeft: "10px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  progressLabel: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1,
    maxHeight: "75px", // borderlinearprogress root.height
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    "& span": {
      width: "100%"
    }
  },
  tableCellRootPositive: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    color: theme.palette.bsColors.NumberGreen,
  },
  tableCellRootNegative: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    color: theme.palette.bsColors.NumberRed,
  },
});

export default componentStyles;
