import React from "react";
// @material-ui/core components
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Divider } from "@material-ui/core";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import allArticles from "./ArticleList.js";

// @material-ui/icons components

// core components
import componentStyles from "assets/theme/views/auth/resources.js";
import cardDeckStyles from "assets/theme/components/cards/card-deck.js";
import buttonsStyles from "assets/theme/components/button.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import FeaturesHeader from "components/Headers/FeaturesHeader.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(cardDeckStyles);
const useStylesButtons = makeStyles(buttonsStyles);


function BlogArticle() {
  const classes = {
    ...useStyles(),
    ...useStylesCardDeck(),
    ...useStylesButtons(),
  };
  const theme = useTheme();
  const { url } = useParams();
  const articleData = allArticles.allArticles.filter((list) => list.url === url)
  return (
    <>
      {/* Page content */}
      <Helmet>
        {articleData[0].title && <title>{articleData[0].title + ' | Bullsheet'}</title>}
        {articleData[0].title && <meta property='og:title' content={articleData[0].title + ' | Bullsheet'} />}
        {articleData[0].description && <meta name='description' property='og:description' content={articleData[0].description} />}
        {articleData[0].image && <meta property='og:image' content={articleData[0].image} />}
      </Helmet>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="3rem"
      >
        
        <Container maxWidth="xl">
          <Box>
          <Link to="../blog">
          <Button
            classes={{ root: classes.buttonCTANormalStrokeBorderless }}
            style={{ marginTop: "3.5rem", marginLeft:"-10px"}}
          >
            ← Back to Blog
          </Button>
        </Link>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={12}>
                <Box marginTop="1rem">
                  <Typography
                    className={classes.typographyH1}
                    component="h1"
                  >
                    {/* {title - description} */}
                    {articleData[0].title}
                  </Typography>
                  <Typography
                    className={classes.typographyH3}
                    style={{paddingBottom:"1rem"}}
                    component="h2"
                  >
                    {/* {title - description} */}
                    {articleData[0].description}
                  </Typography>
                  <img
                    alt="..."
                    src={articleData[0].image}
                    className={classes.cardImgTopArticle}
                    style={{ maxHeight: "200px", width: "100%", objectFit: "cover" }}
                  />
                  <Box marginTop="2rem">
                    <Typography className={classes.typographyLineBreaks}>
                      {articleData[0].articleText}
                    </Typography>
                  </Box>
                  <Divider style={{ marginTop: "2rem" }} />
                  
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <FeaturesHeader/>
      </Box>

    </>
  );
}

export default BlogArticle;
