import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
// @material-ui/core components
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import MemoryIcon from '@material-ui/icons/Memory';
import PublicIcon from '@material-ui/icons/Public';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PaymentIcon from '@material-ui/icons/Payment';
// @material-ui/lab components
// @material-ui/icons components
import Check from "@material-ui/icons/Check";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import FunctionsOutlinedIcon from '@material-ui/icons/FunctionsOutlined';
import CancelIcon from '@material-ui/icons/Cancel';

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader";
import componentStyles from "assets/theme/views/admin/screener.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useService } from "../../../contexts/ServiceContext";
import { useAuth } from "contexts/AuthContext";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import ArticleIcon from '@material-ui/icons/Description';
import styled from "styled-components";
import themeColors from "assets/theme/colors.js";
import { useTranslation } from "react-i18next";
import SubScreener from "./SubScreener";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ScreenerResults from "components/Cards/Sortable/ScreenerResults";
import CardRectangleText from "components/Cards/Cards/CardRectangleText";
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import buffet from "assets/img/icons/screener/buffet.png";
import oneil from "assets/img/icons/screener/oneil.png";
import dividends from "assets/img/icons/screener/dividends.png";
import custom from "assets/img/icons/screener/custom.png";
import saved from "assets/img/icons/screener/save.png";
import FeatScreener from "views/auth/Features/FeatScreener";


const AutoStylingWrapper = styled.div`
  .MuiInputBase-input {
    background: fixed;
    //background-clip: padding-box;
    border-radius: .375rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: .625rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${themeColors.gray[300]};
    box-shadow: none;
    transition: all .2s cubic-bezier(.68,-.55,.265,1.55);
    font-size: .875rem;
    box-sizing: border-box;
  };
  .MuiSvgIcon-root {
    fill: white;
    width: 14px;
    height: 14px;
    display: inline-block;
    font-size: 1.7142857142857142rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  
  };
  .MuiFormLabel-root {
    color: ${themeColors.gray[600]};
    display: inline-block;
    padding: 0;
    font-size: .875rem;
    font-family: Open Sans,sans-serif;
    font-weight: 600;
    line-height: 0.3;
    margin-bottom: 1rem;
  };
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 18px) scale(1);
    pointer-events: none;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

const assetTypes = [/*{"ticker": "Forex", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/eur-usd/150x150.png", "assetClassId": 1}*/,
                    /*{"ticker": "Commodity", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/gold/150x150.png", "assetClassId": 2}*/,
                    /*{"ticker": "Indices", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/nsdq100/150x150.png", "assetClassId": 4}*/,
  { "ticker": "Stocks", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/aapl/150x150.png", "assetClassId": 5 },
                    /*{"ticker": "ETFs", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/3149/150x150.png", "assetClassId": 6}*/,
                    /*{"ticker": "Crypto", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/btc/150x150.png", "assetClassId": 10}*/];

const sectors = [{ "ticker": "Technology", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/aapl/150x150.png" },
{ "ticker": "Communication Services", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/goog/150x150.png" },
{ "ticker": "Consumer Cyclical", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/amzn/150x150.png" },
{ "ticker": "Consumer Defensive", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/1187/150x150.png" },
{ "ticker": "Basic Materials", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/vale/150x150.png" },
{ "ticker": "Financial Services", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/1223/150x150.png" },
{ "ticker": "Healthcare", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/pfe/150x150.png" },
{ "ticker": "Utilities", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/nee/150x150.png" },
/*{"ticker": "Hedge/Bond", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/vxx/150x150.png"}*/,
{ "ticker": "Industrials", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/de/150x150.png" },
{ "ticker": "Energy", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/xom/150x150.png" },
{ "ticker": "Real Estate", "logo": "https://etoro-cdn.etorostatic.com/market-avatars/4307/150x150.png" }];


const countries = [{ 'ticker': 'United States', "logo": 'https://hatscripts.github.io/circle-flags/flags/us.svg' },
{ 'ticker': 'United Kingdom', "logo": 'https://hatscripts.github.io/circle-flags/flags/gb.svg' },
{ 'ticker': 'Hong Kong', "logo": 'https://hatscripts.github.io/circle-flags/flags/hk.svg' },
{ 'ticker': 'China', "logo": 'https://hatscripts.github.io/circle-flags/flags/cn.svg' },
{ 'ticker': 'Germany', "logo": 'https://hatscripts.github.io/circle-flags/flags/de.svg' },
{ 'ticker': 'Japan', "logo": 'https://hatscripts.github.io/circle-flags/flags/jp.svg' },
{ 'ticker': 'France', "logo": 'https://hatscripts.github.io/circle-flags/flags/fr.svg' },
{ 'ticker': 'Italy', "logo": 'https://hatscripts.github.io/circle-flags/flags/it.svg' },
{ 'ticker': 'Netherlands', "logo": 'https://hatscripts.github.io/circle-flags/flags/nl.svg' },
{ 'ticker': 'Spain', "logo": 'https://hatscripts.github.io/circle-flags/flags/es.svg' },
{ 'ticker': 'Portugal', "logo": 'https://hatscripts.github.io/circle-flags/flags/pt.svg' },
{ 'ticker': 'Switzerland', "logo": 'https://hatscripts.github.io/circle-flags/flags/ch.svg' },
{ 'ticker': 'Sweden', "logo": 'https://hatscripts.github.io/circle-flags/flags/se.svg' },
{ 'ticker': 'Norway', "logo": 'https://hatscripts.github.io/circle-flags/flags/no.svg' },
{ 'ticker': 'Denmark', "logo": 'https://hatscripts.github.io/circle-flags/flags/dk.svg' },
{ 'ticker': 'South Korea', "logo": 'https://hatscripts.github.io/circle-flags/flags/kr.svg' },
/*{ 'ticker': 'Other', "logo": 'https://hatscripts.github.io/circle-flags/flags/aq.svg' }*/]


const divYield = {
  "from": [{ 'value': '0', "type": '%' },
  { 'value': '1', "type": '%' },
  { 'value': '2', "type": '%' },
  { 'value': '3', "type": '%' },
  { 'value': '4', "type": '%' },
  { 'value': '5', "type": '%' },
  { 'value': '6', "type": '%' },
  { 'value': '7', "type": '%', 'prefix': '> ' }],
  "to": [{ 'value': '1', "type": '%' },
  { 'value': '2', "type": '%' },
  { 'value': '3', "type": '%' },
  { 'value': '4', "type": '%' },
  { 'value': '5', "type": '%' },
  { 'value': '6', "type": '%' },
  { 'value': '7', "type": '%' },
  { 'value': '8', "type": '%', 'prefix': '< ' }]
}


const marketCap = {
  "from": [{ 'value': '0', "type": 'B' },
  { 'value': '10', "type": 'B' },
  { 'value': '30', "type": 'B' },
  { 'value': '50', "type": 'B' },
  { 'value': '100', "type": 'B' },
  { 'value': '500', "type": 'B' },
  { 'value': '1', "type": 'T' },
  { 'value': '2', "type": 'T', 'prefix': '> ' }],
  "to": [{ 'value': '1', "type": 'B' },
  { 'value': '10', "type": 'B' },
  { 'value': '30', "type": 'B' },
  { 'value': '50', "type": 'B' },
  { 'value': '100', "type": 'B' },
  { 'value': '500', "type": 'B' },
  { 'value': '1', "type": 'T' },
  { 'value': '2', "type": 'T', 'prefix': '< ' }]
}

const revenueTTM = {
  "from": [{ 'value': '0', "type": 'M' },
  { 'value': '100', "type": 'M' },
  { 'value': '300', "type": 'M' },
  { 'value': '500', "type": 'M' },
  { 'value': '1', "type": 'B' },
  { 'value': '20', "type": 'B' },
  { 'value': '50', "type": 'B' },
  { 'value': '100', "type": 'B' },
  { 'value': '200', "type": 'B', 'prefix': '> ' }],
  "to": [{ 'value': '0', "type": 'M' },
  { 'value': '100', "type": 'M' },
  { 'value': '300', "type": 'M' },
  { 'value': '500', "type": 'M' },
  { 'value': '1', "type": 'B' },
  { 'value': '20', "type": 'B' },
  { 'value': '50', "type": 'B' },
  { 'value': '100', "type": 'B' },
  { 'value': '200', "type": 'B', 'prefix': '< ' }]
}

const quarterlyRevenueGrowth = {
  "type": "pct",
  "from": [{ 'value': '0', "type": '%' },
  { 'value': '5', "type": '%' },
  { 'value': '10', "type": '%' },
  { 'value': '20', "type": '%' },
  { 'value': '30', "type": '%' },
  { 'value': '50', "type": '%' },
  { 'value': '75', "type": '%' },
  { 'value': '100', "type": '%', 'prefix': '> ' }],
  "to": [{ 'value': '5', "type": '%' },
  { 'value': '10', "type": '%' },
  { 'value': '20', "type": '%' },
  { 'value': '30', "type": '%' },
  { 'value': '50', "type": '%' },
  { 'value': '75', "type": '%' },
  { 'value': '100', "type": '%', 'prefix': '< ' }]
}

const quarterlyEarningsGrowth = {
  "type": "pct",
  "from": [{ 'value': '0', "type": '%' },
  { 'value': '5', "type": '%' },
  { 'value': '10', "type": '%' },
  { 'value': '20', "type": '%' },
  { 'value': '30', "type": '%' },
  { 'value': '50', "type": '%' },
  { 'value': '75', "type": '%' },
  { 'value': '100', "type": '%', 'prefix': '> ' }],
  "to": [{ 'value': '5', "type": '%' },
  { 'value': '10', "type": '%' },
  { 'value': '20', "type": '%' },
  { 'value': '30', "type": '%' },
  { 'value': '50', "type": '%' },
  { 'value': '75', "type": '%' },
  { 'value': '100', "type": '%', 'prefix': '< ' }]
}

const pct = {
  "type": "pct",
  "from": [{ 'value': '0', "type": '%' },
  { 'value': '5', "type": '%' },
  { 'value': '10', "type": '%' },
  { 'value': '20', "type": '%' },
  { 'value': '30', "type": '%' },
  { 'value': '50', "type": '%' },
  { 'value': '75', "type": '%' },
  { 'value': '100', "type": '%', 'prefix': '> ' }],
  "to": [{ 'value': '5', "type": '%' },
  { 'value': '10', "type": '%' },
  { 'value': '20', "type": '%' },
  { 'value': '30', "type": '%' },
  { 'value': '50', "type": '%' },
  { 'value': '75', "type": '%' },
  { 'value': '100', "type": '%', 'prefix': '< ' }]
}

const num = {
  "type": "num",
  "from": [{ 'value': '0', "type": '' },
  { 'value': '10', "type": '' },
  { 'value': '20', "type": '' },
  { 'value': '30', "type": '' },
  { 'value': '50', "type": '' },
  { 'value': '75', "type": '' },
  { 'value': '100', "type": '', 'prefix': '> ' }],
  "to": [{ 'value': '10', "type": '' },
  { 'value': '20', "type": '' },
  { 'value': '30', "type": '' },
  { 'value': '50', "type": '' },
  { 'value': '75', "type": '' },
  { 'value': '100', "type": '', 'prefix': '< ' }]
}

const ratio = {
  "type": "num",
  "from": [{ 'value': '-2', "type": '' },
  { 'value': '-1.5', "type": '' },
  { 'value': '-1', "type": '' },
  { 'value': '-0.5', "type": '' },
  { 'value': '0', "type": '' },
  { 'value': '0.5', "type": '' },
  { 'value': '0.75', "type": '' },
  { 'value': '1', "type": '' },
  { 'value': '1.25', "type": '' },
  { 'value': '1.5', "type": '' },
  { 'value': '1.75', "type": '' },
  { 'value': '2', "type": '' },
  { 'value': '3', "type": '', "prefix": "> " }],
  "to": [{ 'value': '-1.5', "type": '' },
  { 'value': '-1', "type": '' },
  { 'value': '-0.5', "type": '' },
  { 'value': '0.25', "type": '' },
  { 'value': '0.5', "type": '' },
  { 'value': '0.75', "type": '' },
  { 'value': '1', "type": '' },
  { 'value': '1.25', "type": '' },
  { 'value': '1.5', "type": '' },
  { 'value': '1.75', "type": '' },
  { 'value': '2', "type": '' },
  { 'value': '3', "type": '', "prefix": "< " }]
}

const highRatio = {
  "type": "num",
  "from": [{ 'value': '0', "type": '' },
  { 'value': '0.5', "type": '' },
  { 'value': '1', "type": '' },
  { 'value': '1.5', "type": '' },
  { 'value': '2', "type": '' },
  { 'value': '3', "type": '' },
  { 'value': '5', "type": '' },
  { 'value': '7.5', "type": '' },
  { 'value': '10', "type": '', "prefix": "> " }],
  "to": [{ 'value': '0.5', "type": '' },
  { 'value': '1', "type": '' },
  { 'value': '1.5', "type": '' },
  { 'value': '2', "type": '' },
  { 'value': '3', "type": '' },
  { 'value': '5', "type": '' },
  { 'value': '7.5', "type": '' },
  { 'value': '10', "type": '', "prefix": "< " }]
}

const risk = {
  "type": "num",
  "from": [{ 'value': '0', "type": '' },
  { 'value': '1', "type": '' },
  { 'value': '2', "type": '' },
  { 'value': '3', "type": '' },
  { 'value': '4', "type": '' },
  { 'value': '5', "type": '' },
  { 'value': '6', "type": '' },
  { 'value': '7', "type": '' },
  { 'value': '8', "type": '' },
  { 'value': '9', "type": '' },
  { 'value': '10', "type": '' }],
  "to": [{ 'value': '0', "type": '' },
  { 'value': '1', "type": '' },
  { 'value': '2', "type": '' },
  { 'value': '3', "type": '' },
  { 'value': '4', "type": '' },
  { 'value': '5', "type": '' },
  { 'value': '6', "type": '' },
  { 'value': '7', "type": '' },
  { 'value': '8', "type": '' },
  { 'value': '9', "type": '' },
  { 'value': '10', "type": '' }]
}


const divFrequency = [{ "ticker": "Monthly/Quarterly" },
{ "ticker": "Yearly" }];


// inputType multi means multiple choice (such as country) and multiDual means multiple choice between two values (from and to)
const allScreenerOptions = {
  basicInfo: {
    dirty: false, data: [/*{"label": "📄 Asset Type", "short": "assetType", "options": assetTypes, "icon": ArticleIcon, "inputType": "multi"}*/
      { "label": "🖥 Sector", "short": "sector", "options": sectors, "icon": DesktopMacIcon, "inputType": "multi" },
      { "label": "🧬 Industry", "short": "industry", "options": [], "icon": MemoryIcon, "inputType": "multi" },
      { "label": "🌍 Country", "short": "country", "options": countries, "icon": PublicIcon, "inputType": "multi" },
      { "label": "🏦 Market Cap ($)", "short": "marketCap", "options": marketCap, "icon": LocationCityIcon, "inputType": "multiDual" }]
  },
  profitability: {
    dirty: false, data: [
      { "label": "💵 Div Yield (%)", "short": "divYield", "options": divYield, "icon": MonetizationOnIcon, "inputType": "multiDual" },
      { "label": "🗓 Div Frequency", "short": "divFrequency", "options": divFrequency, "icon": MonetizationOnIcon, "inputType": "multi" },
      { "label": "💲 Revenue TTM ($)", "short": "revenueTTM", "options": revenueTTM, "icon": PaymentIcon, "inputType": "multiDual" },
      /*{"label": "💰 Earnings TTM (Million $)", "short": "earningsTTM", "options": [], "icon": PaymentIcon, "inputType": "num"},*/
      { "label": "Operating Margin (%)", "short": "operatingMargin", "options": pct, "icon": AttachMoneyIcon, "inputType": "multiDual" },
      { "label": "Profit Margin (%)", "short": "profitMargin", "options": pct, "icon": AttachMoneyIcon, "inputType": "multiDual" }]
  },
  performance: {
    dirty: false, data: [
      { "label": "📈 QoQ Revenue Growth", "short": "quarterlyRevenueGrowth", "options": pct, "icon": PaymentIcon, "inputType": "multiDual" },
      { "label": "📈 QoQ Earnings Growth", "short": "quarterlyEarningsGrowth", "options": pct, "icon": PaymentIcon, "inputType": "multiDual" }]
  },
  ratios: {
    dirty: false, data: [
      { "label": "P/E Ratio", "short": "peRatio", "options": num, "icon": AttachMoneyIcon, "inputType": "multiDual" },
      { "label": "PEG Ratio", "short": "pegRatio", "options": highRatio, "icon": AttachMoneyIcon, "inputType": "multiDual" },
      { "label": "P/S Ratio", "short": "priceToSales", "options": num, "icon": AttachMoneyIcon, "inputType": "multiDual" },]
  },
  risk: {
    dirty: false, data: [
      { "label": "⚠️ Risk", "short": "risk", "options": risk, "icon": AttachMoneyIcon, "inputType": "multiDual" },
      { "label": "Beta", "short": "beta", "options": ratio, "icon": AttachMoneyIcon, "inputType": "multiDual" },
      { "label": "52 Week High to Low", "short": "highToLow52Week", "options": pct, "icon": AttachMoneyIcon, "inputType": "multiDual" },
      { "label": "Institutional Ownership %", "short": "institutionalOwnership", "options": pct, "icon": AttachMoneyIcon, "inputType": "multiDual" }]
  }
};


const etfScreenerOptions = [{ "label": "📄 Asset Type", "short": "assetType", "options": assetTypes, "icon": ArticleIcon, "inputType": "multi" },
{ "label": "🖥 Sector", "short": "sector", "options": sectors, "icon": DesktopMacIcon, "inputType": "multi" },
{ "label": "🌍 Predominant Country", "short": "country", "options": countries, "icon": PublicIcon, "inputType": "multi" },
{ "label": "💵 Div Yield (%)", "short": "divYield", "options": [], "icon": MonetizationOnIcon, "inputType": "pct" },
{ "label": "💳 Expense Ratio", "short": "expenseRatio", "options": [], "icon": LocationCityIcon, "inputType": "num" },
{ "label": "🗓 Div Frequency", "short": "divFrequency", "options": [], "icon": MonetizationOnIcon, "inputType": "multi" },
{ "label": "Operating Margin (%)", "short": "operatingMargin", "options": [], "icon": AttachMoneyIcon, "inputType": "pct" },
{ "label": "⚠️ Risk", "short": "risk", "options": [], "icon": AttachMoneyIcon, "inputType": "num" }];

const cachedFilter = localStorage.getItem("screenerFilter");
const baseFilter = {
  basicInfo: {}, profitability: {}, performance: {}, ratios: {}, risk: {},
}

const Screener = () => {
  const classes = { ...useStyles(), ...useStylesCardDeck(), ...useStylesButtons() };
  const { getAssetsByFilter, getAssetCountByFilter, logScreenerSearch } = useService();
  const { currentUser } = useAuth();

  const theme = useTheme();
  const etfRef = React.useRef();
  const [searchButtonEnabled, setSearchButtonEnabled] = React.useState(true);
  const [screenerResults, setScreenerResults] = React.useState();
  const [activeFilters, setActiveFilters] = React.useState(allScreenerOptions);
  const [backendFilter, setBackendFilter] = React.useState(baseFilter);
  const [resultCount, setResultCount] = React.useState();
  const [selectedFilter, setSelectedFilter] = React.useState();
  const [screenSelected, setScreenSelected] = React.useState(false);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Screener' });
  const resultText = getResultText();
  const [filterLabels, setFilterLabels] = React.useState();

  function handleActiveFilters(input, short, inputType) {
    inputType === "multiDual" ?
      backendFilter[selectedFilter][short] = Object.assign({ from: "", to: "", fromType: "", toType: "" }, backendFilter[selectedFilter][short], { ...input })
      : backendFilter[selectedFilter][short] = input;
    setResultCount("loading");
    getResultCount();
  }

  function handleLableData(backendFilter) {
    let objLength = Object.keys(backendFilter).length;
    const stringArray = []
    for (const [key, value] of Object.entries(backendFilter)) {
      for (const [key2, value2] of Object.entries(value)) {
        if ("from" in value2) {
          if (value2.from != "" && value2.to !== "") {
            if(value2.from == value2.to){
              stringArray.push(key2 + " = " + value2.to + value2.toType + "\xa0\xa0" +"x")
            }
            else {
              stringArray.push(value2.from + value2.fromType + " ≤ " + key2 + " ≤ " + value2.to + value2.toType + "\xa0\xa0" +"x")
            }
          }
          else if (value2.from != "" && value2.to == "") {
            stringArray.push(key2 + " ≥ " + value2.from + value2.fromType + "\xa0\xa0" +"x")
          }
          else if (value2.from == "" && value2.to !== "") {
            stringArray.push(key2 + " ≤ " + value2.to + value2.toType + "\xa0\xa0" +"x")
          }
        }
        for (let i = 0; i < value2.length; i++) {
          if ("ticker" in value2[i]) {
            stringArray.push(key2 + " - " + value2[i].ticker + "\xa0\xa0" +"x")
          }
        }
      }
    }
    setFilterLabels(stringArray)
  }

  function getResultCount() {
    let getIndustries = true;  // when sector, industry or country field is changes, get respective industries (TODO: or always?)
    getAssetCountByFilter(backendFilter ? backendFilter : {}, getIndustries).then((resp) => {
      if (selectedFilter === "basicInfo") {
        allScreenerOptions[selectedFilter].data[1].options = resp.data.industries;
      }
      if (selectedFilter) {
        allScreenerOptions[selectedFilter].dirty = !isEmpty(backendFilter[selectedFilter]);
      }
      setActiveFilters(allScreenerOptions);
      setResultCount(resp.data?.count);
      handleLableData(backendFilter);
    }).catch(err => {
      setResultCount();
    })
  }

  function getResultText() {
    if (!resultCount) {
      return "No Results";
    } else if (resultCount && resultCount === "loading") {
      return "Loading...";
    } else if (resultCount !== undefined && resultCount !== "loading") {
      return resultCount + " Results";
    } else {
      return "No Results";
    }
  }

  function validTextFields() {
    // TODO: is there anything else we need to validate?
    return true;
  }

  function submitScreener(input) {
    if (validTextFields()) {
      setResultCount("loading");
      getAssetsByFilter(backendFilter).then((resp) => {
        setScreenerResults(resp.data);
        logScreener();
      }).catch(err => {
        console.log("error posting screener");
        setResultCount();
      })
    }
  }

  function logScreener() {
    logScreenerSearch(backendFilter).then((resp) => {
    }).catch(err => {
      console.log("error logging screener search");
    })
  }

  function handleClick() {
    setScreenSelected(!screenSelected)
  }

  function handleLabelClick(label){
    let toDelete = ""
    let specificDropdown = ""
    let i=0
    let objDelete = -1
    if (isNumeric(label[0])) {
      toDelete = label.split(" ")[2]
    }
    else {
      toDelete = label.split(" ")[0]
      specificDropdown = label.substr(label.indexOf('-')+2); 
      specificDropdown = specificDropdown.substr(0, specificDropdown.length - 3)
    }
    for (var key in backendFilter) {
      allScreenerOptions[key].dirty = false;
      for (var key2 in backendFilter[key]) {
        allScreenerOptions[key].dirty = true;
        if (key2 == toDelete){ //sector f.ex.
          if (backendFilter[key][key2].length > 1) { //if sector (f.ex.) has more than one item
            //Check Specific Sector/Country to Delete and Delete Here
            for (let j=0 ; j < backendFilter[key][key2].length; j++) {
              Object.entries(backendFilter[key][key2][j]).forEach(([key, value]) => {
                if (value == specificDropdown) {
                  objDelete = j //saves position of specific item to delete
                }
              });
            }
            //delete the specific dropdown item
            backendFilter[key][key2].splice(objDelete,1)
          }
          else {
            delete backendFilter[key][key2]
          }
          if (i == 0 && (objDelete == -1)){ //if objDelete not -1, means only an object from array was deleted, so selection should stay blue
            allScreenerOptions[key].dirty = false;
          }
        }
        if (key2 != "") {
          i += 1 //We need it to check if it is the only filter in segment
        }
      }
      i = 0
    }
    setBackendFilter({ ...backendFilter })
  }

  function isNumeric(str){
    return /^\d+$/.test(str);
  }

  function isEmpty(filter) {
    let allEmpty = true;
    for (var key in filter) {
      if (filter[key] && filter[key].length > 0) {
        allEmpty = false;
      }
      for (var key2 in filter[key]) {
        if (filter[key][key2] && filter[key][key2].length > 0) {
          allEmpty = false;
        }
      }
    }
    return allEmpty;
  }

  const handleClose = () => {
    setSelectedFilter();
  };

  const clearFilter = () => {
    for (var key in backendFilter) {
      allScreenerOptions[key].dirty = false;
      for (var key2 in backendFilter[key]) {
        delete backendFilter[key][key2]
      }
    }
    // delete industries as well
    if (activeFilters["basicInfo"]?.data[1]?.options) {
      activeFilters["basicInfo"].data[1].options = [];
    }
    setBackendFilter({ ...backendFilter })
    handleLableData(backendFilter);
  };

  React.useEffect(() => {
    if (screenerResults) {
      setResultCount(screenerResults.length);
    }
  }, [screenerResults]);

  React.useEffect(() => {
    getResultCount();
    //localStorage.setItem("screenerFilter", JSON.stringify(backendFilter));
  }, [backendFilter]);
  return (
    <>
      <AlternativeHeader section={/*"Nice try ;)"*/ t("header") + " (Beta Phase)"} subsection="Portfolio" />
      {/* Page content       */}
      <Helmet>
        <title>Stock Screener | Bullsheet</title>
      </Helmet>
      {currentUser.subscription !== "freeTier" ?
        <Container
          maxWidth={false}
          component={Box}
          marginTop="-4.5rem"
          classes={{ root: classes.containerRoot }}
        >
          <Accordion expanded={!screenSelected} style={{ backgroundColor: theme.palette.white.main }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon classes={{ root: classes.expandIcon }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={handleClick}
            >
              <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                1. Choose a Preset or Create Your Own {screenSelected ? <Check classes={{ root: classes.checkIcon }} /> : ""}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
                <CardRectangleText onClick={handleClick} title="Custom Screen" subtitle="Build your own screen" color="Light" image={custom} />
                <CardRectangleText title="Warren Buffet Value Screen" subtitle="Stocks with solid financials at fair valuations" color="Grey" image={buffet} />
                <CardRectangleText title="O'Neil CANSLIM Screen" subtitle="Stocks poised to grow faster than average" color="Grey" image={oneil} />
                <CardRectangleText title="Dividend Focused Screen" subtitle="Companies with regular dividend payments" color="Grey" image={dividends} />
                <CardRectangleText title="Saved Screens" subtitle="Start with a screen you previously saved" color="Grey" image={saved} />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={screenSelected} style={{ backgroundColor: theme.palette.white.main }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon classes={{ root: classes.expandIcon }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                2. Check and Adjust Filters
            </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ marginBottom: "2rem" }}>
              <Grid container>
                <Grid item xl={12}>
                  <Button
                    variant="contained"
                    size="small"
                    classes={activeFilters["basicInfo"].dirty ? {
                      root: classes.filterButtonSelected,
                    } : {
                      root: classes.filterButton,
                    }}
                    onClick={() => setSelectedFilter("basicInfo")}
                  >
                    <InfoOutlined /> Basic Info
              </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={activeFilters["profitability"].dirty ? {
                      root: classes.filterButtonSelected,
                    } : {
                      root: classes.filterButton,
                    }}
                    onClick={() => setSelectedFilter("profitability")}
                  >
                    <AttachMoneyIcon /> Profitability
              </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={activeFilters["performance"].dirty ? {
                      root: classes.filterButtonSelected,
                    } : {
                      root: classes.filterButton,
                    }}
                    onClick={() => setSelectedFilter("performance")}
                  >
                    <TrendingUpIcon /> Performance
              </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={activeFilters["ratios"].dirty ? {
                      root: classes.filterButtonSelected,
                    } : {
                      root: classes.filterButton,
                    }}
                    onClick={() => setSelectedFilter("ratios")}
                  >
                    <FunctionsOutlinedIcon /> Ratios
              </Button>
                  <Button
                    variant="contained"
                    size="small"
                    classes={activeFilters["risk"].dirty ? {
                      root: classes.filterButtonSelected,
                    } : {
                      root: classes.filterButton,
                    }}
                    onClick={() => setSelectedFilter("risk")}
                  >
                    <ReportProblemOutlinedIcon /> Risk
              </Button>

                  <Button onClick={clearFilter} color={theme.palette.bsColors.MainBlue} classes={{ root: classes.clearButton }}>
                    <CancelIcon /> Clear Filters
              </Button>
                </Grid>
                <Grid item xl={12}>
                  <Box classes={{ root: classes.boxButtonsHeader }}>
                    {filterLabels && filterLabels.length > 0 ? filterLabels.map((prop, key) => (
                      
                        <Button
                          variant="contained"
                          size="small"
                          key={key}
                          classes={{root: classes.buttonRootLabels}}
                          onClick={() => handleLabelClick(prop)}
                        >
                          {prop}
                        </Button>

                    ))
                      :
                    <></>}
                    </Box>
                  </Grid>
                <Dialog
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                    },
                  }}
                  open={(selectedFilter)}
                >
                  <DialogTitle style={{backgroundColor:theme.palette.white.main}}>
                    <Typography style={{ textTransform: "capitalize" }}>
                      {selectedFilter}
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{backgroundColor:theme.palette.white.main}}>
                    {backendFilter && activeFilters[selectedFilter] && activeFilters[selectedFilter].data.map((prop, key) => (
                      <Grid key={key} item>
                        <SubScreener backendFilter={backendFilter} label={prop.label} short={prop.short} inputType={prop.inputType}
                          options={prop.options} icon={<prop.icon />} handleActiveFilters={handleActiveFilters} selectedFilter={selectedFilter} />
                      </Grid>))}
                  </DialogContent>
                  <DialogActions style={{backgroundColor:theme.palette.white.main}}>
                    <div style={{ position: "absolute", left: "8%" }}>{resultText}</div>
                    <Button onClick={handleClose} color={theme.palette.bsColors.MainBlue}>
                      Apply
                  </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Grid container>
            {/* {backendFilter && activeFilters.map((prop, key) => (
                <Grid key={key} item xs={12} sm={6} md={6} lg={4}>
                  <SubScreener backendFilter={backendFilter} label={prop.label} short={prop.short} inputType={prop.inputType}
                    options={prop.options} icon={<prop.icon />} handleActiveFilters={handleActiveFilters} />
                </Grid>))} */}
          </Grid>
          {
            <Button
              variant="contained"
              classes={{
                root: classes.buttonCTANormal,
              }}
              style={{ marginBottom: screenerResults ? "30px" : "100px", marginLeft: "45%", marginTop: "10px" }} disabled={!searchButtonEnabled}
              onClick={() => submitScreener()}>

              {resultText}
            </Button>}
          <Grid container>
            <Grid item xl={12} md={12} xs={12}>
              {screenerResults ? <ScreenerResults screenerResults={screenerResults} /> : <i style={{ marginLeft: "40%" }}>{t("info")}</i>}
            </Grid>
          </Grid>

        </Container> 
        : 
        <Box component="section" paddingTop="1.5rem" >
             <Box
              textAlign="center"
              paddingBottom="4.5rem" 
            >
              <Typography variant="h1" component="h1" className={classes.typographyH1Center} 
                style={{backgroundImage:"linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(126, 38%, 48%, 1) 75%)", 
                      "WebkitBackgroundClip": "text",
                      "WebkitTextFillColor": "transparent",
                      "MozBackgroundClip": "text",
                      "MozTextFillColor": "transparent",}} 
              >
                <b>{"Premium Members Exclusive"}</b>
              </Typography>
              <Box marginTop="1rem" textAlign="center">
              {"Support our project and get access to these premium Bullsheet features"}
              </Box>
              <Box marginTop="2rem" textAlign="center">
                    <Button
                      component={Link}
                      variant="contained"
                      to="/auth/pricing"
                      classes={{ root: classes.buttonCTANormal }}
                    >
                      {"Subscribe Now"}
                    </Button>
                </Box>
            </Box>
            <FeatScreener/>
          </Box> }
    </>
  );
};

export default Screener;
