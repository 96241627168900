import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-etf-holdings.js";
import { useTranslation } from "react-i18next";
import { CardContent } from "@material-ui/core";
import { Radar } from "react-chartjs-2";

const useStyles = makeStyles(componentStyles);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "Chart.js Line Chart - Multi Axis",
    },
  },
  scale: {
    ticks: {
      callback: function () {
        return "";
      },
    },
  },
};

const colors = ["#ef476f50", "#ffbc4260", "#06d6a050", "#118ab250", "#073b4c50"];

export const data = {
  labels: ["QoQ Rev. Growth", "Div Yield", "Operating Margin", "52W High to Low", "P/E Ratio"],
  datasets: [
    {
      label: "TSLA",
      data: [2, 9, 3, 5, 2, 3, 4],
      backgroundColor: colors[0],
      borderColor: colors[0],
      borderWidth: 1,
    },
    {
      label: "TSLA",
      data: [2, 9, 3, 5, 2, 3, 4],
      backgroundColor: colors[1],
      borderColor: colors[1],
      borderWidth: 1,
    },
    {
      label: "AAPL",
      data: [3, 9, 3, 2, 6, 3, 4],
      backgroundColor: colors[2],
      borderColor: colors[2],
      borderWidth: 1,
    },
    {
      label: "AAPL",
      data: [3, 9, 3, 2, 6, 3, 4],
      backgroundColor: colors[3],
      borderColor: colors[3],
      borderWidth: 1,
    },
    {
      label: "AAPL",
      data: [3, 9, 3, 2, 6, 3, 4],
      backgroundColor: colors[4],
      borderColor: colors[4],
      borderWidth: 1,
    },
  ],
};

export default function CardIndustryComparison({ stockData }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation("translation", { keyPrefix: "StockDetails" });
  const [chartData, setChartData] = React.useState(data);

  React.useEffect(() => {
    if (stockData) {
      const minSlices = Math.min(6, stockData.peers.length);
      stockData.peers.slice(0, 6).forEach((peer, index) => {
        if (data.datasets[index] && peer.ticker !== "SQ") {
          data.datasets[index].label = peer.ticker;
          //data.datasets[index].data[0] = peer.priceToSales * 3;
          //data.datasets[index].data[1] = peer.forwardPE;
          data.datasets[index].data[0] = peer.quarterlyRevGrowth * 100;
          data.datasets[index].data[1] = peer.yield * 100;
          data.datasets[index].data[2] = peer.operatingMargin * 100;
          data.datasets[index].data[3] = peer.highToLow52Week * 10;
          data.datasets[index].data[4] = peer.peRatio;
          data.datasets[index].backgroundColor = data.datasets[index].backgroundColor;
          data.datasets[index].borderColor = data.datasets[index].borderColor;
          data.datasets[index].borderWidth = 1;
          data.datasets[index].data = data.datasets[index].data.slice(0, 5);
        }
      });
      data.datasets = data.datasets.slice(0, minSlices);

      //setChartData({...data});
    }
  }, [stockData]);

  React.useEffect(() => {}, [chartData]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }} style={{ height: "685px" }}>
        <CardHeader
          subheader={
            <Grid container component={Box} alignItems="center" justifyContent="space-between">
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {"Industry"}
                  </Box>
                </Box>
                <Box component={Typography} variant="h3" marginBottom="0!important">
                  <Box component="span">{"Industry Comparison"}</Box>
                  <FeatureInfoWithBox description={t("generalInfoTooltip")} />
                </Box>
              </Grid>
              <Grid item xs="auto"></Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent style={{ marginTop: "-5px" }}>
          <Radar options={options} data={data} style={{}} />
        </CardContent>
      </Card>
    </>
  );
}
