const componentStyles = (theme) => ({
  cardRoot: {
    maxWidth: "22rem",
  },
  cardContentRoot: {
    padding: "1rem 1.5rem !important",
  },
  typographyRootH5: {
    textTransform: "uppercase",
    display: "flex",
  },
  tableLogo: {
    width: "1.8rem",
    height: "1.8rem",
    fontSize: ".875rem",
  },
  bgWhite: {
    backgroundColor: theme.palette.white.main,
  },
  bgGradientDefault: {
    background:
      "linear-gradient(87deg," +
      theme.palette.bsColors.MainBlue +
      ",#0F52BA)!important",
  },
  bgGradientError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.bsColors.NumberRed +
      ",#B44143)!important",
  },
  bgGradientSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.bsColors.GreenPantone +
      ",#9EC346)!important",
  },
  bgGradientPrimary: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ",#825ee4)!important",
  },
  bgGradientInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ",#1171ef)!important",
  },
  bgGradientWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.bsColors.Orange +
      ",#FA8334)!important",
  },
  bgGradientLightBlue: {
    background: 
    "linear-gradient(135deg," +
      theme.palette.bsColors.LogoLightBlue +
      ","+ theme.palette.bsColors.BlueGradientBot +")!important",
  },
  bgGradientLogoMainBlue: {
    background:
    "linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(240, 49%, 21%, 1) 90%)",
  }
});

export default componentStyles;
