const Chart = require("chart.js");
const themeColors = require("assets/theme/colors.js").default;
const darkThemeColors = require("assets/theme/colors.js").darkThemeColors;
// Only for demo purposes - return a random number to generate datasets
var randomScalingFactor = function () {
  return Math.round(Math.random() * 100);
};

let mode = "light";

//
// Chart extension for making the bars rounded
// Code from: https://codepen.io/jedtrow/full/ygRYgo
//
Chart.defaults.global.defaultFontFamily = "Open Sans";

Chart.elements.Rectangle.prototype.draw = function () {
  var ctx = this._chart.ctx;
  var vm = this._view;
  var left, right, top, bottom, signX, signY, borderSkipped, radius;
  var borderWidth = vm.borderWidth;
  // Set Radius Here
  // If radius is large enough to cause drawing errors a max radius is imposed
  var cornerRadius = 6;

  if (!vm.horizontal) {
    // bar
    left = vm.x - vm.width / 2;
    right = vm.x + vm.width / 2;
    top = vm.y;
    bottom = vm.base;
    signX = 1;
    signY = bottom > top ? 1 : -1;
    top= signY > 0 ? top : vm.base;
    bottom= signY > 0 ? bottom : vm.y;
    borderSkipped = vm.borderSkipped || "bottom";
  } else {
    // horizontal bar
    left = vm.base;
    right = vm.x;
    top = vm.y - vm.height / 2;
    bottom = vm.y + vm.height / 2;
    signX = right > left ? 1 : -1;
    signY = 1;
    left= signX > 0 ? left : vm.x;
    right= signX > 0 ? right : vm.base;
    borderSkipped = vm.borderSkipped || "left";
  }

  // Canvas doesn't allow us to stroke inside the width so we can
  // adjust the sizes to fit if we're setting a stroke on the line
  if (borderWidth) {
    // borderWidth shold be less than bar width and bar height.
    var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
    borderWidth = borderWidth > barSize ? barSize : borderWidth;
    var halfStroke = borderWidth / 2;
    // Adjust borderWidth when bar top position is near vm.base(zero).
    var borderLeft = left + (borderSkipped !== "left" ? halfStroke * signX : 0);
    var borderRight =
      right + (borderSkipped !== "right" ? -halfStroke * signX : 0);
    var borderTop = top + (borderSkipped !== "top" ? halfStroke * signY : 0);
    var borderBottom =
      bottom + (borderSkipped !== "bottom" ? -halfStroke * signY : 0);
    // not become a vertical line?
    if (borderLeft !== borderRight) {
      top = borderTop;
      bottom = borderBottom;
    }
    // not become a horizontal line?
    if (borderTop !== borderBottom) {
      left = borderLeft;
      right = borderRight;
    }
  }

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  // Corner points, from bottom-left to bottom-right clockwise
  // | 1 2 |
  // | 0 3 |
  var corners = [
    [left, bottom],
    [left, top],
    [right, top],
    [right, bottom],
  ];

  // Find first (starting) corner with fallback to 'bottom'
  var borders = ["bottom", "left", "top", "right"];
  var startCorner = borders.indexOf(borderSkipped, 0);
  if (startCorner === -1) {
    startCorner = 0;
  }

  function cornerAt(index) {
    return corners[(startCorner + index) % 4];
  }

  // Draw rectangle from 'startCorner'
  var corner = cornerAt(0);
  ctx.moveTo(corner[0], corner[1]);

  for (var i = 1; i < 4; i++) {
    corner = cornerAt(i);
    let nextCornerId = i + 1;
    if (nextCornerId === 4) {
      nextCornerId = 0;
    }

    // let nextCorner = cornerAt(nextCornerId);

    let width = corners[2][0] - corners[1][0];
    let height = corners[0][1] - corners[1][1];
    let x = corners[1][0];
    let y = corners[1][1];
    // eslint-disable-next-line
    var radius = cornerRadius;

    // Fix radius being too large
    if (radius > height / 2) {
      radius = height / 2;
    }
    if (radius > width / 2) {
      radius = width / 2;
    }

    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
  }

  ctx.fill();
  if (borderWidth) {
    ctx.stroke();
  }
};

//var mode = mode; //(themeMode) ? themeMode : 'light';
var fonts = {
  base: "Open Sans",
};

// Colors
function getColors(mode){
  if (mode === "light") {
    return {
      gray: themeColors.gray,
      theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: themeColors.secondary.main,
        info: themeColors.info.main,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
      },
      black: themeColors.black.light,
      white: themeColors.white.main,
      transparent: themeColors.transparent.main,
    };
  } else {
    return {
      gray: darkThemeColors?.gray,
      theme: {
        default: darkThemeColors?.dark?.main,
        primary: darkThemeColors?.primary?.main,
        secondary: darkThemeColors?.secondary?.main,
        info: darkThemeColors?.info?.main,
        success: darkThemeColors?.success?.main,
        danger: darkThemeColors?.error?.main,
        warning: darkThemeColors?.warning?.main,
      },
      black: darkThemeColors?.black?.light,
      white: darkThemeColors?.white?.main,
      transparent: darkThemeColors?.transparent?.main,
    };
  }
}

var colors = getColors(mode);
var allColors = (mode === "light" ? themeColors : darkThemeColors);
// Methods

// Chart.js global options
function chartOptions() {
  // Options
  var options = {
    defaults: {
      global: {
        responsive: true,
        maintainAspectRatio: false,
        defaultColor: mode === "dark" ? colors.gray[500] : colors.gray[600],
        defaultFontColor: mode === "dark" ? colors.gray[500] : colors.gray[600],
        defaultFontFamily: fonts.base,
        defaultFontSize: 13,
        layout: {
          padding: 0,
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            usePointStyle: true,
            padding: 16,
          },
        },
        elements: {
          point: {
            radius: 0,
            backgroundColor: colors.theme["primary"],
          },
          line: {
            tension: 0.4,
            borderWidth: 4,
            borderColor: colors.theme["primary"],
            backgroundColor: colors.transparent,
            borderCapStyle: "rounded",
          },
          rectangle: {
            backgroundColor: colors.theme["warning"],
          },
          arc: {
            backgroundColor: colors.theme["primary"],
            borderColor: mode === "dark" ? colors.gray[800] : colors.white,
            borderWidth: 4,
          },
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
      },
      doughnut: {
        cutoutPercentage: 70,
        legendCallback: function (chart) {
          var data = chart.data;
          var content = "";

          data.labels.forEach(function (label, index) {
            var bgColor = data.datasets[0].backgroundColor[index];

            content += '<span class="chart-legend-item">';
            content +=
              '<i class="chart-legend-indicator" style="background-color: ' +
              bgColor +
              '"></i>';
            content += label;
            content += "</span>";
          });

          return content;
        },
      },
    },
  };

  // yAxes
  Chart.scaleService.updateScaleDefaults("linear", {
    gridLines: {
      borderDash: [2],
      borderDashOffset: [2],
      color: mode === "dark" ? colors.gray[900] : colors.gray[300],
      drawBorder: false,
      drawTicks: false,
      lineWidth: 1,
      zeroLineWidth: 1,
      zeroLineColor: mode === "dark" ? colors.gray[900] : colors.gray[300],
      zeroLineBorderDash: [2],
      zeroLineBorderDashOffset: [2],
    },
    ticks: {
      beginAtZero: true,
      padding: 10,
      callback: function (value) {
        if (!(value % 10)) {
          return value;
        }
      },
    },
  });

  // xAxes
  Chart.scaleService.updateScaleDefaults("category", {
    gridLines: {
      drawBorder: false,
      drawOnChartArea: false,
      drawTicks: false,
    },
    ticks: {
      padding: 20,
    },
  });

  return options;
}

// Parse global options
function parseOptions(parent, options) {
  for (var item in options) {
    if (typeof options[item] !== "object") {
      parent[item] = options[item];
    } else {
      parseOptions(parent[item], options[item]);
    }
  }
}

// Example 1 of Chart inside src/views/dashboards/Dashboard.js
let chartExample1 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[700],
            zeroLineColor: colors.gray[700],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                return "$" + value + "k";
              }
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";

          if (data.datasets.length > 1) {
            content += label;
          }

          content += "$" + yLabel + "k";
          return content;
        },
      },
    },
  },
  data1: () => {
    return {
      labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Performance",
          data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
        },
      ],
    };
  },
  data2: () => {
    return {
      labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Performance",
          data: [0, 20, 5, 25, 10, 30, 15, 40, 40],
        },
      ],
    };
  },
};

// Example 2 of Chart inside src/views/dashboards/Dashboard.js and src/views/dashboards/Alternative.js and src/views/pages/Charts.js
let chartExample2 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                //return '$' + value + 'k'
                return value;
              }
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";
          if (data.datasets.length > 1) {
            content += label;
          }
          content += yLabel;
          return content;
        },
      },
    },
  },
  data: {
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Sales",
        data: [25, 20, 30, 22, 17, 29],
        maxBarThickness: 10,
      },
    ],
  },
};

// Example 3 of Chart inside src/views/dashboards/Alternative.js and src/views/pages/Charts.js
let chartExample3 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200],
          },
          ticks: {},
        },
      ],
    },
  },
  data: {
    labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Performance",
        data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
      },
    ],
  },
};

// Example 4 of Chart inside src/views/pages/Charts.js
const chartExample4 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200],
          },
          ticks: {},
        },
      ],
    },
  },
  data: {
    labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Performance",
        data: [10, 18, 28, 23, 28, 40, 36, 46, 52],
        pointRadius: 10,
        pointHoverRadius: 15,
        showLine: false,
      },
    ],
  },
};

// Example 5 of Chart inside src/views/pages/Charts.js
const chartExample5 = {
  data: {
    labels: ["Danger", "Warning", "Success", "Primary", "Info"],
    datasets: [
      {
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        backgroundColor: [
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
        ],
        label: "Dataset 1",
      },
    ],
  },
  options: {
    responsive: true,
    legend: {
      display: "true",
      position: "bottom",
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
    },
  },
};

// Example 6 of Chart inside src/views/pages/Charts.js
const chartExample6 = {
  data: {
    labels: ["Danger", "Warning", "Success", "Primary", "Info"],
    datasets: [
      {
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        backgroundColor: [
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
        ],
        label: "Dataset 1",
      },
    ],
  },
  options: {
    responsive: true,
    legend: {
      position: "top",
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
    },
  },
};

// Example 7 of Chart inside src/views/pages/Charts.js
const chartExample7 = {
  data: {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: colors.theme["danger"],
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        maxBarThickness: 10,
      },
      {
        label: "Dataset 2",
        backgroundColor: colors.theme["primary"],
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        maxBarThickness: 10,
      },
      {
        label: "Dataset 3",
        backgroundColor: colors.theme["success"],
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        maxBarThickness: 10,
      },
    ],
  },
  options: {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  },
};

// Example 1 of Chart inside src/views/dashboards/Dashboard.js
let chartExample8 = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[200],
            zeroLineColor: colors.gray[200],
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                return value;
              }
            },
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";

          if (data.datasets.length > 1) {
            content += label;
          }

          content += "$" + yLabel + "k";
          return content;
        },
      },
    },
  },
  data: () => {
    return {
      labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Performance",
          data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
        },
      ],
    };
  },
};

//This variable lets us have the "x assets" present on the function below
let origValue = undefined;

// Portfolio Breakdown Chart inside Dashboard
const chartPortfolioBreakdown = {
  data: {
    labels: ["AMZN", "AAPL", "FB", "NFLX", "GOOG", "CSCO", "ORCL", "COIN", "DE", "JPM"],
    datasets: [
      {
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        backgroundColor: [
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
        ],
        label: "Portfolio Stocks",
      },
    ],
  },
  options: {
    responsive: true,
    // legend: {
    //   display: "true",
    //   position: "bottom",
    //   align: "start",
    //   labels:{
    //     padding: 20,
    //   }
    // },
    hover: () => {},
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
      onComplete: function (chartInstance) {
        var ctx = this.chart.ctx;
        ctx.font = "bold 0.6rem 'Open Sans'";
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset) {
          for (var i = 0; i < dataset.data.length; i++) {
            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                start_angle = model.startAngle,
                end_angle = model.endAngle,
                mid_angle = start_angle + (end_angle - start_angle)/2;
            var x = mid_radius * Math.cos(mid_angle);
            var y = mid_radius * Math.sin(mid_angle);

            ctx.fillStyle = '#f1f1f1';
            if(dataset.data[i] > 0.01) {
            //ctx.drawImage("/assets/img/brand/bullsheet_symbol.svg",10,10);
            ctx.fillText(chartInstance.chart.config.data.labels[i], model.x + x, model.y + y);
            }  
            }
        });
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      }
    },
    cutoutPercentage: 70,
    tooltips: {
      enabled: false,
      mode: 'nearest',
      intersect: false,
      // callbacks: {
      //   label: function(tooltipItem, data) {
      //     var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0.00;
      //     var ticker = data.labels[tooltipItem.index];
      //     return ticker + ": " + label.toFixed(2) + "%"
      //   },
      // },
      custom: function(tooltip) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          //origValue starts as undefined and gets "x assets" on first iteration
          if(tooltipEl?.innerHTML.includes("assets")){
            origValue = tooltipEl.innerHTML
          }
          else if(tooltipEl?.innerHTML.includes("Anlagen")){
            origValue = tooltipEl.innerHTML
          }
          else if(tooltipEl?.innerHTML.includes("activos")){
            origValue = tooltipEl.innerHTML
          }
          else if(tooltipEl?.innerHTML.includes("attivi")){
            origValue = tooltipEl.innerHTML
          }
          else {
            origValue = origValue || tooltip?.innerHTML
          }
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            tooltipEl.innerHTML = origValue;
            return;
          }
        
          // Set Text
          if (tooltip.body) {
            var total = 0;
            // get the value of the datapoint
            var value = this._data.datasets[tooltip.dataPoints[0].datasetIndex].data[tooltip.dataPoints[0].index];
            var ticker = this._data.labels[tooltip.dataPoints[0].index]
            // calculate value of all datapoints
            var total = 100;
        
            // calculate percentage and set tooltip value - code to add logo is comented
            //<img src="https://etoro-cdn.etorostatic.com/market-avatars/tsla/150x150.png" width="35px" style="border-radius:50%" /><br/>
            tooltipEl.innerHTML = "<h1 style='color: " + themeColors.bsColors.MainBlue + ";'><b>" + ticker + "</b><br/>" + (value / total * 100).toFixed(2) + '%</h1>'; 
          } else {
          // calculate position of tooltip
          var centerX = (this._chartInstance.chartArea.left + this._chartInstance.chartArea.right) / 2;
        
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = centerX + 'px';
          tooltipEl.style.top = "0%";
          tooltipEl.style.fontFamily = "Open Sans";
          tooltipEl.style.fontSize = tooltip.fontSize;
          tooltipEl.style.fontStyle = tooltip._fontStyle;
          tooltipEl.style.color = 'red';
          tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
          }
        },
    },
  },
};

// Portfolio Breakdown Chart inside Dashboard - TODO refactor these 2 pathetic identical objects - theme toggling is not working within this file
const chartPortfolioBreakdownDark = {
  data: {
    labels: ["AMZN", "AAPL", "FB", "NFLX", "GOOG", "CSCO", "ORCL", "COIN", "DE", "JPM"],
    datasets: [
      {
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        backgroundColor: [
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
        ],
        label: "Portfolio Stocks",
      },
    ],
  },
  options: {
    responsive: true,
    // legend: {
    //   display: "true",
    //   position: "bottom",
    //   align: "start",
    //   labels:{
    //     padding: 20,
    //   }
    // },
    hover: () => {},
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
      onComplete: function (chartInstance) {
        var ctx = this.chart.ctx;
        ctx.font = "bold 0.6rem 'Open Sans'";
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset) {
          for (var i = 0; i < dataset.data.length; i++) {
            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                start_angle = model.startAngle,
                end_angle = model.endAngle,
                mid_angle = start_angle + (end_angle - start_angle)/2;
            var x = mid_radius * Math.cos(mid_angle);
            var y = mid_radius * Math.sin(mid_angle);

            ctx.fillStyle = '#f1f1f1';
            if(dataset.data[i] > 0.01) {
            //ctx.drawImage("/assets/img/brand/bullsheet_symbol.svg",10,10);
            ctx.fillText(chartInstance.chart.config.data.labels[i], model.x + x, model.y + y);
            }  
            }
        });
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      }
    },
    cutoutPercentage: 70,
    tooltips: {
      enabled: false,
      mode: 'nearest',
      intersect: false,
      // callbacks: {
      //   label: function(tooltipItem, data) {
      //     var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0.00;
      //     var ticker = data.labels[tooltipItem.index];
      //     return ticker + ": " + label.toFixed(2) + "%"
      //   },
      // },
      custom: function(tooltip) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          //origValue starts as undefined and gets "x assets" on first iteration
          if(tooltipEl?.innerHTML.includes("assets")){
            origValue = tooltipEl.innerHTML
          }
          else if(tooltipEl?.innerHTML.includes("Anlagen")){
            origValue = tooltipEl.innerHTML
          }
          else if(tooltipEl?.innerHTML.includes("activos")){
            origValue = tooltipEl.innerHTML
          }
          else if(tooltipEl?.innerHTML.includes("attivi")){
            origValue = tooltipEl.innerHTML
          }
          else {
            origValue = origValue || tooltip?.innerHTML
          }
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            tooltipEl.innerHTML = origValue;
            return;
          }
        
          // Set Text
          if (tooltip.body) {
            var total = 0;
            // get the value of the datapoint
            var value = this._data.datasets[tooltip.dataPoints[0].datasetIndex].data[tooltip.dataPoints[0].index];
            var ticker = this._data.labels[tooltip.dataPoints[0].index]
            // calculate value of all datapoints
            var total = 100;
        
            // calculate percentage and set tooltip value - code to add logo is comented
            //<img src="https://etoro-cdn.etorostatic.com/market-avatars/tsla/150x150.png" width="35px" style="border-radius:50%" /><br/>
            tooltipEl.innerHTML = "<h1 style='color: " + darkThemeColors.bsColors.MainBlue + ";'><b>" + ticker + "</b><br/>" + (value / total * 100).toFixed(2) + '%</h1>'; 
          } else {
          // calculate position of tooltip
          var centerX = (this._chartInstance.chartArea.left + this._chartInstance.chartArea.right) / 2;
        
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = centerX + 'px';
          tooltipEl.style.top = "0%";
          tooltipEl.style.fontFamily = "Open Sans";
          tooltipEl.style.fontSize = tooltip.fontSize;
          tooltipEl.style.fontStyle = tooltip._fontStyle;
          tooltipEl.style.color = 'red';
          tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
          }
        },
    },
  },
};

// Portfolio Breakdown Chart inside Dashboard
const chartSectorBreakdown = {
  data: {
    labels: ["Basic Materials", "Consumer Cyclical", "Financial Services", "Real Estate", "Comms. Services", "Energy", "Industrials", "Technology", 
            "Consumer Defensive", "Healthcare", "Utilities", "Hedge/Bond",  "Indices", "Forex", "Commodity"],
    datasets: [
      {
        data: [
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
          randomScalingFactor(),
        ],
        backgroundColor: [
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
          colors.theme["danger"],
          colors.theme["warning"],
          colors.theme["success"],
          colors.theme["primary"],
          colors.theme["info"],
          colors.theme["info"],
        ],
        label: "Portfolio Stocks by Sector",
      },
    ],
  },
  options: {
    responsive: true,
    legend: {
      display: "true",
      position: "bottom",
      align: "start",
      labels:{
        padding: 15,
        font: {
          size: 3,
        }
      }
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
    },
    elements: {
      arc: {
        borderWidth: 0,
      }
    },
    cutoutPercentage: 70,
  },
};


// Ratios Chart in Analysis
const chartRatios = {
  data: {
    labels: ["P/E", "P/FCF", "ROE (%)", "ROA (%)"],
    datasets: [
      {
        categoryPercentage: 0.75,

        data: [
          70,
          52,
          7,
          8,
        ],
        backgroundColor: [
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
        ],
        label: "Portfolio",
      },
      {
        categoryPercentage: 0.75,

        data: [
          22,
          25,
          9,
          12,
        ],
        backgroundColor: [
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
        ],
        label: "Market",
      },
    ],
  },
  options: {
    scales: {
      xAxes: [{
          ticks: {
              fontWeight: "600",
          },
          gridLines: {}
      }],
      yAxes: [{
        ticks: {
            fontWeight: "600",
        },
        gridLines: {}
      }]
    },
    tooltips: {
      enabled: false,
    },
    hover: {
        animationDuration: 1
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = themeColors.bsColors.MainBlue;
            ctx.textBaseline = 'bottom';
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    if (data < -7) {
                      ctx.fillStyle = themeColors.bsColors.GlobalWhite;
                      ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    } else {
                      ctx.fillStyle = themeColors.bsColors.MainBlue;
                      ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    }
                });
            });
        }
    }
  }
};

const chartRatiosDark = {
  data: {
    labels: ["P/E", "P/FCF", "ROE (%)", "ROA (%)"],
    datasets: [
      {
        categoryPercentage: 0.75,

        data: [
          70,
          52,
          7,
          8,
        ],
        backgroundColor: [
          darkThemeColors.bsColors.MainBlue,
          darkThemeColors.bsColors.MainBlue,
          darkThemeColors.bsColors.MainBlue,
          darkThemeColors.bsColors.MainBlue,
        ],
        label: "Portfolio",
      },
      {
        categoryPercentage: 0.75,

        data: [
          22,
          25,
          9,
          12,
        ],
        backgroundColor: [
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
        ],
        label: "Market",
      },
    ],
  },
  options: {
    scales: {
      xAxes: [{
          ticks: {
              fontWeight: "600",
          },
          gridLines: {}
      }],
      yAxes: [{
        ticks: {
            fontWeight: "600",
        },
        gridLines: {}
      }]
    },
    tooltips: {
      enabled: false,
    },
    hover: {
        animationDuration: 1
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = darkThemeColors.bsColors.MainBlue;
            ctx.textBaseline = 'bottom';
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    if (data < -7) {
                      ctx.fillStyle = darkThemeColors.bsColors.GlobalWhite;
                      ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    } else {
                      ctx.fillStyle = darkThemeColors.bsColors.MainBlue;
                      ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    }
                });
            });
        }
    }
  }
};

// Ratios SectorvsMarket in Analysis
const chartSectorVsMarket = {
  data: {
    labels: ["Basic Materials", "Consumer Cyclicals", "Financial Services", "Real Estate", "Communication Services", "Energy", "Industrials", "Technology", 
            "Consumer Defensive", "Healthcare", "Utilities", "Hedge/Bond", "Indices", "Forex", "Commodity"],
    datasets: [
      {
        axis: "y",
        data: [
          1,
          15,
          3,
          5,
          1,
          3,
          7,
          38,
          10,
          12,
          5,
          6
        ],
        backgroundColor: [
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
          '#1B1B4F',
        ],
        label: "Portfolio",
      },
      {
        axis: "y",
        data: [
          2.46,
          11.80,
          14.69,
          2.53,
          10.99,
          2.82,
          9.15,
          23.06,
          6.57,
          13.33,
          2.61,
          0,
        ],
        backgroundColor: [
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
          '#4D86C5',
        ],
        label: "S&P500",
      },
    ],
  },
  options: {
    responsive: true,
    indexAxis: "y",
    scales: {
      yAxes: [{
        type: "category",
        ticks: {
          fontSize: 10,
        }
      }],
      xAxes: [{
        ticks: {
            fontWeight: "600",
        },
        gridLines: {}
    }],
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          let value = data.datasets[item.datasetIndex].data[item.index]
          if (value === 0.10367369696969) { // TODO change this absolute madness
            value = 0.0;
          }
          return data.datasets[item.datasetIndex].label + ": " + value?.toFixed(2);
        },
      },
    },
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
    },    
  }
};

// RiskContribution in Analysis
const chartRiskContribution = {
  data: {
    labels: ["AMZN", "AAPL", "FB", "NFLX", "GOOG", "CSCO", "ORCL", "COIN", "DE", "JPM"],
    datasets: [
      {
        axis: "y",
        data: [
          9,
          6,
          5,
          5,
          4,
          -3,
          3,
          3,
          2,
          2,
        ],
        backgroundColor: [
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.NumberRed,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
          themeColors.bsColors.MainBlue,
        ],
        label: "Risk Contribution %",
      },
    ],
  },
  options: {
    responsive: true,
    indexAxis: "y",
    scales: {
      yAxes: [{
        type: "category",
        ticks: {
          fontSize: 10,
        }
      }],
      xAxes: [
        {
          ticks: {
            fontSize: 10,
            callback: function (value) {
              return (value) + '%'; // convert it to percentage
            },
          },
          gridLines: {}
        },
      ],
    },
    title: {
      display:true,
      position: "bottom",
      color: "#1f1f1f",
      text: "Contribution percent",
      padding: 5,
    },
    
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
    },
    
  }
};

// Risk Chart in Dashboard
const chartRiskClusters = {
  data: {
    labels: ["V. Low Risk", "Low Risk", "Medium Risk", "High Risk", "V. High Risk"],
    datasets: [
      {
        data: [
          5,
          20,
          25,
          30,
          20,
        ],
        backgroundColor: [
          themeColors.bsColors.Risk1,
          themeColors.bsColors.Risk3,
          themeColors.bsColors.Risk5,
          themeColors.bsColors.Risk7,
          themeColors.bsColors.Risk9,
        ],
        label: "Portfolio",
      },
    ],
  },
  options: {
    scales: {
      xAxes: [{
          ticks: {
              fontWeight: "600",
              fontColor: themeColors.bsColors.MainBlue,
          }
      }],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: false,
            
          //   callback: function (value) {
          //     return (value) + '%'; // convert it to percentage
          //   },
          },
        },
      ],
    },
    tooltips: {
      enabled:false,
      //Code to Add check Analysis for more:
      // callbacks: {
      //   label: function(tooltipItem, data) {
      //     var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
      //     return label ? "Check Analysis for more" : "Risk Weight: 0.00%";
      //   },
      // },
    },
    hover: {
        animationDuration: 1
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = themeColors.bsColors.MainBlue;
            ctx.textBaseline = 'bottom';
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = data + '%';
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            });
        }
    }
  }
};

// Risk Chart in Dashboard
const chartRiskClustersDark = {
  data: {
    labels: ["V. Low Risk", "Low Risk", "Medium Risk", "High Risk", "V. High Risk"],
    datasets: [
      {
        data: [
          5,
          20,
          25,
          30,
          20,
        ],
        backgroundColor: [
          darkThemeColors.bsColors.Risk1,
          darkThemeColors.bsColors.Risk3,
          darkThemeColors.bsColors.Risk5,
          darkThemeColors.bsColors.Risk7,
          darkThemeColors.bsColors.Risk9,
        ],
        label: "Portfolio",
      },
    ],
  },
  options: {
    scales: {
      xAxes: [{
          ticks: {
              fontWeight: "600",
              fontColor: darkThemeColors.bsColors.MainBlue,
          },
          gridLines: {}
      }],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            display: false,
            
          //   callback: function (value) {
          //     return (value) + '%'; // convert it to percentage
          //   },
          },
        },
      ],
    },
    tooltips: {
      enabled:false,
      //Code to Add check Analysis for more:
      // callbacks: {
      //   label: function(tooltipItem, data) {
      //     var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
      //     return label ? "Check Analysis for more" : "Risk Weight: 0.00%";
      //   },
      // },
    },
    hover: {
        animationDuration: 1
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = darkThemeColors.bsColors.MainBlue;
            ctx.textBaseline = 'bottom';
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = data + '%';
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            });
        }
    }
  }
};

// Top Chart in After Hours
const chartTopAfterHours = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          fontColor:(mode === "light" ? "#1f1f1f" : "#fafafa"),
        }
      }],
      yAxes: [
        {
          gridLines: {
            display:false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    events: [],
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: -5,
        right: -5,
        top: 35,
        bottom: 2
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = allColors.bsColors.NumberGreen;
            ctx.textBaseline = 'bottom';
            ctx.font = "bold 10px 'Open Sans'"
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = data ? data + '%' : "";
                    ctx.fillText(data, bar._model.x, bar._model.y - 2);
                    var img = new Image();
                    img.onload=function(){
                      ctx.save();
                      roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                      ctx.clip();
                      ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                      ctx.restore();
                    }
                    img.src = dataset.logoData[index];
                });
            });
        }
    }
  }
};

function roundedImage(ctx, x,y,width,height,radius){
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

const chartTopAfterHoursDark = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          fontColor:"#fafafa",
        }
      }],
      yAxes: [
        {
          gridLines: {
            display:false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    events: [],
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: -5,
        right: -5,
        top: 35,
        bottom: 2
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = darkThemeColors.bsColors.NumberGreen;
            ctx.textBaseline = 'bottom';
            ctx.font = "bold 10px 'Open Sans'"
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = data ? data + '%' : "";
                    ctx.fillText(data, bar._model.x, bar._model.y - 2);
                    var img = new Image();
                    img.onload=function(){
                      ctx.save();
                      roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                      ctx.clip();
                      ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                      ctx.restore();
                    }
                    img.src = dataset.logoData[index];
                });
            });
        }
    }
  }
};

// Bot Chart in After Hours
const chartBotAfterHours = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          fontColor:(mode === "light" ? "#1f1f1f" : "#fafafa"),
        }
      }],
      yAxes: [
        {
          gridLines: {
            display:false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    events: [],
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: -5,
        right: -5,
        top: 35,
        bottom: 2
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = allColors.bsColors.NumberRed;
            ctx.textBaseline = 'bottom';
            ctx.font = "bold 10px 'Open Sans'"
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = data ? "-" + data + '%' : "";
                    ctx.fillText(data, bar._model.x, bar._model.y - 2);
                    var img = new Image();
                    img.onload=function(){
                      ctx.save();
                      roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                      ctx.clip();
                      ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                      ctx.restore();
                    }
                    img.src = dataset.logoData[index];
                });
            });
        }
    }
  }
};

// Bot Chart in After Hours
const chartBotAfterHoursDark = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          fontColor:"#fafafa",
        }
      }],
      yAxes: [
        {
          gridLines: {
            display:false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    events: [],
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: -5,
        right: -5,
        top: 35,
        bottom: 2
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = allColors.bsColors.NumberRed;
            ctx.textBaseline = 'bottom';
            ctx.font = "bold 10px 'Open Sans'"
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = data ? "-" + data + '%' : "";
                    ctx.fillText(data, bar._model.x, bar._model.y - 2);
                    var img = new Image();
                    img.onload=function(){
                      ctx.save();
                      roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                      ctx.clip();
                      ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                      ctx.restore();
                    }
                    img.src = dataset.logoData[index];
                });
            });
        }
    }
  }
};

// Bot Chart in After Hours
const chartMixedCrypto = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          fontColor: allColors.bsColors.MainBlue,
        }
      }],
      yAxes: [
        {
          gridLines: {
            display:false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    events: [],
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: -5,
        right: -5,
        top: 35,
        bottom: 2
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = "bold 10px 'Open Sans'"
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    if (data < 0) {
                      var data = data ? data + '%' : "";
                      ctx.fillStyle = themeColors.bsColors.NumberRed;
                      ctx.fillText(data, bar._model.x, bar._model.y + 15);
                      var img = new Image();
                      img.onload=function(){
                        ctx.save();
                        roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                        ctx.clip();
                        ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                        ctx.restore();
                      }
                      img.src = dataset.logoData[index];
                    }
                    else if (data >= 0) {
                      var data = data ? data + '%' : "";
                      ctx.fillStyle = themeColors.bsColors.NumberGreen;
                      ctx.fillText(data, bar._model.x, bar._model.y - 2);
                      var img = new Image();
                      img.onload=function(){
                        ctx.save();
                        roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                        ctx.clip();
                        ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                        ctx.restore();
                      }
                      img.src = dataset.logoData[index];
                    }
                });
            });
        }
    }
  }
};

const chartMixedCryptoDark = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
            display:false
        },
        ticks: {
          fontColor: darkThemeColors.bsColors.MainBlue,
        }
      }],
      yAxes: [
        {
          gridLines: {
            display:false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    events: [],
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: -5,
        right: -5,
        top: 35,
        bottom: 2
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.font = "bold 10px 'Open Sans'"
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    if (data < 0) {
                      var data = data ? data + '%' : "";
                      ctx.fillStyle = darkThemeColors.bsColors.NumberRed;
                      ctx.fillText(data, bar._model.x, bar._model.y + 15);
                      var img = new Image();
                      img.onload=function(){
                        ctx.save();
                        roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                        ctx.clip();
                        ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                        ctx.restore();
                      }
                      img.src = dataset.logoData[index];
                    }
                    else if (data >= 0) {
                      var data = data ? data + '%' : "";
                      ctx.fillStyle = themeColors.bsColors.NumberGreen;
                      ctx.fillText(data, bar._model.x, bar._model.y - 2);
                      var img = new Image();
                      img.onload=function(){
                        ctx.save();
                        roundedImage(ctx, bar._model.x - 10, bar._model.y - 35, 20, 20, 11);
                        ctx.clip();
                        ctx.drawImage(img, bar._model.x - 10, bar._model.y - 35, 20, 20);
                        ctx.restore();
                      }
                      img.src = dataset.logoData[index];
                    }
                });
            });
        }
    }
  }
};

// Risk Chart in Dashboard
const chartRiskSummary = {
  data: {
    labels: ["Highest", "High", "Medium", "Low", "Lowest"],
    datasets: [
      {
        data: [
          25,
          20,
          30,
          5,
          20,
        ],
        backgroundColor: [
          '#D62828',
          '#F77F00',
          '#FCBF49',
          '#2A9D8F',
          '#1B1B4F',
        ],
        label: "Portfolio",
      },
    ],
  },
  options: {
    scales: {
      xAxes: [{
          ticks: {
              fontWeight: "600",
          }
      }],
      yAxes: [
        {
          ticks: {
      
          },
        },
      ],
    },
    title: {
      display:true,
      position: "bottom",
      color: "#1f1f1f",
      text: "Invested %",
      padding: 5,
    },
    tooltips: {
      enabled: true
    },
    hover: {
        animationDuration: 1
    },
    layout: {
      padding: {
        left: 0,
        right: 30,
        top: 0,
        bottom: 0
      }
    },
    animation: {
    duration: 0,
    onComplete: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "rgba(0, 0, 0, 1)";
            ctx.textBaseline = 'bottom';
            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = data + '%';
                    ctx.fillText(data, bar._model.x +15, bar._model.y + 5);
                });
            });
        }
    }
  }
};

// Sector Breakdown Horizontal Chart inside Dashboard
const chartSectorBreakdownHorizontal = {
  options: {
    indexAxis: "y",
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            crossAlign: "far",
            align: "start",
            mirror: false,
            padding: 5,
            fontColor: (mode == "light" ? "#1b1b4f" : "#f1f1f1"),
            fontStyle: "600",
            fontFamily: "Open Sans",
            z: 2,
            beginAtZero: true,
          }
      }],
      xAxes: [
        {
          ticks: {
            crossAlign: "far",
            align: "start",
            mirror: false,
            padding: 5,
            fontColor: (mode == "light" ? "#1b1b4f" : "#f1f1f1"),
            fontStyle: "400",
            fontFamily: "Open Sans",
            z: 2,
            beginAtZero: true,
          },
          gridLines: {}
      }]
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
          return label ? "Sector Weight: " + Number(label).toFixed(2) + "%" : "Sector Weight: 0.00%";
        },
      },
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
      onLoad: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'right';
            ctx.fillStyle = "#1f1f1f";
            ctx.font = "bold 0.8rem Open Sans";
            ctx.textBaseline = 'bottom';

            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = Number(Math.round(parseFloat(data + 'e' + 1)) + 'e-' + 1)
                    var data = data + '%';
                    ctx.fillText(data, +390, bar._model.y + 5);
                });
            });
        }
    },
    layout: {
      padding: {
        left: 0,
        right: 35,
        top: 0,
        bottom: 0
      }
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
  },
};

// Sector Breakdown Horizontal Chart on ETF Search
const chartETFSectorBreakdownHorizontal = {
  options: {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            crossAlign: "far",
            align: "start",
            mirror: false,
            padding: 5,
            fontColor: (mode == "light" ? "#1b1b4f" : "#f1f1f1"),
            fontStyle: "600",
            fontFamily: "Open Sans",
            z: 2,
            beginAtZero: true,
          },
      }]
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
          return label ? "Sector Weight: " + Number(label).toFixed(2) + "%" : "";
        },
      },
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
      onLoad: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'right';
            ctx.fillStyle = "#1f1f1f";
            ctx.font = "bold 0.8rem Open Sans";
            ctx.textBaseline = 'bottom';

            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = Number(Math.round(parseFloat(data + 'e' + 1)) + 'e-' + 1)
                    var data = data + '%';
                    ctx.fillText(data, +390, bar._model.y + 5);
                });
            });
        }
    },
    layout: {
      padding: {
        left: 0,
        right: 30,
        top: 0,
        bottom: 0
      }
    },
  },
};

const chartETFSectorBreakdownHorizontalDark = {
  options: {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            crossAlign: "far",
            align: "start",
            mirror: false,
            padding: 5,
            fontColor: "#f1f1f1",
            fontStyle: "600",
            fontFamily: "Open Sans",
            z: 2,
            beginAtZero: true,
          },
      }]
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
          return label ? "Sector Weight: " + Number(label).toFixed(2) + "%" : "";
        },
      },
    },
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
      onLoad: function () {
        var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'right';
            ctx.fillStyle = "#1f1f1f";
            ctx.font = "bold 0.8rem Open Sans";
            ctx.textBaseline = 'bottom';

            // Loop through each data in the datasets
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    var data = Number(Math.round(parseFloat(data + 'e' + 1)) + 'e-' + 1)
                    var data = data + '%';
                    ctx.fillText(data, +390, bar._model.y + 5);
                });
            });
        }
    },
    layout: {
      padding: {
        left: 0,
        right: 30,
        top: 0,
        bottom: 0
      }
    },
  },
};

const chartCryptoPortfolioBreakdown = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
       display: false,
    },
    hover: () => {},
    animation: {
      duration: 0,
      animateScale: false,
      animateRotate: false,
      onComplete: function (chartInstance) {
        var ctx = this.chart.ctx;
        ctx.font = "bold 0.6rem 'Open Sans'";
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset) {
          for (var i = 0; i < dataset.data.length; i++) {
            if(dataset.data[i] > 2) {
              var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                  mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius)/2,
                  start_angle = model.startAngle,
                  end_angle = model.endAngle,
                  mid_angle = start_angle + (end_angle - start_angle)/2;
              var x = mid_radius * Math.cos(mid_angle);
              var y = mid_radius * Math.sin(mid_angle);

              ctx.fillStyle = '#f1f1f1';
              //ctx.drawImage("/assets/img/brand/bullsheet_symbol.svg",10,10);
              ctx.fillText(chartInstance.chart.config.data.labels[i], model.x + x, model.y + y);
              }  
            }
        });
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      }
    },
    cutoutPercentage: 70,
    tooltips: {
      enabled: false,
      mode: 'nearest',
      intersect: false,
      custom: function(tooltip) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          //origValue starts as undefined and gets "x assets" on first iteration
          if(tooltipEl?.innerHTML.includes("cryptos")){
            origValue = tooltipEl.innerHTML
          }
          else {
            origValue = origValue || tooltip?.innerHTML
          }
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            tooltipEl.innerHTML = origValue;
            return;
          }
        
          // Set Text
          if (tooltip.body) {
            var total = 0;
            // get the value of the datapoint
            var value = this._data.datasets[tooltip.dataPoints[0].datasetIndex].data[tooltip.dataPoints[0].index];
            var ticker = this._data.labels[tooltip.dataPoints[0].index]
            // calculate value of all datapoints
            var total = this._data.datasets[tooltip.dataPoints[0].datasetIndex].data.reduce((a, b) => a + b, 0);
        
            // calculate percentage and set tooltip value - code to add logo is comented
            //<img src="https://etoro-cdn.etorostatic.com/market-avatars/tsla/150x150.png" width="35px" style="border-radius:50%" /><br/>
            if (mode === "dark"){
              tooltipEl.innerHTML = "<h1 style='color: #fafafa;'><b>" + ticker + "</b><br/>" + (value / total * 100).toFixed(2) + '%</h1>';
            } else {
              tooltipEl.innerHTML = '<h1><b>' + ticker + "</b><br/>" + (value / total * 100).toFixed(2) + '%</h1>';
            }
          }
          // calculate position of tooltip
          var centerX = (this._chartInstance.chartArea.left + this._chartInstance.chartArea.right) / 2;
        
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = centerX + 'px';
          tooltipEl.style.top = "0%";
          tooltipEl.style.fontFamily = "Open Sans";
          tooltipEl.style.fontSize = tooltip.fontSize;
          tooltipEl.style.fontStyle = tooltip._fontStyle;
          tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
        },
    },
  },
};


module.exports = {
  chartOptions, // used alonside with the chartExamples variables
  parseOptions, // used alonside with the chartExamples variables
  chartExample1, // used inside src/views/dashboards/Dashboard.js
  chartExample2, // used inside src/views/dashboards/Dashboard.js and src/views/dashboards/Alternative.js and src/views/pages/Charts.js
  chartExample3, // used inside src/views/dashboards/Alternative.js and src/views/pages/Charts.js
  chartExample4, // used inside src/views/pages/Charts.js
  chartExample5, // used inside src/views/pages/Charts.js
  chartExample6, // used inside src/views/pages/Charts.js
  chartExample7, // used inside src/views/pages/Charts.js
  chartExample8, // used inside src/views/pages/Charts.js
  chartPortfolioBreakdown,
  chartPortfolioBreakdownDark,
  chartSectorBreakdown,
  chartRatios,
  chartRatiosDark,
  chartSectorVsMarket,
  chartRiskContribution,
  chartRiskClusters,
  chartRiskClustersDark,
  chartRiskSummary,
  chartSectorBreakdownHorizontal,
  chartETFSectorBreakdownHorizontal,
  chartETFSectorBreakdownHorizontalDark,
  chartTopAfterHours,
  chartBotAfterHours,
  chartMixedCrypto,
  chartTopAfterHoursDark,
  chartBotAfterHoursDark,
  chartMixedCryptoDark,
  chartCryptoPortfolioBreakdown,
};
