import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import NoBreadcrumbsHeader from "components/Headers/NoBreadcrumbsHeader";
import { Typography } from "@material-ui/core";
import PricingHeader from "components/Headers/PricingHeader";
import getTheme from "assets/theme/theme.js";

function TermsConditionsPage() {
  const theme = getTheme("light");
  return (
    <>
      <PricingHeader title="Terms and Conditions" description="Important: Bullsheet does not offer investment advice. Use our software and data at your own risk, please Do Your Own Research (DYOR) before investing in any asset."/>
      <Helmet>
        <title>Terms and Conditions | Bullsheet</title>
      </Helmet>
      <Container
        component={Box}
        maxWidth="xl"
        marginTop="-7rem"
        paddingBottom="3rem"
        position="relative"
        zIndex="101"
        style={{backgroundColor:"#F8F9FD", paddingTop:"10px"}}
      >
        <Box>
          <Typography variant="h3" style={{color:theme.palette.bsColors.MainBlue}}>
            <p>Last updated: July 05, 2021</p>
            <p>Please read these terms and conditions carefully before using Our Service.</p>
            <h1>Interpretation and Definitions</h1>
            <h2>Interpretation</h2>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <h2>Definitions</h2>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul>
            <li>
            <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
            </li>
            <li>
            <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Bullsheet.</p>
            </li>
            <li>
            <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
            </li>
            <li>
            <p><strong>Service</strong> refers to the Website.</p>
            </li>
            <li>
            <p><strong>Terms and Conditions</strong> (also referred as &quot;Terms&quot;) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</p>
            </li>
            <li>
            <p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
            </li>
            <li>
            <p><strong>Website</strong> refers to Bullsheet, accessible from <a href="https://bullsheet.me" rel="external nofollow noopener noreferrer" target="_blank">https://bullsheet.me</a></p>
            </li>
            <li>
            <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </li>
            </ul>
            <h1>Acknowledgment</h1>
            <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
            <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service and should contact us via the email available below.</p>
            <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
            <h1>General Rules</h1>
            <p>By registering an account with us, you promise to follow these basic rules:</p>
            <p>1. You will not use Bullsheet to reverse engineer any formula that could put you in a breach of service with your broker. </p>
            <p>2. You will not abuse Bullsheet's public profile search capabilities to perform too many user searches that could be viewed as unfair use of the brokers' API to create a database of investments.</p>
            <p>3. You will not spam your refferal links in any website or source with the intent of getting a large number of referrals through spam..</p>
            <p>4. You will not share your account with another user in an attempt to spare some bucks on 2 subscriptions instead of one. We appreciate all the support you give us to keep working on this project.</p>
            <h1>Further Prohibited Uses of Bullsheet</h1>
            <p>1. You will not use or launch robots, spiders, readers or any other automated requests to send more automated requests to our server than a human being normally could using a conventional browser.</p>
            <p>2. You will not use our Services in any way that is unlawful or prohibited by these terms. </p>
            <p>3. You will not attempt to gain unauthorized access to our Subscription Services. </p>
            <h1>Subscription Services</h1>
            <p><b>Monthly Subscription:</b> By purchasing a Monthly Subscription, you agree to an initial and recurring Monthly Subscription fee at the then-current Monthly Subscription rate, and you accept responsibility for all recurring charges until you cancel your subscription. You may cancel your Monthly Subscription at any time, subject to the terms of our cancellation policy.</p>
            <p><b>AUTOMATIC MONTHLY RENEWAL TERMS:</b> Once you subscribe, Bullsheet will automatically process your Monthly Subscription fee in the next billing cycle. Bullsheet will continue to automatically process your Monthly Subscription fee each month at the then-current Monthly Subscription rate, until you cancel your subscription. We explain how to cancel your Monthly Subscription below at the Section "Cancellation Policy".</p>
            <p><b>Annual Subscription:</b> By purchasing an Annual Subscription, you agree to an initial pre-payment for one full year of service (after you are off the waitlist). After one year and annually thereafter, you will be billed a recurring Annual Subscription renewal fee at the then-current Annual Subscription rate. We will notify you before the renewal fee is billed. You may cancel your Annual Subscription anytime before the next billing cycle, subject to the terms of our cancellation policy.</p>
            <p><b>AUTOMATIC ANNUAL RENEWAL TERMS:</b> We bill your credit card for the Annual Subscription renewal fee in the first month of your next Annual Subscription.</p>
            <p>For example, let's say you purchased an Annual Subscription beginning in August 2021 and ending in July 2022. We will bill you for the second year (August 2022 to July 2023) in August 2022, unless you cancel your Annual Subscription before August 2022. You accept responsibility for all recurring charges prior to cancellation. Once you subscribe, Bullsheet will automatically process your Annual Subscription fee at the then-current Annual Subscription rate. We explain how to cancel your Annual Subscription below at the Section "Cancellation Policy".</p>
            <h1>Cancellation Policy</h1>
            <p>You may cancel your Monthly Subscription at any time by logging into your account, clicking on Account/Edit Account/Membership, and following the cancellation procedures described there. If you need help, feel free to contact us on info@bullsheet.me . If you cancel your Monthly Subscription, the cancellation will take effect for your next monthly billing cycle. You will not be eligible for a refund for monthly subscription fees paid prior to the month the cancellation takes effect.</p>
            <h1>Cancellation Policy for Annual Subscription Renewals</h1>
            <p>Annual Subscription fees are non-refundable. You may cancel your Annual Subscription renewal at any time after you are billed for the then-current year and before you are billed for the next year, by logging into your account, clicking on Account/Edit Account/Membership, and following the cancellation procedures or by contacting us at info@bullsheet.me</p>
            <h1>Termination by the Bullsheet Team</h1>
            <p>We reserve the right to terminate your subscription without notice. In this case, refunds will be processed depending on your account situation. We also reserve the right to terminate any free account without notice.</p>
            <h1>Intellectual Property</h1>
            <p>All Content (graphics, layouts, texts, images, "Cards", etc.), including but not limited to the "look and feel," layout, design, structure and color scheme are owned by or licensed to Bullsheet. Such content is protected by copyright, trademark, trade dress and various other intellectual property and unfair competition laws.</p>
            <p>Except with our prior written consent, you are not allowed to redistribute or create derivative works of our Content</p>
            <h1>Links to Other Websites</h1>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
            <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
            <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
            <h1>Limitation of Liability</h1>
            <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 10 USD if You haven't purchased anything through the Service.</p>
            <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
            <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
            <h1>&quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer</h1>
            <p>The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
            <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
            <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
            <h1>Disputes Resolution</h1>
            <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
            <h1>For European Union (EU) Users</h1>
            <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>
            <h1>United States Legal Compliance</h1>
            <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
            <h1>Severability and Waiver</h1>
            <h2>Severability</h2>
            <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
            <h2>Waiver</h2>
            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
            <h1>Translation Interpretation</h1>
            <p>These Terms and Conditions may have been translated if We have made them available to You on our Service.
            You agree that the original English text shall prevail in the case of a dispute.</p>
            <h1>Changes to These Terms and Conditions</h1>
            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
            <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
            <h1>Contact Us</h1>
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
            <ul>
            <li>By email: info@bullsheet.me</li>
            </ul>
            <br/>
            <h1>Final Disclaimer: Bullsheet does not offer any investment advice.</h1>

          </Typography>
        </Box>
      </Container>
    </>
  );
}

export default TermsConditionsPage;
