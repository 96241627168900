import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "1rem",
    },
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  cardContent: {
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  checksBox: {
    paddingLeft:"0.5rem",
    paddingTop:"2rem",
    fontWeight:"600",
    lineHeight:"2.4rem",
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
      textAlign: "left",
      lineHeight:"2rem",
      paddingTop:"1rem",
      fontSize: theme.typography.h5.fontSize,
    },
  },
  header: {
    
    [theme.breakpoints.up("md")]: {
      paddingTop: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: "3rem",
      marginRight: "3rem",
    },
  },
  gridItem: {
    paddingTop:"8rem", 
    paddingBottom: "4rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1rem",
      textAlign: "center",
      marginLeft: "2rem",
      marginRight: "2rem",
    },
  },
  imgRegister: {
    borderTopLeftRadius: "calc(.375rem - 1px)",
    borderTopRightRadius: "calc(.375rem - 1px)",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    maxWidth: "25rem",
    objectFit: "cover",
    paddingTop: "3rem",
  },
  buttonImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  buttonRoot: {
    backgroundColor: theme.palette.white.main,
    maxWidth: "8.5rem",
    color: theme.palette.primary.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    borderColor: theme.palette.white.main + "!important",
    "&:hover": {
      color: theme.palette.gray[900],
      borderColor: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.white.main,
    },
  },
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  footerLinks: {
    color: theme.palette.bsColors.MainBlue,
    textDecoration: "none",
  },
  checkIcon: {
    color: theme.palette.bsColors.NumberGreen,
    width: 25,
    height: 25,
  },
  typographyH1: {
    color: theme.palette.bsColors.MainBlue,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: "2.75rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  typographyH2: {
    color: theme.palette.bsColors.MainBlue,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: "2rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  heroSubheader: {
    color: theme.palette.bsColors.MainBlue,
    fontWeight: "400!important",
    marginBottom: "0!important",
    fontSize: "1.1275rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  typographyH3: {
    color: theme.palette.bsColors.MainBlue,
    fontWeight: "700!important",
    marginBottom: "0!important",
    fontSize: "1.1275rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  typographyH4: {
    color: theme.palette.bsColors.MainBlue,
    fontWeight: "700!important",
    marginBottom: "0!important",
    fontSize: "1rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  imgBox: {
    verticalAlign: "middle",
    borderStyle: "none",
    width: "90%",
    transition: "opacity 2s ease-in",
    [theme.breakpoints.down("md")]: {
      paddingTop: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "4rem",
      width: "90%",
      alignSelf: "center",
    },
  },
  buttonRoot: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.bsColors.LogoLightBlue,
    borderColor: theme.palette.bsColors.LogoLightBlue,
    marginRight: "1rem",
    fontSize: theme.typography.h4.fontSize,
    "&:hover": {
      backgroundColor: theme.palette.bsColors.MainBlue,
      borderColor: theme.palette.bsColors.MainBlue,
      color: theme.palette.white.main,
    },
  },
  ptLg5: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "3rem",
    },
  },
});

export default componentStyles;
