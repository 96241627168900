import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { useService } from "contexts/ServiceContext";
import Avatar from "@material-ui/core/Avatar";

// core components
import componentStyles from "assets/theme/components/card-market-performance.js";
import boxShadows from "assets/theme/box-shadow.js";

const useStyles = makeStyles(componentStyles);
const countryCodes = {'S&P 500' : 'United States', 'DJ 30' : 'United States', 'NDQ 100': 'United States', 'DAX' : 'Germany', 'BTC' : "Bitcoin", '10-Yr Tr.' : 'United States',};

function CardMarketPerformance({ direction, subtitle, title, change}) {
  const classes = useStyles();
  const theme = useTheme();
  const {flags} = useService();
  let country = countryCodes[title];
  return (
    <>
      <Card classes={{ root: classes["cardRoot"+ direction] }} elevation={0}>
        <CardContent classes={{ root: classes.cardContentRoot }}>
        <Box display="flex">
            <img className={classes.logoStyle} src={flags[country]} height="80vh"/>
                <Box alignSelf="center" style={{paddingLeft: "10px"}}>
                  <Typography className={classes.typographyH4White}>{title}</Typography>
                  <Typography className={classes.typographyH5White}>
                    ${subtitle}<br/>
                    {change}%
                  </Typography>
                </Box>
                </Box>
        </CardContent>
      </Card>
    </>
  );
}

CardMarketPerformance.defaultProps = {
  color: "bgPrimaryLight",
};

CardMarketPerformance.propTypes = {
  direction: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  change: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]),
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.oneOf([
    "bgPrimary",
    "bgPrimaryLight",
    "bgError",
    "bgErrorLight",
    "bgWarning",
    "bgWarningLight",
    "bgInfo",
    "bgInfoLight",
    "bgSuccess",
    "bgSuccessLight",
  ]),
};

export default CardMarketPerformance;
