import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStyles from "assets/theme/components/cards/charts/card-portfolio-breakdown.js";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import ShowMoreButton from "./ShowMoreButton";
import { useTranslation } from 'react-i18next';

const DEFAULT_PAGINATION = 20;

const useStylesButtons = makeStyles(componentStylesButtons);
const useStyles = makeStyles(componentStyles);

/**
 * Component to use as info table on clicked tables/maps
 * @param {Object} modalData - Data displayed on the table
 *            {
 *              label: string - Name of the clicked entity
 *              weight: number - Weight the clicked entity
 *              data[]: 
 *                    {
 *                      tickerName: string - Name of the asset
 *                      weight?: number - Weight of that asset
 *                      parent?: string - Parent of that asset
 *                      logo?: string - URL of logo of that asset
 *                    }
 *            }
 * @param {Function} setModalData - Parent state setting function
 * @param {List} tableHead - column names in the table. Must correspond to data provided.
 * @param {string} dataType - data type that defines which columns to use
 */

export default function ChartDetailModal({ modalData, setModalData, tableHead, dataType }) {
  const [showMore, setShowMore] = React.useState(DEFAULT_PAGINATION);
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardSectorBreakdownHorizontal' });

  const handleClose = () => {
    setModalData({...modalData, isOpen: false });
    setShowMore(DEFAULT_PAGINATION)
  };

  const translationDict = {
    "ticker": "Ticker",
    "weight": "Weight",
    "source": "Source",
    "parent": "Parent",
    "other": "Other",
  }

  return (
    <Dialog
      open={modalData?.isOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DialogTitle style={{backgroundColor:theme.palette.white.main}}>
        <Typography style={{color:theme.palette.bsColors.MainBlue, fontWeight:"600"}}>
          {dataType === "MAP_PERFORMANCE" ? `${modalData?.label} (${modalData?.weight?.toFixed(2)}%)` : `${t(modalData?.label)} (${modalData?.weight?.toFixed(2)}%)`}
        </Typography>
      </DialogTitle>
      <DialogContent style={{backgroundColor:theme.palette.white.main}}>
        <TableContainer style={{marginTop: -25}}>
        <Box component={Table} alignItems="center" marginBottom="0!important">
        <TableHead>
          <TableRow>
          {tableHead.map((prop, key) => (
              <TableCell
              key={key}
              scope="col"
              classes={{
                  root:
                  classes.tableCellRoot + " sort " + classes.tableCellRootHead,
              }}
              >
              {t(translationDict[prop])}
              </TableCell>
          ))}
          </TableRow>
        </TableHead>
          <TableBody className="list">
          { modalData?.data ? modalData?.data.slice(0, showMore)
          .map((prop, key) => (
              <TableRow key={key}>
              <TableCell
                  classes={{
                  root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
              >
                  <Box alignItems="center" display="flex">
                  <Box
                      component={Avatar}
                      style={{ width: "20px", height: "20px" }}
                      marginRight="0.875rem"
                      alt="..."
                      src={prop.logo}
                  />
                  <Box display="flex" alignItems="flex-start">
                      <Box fontSize={theme.typography.h5.fontSize} component="span" className="ticker">
                      {prop.tickerName}
                      </Box>
                  </Box>
                  </Box>
              </TableCell>
              { modalData.data[0].hasOwnProperty("weight") && 
                <TableCell classes={{ root: classes["tableCellRoot" + (prop.priceChangePercentage > 0 ? "Positive" : "Negative")]}}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="change">
                    <Box
                        marginRight="-0.5rem"
                    />
                    {"• "}
                    {prop.weight?.toFixed(2)}%
                    </Box>
                </TableCell>
              }
              { modalData.data[0].hasOwnProperty("parent") &&
                <TableCell classes={{ root: classes.tableCellRoot }} className="price">
                    {prop.parent ? prop.parent : currentEtoroUserData?.userInfo?.userName}
                </TableCell>
              }
              </TableRow>
          )) : <></> }
          </TableBody>
        </Box>
        </TableContainer>
        <ShowMoreButton
          DEFAULT_PAGINATION={DEFAULT_PAGINATION}
          setShowMore={setShowMore}
          showMore={showMore}
          tableSize={modalData?.data?.length}
        />
      </DialogContent>
      <DialogActions style={{backgroundColor:theme.palette.white.main}}>
          <Button onClick={handleClose} color={theme.palette.bsColors.MainBlue}>
            Close
          </Button>
      </DialogActions>
    </Dialog>
  )
}