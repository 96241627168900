import firebase from 'firebase/app'
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const app = firebase.initializeApp({
    apiKey: "AIzaSyAj15OxU-PSo_lWOG3_f6IqW0PQ6yJGlaU",
    authDomain: "bullsheet.me",
    projectId: "bullsheet-react-develop",
    storageBucket: "bullsheet-react-develop.appspot.com",
    messagingSenderId: "951224457061",
    appId: "1:951224457061:web:a52421c05c7522eea2738c",
});

export const fieldValue = firebase.firestore.FieldValue;
export const analytics = app.analytics();
export const auth = app.auth();
export const firestore = app.firestore();
export default app;