import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
// import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward"
import Button from "@material-ui/core/Button";


// core components
import componentStyles from "assets/theme/components/cards/cards/card-bg-image.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
const MAX_TITLE_LENGTH = 50;

function CardNewsBgImage({ ticker, date, image, title, source, url}) {
  const classes = { ...useStyles(), ...useStylesCardImg() };
  const theme= useTheme();
  let utm = "?utm_source=bullsheet&utm_medium=referral";
  function handleClick(){
    window.open(url + utm, "_blank");
  }
  return (
    <>
      <Card className={classes.cardRoot}>
        <img
          alt="..."
          className={classes.cardImgTopBackground}
          src={image}
          style={{maxHeight:"150px"}}
        />
        <Box
          display="flex"
          alignItems="center"
          className={classes.cardImgOverlay}
        >
          <div style={{backdropFilter:"blur(2px)"}}>
          <Box
              component="p"
              marginBottom="0"
              fontWeight="400"
              lineHeight="1.7"
              fontSize={theme.typography.h6.fontSize}
              marginTop="0"
            >
              {source} - {date}
            </Box>
            <Typography variant="h5" className={classes.typographyH2}>
              {title.length > MAX_TITLE_LENGTH ? title.slice(0, MAX_TITLE_LENGTH) + "..." : title} - {ticker}
            </Typography>
            
          </div>
          <Button onClick={handleClick}
            variant="text"
            disableRipple
            component={Box}
            padding="0!important"
            style={{float:"right", color: theme.palette.white.main, minWidth:"40px", right:"3px", bottom:"10px", position:"absolute"}}
          >
            <ArrowForward />
          </Button>
        </Box>
      </Card>
    </>
  );
}

export default CardNewsBgImage;
