import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components


import componentStyles from "assets/theme/components/headers/features-header.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import FeatExportPortfolio from './FeatExportPortfolio';
import FeatETFDetails from './FeatETFDetails';
import FeatScreener from './FeatScreener';

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const AdditionalSuperpowersSection = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = getTheme("light");
  return (
      <>
      <Box style={{backgroundColor:theme.palette.background.default}}>
        <Box paddingTop="4.5rem" paddingBottom="4.5rem" component="section">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={12} style={{textAlign:"center"}}>
                <Typography variant="h1" component="h1" className={classes.typographyH1Center}>
                  Additional Superpowers for your Portfolio
                </Typography>
              </Grid>
              <FeatScreener/>
              <FeatExportPortfolio/>
              <FeatETFDetails/>
              {/* <Grid item xs={12} md={6} className={classes.orderMd2}>
                <Box
                  component="img"
                  alt="Automate your Etoro Portfolio"
                  maxWidth="100%"
                  height="auto"
                  className={classes.imgBox}
                  src={require("assets/img/theme/hero_hp_test.png").default}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6} className={classes.orderMd1}>
                <div className={classes.prMd5}>
                  <Typography variant="h2" component="h2" className={classes.typographyH2Strong}>
                    Which are your top industries?
                  </Typography>
                  <Box
                    component="p"
                    fontWeight="300"
                    lineHeight="1.7"
                    fontSize="1rem"
                    marginBottom="1rem"
                    marginTop="0"
                    color={theme.palette.bsColors.MainBlue}
                  >
                    Check which Industries your stocks belong to. Are you too heavily invested in a specific industry?
                    <br/><br/>
                    See if your sector diversification is further diversified across sectors' industries
                    <br/><br/>
                    Find out your profit or loss by Industry
                  </Box>
                </div>
              </Grid> */}
            </Grid>
          </Container>
        </Box>
        </Box>
      </>
  )
}

export default AdditionalSuperpowersSection;