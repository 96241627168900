import React, { Fragment } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { Box, Button, CardContent, CardHeader, Grid, TextField } from '@material-ui/core';
import Card from "@material-ui/core/Card";
import componentStyles from "assets/theme/components/cards/charts/card-portfolio-ratios";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import FeatureInfoWithBox from 'components/Custom/FeatureInfoWithBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import styled from "styled-components";

const AutoStylingWrapper = styled.div`
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0px;
  }
  .MuiAutocomplete-clearIndicator {
    padding: 0px;
    visibility: hidden;
    margin-right: 0px;
  }
`;

const RADIUS = 0;

let data = {
  datasets: [{
    label: ['Base dataset'],
    title: "baseData",
    data: [],
    backgroundColor: "#FF9966"
  }]
};

const yAxisOptions = [
  { value: "enterpriseValueRevenue", label: "EV/Revenue TTM", suffix: ""},
  { value: "evToNtmRevenue", label: "EV/Revenue NTM", suffix: ""},
  { value: "priceToGrossProfit", label: "Price/Gross Profit", suffix: ""},
  { value: "enterpriseValueEbitda", label: "EV/EBITDA", suffix: ""},
  { value: "peRatio", label: "P/E Ratio", suffix: ""},
  { value: "forwardPE", label: "Forward P/E", suffix: ""},
  { value: "pegRatio", label: "PEG Ratio", suffix: ""},
  { value: "priceToSales", label: "P/S Ratio", suffix: ""},
  { value: "marketCap", label: "Market Cap", suffix: ""},
  { value: "revenueTTM", label: "Revenue", suffix: ""},
  { value: "risk", label: "Risk", suffix: ""},
];

const xAxisOptions = [
  { value: "quarterlyEarningsGrowth", label: "QoQ Earnings Growth", suffix: "%"},
  { value: "quartelyRevenueGrowth", label: "QoQ Revenue Growth", suffix: "%"},
  { value: "revenueEstimateGrowth", label: "Est. Revenue Growth", suffix: "%"},
];

const typeOptions = [
  { value: "value", label: "Value" },
  { value: "growth", label: "Growth" },
  { value: "all", label: "All" },
];

function roundedImage(ctx, x,y,width,height,radius){
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

const baseOptions = {
  hover: () => {},
  responsive: true,
  tooltips: {
    enabled: true,
    mode: 'nearest',
    intersect: false,
    custom: function(tooltip) {
      // TODO add a custom tooltip
    },
  },
  events: [], // TODO fix flicker on removal of this line
  animation: {
    duration: 0,
    animateScale: false,
    animateRotate: false,
    onComplete: function () {
      var chartInstance = this.chart,
          ctx = chartInstance.ctx;
          // Loop through each data in the datasets
          this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  var img = new Image();
                    img.onload=function(){
                      ctx.save();
                      roundedImage(ctx, bar._model.x - 10, bar._model.y - 10, 20, 20, 11);
                      ctx.clip();
                      ctx.drawImage(img, bar._model.x - 10, bar._model.y - 10, 20, 20);
                      ctx.restore();
                    }
                    img.src = data.logoData;
                });
          });
      }
  },
  type: 'bubble',
  layout: {
    padding: {
      top: 10,
      right: 20
    }
  },
  ticks: {
    autoSkip: false
  },
  title: {
    display: false,
    text: ' '
  },
  legend: {
    position: 'top',
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: "y Axis"
      }
    }],
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: "x Axis"
      }
    }]
  },
};

const useStyles = makeStyles(componentStyles);

export default function CardBubbles({fundamentals}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Analysis' });
  const theme = useTheme();
  const [updatedData, setUpdatedData] = React.useState();
  const [xSelection, setxSelection] = React.useState(xAxisOptions[0]);
  const [ySelection, setySelection] = React.useState(yAxisOptions[0]);
  const [typeSelection, setTypeSelection] = React.useState(typeOptions[0]);
  const [options, setOptions] = React.useState(baseOptions);

  function handleChange(value, type) {
    if (type === "type") {
      setTypeSelection(value);
    } else if (type === "x") {
      setxSelection(value);
    } else {
      setySelection(value);
    }
  }

  function moneyFormat(selection) {
    return function(value) {
      if (value > 1000000000) { 
        return value/1000000000 + "B";
      } else if (value > 1000000) { 
        return value/1000000 + "M";
      } else if (value > 1000) {
        return value/1000 + "K";
      } else if (selection.suffix === "%") { 
        return value*100 + selection.suffix;
      } else {
        return value;
      }
    }
  }

  React.useEffect( () => {
    let newData = [];
    // TODO change this to a filter, so we don't have to loop over every entry manually
    fundamentals.forEach(entry => {
        if (typeSelection.value === "value" && entry.peRatio && entry.peRatio < 60 && entry.priceToSales < 10) {
          newData.push({ticker: entry.ticker, x: entry[xSelection.value], y: entry[ySelection.value], 
            r: RADIUS, logoData: entry.logo}) 
        } else if (typeSelection.value === "growth" && entry.priceToSales && entry.priceToSales >= 10) { 
          newData.push({ticker: entry.ticker, x: entry[xSelection.value], y: entry[ySelection.value], 
            r: RADIUS, logoData: entry.logo}) 
        } else if (typeSelection.value === "all") {
          newData.push({ticker: entry.ticker, x: entry[xSelection.value], y: entry[ySelection.value], 
            r: RADIUS, logoData: entry.logo}) 
        }
    })
    options.scales.xAxes[0].scaleLabel.labelString = xSelection.label;
    options.scales.xAxes[0].ticks = { callback: moneyFormat(xSelection) };
    options.scales.yAxes[0].scaleLabel.labelString = ySelection.label;
    options.scales.yAxes[0].ticks = { callback: moneyFormat(ySelection) };
    data.datasets[0].data = newData;
    setUpdatedData({...data});
    setOptions(options);
    
  }, [xSelection, ySelection, typeSelection]);

  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
        style={{height:"485px"}}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]} >
                  {t("analytics")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span" >{"Quadrant Analysis"}</Box>
                  <FeatureInfoWithBox description={"Quadrant Analysis"}/>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <AutoStylingWrapper className={classes.boxButtonsHeader}>
                <Autocomplete
                    id="combo-box-demo"
                    options={typeOptions}
                    getOptionLabel={(option) => option.label}
                    closeIcon={null}
                    defaultValue={typeOptions[0]}
                    style={{marginRight: "1rem", minWidth: "120px"}}
                    onChange={(event, value) => handleChange(value, "type")}
                    popupIcon={
                      <Box
                        component={KeyboardArrowDown}
                        width="1.25rem!important"
                        height="1.25rem!important"
                      />
                    }
                    renderOption={(option) => (
                      <Typography style={{ fontSize: "1rem" }}>{option.label}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        inputProps={{
                          style: {
                            padding: "10px !important"
                          }
                        }}   
                        {...params}
                        label="Type 🏷"
                        variant="outlined"
                      />
                    )}
                  />
                  <Autocomplete
                    id="combo-box-demo"
                    options={xAxisOptions}
                    getOptionLabel={(option) => option.label}
                    closeIcon={null}
                    defaultValue={xAxisOptions[0]}
                    style={{marginRight: "1rem", minWidth: "120px"}}
                    onChange={(event, value) => handleChange(value, "x")}
                    popupIcon={
                      <Box
                        component={KeyboardArrowDown}
                        width="1.25rem!important"
                        height="1.25rem!important"
                      />
                    }
                    renderOption={(option) => (
                      <Typography style={{ fontSize: "1rem" }}>{option.label}</Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        inputProps={{
                          style: {
                            padding: "10px !important"
                          }
                        }}   
                        {...params}
                        label="X Axis ➡"
                        variant="outlined"
                      />
                    )}
                  />
                  <Autocomplete
                  id="combo-box-demo"
                  options={yAxisOptions}
                  getOptionLabel={(option) => option.label}
                  closeIcon={null}
                  defaultValue={yAxisOptions[0]}
                  style={{marginRight: "1rem", minWidth: "120px"}}
                  onChange={(event, value) => handleChange(value, "y")}
                  popupIcon={
                    <Box
                      component={KeyboardArrowDown}
                      width="1.25rem!important"
                      height="1.25rem!important"
                    />
                  }
                  renderOption={(option) => (
                    <Typography style={{ fontSize: "1rem", marginTop:"2px"}}>{option.label}</Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Y Axis ⬆"
                      variant="outlined"
                    />
                  )}
                />
                </AutoStylingWrapper>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
              { updatedData ?
                <Bubble options={options} data={updatedData} onHover={() => console.log("hovering")}/> : <></> }              
        </CardContent>
      </Card>
    </>
  );
}
