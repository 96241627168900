import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Helmet from "react-helmet";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons components
import MoreHoriz from "@material-ui/icons/MoreHoriz";
// core components
import componentStyles from "assets/theme/views/admin/alternative-dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import buttonStyles from "assets/theme/components/button.js";

import AlternativeHeader from "components/Headers/AlternativeHeader";
import CardRatios from "components/Cards/Charts/CardRatios";
import CardIndustryBreakdown from "components/Cards/Sortable/CardIndustryBreakdown";
import CardSectorVsMarket from "components/Cards/Charts/CardSectorVsMarket";
import CardRiskContribution from "components/Cards/Charts/CardRiskContribution";
import CardDividendFocus from "components/Cards/Tables/CardDividendFocus";
import CardRiskLine from "components/Cards/Dashboard/CardRiskLine";
import CardBubbles from "components/Cards/Charts/CardBubbles";
import { useEtoroUser } from "contexts/EtoroUserContext";

import { useAuth } from "contexts/AuthContext";
import PortfolioInsightsSection from "views/auth/Features/PortfolioInsightsSection";
import FeatAssetRisk from "views/auth/Features/FeatAssetRisk";
import FeatIndustryBreakdown from "views/auth/Features/FeatIndustryBreakdown";
import FeatDividendFocus from "views/auth/Features/FeatDividendFocus";
import FeatPortfolioRatios from "views/auth/Features/FeatPortfolioRatios";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);
const useStylesButton = makeStyles(buttonStyles);


function Analysis() {
  const classes = { ...useStyles(), ...useStylesCardDeck(), ...useStylesButton() };
  const { currentEtoroUserData } = useEtoroUser();
  const { currentUser } = useAuth();
  const { t, i18n } = useTranslation('translation');

  return (
    <>
        <AlternativeHeader section={t("Analysis.header")}/>
        <Helmet>
          <title>Analysis | Bullsheet</title>
        </Helmet>
        <Container
          maxWidth={false}
          component={Box}
          marginTop="-4.5rem"
          classes={{ root: classes.containerRoot }}
        >
        {currentUser.subscription !== "freeTier" ?
        <>  
          { ["jdiogoc@gmail.com", "filsommer@gmail.com"].includes(currentUser.email) ? <>
          <Grid container>
            <Grid item xs={12} xl={5}>
              <CardDividendFocus portfolioDividends={currentEtoroUserData?.portfolioDividends}/>
            </Grid>
            <Grid item xs={12} xl={7}>
              <CardBubbles fundamentals={currentEtoroUserData?.assetFundamentals} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} xl={4}>
              <CardRiskContribution/>
            </Grid>
            <Grid item xs={12} xl={4} height={40}>
                <CardRatios currentRatiosVsMarketBreakdown={currentEtoroUserData?.currentRatiosVsMarketBreakdown}/>
            </Grid>
            <Grid item xs={12} xl={4}>
              <CardSectorVsMarket currentSectorVsMarketBreakdown={currentEtoroUserData?.currentSectorVsMarketBreakdown}/>
            </Grid>
          </Grid> </> : <>
          <Grid container>
            <Grid item xs={12} xl={8}>
              <CardDividendFocus portfolioDividends={currentEtoroUserData?.portfolioDividends}/>
            </Grid>
            <Grid item xs={12} xl={4}>
              <CardSectorVsMarket currentSectorVsMarketBreakdown={currentEtoroUserData?.currentSectorVsMarketBreakdown}/>
            </Grid>
          </Grid>
          <Grid container>
            
            <Grid item xs={12} xl={4}>
              <CardRiskContribution/>
            </Grid>
            
            <Grid item xs={12} xl={8}>
                <CardRatios currentRatiosVsMarketBreakdown={currentEtoroUserData?.currentRatiosVsMarketBreakdown}/>
            </Grid> 
          </Grid> </> }
          <Grid container>
            <Grid item xs={12} xl={12}>
              <CardRiskLine instrumentRisksArray={currentEtoroUserData?.instrumentRisksArray} riskClusters={currentEtoroUserData?.riskClusters}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} xl={12}>
              <CardIndustryBreakdown industries={currentEtoroUserData?.industries}/>
            </Grid>
          </Grid>
          {/* <Box className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
            <CardStockMatrix />
            <CardStockMatrix />
            <CardStockMatrix />
          </Box> */}
          </>
          :
          <Box component="section" paddingTop="1.5rem" >
             <Box
              textAlign="center"
              paddingBottom="4.5rem" 
            >
              <Typography variant="h1" component="h1" className={classes.typographyH1Center} 
                style={{backgroundImage:"linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(126, 38%, 48%, 1) 75%)", 
                      "WebkitBackgroundClip": "text",
                      "WebkitTextFillColor": "transparent",
                      "MozBackgroundClip": "text",
                      "MozTextFillColor": "transparent",}} 
              >
                <b>{t("PremiumContent.premiumContentTitle")}</b>
              </Typography>
              <Box marginTop="1rem" textAlign="center">
              {t("PremiumContent.premiumContentDescription")}
              </Box>
              <Box marginTop="2rem" textAlign="center">
                    <Button
                      component={Link}
                      variant="contained"
                      to="/auth/pricing"
                      classes={{ root: classes.buttonCTANormal }}
                    >
                      {t("PremiumContent.premiumCtA")}
                    </Button>
                </Box>
            </Box>
            <Container maxWidth="xl">
              <Grid container alignItems="center">
                <FeatAssetRisk/>
                <FeatIndustryBreakdown/>
              </Grid>
            </Container>
            <FeatDividendFocus/>
            <FeatPortfolioRatios/>
            <Box marginTop="2rem" textAlign="center">
                      <Button
                        component={Link}
                        variant="contained"
                        to="/auth/pricing"
                        classes={{ root: classes.buttonCTANormal }}
                      >
                        {t("PremiumContent.premiumCtA")}
                      </Button>
                  </Box>
          </Box> }
        </Container> 
    </> 
  );
}

export default Analysis;
