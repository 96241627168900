import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useLocation, Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components
import Build from "@material-ui/icons/Build";
import Code from "@material-ui/icons/Code";
import Done from "@material-ui/icons/Done";
import Public from "@material-ui/icons/Public";
import EmojiEmotions from "@material-ui/icons/EmojiEmotions";
import Settings from "@material-ui/icons/Settings";
import Badge from "@material-ui/core/Badge";
import TrendingUp from "@material-ui/icons/TrendingUp";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Update from "@material-ui/icons/Update";

import CardInfoBadges from "components/Cards/Index/CardInfoBadges.js";

import componentStyles from "assets/theme/components/headers/features-header.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import { useTranslation } from 'react-i18next';
import themeColors from 'assets/theme/colors';

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const FeatExportPortfolio = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Export' });
  const location = useLocation();
  const ungated = location.pathname.includes("auth/");
  return (
      <>
              <Box paddingTop="3rem" paddingBottom="4.5rem" component="section">
                <Container maxWidth="xl">
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Box
                        component="img"
                        alt="..."
                        maxWidth="100%"
                        height="auto"
                        className={classes.imgBox}
                        src={require("assets/img/theme/export_image.png").default}
                      ></Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.prMd5}>
                        <Typography variant="h1" component="h1" className={classes.typographyH2Strong} style={{color: (ungated ? themeColors.bsColors.MainBlue : theme.palette.bsColors.MainBlue)}}>
                          {t("premiumTitle")}
                        </Typography>
                        <Box
                          component="p"
                          fontWeight="300"
                          lineHeight="1.7"
                          fontSize="1rem"
                          marginBottom="1rem"
                          marginTop="0"
                          color={(ungated ? themeColors.bsColors.MainBlue : theme.palette.bsColors.MainBlue)}
                          >
                          {t("premiumDescription1")} <br/><br/>
                          {t("premiumDescription2")}
                          <br/><br/>
                          {t("premiumDescription3")}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
      </>
  )
}

export default FeatExportPortfolio;