import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import PerfectScrollbar from "react-perfect-scrollbar";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";

// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/alternative/card-daily-performance.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Ticker", "Price", "Change",];

export default function CardBiggestWinners({marketInfo}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Markets' });

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("Market")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">🚀🚀🚀</Box>
                  <FeatureInfoWithBox description={t("cardBiggestWinnersTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
              
              </Grid>
            </Grid>
              }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <PerfectScrollbar style={{}}> 
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root:
                        classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { marketInfo?.latestMarketMovers?.latestWinners ? marketInfo.latestMarketMovers.latestWinners.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.avatarRoot }}
                        marginRight="0.875rem"
                        alt="..."
                        src={prop.logo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box fontSize={theme.typography.h5.fontSize} component="span">
                          <a style={{color:theme.palette.primary.main}} href={"https://www.etoro.com/markets/" + prop.ticker} target="_blank"> {prop.ticker} </a>
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {prop.price}
                  </TableCell>
                  {prop.priceChangePercentage.toString().substring(0,1) == "-" ? 
                  <TableCell classes={{ root: classes.tableCellRootNegative }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                      {prop.priceChangePercentage}%
                    </Box>
                  </TableCell>
                  :
                  <TableCell classes={{ root: classes.tableCellRootPositive }}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem">
                      {prop.priceChangePercentage}%
                    </Box>
                  </TableCell>
                  }
                </TableRow>
              )) : <></> }
            </TableBody>
          </Box>
        </TableContainer>
        </PerfectScrollbar>
      </Card>
    </>
  );
}
