import React, { useRef, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Container from "@material-ui/core/Container";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import componentStyles from "assets/theme/views/auth/login.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useAuth, gmailProvider, facebookProvider, twitterProvider } from "../../contexts/AuthContext"
import { Alert } from "react-bootstrap"
import { Link } from "react-router-dom"

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

function ForgotPassword() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = useTheme();
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [resetButtonEnabled, setResetButtonEnabled] = useState(false);

  function handleInputChange(e) {
    e.stopPropagation();
    
    setError("");

    if (emailRef && emailRef.current.value) {
        setResetButtonEnabled(true);
    } else {
        setResetButtonEnabled(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for further instructions");
    } catch (e) {
      setError("Failed to reset password. " + e);
    }

    setLoading(false)
  }

  return (
    <>
      <AuthHeader
        title="Forgot Password?"
        description="Worry not, you'll get back in soon."
      />
      {/* Page content */}
      <Container
        component={Box}
        maxWidth="xl"
        marginTop="-8rem"
        paddingBottom="3rem"
        position="relative"
        zIndex="101"
      >
        <Box component={Grid} container justifyContent="center">
          <Grid item xs={12} lg={6} md={8}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardContent classes={{ root: classes.cardContent }}>
                <form onSubmit={handleSubmit}>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="center"
                  marginBottom="1rem"
                  marginTop=".5rem"
                  fontSize="1rem"
                >
                  <Box fontSize="80%" fontWeight="400" component="small">
                    Forgot your password? Enter your email address to get further instructions.
                  </Box>
                </Box>
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                <FormControl
                  variant="filled"
                  component={Box}
                  width="100%"
                  marginBottom="1rem!important"
                >
                  <FilledInput inputRef={emailRef} onChange={handleInputChange}
                    autoComplete="off"
                    type="email"
                    placeholder="Email"
                    startAdornment={
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Box
                  textAlign="center"
                  marginTop="1.5rem"
                  marginBottom="1.5rem"
                >
                  <Button type="submit" disabled={!resetButtonEnabled || loading} 
                    variant="contained"
                    classes={{ root: classes.buttonContainedInfo }}
                  >
                    Reset Password
                  </Button>
                </Box>
                </form>
              </CardContent>
            </Card>
            <Grid container component={Box} marginTop="-0.5rem">
              <Grid item xs={6} component={Box} textAlign="left">
              </Grid>
              <Grid item xs={6} component={Box} textAlign="right">
                <div className={classes.footerLinks}
                >
                  Need an account? <Link to="/auth/register">Create here</Link>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default ForgotPassword;
