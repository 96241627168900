export const allArticles = [
    {
        type: "eToro",
        title: "Dividends on eToro",
        description: "How to check your eToro dividends and how to know important dividend dates for your stocks",
        date: "2021-10-30",
        image: "https://images.pexels.com/photos/3943716/pexels-photo-3943716.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        source: "Bullsheet Team",
        url: "dividends-etoro",
        articleText: `The stable and growing income stream from a balanced eToro dividend portfolio can be a great secondary income source. But when does eToro pay dividends, and how can you see the important dividend dates for stocks and ETFs in your portfolio?

        First of all, the basics of dividend investing. Some companies distribute dividends to shareholders periodically throughout the year. While dividends are typically distributed on a quarterly basis, some companies pay dividends every month, semester, or year. There are two important dates when it comes to dividend investing on etoro. The Ex-Dividend date is the cutoff date of when you have to own the stock to receive dividends. You should hold the stock on eToro on the day before the ex-dividend date to make sure you are eligible to receive that dividend. The other important date is the dividend payment date, which is the date when the company normally distributes said dividend.
        
        It's important to note that you will only receive dividend payments if you hold BUY positions for those stocks or ETFs. What if you hold SELL positions? In that case, you will have to pay the company's dividend from your available balance. When does etoro pay dividends? For CFD positions, you don't even need to wait for the dividend payment date - you will receive the dividend payment on the ex-dividend date. If your positions are secured with the real asset (which is normally the case), you will get your dividend payment on the dividend payment date. This is valid for both stocks and ETF dividends. Copy dividends are what you get when the investor you are copying withdraws some of his profits.
        
        If by now you're asking "How can I track dividend dates for my eToro stocks and ETFs?", we got you covered. Our Bullsheet Calendar tracks dividend payment, ex-dividend and even earnings dates for all your eToro stocks! This feature is free. You only need to create your account and write your eToro username. Completely safe, no connection to eToro required.`
    },
    {
        type: "eToro",
        title: "ADA and TRX Staking in eToro",
        description: "How does staking in eToro work? How do you opt-in?",
        date: "2021-10-30",
        image: "https://images.pexels.com/photos/2228570/pexels-photo-2228570.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        source: "Bullsheet Team",
        url: "staking-ada-etoro",
        articleText: `With TRX and ADA staking on eToro, you can earn passive income from some of your crypto investments. These eToro staking rewards are distributed each month and vary approximately between 4 to 5% yield per year. This makes your crypto grow every month by earning staking rewards for your cryptoassets!

        eToro staking rewards are paid around the 10th day of each month for both ADA and TRX. You will get the staking rewards as new positions for the coin you hold. You are opted in by default for ADA and TRX staking rewards on eToro. If you want to opt out for tax purposes, you can contact eToro's customer support and ask not to receive staking rewards.
        
        The staking you get is calculated by taking your daily average position in a month, multiplying it by ADA's or TRX's monthly yield, and finally multiplying that number by eToro's club percentage fee. At the time of writing, Bronze club members (and all US users) get 75% of the rewards, Silver to Platinum get 85%, while Platinum+ and Diamond get 90%.
        
        Is there a minimum amount of ADA or TRX you need to have to be eligible for staking rewards? Yes. Since eToro's minimum staking rewards payment is $1, you need to have a certain amount that generates at least $1 when multiplied by the monthly yield and eToro club reward percentage.
        
        Example: A Gold member (85%), for a monthly yield of 0.4%, would need an average daily position of $1/0.004 = $250 in ADA or TRX to be eligible for staking rewards. Bear in mind that monthly yields may change, and if the monthly yield is only 0.3%, you would need $334 to earn staking rewards.`
    },
    {
        type: "eToro",
        title: "Earnings & Dividends Calendar for eToro",
        description: "How to use Bullsheet to track your eToro earnings and dividends on a calendar",
        date: "2021-10-30",
        image: "https://images.pexels.com/photos/5386754/pexels-photo-5386754.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        source: "Bullsheet Team",
        url: "earnings-dividends-calendar-etoro",
        articleText: `When you have many stocks and ETFs on your eToro portfolio, it can become overwhelming to track all of your stocks' important dates. These include earnings dates, as well as ex-dividend and dividend payment dates for your eToro stocks.

        Bullsheet allows you to search your eToro username and get all the important dates for your stocks and ETFs for free! We all know how important earnings dates are. With our etoro earnings calendar you will know when to expect earnings for your stocks and adjust your strategy accordingly.
        
        Although eToro pays you dividends on time, it's hard to keep track of eToro dividends on the broker's platform. With Bullsheet's free calendar, you'll know your stocks' important dividend dates, namely ex-dividend and dividend payment dates. eToro dividends made easy! Make sure you have the stock on eToro on the day before the ex-dividend date to make sure you are eligible for eToro dividend payments. If you hold a CFD dividend paying position, you will get the payment on the ex-dividend date.
        
        Making money on etoro is everyone's objective. While our etoro economic calendar doesn't directly make you money, it can help you keep track of your investments' important dates.
        
        Our Earnings and Dividends Calendar for eToro is only one of many cool Bullsheet features we have for you. Sign up for free, search your eToro username (no connecting required) and you will get access to some nice features we have been building for you. We hope you enjoy our Bullsheet :)`

    },
    {
        type: "eToro",
        title: "How to Export eToro Portfolio to csv (and other formats)",
        description: "While eToro does not have a native tool to do it, Bullsheet allows you to export your eToro portfolio to csv, xls and even Tradingview",
        date: "2021-10-30",
        image: "https://images.pexels.com/photos/5668765/pexels-photo-5668765.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        source: "Bullsheet Team",
        url: "export-etoro-portfolio-csv",
        articleText: `Exporting your eToro portfolio allows you to examine your current portfolio with your own tools. It can also be helpful to store a snapshot of your portfolio from a moment in time so that you can see how much Bitcoin you would have by now.

        While eToro doesn't offer an export option for your portfolio, you can use Bullsheet to export your eToro portfolio to csv. You can also export your eToro portfolio to xls, or to Tradingview, so that you can follow your stocks there.
        
        Exporting your eToro portfolio is one of the many Bullsheet features we have to offer you. While exports are a premium feature, start by signing up for free and exploring our free features. No need to connect your account to eToro, you just need to search your username and, as long as it is a public username, we can get your public eToro information.`
    },
]

export default {
    allArticles,
}