import axios from 'axios';
import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStylesButtons from "assets/theme/components/button.js";
import componentStyles from "assets/theme/components/cards/alternative/card-daily-performance.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useService } from "contexts/ServiceContext.js";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from 'contexts/AuthContext';
import CircleLoader from "react-spinners/CircleLoader";
import { CardContent } from '@material-ui/core';

const useStylesButtons = makeStyles(componentStylesButtons);
const useStyles = makeStyles(componentStyles);

const tableHead = ["ticker", "price", "change"];

const IconWrapper = styled.div`
  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  };
`

// quick find: Daily Performance
export default function CardDailyPerformance() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  const { getLatestAssetPrices, getParentPerformances } = useService();
  const [updates, setUpdates] = React.useState(0);
  const [updatedDailyPrices, setUpdatedDailyPrices] = React.useState();
  const [sort, setSort] = React.useState({'attribute': 'priceChangePercentage', 'direction': 'desc'});
  const history = useHistory();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardDailyPerformance' });
  const { currentUser } = useAuth();

  function handleClick(e) {
    e.preventDefault();
    history.push("/app/after-hours");
  }

  function handleSort(attribute) {
    setSort({'attribute': attribute, 'direction': sort.direction === 'asc' ? 'desc' : 'asc'});
  }

  async function fetchData() {
    const responses = await axios.all([getLatestAssetPrices(currentEtoroUserData.userInstruments.instruments), getParentPerformances(currentEtoroUserData.userInfo.userName)]);
    sortRows(responses[1].data.dailyPerformance.concat(responses[0].data.latestAssetPrices));
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdates(updates => updates + 1);
    }, 30000);
    if(!document.hidden && currentEtoroUserData?.latestAssetPrices && currentEtoroUserData.latestAssetPrices.length > 0 && currentEtoroUserData?.userInfo?.userName) {
      fetchData();
    }
    return () => clearInterval(interval);
  }, [updates]);
  

  React.useEffect(() => { 
    if (!currentEtoroUserData?.latestAssetPrices) {
      fetchData();
    }
    return () => {
      setUpdatedDailyPrices();
    };
  }, [currentEtoroUserData]);


  React.useEffect(() => {
    if (updatedDailyPrices && sort) {
      sortRows(updatedDailyPrices);
    }
  }, [sort]);

  function sortRows(updatedDailyPrices) {
    if(!updatedDailyPrices) {
      return;
    }
    if (sort.attribute === 'ticker') {
      const newArray = sortStrings(updatedDailyPrices, sort.direction);
      // creates a new array so that component is updated via useEffect. If same array is simply changed, re-render isn't
      setUpdatedDailyPrices([...newArray]);
    } else if  (sort.attribute === 'price') {
      const newArray = updatedDailyPrices?.sort(function(a, b){
        return sort.direction === 'asc' ? a.price - b.price : b.price - a.price;
      })
      setUpdatedDailyPrices([...newArray]);
    } else {
      const newArray = updatedDailyPrices?.sort(function(a, b){
        return sort.direction === 'asc' ? a.priceChangePercentage - b.priceChangePercentage : b.priceChangePercentage - a.priceChangePercentage;
      })
      setUpdatedDailyPrices([...newArray]);
    }
  }

  function sortStrings(array, direction) {
    return array.sort(function(a, b){
      const tickerA=a.ticker.toLowerCase(), tickerB=b.ticker.toLowerCase();
      if (tickerA < tickerB) //sort string ascending
          return direction === 'asc' ? -1 : 1;
      if (tickerA > tickerB)
        return direction === 'asc' ? 1 : -1;
      return 0 //default return value (no sorting)
    })
  }
  
function getParentPhoto(username) {
  const parent = currentEtoroUserData.userInstruments?.copyParentUsernames ? currentEtoroUserData.userInstruments.copyParentUsernames[username] : undefined;
      if (parent) {
        return parent.media[parent.media.length - 1].uri
  }
}

function eventIsTodayOrYesterday(event) {
  const d = new Date();
  if (event.start == d.toISOString().split('T')[0]) {
    return true;
  } else {
    d.setDate(d.getDate()-1);
    return event.start == d.toISOString().split('T')[0];
  }
}

function hasEvent(instrumentId) {
  const event = currentEtoroUserData?.allCalendarEvents?.find(event => event.instrumentId === instrumentId && eventIsTodayOrYesterday(event));
  return event ? <FeatureInfoWithBox color="green"
                      description={event.description ? `${event.start}: ${event.description}` : ""}/> : "";
}

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("title")}</Box>
                  <FeatureInfoWithBox description={t("dailyPerformanceTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box classes={{root: classes.boxButtonsHeader}}>
                  <Button variant="contained" size="small" classes={{ root: classes.buttonCTANormal}} onClick={handleClick}>
                    {t('seeExtHours')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        { updatedDailyPrices && updatedDailyPrices.length > 0 ? 
         <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    {...{
                      "data-sort": "dunnoWhyWeNeedToSetThisShitButItsImportant"
                    }}
                    onClick={() => handleSort(prop)}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot + " sort " + classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              { updatedDailyPrices ? updatedDailyPrices.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="head"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.avatarRoot }}
                        marginRight="0.875rem"
                        alt="..."
                        src={prop.isParent ? getParentPhoto(prop.ticker) : prop.logo}
                      />
                      <Box>
                        <Box display="flex" alignItems="flex-start" fontSize={theme.typography.h5.fontSize} component="span" className="ticker">
                          <div>{prop.ticker}</div>
                          
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }} className="price">
                  <Box display="flex" alignItems="flex-start" fontSize={theme.typography.h5.fontSize} component="span" className="ticker">

                    {prop.isParent ? "Copy" : prop.price }
                    <IconWrapper>
                            {["filsommer@gmail.com", "bernardofrcarlos@gmail.com"].includes(currentUser.email) && !prop.isParent ?
                              hasEvent(prop.instrumentId)
                            : ""}
                          </IconWrapper>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes["tableCellRoot" + (prop.priceChangePercentage > 0 ? "Positive" : "Negative")]}}>
                    <Box paddingTop=".35rem" paddingBottom=".35rem" className="change">
                      <Box
                        marginRight="-0.5rem"
                      />
                      {"• "}
                      {prop.priceChangePercentage}%
                    </Box>
                  </TableCell>
                </TableRow>
              )) : <></>
            }
            </TableBody>
          </Box>
        </TableContainer>
        : 
        <Box position="relative" padding="20px" marginBottom={"20px"} marginLeft={"5px"}>
          <CircleLoader color={theme.palette.bsColors.LogoLightBlue} loading={!updatedDailyPrices} size={20}/> 
        </Box> 
      }
      </Card>
    </>
  );
}
