import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import strings from "../../../strings/en.js";
// @material-ui/icons components

// core components
import { useEtoroUser } from "contexts/EtoroUserContext";
import componentStyles from "assets/theme/components/cards/cards/card-staking.js";
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

function CardStaking({portfolioDividends}) {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const history = useHistory();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CryptoDashboard' });

  //Send Staking assets array from backend?
  const stakingCoins = {
      assets:[],
      portfolioYield: 0.0,
  }
  for (let i=0; i<portfolioDividends.assets.length; i++) {
    if (["ADA","TRX"].includes(portfolioDividends.assets[i].ticker)) {
      stakingCoins.assets.push({"logo":portfolioDividends.assets[i].logo,"ticker":portfolioDividends.assets[i].ticker, "yield":portfolioDividends.assets[i].yield})
      stakingCoins.portfolioYield += portfolioDividends.assets[i].yield * portfolioDividends.assets[i].value 
    }
  }
  return (
    <>
      <Card className={classes.cardRoot}>
      <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Crypto Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t('estimatedYield')}</Box>
                  <FeatureInfoWithBox description={t("cryptoStakingTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        { stakingCoins?.assets && stakingCoins.assets.length > 0 ?
        <CardContent>
          <Box
            fontSize={theme.typography.h1.fontSize}
            fontWeight="600"
            textAlign="center!important"
            classes= {{ root: classes.dividendPercentageBox}}
          >
            { stakingCoins.portfolioYield?.toFixed(2)}%
          </Box>
          <Box 
            component={Typography} 
            variant="h5" 
            textAlign="center!important"
            classes= {{ root: classes.dividendValueBox}}
            >
          </Box>
          <Box
            component="p"
            marginBottom="0.5rem"
            fontWeight="700"
            lineHeight="1.7"
            fontSize={theme.typography.h5.fontSize}
            marginTop="2rem"
          >
            {t('estimatedYieldApprox')}
          </Box>
          <Grid container component={Box} alignItems="center">
                    <Grid item xs={2}>
                    </Grid>
                    <Grid
                      item xs={2}
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      <Box component="small" fontSize={theme.typography.h5.fontSize} fontWeight="600">
                        Crypto
                      </Box>
                      
                    </Grid>
                    <Grid
                      item xs={2}
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      <Box component="small" fontSize={theme.typography.h5.fontSize} fontWeight="600">
                        Yield
                      </Box>
                      
                    </Grid>
                    <Grid
                      item xs={2}
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      <Box component="small" fontSize={theme.typography.h5.fontSize} fontWeight="600">
                        Min. $
                      </Box>
                      
                    </Grid>
                  </Grid>
          <List disablePadding>
            {stakingCoins ?  stakingCoins.assets.slice(0, 2).map((prop, key) => {
              return (
                <ListItem key={key} className={classes.listItemRoot}>
                  <Grid container component={Box} alignItems="center">
                    <Grid item xs={2}>
                      <img alt="..." className={classes.img} src={prop.logo} />
                    </Grid>
                    <Grid
                      item xs={6}
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                    
                      <Typography
                        component="h5"
                        variant="h5"
                        className={classes.mb0}
                      >
                        {prop.ticker}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      
                      <Typography
                        component="h5"
                        variant="h5"
                        className={classes.mb0}
                      >
                        {(prop.yield * 100).toFixed(2)}%
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      
                      <Typography
                        component="h5"
                        variant="h5"
                        className={classes.mb0}
                      >
                        {prop.ticker=="ADA" ? "~$275" : "~$225"}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }) : <></> }
          </List>
          <Box component="small" fontSize={theme.typography.h5.fontSize} fontWeight="600">
            {t('noteMinimumAmount')}
          </Box>
        </CardContent> : 
        <CardContent>
          <Typography
              component="h5"
              variant="h5"
              style={{marginTop: "80px", marginLeft: "50px"}}
            >
              {t('noteNoStakingCoins')}
              
          </Typography>
          <img
          alt="..."
          src={require("assets/img/theme/weak_bull.png").default}
          style={{width:"200px", marginTop: "10px", marginLeft: "50px"}}
          /> 
        </CardContent> }
      </Card>
    </>
  );
}

export default CardStaking;
