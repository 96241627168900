import React, { useEffect } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Doughnut } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import styled, { ThemeProvider } from "styled-components";
import ChartDetailModal from "./ChartDetailModal";

import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/icons components

// core components
import componentStyles from "assets/theme/components/cards/charts/card-portfolio-breakdown.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { useTranslation } from 'react-i18next';
import { useDarkMode } from "contexts/DarkModeContext";
import { chartOptions, parseOptions, chartPortfolioBreakdown, chartPortfolioBreakdownDark } from "variables/charts.js";

const useStylesButtons = makeStyles(componentStylesButtons);
const useStyles = makeStyles(componentStyles);

const ChartWrapper = styled.div`
  height: 350px;
  position: relative;
  margin-top: 0px;
  #myChart {
    position: relative;
    z-index: 10;
  }
  #chartjs-tooltip {
    left: 50%;
    background: transparent;
    top: 0%;
    font-family:  Open Sans, sans-serif;
    font-style: normal;
    line-height: 2rem;
    right: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 0;
    height: 100%;
    padding: 0;
    opacity: 1 !important;
    align-items: center;
    color: ${props => props.color};
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    text-align: center;
    vertical-align: middle;
  }
`;

function CardPortfolioBreakdown() {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  const [selectedButton, setSelectedButton] = React.useState("byValue");
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardPortfolioBreakdown' });
  const [modalData, setModalData] = React.useState({isOpen: false});
  const { activeTheme } = useDarkMode();
  const [options, setOptions] = React.useState(chartPortfolioBreakdown.options);


  // { chartOptions, parseOptions, chartPortfolioBreakdown }
  const backgroundColor = [theme.palette.bsColors.Pie0, theme.palette.bsColors.Pie1, theme.palette.bsColors.Pie2, theme.palette.bsColors.Pie3,
    theme.palette.bsColors.Pie4, theme.palette.bsColors.Pie5, theme.palette.bsColors.Pie6, theme.palette.bsColors.Pie7, 
    theme.palette.bsColors.Pie8, theme.palette.bsColors.Pie9, theme.palette.bsColors.Pie10, theme.palette.bsColors.Pie11, theme.palette.bsColors.Yellow, "#70F", "#000", "#70F", "#000", "#70F", "#000", "#70F", "#000",
  ];

  const getTicker = (instrumentId) => {
    let companyLogo = ""; 
    let isParent = false;
    let asset = currentEtoroUserData?.userInstruments ? currentEtoroUserData.userInstruments.instruments.find(entry => entry.instrumentId === instrumentId) : undefined;
    if (!asset && instrumentId in currentEtoroUserData?.userInstruments?.copyParentUsernames) {
      asset = currentEtoroUserData.userInstruments.copyParentUsernames[instrumentId];
      isParent = true;
    }
    if (asset) {
      companyLogo = asset.media[asset.media.findIndex(x => x.width == 150)].uri;
      return {tickerName: isParent ? asset.userName : asset.ticker, logo: companyLogo};
    }
    return undefined;
  }

  const handleOpenModal = (e) => {
    if(!e[0]) {
      return;
    }
    const isValuePressed = selectedButton === "byValue";
    const otherAssetsIndex = currentEtoroUserData.currentPortfolio.positionsSortedByValue.findIndex(entry => entry.instrumentId === "Other")
    if (otherAssetsIndex > -1 && otherAssetsIndex === e[0]._index) {
      const breakdown = isValuePressed ? 
      currentEtoroUserData.currentPortfolio.positionsSortedByValue[otherAssetsIndex] : 
        currentEtoroUserData.currentPortfolio.positionsSortedByInvested[otherAssetsIndex];
      const tickerData = [];
      breakdown.otherTickers.forEach(ot => {
        const ticker = getTicker(ot.instrumentId);
        tickerData.push({ tickerName: ticker?.tickerName, logo: ticker?.logo, weight: ot.weight})
      })
      setModalData( { label: breakdown.instrumentId, weight: breakdown.investmentPctRealized, data: tickerData, isOpen: true })
    }
  }

  function handleClick(byValue) {
    setSelectedButton(byValue ? "byValue" : "byInvested");
  }

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }, [t])

  useEffect(() => {
    if (currentEtoroUserData?.portfolioBreakdown) {
      currentEtoroUserData.portfolioBreakdown[0].datasets[0].backgroundColor = backgroundColor;
      currentEtoroUserData.portfolioBreakdown[1].datasets[0].backgroundColor = backgroundColor;
      setOptions(activeTheme === "light" ? chartPortfolioBreakdown.options : chartPortfolioBreakdownDark.options);
    }
  }, [activeTheme])

  return (
    <>
      <ChartDetailModal
        setModalData = {setModalData}
        modalData = {modalData}
        tableHead = {["ticker", "weight"]}
        dataType = "MAP_PERFORMANCE"
      />
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("assetBreakdown")}</Box>
                  <FeatureInfoWithBox description={t("assetBreakdownTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box classes={{root: classes.boxButtonsHeader}}>
                  <Button onClick={() => handleClick(true)}
                    variant="contained"
                    size="small"
                    classes={selectedButton === "byValue" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRootDefault,
                    }}
                  >
                    {t("value")}
                  </Button>
                  <Button onClick={() => handleClick(false)}
                    variant="contained"
                    size="small"
                    style={{marginLeft:"3px"}}
                    classes={selectedButton === "byInvested" ? {
                      root: classes.buttonRootSelected,
                    } : {
                      root: classes.buttonRootDefault,
                    }}
                  >
                    {t("invested")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          <Box position="relative" height="350px">
            { currentEtoroUserData?.portfolioBreakdown && selectedButton === "byValue" ? 
            <ThemeProvider theme={theme}>
              <ChartWrapper color={theme.palette.bsColors.MainBlue}>
                <Doughnut
                  //id="myChart"
                  data={currentEtoroUserData.portfolioBreakdown[0]}
                  options={options}
                  getElementAtEvent={(e) => {handleOpenModal(e)}}
                />
                <RetardedChartJsTooltipUpdate i18n={i18n} length={currentEtoroUserData?.currentPortfolio?.summary?.positions?.length}/>
              </ChartWrapper>
            </ThemeProvider>
               : <></> }
            { currentEtoroUserData?.portfolioBreakdown && selectedButton === "byInvested" ? 
            <ThemeProvider theme={theme}>
              <ChartWrapper color={theme.palette.bsColors.MainBlue}>
                <Doughnut
                data={currentEtoroUserData?.portfolioBreakdown[1]}
                options={options}
                getElementAtEvent={(e) => handleOpenModal(e)}
              />
              <RetardedChartJsTooltipUpdate i18n={i18n} length={currentEtoroUserData?.currentPortfolio?.summary?.positions?.length}/>
            </ChartWrapper>
          </ThemeProvider>
           : <></> }
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

// TODO: please god someone with more than two frontend developer ventromedial prefrontal cortex braincells fix this ungodly way of translating the ChartJs innerHtml tooltip
function RetardedChartJsTooltipUpdate({i18n, length}) {
  return (
    <>
    { i18n?.language === "en" ? 
                <div id="chartjs-tooltip">{length} <br/>{"assets"} </div>
              : null }
    { i18n?.language === "de" ? 
              <div id="chartjs-tooltip">{length} <br/>{"Anlagen"} </div>
            : null }
    { i18n?.language === "es" ? 
              <div id="chartjs-tooltip">{length} <br/>{"activos"} </div>
            : null }
    { i18n?.language === "it" ? 
              <div id="chartjs-tooltip">{length} <br/>{"attivi"} </div>
            : null }
    </>
  )
}

export default CardPortfolioBreakdown;
