/* eslint-disable react/jsx-no-target-blank*/
import React from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// @material-ui/icons components
// import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import FeaturesSection from "./auth/Homepage/FeaturesSection";
import BetterInvestmentDecisionsSection from "./auth/Homepage/BetterInvestmentDecisionsSection";
import ReadyBottomCTA from "./auth/Homepage/ReadyBottomCTA";
import AndMuchMoreSection from "./auth/Homepage/AndMuchMoreSection";
import { Helmet } from "react-helmet";
import getTheme from "assets/theme/theme";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);


export default function Index() {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = getTheme("light");
  const location = useLocation();
  React.useEffect(() => {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
  }, [location]);
  return (
    <>
      <Helmet>
        <title>Bullsheet - Automatic Portfolio Manager for eToro</title>
        <meta name="description" content="Bullsheet helps you manage your eToro portfolio more effectively. Track your stocks after hours, analyse your risk, sector diversification and who you copy" />
      </Helmet>
      <IndexNavbar />
      <Box position="relative">
        <IndexHeader />
        <BetterInvestmentDecisionsSection/>
        <FeaturesSection/>
        <AndMuchMoreSection/>
        {/* <AutomaticPortfolioImportSection/>
        <PricingHomepageSection/>
        <JoinNewsletterSection/> */}
        <ReadyBottomCTA/>
        <Box style={{backgroundColor:theme.palette.dark.main}}>
          <AuthFooter />
        </Box>
      </Box>
    </>
  );
}
