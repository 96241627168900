import React, { useContext, useState, useEffect } from "react"
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useService } from "contexts/ServiceContext";
import { toast } from 'react-toastify';
import componentStyles from "assets/theme/components/navbars/admin-navbar-alternative.js";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const EtoroUserContext = React.createContext();
const useStyles = makeStyles(componentStyles);

export function useEtoroUser() {
  return useContext(EtoroUserContext);
}

export function EtoroUserProvider({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [currentEtoroUserData, setCurrentEtoroUserData] = useState();
  const [analysisPageLoading, setAnalysisPageLoading] = useState(false);
  const [notification, setNotification] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  
  const { validatePortfolio, getUserPortfolio, getNews, getAssetFundamentals, getInstrumentRisks, getEarnings, getLatestAssetPrices, flags,
    getUserInstruments, getMarketInfo, getRiskContributers, getCopySync, countryCodes, countryCodesNew, countryRegions, getDividends, getLatestInsiderTradesByInstrument,
    getParentPerformances, getPortfolioCountries, getCryptoData } = useService();

    const notify = (notificationType) => {
    if (notificationType === "success") {
      toast("🐶 Wow. such portfolio!", {className: classes.alertSuccess});
    } else if (notificationType === "invalid_user") {
      toast("😐 Invalid or private user", {className: classes.alertWarning});
    } else if (notificationType === "eToro_API_down") {
      toast("🌩 eToro's API is down. Try again later!", {className: classes.alertDanger});
    } else if (notificationType === "Unauthorized") {
      toast("🔒 Unauthorized", {className: classes.alertWarning});
    } else if (notificationType === "Too Many Requests") {
      toast("🔒 Too many requests, please wait and try again later", {className: classes.alertWarning});
    } else if (notificationType === "etf_error") {
      toast("🌍 ETF not found", {className: classes.alertWarning});
    } else {
      toast("👩🏽‍💻 Servers under maintenance, brb!", {className: classes.alertDanger});
    }
  }

  function addPortfolioBreakdown(currentEtoroUserPortfolio, userInstruments) {
    const labelsByValue = [];
    const dataByValue = [];
    const labelsByInvested = [];
    const dataByInvested = [];
    let ticker = "";   
    currentEtoroUserPortfolio.positionsSortedByValue.forEach(position => {
      if (position.instrumentId === "Other") {
        ticker = "Other"
      } else {
        const userInstrument = userInstruments.instruments.find(entry => entry.instrumentId === position["instrumentId"])
        userInstrument ? ticker = userInstrument.ticker : ticker = position["instrumentId"];
      }
      labelsByValue.push(ticker);
      dataByValue.push(position["valuePctUnrealized"]);
    });   
    currentEtoroUserPortfolio.positionsSortedByInvested.forEach(position => {
      if (position.instrumentId === "Other") {
        ticker = "Other"
      } else {
        const userInstrument = userInstruments.instruments.find(entry => entry.instrumentId === position["instrumentId"])
        userInstrument ? ticker = userInstrument.ticker : ticker = position["instrumentId"];
      }
      labelsByInvested.push(ticker);
      dataByInvested.push(position["investmentPctRealized"]);
    });   
    // finally, adds cash money $$ gucci gang
    labelsByInvested.push("Cash");
    dataByInvested.push(currentEtoroUserPortfolio.summary["creditPctRealized"]);
    labelsByValue.push("Cash");
    dataByValue.push(currentEtoroUserPortfolio.summary["creditPctUnrealized"]);

    const backgroundColor = [theme.palette.bsColors.Pie0, theme.palette.bsColors.Pie1, theme.palette.bsColors.Pie2, theme.palette.bsColors.Pie3,
      theme.palette.bsColors.Pie4, theme.palette.bsColors.Pie5, theme.palette.bsColors.Pie6, theme.palette.bsColors.Pie7, 
      theme.palette.bsColors.Pie8, theme.palette.bsColors.Pie9, theme.palette.bsColors.Pie10, theme.palette.bsColors.Pie11, theme.palette.bsColors.Yellow, "#70F", "#000", "#70F", "#000", "#70F", "#000", "#70F", "#000",
    ];
    const mainLabel = "Portfolio Stocks";
    return [
      {
      labels: labelsByValue,
      datasets: [
        {
          data: dataByValue,
          backgroundColor: backgroundColor,
          label: mainLabel,
        },
      ],
    },
    {
      labels: labelsByInvested,
      datasets: [
        {
          data: dataByInvested,
          backgroundColor: backgroundColor,
          label: mainLabel,
        },
      ],
    }];
  }

  function addSectorBreakdown(currentEtoroUserPortfolio) {
    const labels = ["Crypto", "Basic Materials", "Consumer Cyclical", "Financial Services", "Real Estate", "Communication Services", "Energy", "Industrials", "Technology", 
                    "Consumer Defensive", "Healthcare", "Utilities", "Hedge/Bond"];
    const sectorLabelsByValue = Object.keys(currentEtoroUserPortfolio.sortedByValueSectorBreakdown);
    const sectorDataByValue = Object.keys(currentEtoroUserPortfolio.sortedByValueSectorBreakdown).map(function(key){
      return currentEtoroUserPortfolio.sortedByValueSectorBreakdown[key]["weight"];
    });
    const sectorLabelsByinvested = Object.keys(currentEtoroUserPortfolio.sortedByInvestedSectorBreakdown);
    const sectorDataByInvested = Object.keys(currentEtoroUserPortfolio.sortedByInvestedSectorBreakdown).map(function(key){
      return currentEtoroUserPortfolio.sortedByInvestedSectorBreakdown[key]["weight"];
    });
    labels.forEach(label => {
      if (!sectorLabelsByValue.includes(label)) {
        sectorLabelsByValue.push(label);
        sectorDataByValue.push(0.0);
      }
      if (!sectorLabelsByinvested.includes(label)) {
        sectorLabelsByinvested.push(label);
        sectorDataByInvested.push(0.0);
      }
    })

    return [
      {
      labels: sectorLabelsByValue,
      datasets: [
        {
          data: sectorDataByValue,
          backgroundColor: [theme.palette.bsColors.Pie0, theme.palette.bsColors.Pie1, theme.palette.bsColors.Pie2, theme.palette.bsColors.Pie3, theme.palette.bsColors.Pie4, theme.palette.bsColors.Pie5, 
            theme.palette.bsColors.Pie6, theme.palette.bsColors.Pie7, theme.palette.bsColors.Pie8, theme.palette.bsColors.Pie9, theme.palette.bsColors.Pie10, 
            theme.palette.bsColors.Pie11, theme.palette.bsColors.Pie12, "#fde2e4", "#fad2e1", "#c5dedd",
          ],
          label: "Sector Breakdown",
          categoryPercentage: 0.99,
          barPercentage: 0.99,
        },
      ],
      },
      {
      labels: sectorLabelsByinvested,
      datasets: [
        {
          data: sectorDataByInvested,
          backgroundColor: [theme.palette.bsColors.Pie0, theme.palette.bsColors.Pie1, theme.palette.bsColors.Pie2, theme.palette.bsColors.Pie3, theme.palette.bsColors.Pie4, theme.palette.bsColors.Pie5, 
            theme.palette.bsColors.Pie6, theme.palette.bsColors.Pie7, theme.palette.bsColors.Pie8, theme.palette.bsColors.Pie9, theme.palette.bsColors.Pie10, 
            theme.palette.bsColors.Pie11, theme.palette.bsColors.Pie12, "#fde2e4", "#fad2e1", "#c5dedd",
          ],
          label: "Sector Breakdown",
          categoryPercentage: 0.99,
          barPercentage: 0.99,
        },
      ],
    }];
  }

  function toggleSectorVsMarketBreakdown(currentEtoroUserPortfolio, byValue) {
    const labels = ["Basic Materials", "Consumer Cyclical", "Financial Services", "Real Estate", "Communication Services", "Energy", "Industrials", "Technology", 
                    "Consumer Defensive", "Healthcare", "Utilities"];
    let portfolioSectors = [];
    if (byValue) {
      labels.forEach(label => {
        const portfolioSector = currentEtoroUserPortfolio.sortedByValueSectorBreakdown[label];
        portfolioSectors.push(portfolioSector && portfolioSector["weight"] ? portfolioSector["weight"] : 0.10367369696969);
      })
    } else {
      portfolioSectors = Object.keys(currentEtoroUserPortfolio.sortedByInvestedSectorBreakdown).map(function(key){
        return currentEtoroUserPortfolio.sortedByInvestedSectorBreakdown[key]["weight"];
      });
    }
    return {
      labels: labels,
      datasets: [
        {
          data: portfolioSectors,
          backgroundColor: [theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, 
            theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue,
            theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue,
          ],
          label: "Portfolio",
        },
        {
          //Last Update 15 June 2022 - Should prob change to SPY/VOO "relative to category" sector weigths
          data: [2.75, 10.74, 14.01, 2.46, 8.75, 3.37, 9.87, 24.05, 6.96, 14.56, 2.48],
          backgroundColor: [theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, 
            theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue,
            theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue,
          ],
          label: "S&P500",
        },
      ],
    };
  }

  function toggleRatiosVsMarketBreakdown(currentEtoroUserPortfolio) {
    const labels = ["P/E", "P/S", "Q/Q Rev. Growth", "ROA"];
    let portfolioRatios = [0, 0, 0, 0];
    if(currentEtoroUserPortfolio) {
      portfolioRatios[0] = currentEtoroUserPortfolio.ratiosVsMarket["peRatio"].toFixed(2);
      portfolioRatios[1] = currentEtoroUserPortfolio.ratiosVsMarket["priceToSales"].toFixed(2);
      portfolioRatios[2] = (currentEtoroUserPortfolio.ratiosVsMarket["quartelyRevenueGrowth"] * 100).toFixed(2);
      portfolioRatios[3] = (currentEtoroUserPortfolio.ratiosVsMarket["returnOnAssets"] * 100).toFixed(2);
    }
    return {
      labels: labels,
      datasets: [
        {
          categoryPercentage: 0.75,
          data: portfolioRatios,
          backgroundColor: [
            theme.palette.bsColors.MainBlue,
            theme.palette.bsColors.MainBlue,
            theme.palette.bsColors.MainBlue,
            theme.palette.bsColors.MainBlue,
          ],
          label: "Portfolio",
        },
        {
          categoryPercentage: 0.75,
          data: [
            17.65, //TODO: get from SPY Json (Price\/Prospective Earnings). Same for PS
            2.28,
            18.15, //https://csimarket.com/Industry/industry_growth_rates.php?
            3.43, //https://csimarket.com/Industry/industry_ManagementEffectiveness.php?
          ],
          backgroundColor: [
            theme.palette.bsColors.LogoLightBlue,
            theme.palette.bsColors.LogoLightBlue,
            theme.palette.bsColors.LogoLightBlue,
            theme.palette.bsColors.LogoLightBlue,
          ],
          label: "S&P500",
        },
      ],
    };
  }

  function getRiskClusterGraph(compactRiskClusterData) {
    let riskClusterLabels = ["Hedge","V. Low Risk", "Low Risk", "Medium Risk", "High Risk", "V. High Risk"];
    return {
      labels: riskClusterLabels,
      datasets: [
        {
          data: compactRiskClusterData,
          backgroundColor: ["#cacaca",
          theme.palette.bsColors.Risk1,
          theme.palette.bsColors.Risk3,
          theme.palette.bsColors.Risk5,
          theme.palette.bsColors.Risk7,
          theme.palette.bsColors.Risk9,
          "#eeeeee",
          ],
          label: "Risk Clusters",
        },
      ],
    };
  }

  function parsePortfolioDividends(assetFundamentals, userInstruments, currentEtoroUserPortfolio) {
    const dividendList = [];
    const dividendSuspendedList = [];
    let portfolioYield = 0.0
    userInstruments.instruments.forEach((userInstrument) => {
      if (userInstrument["instrumentId"] == 100017 || userInstrument["instrumentId"] == 100026) {
          const position = currentEtoroUserPortfolio.summary.positions.find(entry => entry.instrumentId === userInstrument["instrumentId"]);
          const stakingYield = 0.043;
          portfolioYield = portfolioYield + (position.valuePctUnrealized * stakingYield);
          dividendList.push({"logo": "https://etoro-cdn.etorostatic.com/market-avatars/" + userInstrument["instrumentId"] + "/150x150.png", "ticker": userInstrument["instrumentId"] == 100017 ? "ADA" : "TRX", 
          "yield": stakingYield, "value": position.valuePctUnrealized, "exDividendDate": "-"});
          return;
      } else {
        let asset;
        if (assetFundamentals && assetFundamentals.length > 0) {
          asset = assetFundamentals.find(entry => entry["instrumentId"] === userInstrument["instrumentId"]);
          if (!asset) { return }
          const ticker = asset.ticker;
          const position = currentEtoroUserPortfolio.summary.positions.find(entry => entry.instrumentId === asset["instrumentId"]);
          const value = position.valuePctUnrealized;
          const exDividendDate = asset.exDivDate;
          const currentDate = new Date();
          let forwardAnnualDividendYield = asset.forwardAnnualDividendYield;
          if (exDividendDate) {
            const monthDifference = exDividendDate.split("-")[1] - currentDate.getMonth()-1 + (12 * (exDividendDate.split("-")[0] - currentDate.getFullYear()));
            forwardAnnualDividendYield = monthDifference < -12 ? "Suspended" : forwardAnnualDividendYield;
          }
          if (forwardAnnualDividendYield === "Suspended") {
            dividendSuspendedList.push({"logo": userInstrument.media[userInstrument.media.findIndex(x => x.width == 150)].uri, "ticker": ticker, "yield": forwardAnnualDividendYield, "value": value, "exDividendDate": exDividendDate})
          } else {
            portfolioYield = portfolioYield + (value * forwardAnnualDividendYield);
            dividendList.push({"logo": userInstrument.media[userInstrument.media.findIndex(x => x.width == 150)].uri, "ticker": ticker, "yield": forwardAnnualDividendYield, "value": value, "exDividendDate": exDividendDate})
          }
        } else {
          return;
        }
      }
    });
    Object.keys(userInstruments.copyParentUsernames)?.map(function(key){
      const value = userInstruments.copyParentUsernames[key];
      const position = currentEtoroUserPortfolio.summary?.socialTrades?.find(entry => entry.parentUsername === key);
      if (position) {
        dividendList.push({"logo": value.media[value.media.findIndex(x => x.width == 150)].uri, "ticker": key, "yield": 0.0, 
                          "value": position.valuePctUnrealized, "exDividendDate": "-"})
      }
    });
    if(dividendList && dividendList.length > 1) {
      dividendList.sort((b,a) => (a.yield > b.yield) ? 1 : ((b.yield > a.yield) ? -1 : 0))
    }
    return {"assets": [...dividendList, ...dividendSuspendedList], "portfolioYield": portfolioYield};
  }

  function addIndustries(currentEtoroUserPortfolio) {
    const industryDataByInvested = Object.keys(currentEtoroUserPortfolio.sortedByInvestedIndustryBreakdown).map(function(key){
      return currentEtoroUserPortfolio.sortedByInvestedIndustryBreakdown[key];
    });
    return industryDataByInvested;
  }
  
  function addCountryToDictionary(userInstrument, position, country, ticker, assetsByCountryList, etfFactor) {
    const countryAlreadyExists = assetsByCountryList.find(entry => entry.country === country);
      if (countryAlreadyExists) {
        countryAlreadyExists.tickers.push({ tickerLogo: userInstrument.media[userInstrument.media.findIndex(x => x.width == 150)].uri, tickerName: ticker});
        countryAlreadyExists.invested = countryAlreadyExists.invested + position.investmentPctRealized * etfFactor;
        countryAlreadyExists.value = countryAlreadyExists.value + position.valuePctUnrealized * etfFactor;
        countryAlreadyExists.profitNumerator = countryAlreadyExists.profitNumerator + (position.investmentPctRealized * position.profitPct * etfFactor)
        countryAlreadyExists.profit = countryAlreadyExists.profitNumerator / countryAlreadyExists.invested;
      } else {
        assetsByCountryList.push({flag: flags[country],
        country: country, tickers: [{ tickerLogo: userInstrument.media[userInstrument.media.findIndex(x => x.width == 150)].uri, tickerName: ticker}], 
          invested: position.investmentPctRealized * etfFactor, profitNumerator: position.profitPct * position.investmentPctRealized * etfFactor, profit: position.profitPct, value: position.valuePctUnrealized * etfFactor});
      }
  }

  function addAssetsByCountry(currentEtoroUserPortfolio) {
    const assetsByCountryList = currentEtoroUserPortfolio?.countriesBreakdown ? currentEtoroUserPortfolio.countriesBreakdown : [];
    let mapData = {}
    assetsByCountryList.forEach((asset) => {
      mapData[countryCodesNew[asset.country]] = asset.invested
    });
    return {assetsByCountryList: assetsByCountryList, mapData: mapData};
  }

  function clearCurrentUser() {
    setCurrentEtoroUserData();
  }

  function setLocalStorage() {
    let jsonStorage = JSON.stringify({
      "currentEtoroUserData": currentEtoroUserData,
    });
    // localStorage maximum size: https://stackoverflow.com/questions/2989284/what-is-the-max-size-of-localstorage-values
    // TODO: In the future we need to optimize this cachedEtoroUserData structure, as a single JSON is innefficient.
    // For now, if it's too big, we drop some not so crucial data such as extended hours
    if (jsonStorage.length > 5242000) {
      currentEtoroUserData.extendedPricesPlusMarket.extendedPrices.splice(1);
      currentEtoroUserData.extendedPricesPlusMarket.extendedPricesMarket.splice(1);
      currentEtoroUserData.userInfo.about = "";
      jsonStorage = JSON.stringify({
        "currentEtoroUserData": currentEtoroUserData,
      });
    }
    
    try {
      localStorage.setItem("cachedEtoroUserData", jsonStorage);
    } catch (e) {
      console.log(e);
    }
  }


  function fetchLocalStorage(cachedData) {
    const cachedDataJSON = JSON.parse(cachedData);
    //loadEtoroUser(cachedDataJSON, cachedDataJSON)
    setCurrentEtoroUserData(cachedDataJSON.currentEtoroUserData);
  }

  function createInstrumentRisksArray(currentEtoroUserPortfolio, instrumentRisks, userInstruments, riskContributers) {
      let instrumentRisksArray = [];
      let childRiskAggregate = 0.0
      let parentRiskAggregate = 0.0
      let parentInvestedAggregate = 0.0
      instrumentRisks.forEach(instrumentRisk => {
        const userInstrument = userInstruments.instruments.find(entry => entry.instrumentId === instrumentRisk.instrumentId);
        const riskContributer = riskContributers.contributers.find(entry => entry.instrumentId === instrumentRisk.instrumentId);
        if (riskContributer) {
          childRiskAggregate += riskContributer.riskContributionPct;
          instrumentRisksArray.push({"risk": instrumentRisk.risk, "tickerLogo": userInstrument.media[userInstrument.media.findIndex(x => x.width == 150)].uri, 
          "tickerName" : userInstrument.ticker, "invested": riskContributer.investmentPct, "riskContribution": riskContributer.riskContributionPct});  
        }
      })
      // TODO add parents as risk (aggregated)
      Object.entries(userInstruments.copyParentUsernames).forEach(([username, data]) => {
        const copyPosition = currentEtoroUserPortfolio.summary.socialTrades.find(entry => entry.parentUsername === username);
        parentRiskAggregate += data.parentRiskScore;
        parentInvestedAggregate += copyPosition.investmentPctRealized;
        });
      Object.entries(userInstruments.copyParentUsernames).forEach(([username, data]) => {
        const copyPosition = currentEtoroUserPortfolio.summary.socialTrades.find(entry => entry.parentUsername === username);
        instrumentRisksArray.push({"risk": data.parentRiskScore, "tickerLogo": data.media[data.media.findIndex(x => x.width == 150)].uri, 
        "tickerName" : username, "invested": copyPosition.investmentPctRealized?.toFixed(2), "riskContribution": ((100 - childRiskAggregate) * (copyPosition.investmentPctRealized/parentInvestedAggregate)).toFixed(2) });
      });
      return instrumentRisksArray.sort((a,b) => (a.risk > b.risk) ? 1 : ((b.risk > a.risk) ? -1 : 0));
  }

  function createEarningsEvents(earnings, userInstruments) {
      const earningsEvents = [];
      earnings.forEach(entry => {
        let userInstrument = userInstruments.instruments.find(instrument => instrument.instrumentId === entry.instrumentId);
        if (!userInstrument) {
          userInstrument = userInstruments.parentInstruments ? userInstruments.parentInstruments.find(inst => inst.instrumentId === entry.instrumentId) : undefined;
        }
        let beforeAfterMarketString = "";
        let beforeAfterMarketIcon = "";
        if (entry.beforeOrAfterMarket) {
          if (["AfterMarket", "amc"].includes(entry.beforeOrAfterMarket)) {
            beforeAfterMarketString = " (After market close)";
            beforeAfterMarketIcon = "🌙"
          } else {
            beforeAfterMarketString = " (Before market open)";
            beforeAfterMarketIcon = "☀️"
          }
        }
        let epsEstimate = entry.epsEstimate ? ". EPS Estimate: " + entry.epsEstimate : "";
        let epsActual= entry.epsActual ? ". EPS Actual: " + entry.epsActual : "";
        earningsEvents.push({id: 1, title: beforeAfterMarketIcon + " " + entry.ticker + " earnings ", start: entry.earningsDate, allDay: true, backgroundColor: theme.palette.bsColors.LogoLightBlue,
        description: entry.ticker + " earnings release date" + beforeAfterMarketString + epsEstimate + epsActual, tickerLogo: userInstrument?.media[userInstrument.media.findIndex(x => x.width == 150)]?.uri,
        instrumentId: entry.instrumentId});
      });
      return earningsEvents;
  }

  function addDividendEvents(dividends, earningsDates, userInstruments) {
    const dividendEvents = [];
    dividends.forEach(entry => {
      let userInstrument = userInstruments.instruments.find(instrument => instrument.instrumentId === entry.instrumentId);
      if (!userInstrument) {
        userInstrument = userInstruments.parentInstruments ? userInstruments.parentInstruments.find(inst => inst.instrumentId === entry.instrumentId) : undefined;
      }
      let divAmount= entry.amount ? ". Dividend amount: " + entry.amount : "";
      dividendEvents.push({id: 2, title: entry.ticker + " ex-div date", start: entry.exDivDate, allDay: true, backgroundColor: theme.palette.bsColors.Orange,
      description: entry.ticker + " ex-dividend date" + divAmount, tickerLogo: userInstrument.media[userInstrument.media.findIndex(x => x.width == 150)].uri,
      instrumentId: entry.instrumentId});
      if(entry.payDate) {
        dividendEvents.push({id: 3, title: entry.ticker + " div payment", start: entry.payDate, allDay: true, backgroundColor: theme.palette.bsColors.Pie11,
        description: entry.ticker + " divident payment date" + divAmount, tickerLogo: userInstrument?.media[userInstrument.media.findIndex(x => x.width == 150)]?.uri,
        instrumentId: entry.instrumentId});
      }
    });
      // adds background to current day
      dividendEvents.push({
        "id": 3,
        start: new Date(),
        end: new Date(),
        title: "Last username refresh",
        description: "Reload Portfolio to ensure fresh data",
        allDay: true,
        tickerLogo: require("assets/img/icons/common/eToro-icon.png").default,
        backgroundColor: "#1b1b4f",
      });
      return earningsDates.concat(dividendEvents);
}

  async function loadEtoroUser(username, userInfo) {
    setPageLoading(true);
    let errorMessage;
    try {
      await axios.all([validatePortfolio(username)]);
    } catch(err) {
      setPageLoading(false);
      if (err && err.message.includes("404")) {
        errorMessage = "invalid_user";
      } else if (err && err.message.includes("503")) {
        errorMessage = "eToro_API_down";
      } else if (err && err.message.includes("501")) {
        history.push("/maintenance");
      } else {
        errorMessage = "error";
      }
      return errorMessage
    }
    let portfolio, userInstruments, marketInfo, riskContributers, copySyncData, parentPerformances, countries, cryptoData;
    try {
      const responses = await axios.all([getUserPortfolio(username), getUserInstruments(username), getMarketInfo(), getRiskContributers(username), 
        getCopySync(username), getParentPerformances(username), getPortfolioCountries(username), getCryptoData()]);
      portfolio = responses[0].data;
      userInstruments = responses[1].data;
      marketInfo = responses[2].data;
      riskContributers = responses[3].data;
      copySyncData = responses[4].data;
      parentPerformances = responses[5].data;
      countries = responses[6].data;
      cryptoData = responses[7].data;
    } catch(err) {
      console.log(err)
      setPageLoading(false);
      return errorMessage;
    }
    try {
      const responses = await axios.all([getLatestAssetPrices(userInstruments.instruments), getAssetFundamentals(userInstruments.instruments),
        getInstrumentRisks(userInstruments.instruments), getEarnings(copySyncData, userInstruments.instruments), getDividends(userInstruments.instruments), getLatestInsiderTradesByInstrument(userInstruments.instruments),
        ]);
      const latestPrices = responses[0].data;
      const latestAssetPrices = latestPrices.latestAssetPrices.concat(parentPerformances.dailyPerformance)
      const extendedPricesPlusMarket = {extendedPrices: latestPrices.extendedPrices, extendedPricesMarket: latestPrices.extendedPricesMarket}
      const assetFundamentals = responses[1].data;
      const instrumentRisks = responses[2].data;
      const earnings = responses[3].data;
      const dividends = responses[4].data;
      marketInfo.latestInsiderTradesByInstrument = responses[5].data;
      const instrumentRisksArray = instrumentRisks === "premium blocked" ? {} : createInstrumentRisksArray(portfolio, instrumentRisks, userInstruments, riskContributers);
      const earningsEvents = createEarningsEvents(earnings, userInstruments);
      const allCalendarEvents = addDividendEvents(dividends, earningsEvents, userInstruments);
      const portfolioBreakdown =  addPortfolioBreakdown(portfolio, userInstruments);
      const sectorBreakdown =  addSectorBreakdown(portfolio);
      const currentSectorVsMarketBreakdown =  toggleSectorVsMarketBreakdown(portfolio, true);
      const portfolioDividends =  parsePortfolioDividends(assetFundamentals, userInstruments, portfolio);
      const currentRatiosVsMarketBreakdown =  toggleRatiosVsMarketBreakdown(portfolio);
      const riskClusters =  getRiskClusterGraph(riskContributers.compactRiskClusterData);
      const industries = addIndustries(portfolio);
      const assetsByCountry =  addAssetsByCountry(countries);
      setCurrentEtoroUserData({userInfo: userInfo, currentPortfolio: portfolio, userInstruments: userInstruments, marketInfo: marketInfo, riskContributers: riskContributers,
        copySyncData: copySyncData, extendedPricesPlusMarket: extendedPricesPlusMarket, assetFundamentals: assetFundamentals, instrumentRisks: instrumentRisks,
        instrumentRisksArray: instrumentRisksArray, portfolioBreakdown: portfolioBreakdown, sectorBreakdown: sectorBreakdown,
        currentSectorVsMarketBreakdown: currentSectorVsMarketBreakdown, portfolioDividends: portfolioDividends, currentRatiosVsMarketBreakdown: currentRatiosVsMarketBreakdown,
        riskClusters: riskClusters, allCalendarEvents: allCalendarEvents, industries: industries, assetsByCountry: assetsByCountry, cryptoData: cryptoData})
    } catch(err) {
      console.log("err2", err)
      setPageLoading(false);
      return "error";
    }
  }
  

  useEffect(() => {
    const cachedData = localStorage.getItem("cachedEtoroUserData");
    // only redirects user to dashboard when all info is loaded and set, or data is cached
    if(cachedData && !currentEtoroUserData) {
      fetchLocalStorage(cachedData);
    } else if (currentEtoroUserData) {
      if (!cachedData) {
        setLocalStorage();
      }
      setPageLoading(false);
      setNotification("success");
      if(window.location.pathname === "/auth/lock"){
        history.push("/app/dashboard");
      } 
    }
  }, [currentEtoroUserData]);
  
  const value = {
    clearCurrentUser,
    notify,
    setLocalStorage,
    setPageLoading,
    loadEtoroUser,
    setAnalysisPageLoading,
    analysisPageLoading,
    pageLoading,
    notification,
    currentEtoroUserData,
  }

  return (
    <EtoroUserContext.Provider value={value}>
      {children}
    </EtoroUserContext.Provider>
  )
}