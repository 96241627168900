const componentStyles = (theme) => ({
  cardRoot: {
    background: "green",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("xl")]: {
      marginBottom: 0,
    },
  },
  cardRootUp: {
    background: "linear-gradient(135deg," +
      theme.palette.bsColors.GreenGradientTop +
      "," + theme.palette.bsColors.GreenGradientBot + ")!important",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("xl")]: {
      marginBottom: 0,
    },
    borderRadius: "15px",
    overflow: "hidden",
  },
  cardRootDown: {
    background: "linear-gradient(135deg," +
      theme.palette.bsColors.RedGradientTop +
      "," + theme.palette.bsColors.RedGradientBot + ")!important",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("xl")]: {
      marginBottom: 0,
    },
    borderRadius: "15px",
    overflow: "hidden",
  },
  cardContentRoot: {
    flex: "1 1 auto",
    maxHeight: "5rem",
    padding: "1rem 1.5rem !important",
    minHeight: "1px",
    marginBottom: "1.1rem",
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  textMarketName: {
    textTransform: "uppercase",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  textMarketValue: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  bgPrimary: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ",#825ee4)!important",
  },
  bgPrimaryLight: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.light +
      ",#825ee4)!important",
  },
  bgError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      ",#f56036)!important",
  },
  bgErrorLight: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.light +
      ",#f56036)!important",
  },
  bgWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      ",#fbb140)!important",
  },
  bgWarningLight: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.light +
      ",#fbb140)!important",
  },
  bgInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ",#1171ef)!important",
  },
  bgInfoLight: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.light +
      ",#1171ef)!important",
  },
  bgSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      ",#2dcecc)!important",
  },
  bgSuccessLight: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.light +
      ",#2dcecc)!important",
  },
  flagRoot: {
    height: "15px",
    width: "15px",
    alignSelf: "center",
  },
  typographyH4White: {
    color: theme.palette.white.main,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: theme.typography.h4.fontSize,
    textAlign: "left",
    lineHeight: 1.5,
  },
  typographyH5White: {
    color: theme.palette.white.main,
    fontWeight: "600!important",
    fontSize: theme.typography.h5.fontSize,
    textAlign: "left",
    lineHeight: 1.5,
  },
  logoStyle: {
    borderRadius: "50%",
    marginLeft: "-20px",
    marginRight: "20px",
    //border:"0.5px solid #1f1f1f"
  }
});

export default componentStyles;
