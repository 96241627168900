import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";
import themeColors from "assets/theme/colors.js";

import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components

// core components
import componentStyles from "assets/theme/views/auth/resources.js";
import cardGroupStyles from "assets/theme/components/cards/card-group.js";
import buttonsStyles from "assets/theme/components/button.js";
import { Button } from "@material-ui/core";

const BlogButton = {
  backgroundColor: themeColors.bsColors.LogoLightBlue,
  marginTop:"1.5rem",
    borderColor: themeColors.bsColors.LogoLightBlue,
    color: themeColors.white.main,
    "&:hover": {
      backgroundColor: themeColors.bsColors.MainBlue,
      borderColor: themeColors.bsColors.LogoLightBlue,
      color: themeColors.white.main,
    },
};


const useStyles = makeStyles(componentStyles);
const useStylesCardGroup = makeStyles(cardGroupStyles);
const useStylesButtons = makeStyles(buttonsStyles);

function HeaderArticlePreview({title,description,type,image,url}) {
  const classes = {
    ...useStyles(),
    ...useStylesCardGroup(),
    ...useStylesButtons(),
  };
  const theme = getTheme("light");
  
  function handleClick(){
    window.open(window.location.href + "/" + url);
  }

  return (
    <>
      {/* Page content */}
      
      <Box 
        className={classes.pricingBackground}
        paddingBottom="5rem"
      >
        <Container 
          className={classes.flipBox}
          maxWidth="xl"
        >
          <Box paddingTop="10rem">
              <Box>
                  {type == "eToro" ?
                  <>
                    <img style={{borderRadius:"20px", marginRight:"0.5rem"}} width="50px" src={require("assets/img/brand/eToro-icon-stretch.png").default}/>
                    <b style={{color:themeColors.bsColors.MainBlue}}>eToro Help</b>
                  </>
                  :
                  <>
                    <img style={{borderRadius:"20px", marginRight:"0.5rem"}} width="50px" src={require("assets/img/brand/bs-icon-stretch.png").default}/>
                    <b style={{color:themeColors.bsColors.MainBlue}}>Bullsheet Help</b>
                  </>
                }
              </Box>
              <Typography 
                className={classes.typographyH1}
                component="h2"  
              >
                {title}
                </Typography>
          </Box>
          <Box paddingTop="1rem" maxWidth="30rem">
            <Typography 
              className={classes.typographyH2}
              component="h3"
              >
                {description}
                </Typography>
          </Box>
          <Link to={`/auth/resources/${url}`}>
            <Button
              style={BlogButton}
            >
              Read Article
            </Button>

          </Link>
        </Container>
      </Box>
    </>
  );
}

export default HeaderArticlePreview;
