export default {
    //Dashboard
    assetBreakdown: "Your investments broken down by percentage in each asset. You can toggle between value % and invested % to see the difference between the position sizes you invested in, and what their current value % is now.",
    estimatedDividendYield: "An estimate of how much dividend yield you will receive per year, considering your current stocks, their dividend yield and your value % in each of them.",
    sectorBreakdown: "Your investments broken down by sector. Copy positions are currently not broken down by sectors.",
    dailyPerformance: "Check which are your best performing stocks today",
    portfolioRiskOverview: "A breakdown of your stocks by risk cluster. You can check a more detailed view on the Analysis tab.",
    recentPerformance: "Check your recent performance across different time horizons.",
    stocksByGeography: "Your stocks broken down by country. Crypto and Copy positions are currently excluded. Click on a country see more!",

    //Holdings
    cardHoldings: "Your investments by Profit/Loss, invested and value%. On the Ratios and Financials tabs, you can find several other ratios and financial performance information about your stocks.",

    //Extended Hours
    porfolioExtendedHours: "Track your stocks' extended hours prices here. US Stocks only.",

    //Analysis
    portfolioDividends: "A full breakdown of the available dividend information for your stocks.",
    sectorDiversification: "The sector diversification of your portfolio versus the approximate S&P500 sector diversification.",
    assetRiskContribution: "Which stocks are weighing your eToro risk score down the most?",
    portfolioRatiosVsMarket: "The PE, PS, QoQ Rev Growth and RoA of your stocks compared to an approximate average of S&P 500 stocks. We use the average of available ratios for your stocks.",
    assetRisk: "A detailed analysis of your stocks regarding Risk Score and overall risk contribution. Disclaimer: The risk score for each of your stocks was attributed by our proprietary risk formula and may not always be 100% correct, especially if the stock is very recent. It represents how much a particular stock impacts eToro risk.",
    industryBreakdown: "A full breakdown of your stocks across industries, as well as your P/L%  and investment % by each of them",

    //News
    stockNews: "Latest News for stocks in your portfolio",

    //Calendar
    infoCalendar: "Check your stocks' earnings, ex-dividend and dividend payment dates.",

    //Copy Sync
    effectivePortfolio: "What your portfolio actually looks like if you add your holdings to the holdings of the people you copy.",
    cardCopySync1: "How in sync are you with ",
    cardCopySync2: "? This table shows the most out of sync stocks. 50% means you only have half the amount of invested % compared to the person you are copying. 130% means your copy position is bigger than it should be.",
    parentTradesTooltip: "Track the latest movements of the people you copy.",

    //Markets
    cardBiggestWinners: "Stocks with the highest % gains today.",
    cardBiggestLosers: "Stocks with the highest % losses today.",
    infoOpeningHours: "A schedule for most markets present in eToro (hours in local time)",

    //Real Time Trades
    parentTrades: "Track the latest movements of your copy positions.",
    popularInvestorsTrades: "Check what eToro's Elite PIs are buying and selling recently.",
    infoInsiderTrading: "In which companies are highly ranked employees buying or selling stocks?",

    //ETF Analysis
    etfInfo: "Check an ETF's Holdings, Sector Breakdown, Performance and Ratios with our ETF Analyzer.",
    top50Holdings: "The ETF's top 50 holdings in terms of ETF %.",
    etfSectorBreakdown: "ETF Stocks divided into the 11 standard sectors.",
    etfGeography: "ETF Stocks divided into regions",
    etfAnnualisedReturns: "What the ETF has returned per year over a certain timeframe. 0% on longer timeframes typically means that those ETFs weren't available 5/10 years ago.",
    etfValuationRatios: "The weighted average of selected ratios considering all of the ETF's stocks",
    etfGrowthRatios: "The weighted average of selected growth measures considering all of the ETF's stocks",

    //Export
    exportPortfolio: "Export your portfolio to .xlsx",

    //Screener
    screenerInfo: "Find the next big thing with our exclusive eToro Stock Screener",

    //Other
    searchUsernames: "Search for other profiles besides yours to see how others are managing their portfolios.",
    cryptoMoversTooltip: "The biggest winners and losers in the overall market",
    cryptoDashboardInfo: "Check your crypto Portfolio, whale transactions and the broad crypto market.",
}