import React from "react";
// javascipt plugin for creating charts
// react plugin used to create charts
import PerfectScrollbar from "react-perfect-scrollbar";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from '@material-ui/core/LinearProgress';
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/icons components

// core components
import componentStyles from "assets/theme/components/cards/charts/card-copy-sync.js";
import componentStyles2 from "assets/theme/components/cards/dashboard/card-risk-line.js";

import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { useTranslation } from "react-i18next";


const useStyles1 = makeStyles(componentStyles);
const useStyles2 = makeStyles(componentStyles2);

const useStyles = () => {
  const classes = useStyles1();
  const classes2 = useStyles2();

  return { ...classes, ...classes2 };
};

const tableHead = ["Ticker", "Invested", "Type", "Date (UTC)"];

function CardCopySync({parentUsername, latestTrades, biggestPosition, parentCash, parentRisk}) {
  const classes = useStyles();
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  const [parentPhoto, setParentPhoto] = React.useState();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CopySync' });

  React.useEffect(() => {
    if (currentEtoroUserData?.userInstruments) {
      const parent = currentEtoroUserData.userInstruments?.copyParentUsernames ? currentEtoroUserData.userInstruments.copyParentUsernames[parentUsername] : undefined;
      if (parent) {
        setParentPhoto(parent.media[parent.media.length - 1].uri)
      }
    }
  }, [currentEtoroUserData]);

  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("header")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{parentUsername}</Box>
                  <FeatureInfoWithBox description={t("cardCopySyncTooltip1") + parentUsername + t("cardCopySyncTooltip2")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                <Box
                    component={Avatar}
                    classes={{ root: classes.avatarRoot }}
                    marginRight="0.875rem"
                    alt="..."
                    src={parentPhoto}
                  />
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        
        <CardContent>
                <Box display="flex" alignItems="flex-end" mb={4} >
                    <Box width="50%" mb={"2px"} mr={"5px"}>
                      <Typography variant="h4">{t("Largest Position")}</Typography>
                      <Box alignItems="center" display="flex">
                          <Box
                            component={Avatar}
                            classes={{ root: classes.logoRoot }}
                            marginRight="0.875rem"
                            alt="..."
                            src={biggestPosition.logo}
                          />
                          <Box display="flex" alignItems="flex-start">
                            <Box fontSize={theme.typography.h5.fontSize} component="span">
                              {`${biggestPosition.ticker} (${biggestPosition.investmentPctRealized?.toFixed(2)}%)`}
                            </Box>
                          </Box>
                        </Box>
                    </Box>
                    <Box width="50%" mr={1} textAlign="right">
                      <Typography variant="h4">{t("Risk")}</Typography>
                      <Box>
                        <Avatar variant="rounded" className="risk" classes={{ root: classes["avatarRootRisk" + parentRisk] }}>
                          {parentRisk}
                        </Avatar>
                      </Box>
                    </Box>
                </Box>   
                <Box display="flex" alignItems="flex-end" mb={1}>
                    <Box width="20%" mb={"2px"} mr={"5px"} justifyContent="right">
                      <Typography variant="h4">% {t("Invested")}</Typography>
                    </Box>
                    <Box width="80%" mr={1}>
                        <LinearProgress variant="determinate" value={100-parentCash} style={{height:"30px !important"}}/>
                    </Box>
                    <Box justifyContent="center" ml={1}>
                        <Typography variant="h4">{100-parentCash.toFixed(1)}%</Typography>
                    </Box>
                </Box>   
                <PerfectScrollbar>
                    <TableContainer classes={{ root: classes.tableRoot }}>
                      <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                          <TableRow>
                            {tableHead.map((prop, key) => (
                              <TableCell
                                key={key}
                                classes={{
                                  root:
                                    classes.tableCellRoot + " " + classes.tableCellRootHead,
                                }}
                              >
                                {t(prop)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {latestTrades ? latestTrades.slice(0,25).map((position, key) => (
                            <TableRow key={key}>
                              <TableCell
                                classes={{
                                  root:
                                    classes.tableCellRoot +
                                    " " +
                                    classes.tableCellRootBodyHead,
                                }}
                                component="th"
                                variant="head"
                                scope="row"
                              >
                                <Box alignItems="center" display="flex">
                                  <Box
                                    component={Avatar}
                                    classes={{ root: classes.logoRoot }}
                                    marginRight="0.875rem"
                                    alt="..."
                                    src={position.logo}
                                  />
                                  <Box display="flex" alignItems="flex-start">
                                    <Box fontSize={theme.typography.h5.fontSize} component="span">
                                      {position.ticker}
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                {position.investmentPct?.toFixed(2)}%
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                {position.isBuy == true ? <span style={{color: "green"}}>Buy</span> : <span style={{color: "red"}}>Sell</span>}
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                {position.datetimeStr?.split("T")[0]}
                              </TableCell>
                            </TableRow>
                        )) : <></> }
                        </TableBody>
                      </Box>
                    </TableContainer>
                </PerfectScrollbar>
        </CardContent>
        </>
      </Card>
    </>
  );
}

export default CardCopySync;
