import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ArrowForward from "@material-ui/icons/ArrowForward"
import { useEtoroUser } from "../../../contexts/EtoroUserContext";

// @material-ui/icons components

// core components

import componentStyles from "assets/theme/components/cards/cards/card-news.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);

function CardNewsSmall({ ticker, date, image, title, source, url }) {
  const classes = { ...useStyles(), ...useStylesCardImg() };
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  let companyLogo = ""; 
  let utm = "?utm_source=bullsheet&utm_medium=referral";
  let asset = currentEtoroUserData?.userInstruments ? currentEtoroUserData.userInstruments.instruments.find(entry => entry.ticker === ticker) : undefined;
  if (!asset) {
    asset = currentEtoroUserData?.userInstruments.parentInstruments ? currentEtoroUserData.userInstruments.parentInstruments.find(entry => entry.ticker === ticker) : undefined;
  }
  function handleClick(){
    window.open(url + utm, "_blank");
  }
  if (asset) {
    companyLogo = asset.media[asset.media.findIndex(x => x.width == 150)].uri;
  }
  return (
    <>
      <Card className={classes.cardRoot}>
        <img
          alt="..."
          src={image}
          className={classes.cardImgTopSmall}
        />
        <CardContent>
          
        <Box
            component="small"
            fontSize={theme.typography.h5.fontSize}
            fontWeight="600"
            color={theme.palette.bsColors.MainBlue}
          >
            <Box
              component={Avatar}
              classes={{ root: classes.companyLogo }}
              alt="..."
              src={companyLogo}
            />

            {ticker}
          </Box>
          <Box
            component="p"
            fontSize={theme.typography.h5.fontSize}
            fontWeight="400"
            color={theme.palette.gray[600]}
          >
            {source} - {date}
          </Box>
          <Box component={Typography} variant="h4" marginBottom="0!important">
            {title.length > 62 ? 
              title.substring(0,60)+"..."
            :
              title
            }
          </Box>
          <Button onClick={handleClick}
            variant="text"
            disableRipple
            component={Box}
            padding="0!important"
            style={{float:"right", color: theme.palette.bsColors.LogoLightBlue, minWidth:"40px", right:"5px", bottom:"10px", position:"absolute"}}
          >
            <ArrowForward />
          </Button>
        </CardContent>
      </Card>
    </>
  );
}

export default CardNewsSmall;
