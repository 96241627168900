import React from "react";
// react plugin used to create datetimepicker
// plugin for text editor
// plugin for drag-and-drop files
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import CircleLoader from "react-spinners/CircleLoader";
import styled from "styled-components";

// @material-ui/lab components
import Autocomplete from "@material-ui/lab/Autocomplete";
// @material-ui/icons components
import Person from "@material-ui/icons/Person";
import Email from "@material-ui/icons/Email";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// core components

import componentStyles from "assets/theme/views/admin/components.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { FilledInput } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { useService } from "contexts/ServiceContext";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import getTheme from "assets/theme/theme";


const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const InputWrapper = styled.div`
  .MuiInputBase-input {
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      font-size: 16px;
    }
  }
`

const CardFeedbackForm = () => {
  const classes = {
    ...useStyles(),
    ...useStylesButtons(),
  };
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const typeRef = React.useRef();
  const textRef = React.useRef();
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = React.useState(false);
  const { postFeedback } = useService();
  const maxCharacters = 1500;
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Contact' });
  const theme = getTheme();

  const dropdownOptions = [
    { value: 1, label: t("Bugs") },
    { value: 2, label: t("Feature Request") },
    { value: 3, label: t("General Feedback") },
  ];

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true);
    setError("")
    setSuccess("")
    setLoading(true)
    postFeedback(nameRef?.current?.value, emailRef?.current?.value, typeRef?.current?.value, textRef?.current?.value)
    .then(response => {
      setSuccess("Feedback received! Thanks :)");
    })
    .catch(err => {
      setError("Error sending feedback, please try again later or contact info@bullsheet.me");
    });
    setLoading(false);
  }

  function handleInputChange(e) {
    e.stopPropagation();
    
    setError("");
    if (textRef?.current?.value?.length >= maxCharacters) {
      setError("Limit of " + maxCharacters + " characters reached! Send an email to info@bullsheet.me instead.");
    }

    if (nameRef?.current?.value && emailRef?.current?.value && typeRef?.current?.value && textRef?.current?.value && !loading) {
      setSubmitButtonEnabled(true);
    } else {
      setSubmitButtonEnabled(false);
    }
  }
  return (
    <>      
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                className={classes.cardHeader}
                title={t("feedbackForm")}
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
              <CardContent>
                <Form onSubmit={handleSubmit}>
                <InputWrapper>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                      <OutlinedInput
                        fullWidth
                        type="text"
                        placeholder={t("yourName")}
                        inputRef={nameRef}
                        onChange={handleInputChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup>
                        <OutlinedInput
                          fullWidth
                          type="email"
                          onChange={handleInputChange}
                          placeholder={t("yourEmail")}
                          inputRef={emailRef}
                          style={{ fontSize: "32px !important" }}
                          startAdornment={
                            <InputAdornment position="start">
                              <Email />
                            </InputAdornment>
                          }
                        />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="combo-box-bs"
                      options={dropdownOptions}
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => <span style={{color: theme.palette.white.main}}>{option.label}</span>}
                      closeIcon={null}
                      popupIcon={
                        <Box
                          component={KeyboardArrowDown}
                          width="1.25rem!important"
                          height="1.25rem!important"
                        />
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={typeRef}
                          label={t("type")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      size="medium"
                      component={Box}
                      marginBottom="1rem!important"
                      style={{marginTop:"15px"}}
                    >
                      <FilledInput disabled={false}
                        inputProps={{maxLength: maxCharacters}}
                        type="text"
                        style={{border:"1px solid #fafafa"}}
                        multiline={true}
                        rows={7}
                        inputRef={textRef}
                        onChange={handleInputChange}
                        placeholder={t("description")}
                        
                      />
                    </FormControl>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                    <Button  disabled={!submitButtonEnabled}
                      variant="contained"
                      type="submit"
                      classes={{
                        root: classes.buttonFormDefault
                      }}
                    >  {t("send")}
                    <Box position="relative" style={{marginRight: "27px", marginTop: "-20px"}}>
                      <CircleLoader color={"#4d86c5"} loading={false} size={30} /> 
                    </Box>   
                  </Button>
                  </Grid>
                </Grid>
                </InputWrapper>
                </Form>
              </CardContent>
            </Card>
            
    </>
  );
};

export default CardFeedbackForm;
