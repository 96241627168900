import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/icons components

// core components
import componentStyles from "assets/theme/components/cards/cards/card-dividends.js";
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(componentStyles);

// quick find: Estimated Dividend Yield
function CardDividends({portfolioDividends}) {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const history = useHistory();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardDividends' });
  return (
    <>
      <Card className={classes.cardRoot}>
      <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("title")}</Box>
                  <FeatureInfoWithBox description={t("cardDividendsTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        { portfolioDividends?.assets && portfolioDividends.assets.length > 0 ?
        <CardContent>
          <Box
            fontSize={theme.typography.h1.fontSize}
            fontWeight="600"
            textAlign="center!important"
            classes= {{ root: classes.dividendPercentageBox}}
          >
            { portfolioDividends.portfolioYield?.toFixed(2)}%
          </Box>
          <Box 
            component={Typography} 
            variant="h5" 
            textAlign="center!important"
            classes= {{ root: classes.dividendValueBox}}
            >
          </Box>
          <Box
            component="p"
            marginBottom="0.5rem"
            fontWeight="700"
            lineHeight="1.7"
            fontSize={theme.typography.h5.fontSize}
            marginTop="2rem"
          >
            {t("topAssets")}
          </Box>
          <Grid container component={Box} alignItems="center" justifyContent="space-between">
                    <Grid item xs={2}>
                    </Grid>
                    <Grid
                      item xs={2}
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      <Box component="small" fontSize={theme.typography.h5.fontSize} fontWeight="600">
                        {t("asset")}
                      </Box>
                      
                    </Grid>
                    <Grid
                      item xs={2}
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      <Box component="small" fontSize={theme.typography.h5.fontSize} fontWeight="600">
                        {t("yield")}
                      </Box>
                      
                    </Grid>
                  </Grid>
          <List disablePadding>
            {portfolioDividends ?  portfolioDividends.assets.slice(0, 5).map((prop, key) => {
              return (
                <ListItem key={key} className={classes.listItemRoot}>
                  <Grid container component={Box} alignItems="center">
                    <Grid item xs={2}>
                      <img alt="..." className={classes.img} src={prop.logo} />
                    </Grid>
                    <Grid
                      item xs={6}
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                    
                      <Typography
                        component="h5"
                        variant="h5"
                        className={classes.mb0}
                      >
                        {prop.ticker}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      component={Box}
                      maxWidth="100%!important"
                      flexBasis="0!important"
                      flexGrow="1!important"
                    >
                      
                      <Typography
                        component="h5"
                        variant="h5"
                        className={classes.mb0}
                      >
                        {(prop.yield * 100).toFixed(2)}%
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            }) : <></> }
          </List>
          <Button
            classes={{root: classes.buttonRootDefaultSmall}}
            onClick={() => history.push("/app/stats")}
          >
            {t("seeMore")}
          </Button>
        </CardContent> : 
        <CardContent>
          <Typography
              component="h5"
              variant="h5"
              style={{marginTop: "150px", marginLeft: "50px"}}
            >
              You've got no dividend assets :(
          </Typography>
          <img
          alt="..."
          src={require("assets/img/theme/weak_bull.png").default}
          style={{width:"200px", marginTop: "10px", marginLeft: "50px"}}
          /> 
        </CardContent> }
      </Card>
    </>
  );
}

export default CardDividends;
