import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  header: {
    background: "linear-gradient(180.04deg, #FFFFFF 15.84%, #B3CEE8 240.04%)",
  },
  typographyH1: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: "2.75rem",
    textAlign: "left",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  typographyH1Center: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: "2.75rem",
    textAlign: "center",
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  typographyH2: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "400!important",
    marginBottom: "0!important",
    fontSize: "1.6275rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  typographyH2Strong: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: "1.6275rem",
    textAlign: "left",
    lineHeight: 1.5,
  },
  orderMd1: {
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  orderMd2: {
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
  prMd5: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "3rem",
    },
  },
  heroSubheader: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "400!important",
    marginBottom: "0!important",
    fontSize: "1.1275rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  typographyH3: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "600!important",
    marginBottom: "0!important",
    fontSize: "1.1275rem",
    textAlign: "left",
    lineHeight: 1.5,
  },
  typographyH4: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "400!important",
    marginBottom: "0!important",
    fontSize: "1rem",
    textAlign: "center",
    lineHeight: 1.5,
  },
  typographyH5: {
    color: themeColors.bsColors.MainBlue,
    fontWeight: "600!important",
    fontSize: theme.typography.h5.fontSize,
    textAlign: "left",
    lineHeight: 1.5,
  },
  imgBox: {
    verticalAlign: "middle",
    borderStyle: "none",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "4rem",
      width: "90%",
      alignSelf: "center",
    },
  },
  imgBoxHeader: {
    verticalAlign: "middle",
    borderStyle: "none",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "4rem",
      width: "90%",
      alignSelf: "center",
    },
  },
  buttonRoot: {
    color: themeColors.white.main,
    backgroundColor: themeColors.bsColors.LogoLightBlue,
    borderColor: themeColors.bsColors.LogoLightBlue,
    marginRight: "1rem",
    fontSize: theme.typography.h4.fontSize,
    "&:hover": {
      backgroundColor: themeColors.bsColors.MainBlue,
      borderColor: themeColors.bsColors.MainBlue,
      color: themeColors.white.main,
    },
  },
  ptLg5: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "3rem",
    },
  },
  AbsoluteImg: {
    foo: props => ({
      backgroundColor: props.backgroundColor,
      left: props.left,
      top: props.top,
      transform: "rotate({props.rotate})",
    }),
    position: "absolute",
  },
});

export default componentStyles;
