import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import { useLocation, Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components
import Done from "@material-ui/icons/Done";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Update from "@material-ui/icons/Update";
import SearchIcon from '@material-ui/icons/Search';

import CardInfoBadges from "components/Cards/Index/CardInfoBadges.js";

import componentStyles from "assets/theme/views/index.js";
import badgeStyles from "assets/theme/components/badge.js";
import buttonStyles from "assets/theme/components/button.js";
import getTheme from 'assets/theme/theme';

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(badgeStyles);
const useStylesButton = makeStyles(buttonStyles);

const BetterInvestmentDecisionsSection = () => {
  const classes = { ...useStyles(), ...useStylesBadge(), ...useStylesButton() };
  const theme = getTheme("light");
  return (
      <>
        <Box
          paddingTop="9rem"
          paddingBottom="4rem"
          component="section"
          className={classes.bgWhite}
        >
          <Container maxWidth={false}>
            <Grid container>
              <Grid item xs={12} md={12} className={classes.centerElements}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.typographyH2Dark}
                  style={{color:theme.palette.bsColors.MainBlue}}
                >
                  Make better investment decisions based on data
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} xl={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <TrendingUp classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Be more efficient
                  </Typography>
                  <Box>        
                    No more manually importing your assets every time you buy or sell. Just type your eToro username.
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} xl={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <Done classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Focus on what matters
                  </Typography>
                  <Box>        
                    Get insights about your portfolio diversification and risk and find unwanted imbalances.
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} xl={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <Update classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Follow the money
                  </Typography>
                  <Box>        
                    Find out what popular investors are trading in real time and follow Insiders Trading.
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} xl={3} className={classes.leftElements}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  fontWeight="300"
                  lineHeight="1.7"
                  fontSize="1rem"
                  marginBottom="1rem"
                  marginTop="1.5rem"
                  marginLeft="3rem"
                  marginRight="3rem"
                >
                  <SearchIcon classes={{ root: classes.largeIconGreen }}/>
                  <Typography
                    component="h2"
                    variant="h2"
                    style={{color:theme.palette.bsColors.MainBlue}}
                  >
                    Find your next Winner
                  </Typography>
                  <Box>        
                    Use our Screener to find stocks to invest in that are available on eToro.
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
  )
}

export default BetterInvestmentDecisionsSection;