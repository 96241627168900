import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-pi-trades.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useService } from "contexts/ServiceContext";
import CustomCheckbox from "components/Custom/CustomCheckbox.js";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(componentStyles);

const tableHead = ["Company", "Name", "Position at Company", "Type", "Amount", "Date"];

// quick find: Insider Trading
export default function CardInsiderTrades() {
  const classes = useStyles();
  const theme = useTheme();
  const tableConatinerRef = React.useRef(null);
  const { currentEtoroUserData } = useEtoroUser();
  const { getMarketInfo, getLatestInsiderTradesByInstrument } = useService();
  const [marketInfo, setMarketInfo] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'RealTimeTrades' });

  function handleClick(e) {
    e.preventDefault();
    checked === true ? setChecked(false) : setChecked(true);
  }

  React.useEffect(() => {
    if(currentEtoroUserData?.marketInfo && currentEtoroUserData?.userInstruments) {
      getMarketInfo().then(response => {
        const marketInfo = response.data;

        getLatestInsiderTradesByInstrument(currentEtoroUserData?.userInstruments?.instruments).then(response => {
          marketInfo.latestInsiderTradesByInstrument = response.data;
          setMarketInfo(marketInfo);
        });
      });
      new List(tableConatinerRef.current, {
        valueNames: ["ticker", "name", "position at company", "type", "amount", "date"],
        listClass: "list",
      });
    }
    return () => {
      setMarketInfo();
    };
  }, [currentEtoroUserData]);

  React.useEffect(() => {
    setMarketInfo(currentEtoroUserData?.marketInfo);
    return () => {
      setMarketInfo();
    };
  }, []);
  
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("realTimeTracking")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("insiderTrading")}</Box>
                  <FeatureInfoWithBox description={t("infoInsiderTradingTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto" className={classes.gridItemRoot}>
                <CustomCheckbox checked={checked}  onClick={handleClick}/>
                <Box color={theme.palette.bsColors.MainBlue} fontSize={theme.typography.h5.fontSize} fontWeight="600" component="small">
                 {t("myCompanies")}
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        {marketInfo?.latestInsiderTrades && marketInfo.latestInsiderTrades.length > 0 ?
        <TableContainer classes={{ root: classes.tableRoot }} ref={tableConatinerRef}>
          <Table stickyHeader aria-label="sticky table">
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      scope="col"
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " sort " +
                          classes.tableCellRootHead,
                      }}
                    >
                      {t(prop)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="list">
                {(checked === true ? (marketInfo?.latestInsiderTradesByInstrument) : (marketInfo?.latestInsiderTrades)).map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                    >
                      <Box alignItems="center" display="flex">
                        <Box
                          component={Avatar}
                          marginRight="1rem"
                          alt="..."
                          classes={{ root: classes.avatarRoot}}
                          src={prop.logo}
                        />
                        <Box display="flex" alignItems="flex-start">
                          <Box
                            fontSize={theme.typography.h5.fontSize}
                            component="span"
                            className="ticker"
                          >
                            {prop.ticker}
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot + " budget" }}
                    >
                      <Box
                          component="span"
                          marginRight=".5rem"
                          className="name"
                        >
                        {prop.reportingName}
                      </Box>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot + " budget" }}
                    >
                      <Box
                          component="span"
                          marginRight=".5rem"
                          className="position at company"
                        >
                        {prop.typeOfOwner ?
                        (prop.typeOfOwner.length < 55 ?  prop.typeOfOwner : prop.typeOfOwner.substring(0,54)+"(...)")
                        : "-"}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes["tableCellRoot" + prop.transactionType] }}>
                      <Box paddingTop=".35rem" paddingBottom=".35rem" className="type">
                        {prop.acquistionOrDisposition === "BUY" ? <span style={{color: "green"}}>Buy</span> 
                        : <span style={{color: "red"}}>Sell</span>}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box paddingTop=".35rem" paddingBottom=".35rem" className="amount">
                        {prop.securitiesTransacted}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box display="flex" alignItems="center" className="date">
                          {prop.transactionDate}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </Table>
        </TableContainer>
        : <></> }
      </Card>
    </>
  );
}
