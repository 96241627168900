import React, { useEffect } from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-after-hours-top.js";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import strings from "../../../strings/en.js";
import { Bar } from "react-chartjs-2";
import { chartTopAfterHours, chartTopAfterHoursDark } from "variables/charts.js";
import { chartBotAfterHours, chartBotAfterHoursDark } from "variables/charts.js";
import { chartMixedCrypto, chartMixedCryptoDark } from "variables/charts.js";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "contexts/DarkModeContext.js";

const useStyles = makeStyles(componentStyles);

export default function CardAfterHoursChart({updatedPrices, type, asset}) {
  const classes = useStyles();
  const theme = useTheme();
  const { activeTheme } = useDarkMode();
  const [chartData, setChartData] = React.useState();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardAfterHours' });
  let countNegativeDashes = 0;
  useEffect(() => {
    if (updatedPrices) {
      let tickersData = [];
      let changesData = [];
      let logoData = [];
      let colorData = [];
      if (type === "Rising" && asset === "Stocks") {
        for (var i = 0; i < 10; i++) {
          if(updatedPrices && updatedPrices[i]?.extendedPriceChangePercentage > 0.00) {
            tickersData.push(updatedPrices[i].ticker);
            changesData.push(updatedPrices[i].extendedPriceChangePercentage);
            logoData.push(updatedPrices[i].logo);
          } else {
            tickersData.push("-");
            changesData.push(0);
            logoData.push("");
          }
        }
      } else if (type === "Falling" && asset === "Stocks") {
        for (var i = 9; i >= 0; i--) {
          if (updatedPrices && updatedPrices[i]?.extendedPriceChangePercentage < 0.00) {
            let modPriceChange = 0 - updatedPrices[i].extendedPriceChangePercentage;
            tickersData.push(updatedPrices[i].ticker);
            changesData.push(modPriceChange);
            logoData.push(updatedPrices[i].logo);
          } else {
            countNegativeDashes += 1;
          }
        }
        while (countNegativeDashes > 0) {
          tickersData.push("-");
          changesData.push(0);
          logoData.push("");
          countNegativeDashes -= 1;
        }
        
      }
      else if (type === "Mixed" && asset === "Crypto") {
        for (var i = 0; i < 3; i++) {
          let modPriceChange = updatedPrices[i]?.priceChangePercentage;
          if (modPriceChange < 0){
            colorData.push(theme.palette.bsColors.NumberRed)
          }
          else{
            colorData.push(theme.palette.bsColors.NumberGreen)
          }
          tickersData.push(updatedPrices[i]?.ticker);
          changesData.push(modPriceChange);
          logoData.push(updatedPrices[i]?.logo); 
        }
        for (var i = (updatedPrices.length - 3) ; i < updatedPrices.length ; i++) {
          if (updatedPrices && updatedPrices[i]?.priceChangePercentage < 0.00) {
            let modPriceChange = updatedPrices[i]?.priceChangePercentage;
            if (modPriceChange < 0){
              colorData.push(theme.palette.bsColors.NumberRed)
            }
            else{
              colorData.push(theme.palette.bsColors.NumberGreen)
            }
            tickersData.push(updatedPrices[i]?.ticker);
            changesData.push(modPriceChange);
            logoData.push(updatedPrices[i]?.logo);
          }  
        }
    }
      setChartData({
        labels: tickersData,
        datasets: [
          {
            data: changesData,
            backgroundColor: type == "Mixed" ? colorData
            : (type === "Rising" ?
            theme.palette.bsColors.NumberGreen : theme.palette.bsColors.NumberRed),
            label: t(type),
            logoData: logoData,
          },
        ],
      });
    }
  }, [updatedPrices])

  return (
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {asset === "Stocks" ? t("title") : "Crypto Market"}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t(type)}</Box>
                  <FeatureInfoWithBox description={asset == "Crypto" ? strings.cryptoMoversTooltip : strings.porfolioExtendedHours}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box paddingTop=".35rem" paddingBottom=".35rem" className="price difference">
                      <Box
                        width="1.5rem!important"
                        height="1.5rem!important"
                        marginRight="-0.5rem"
                      />
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
        { chartData && updatedPrices && updatedPrices.length > 0 ? 
        <Box position="relative" height="250px">          
          <Bar
            data={chartData}
            options={activeTheme == "light" ? (asset == "Crypto" ? chartMixedCrypto.options : (type === "Rising" ? chartTopAfterHours.options : chartBotAfterHours.options))
                      : (asset == "Crypto" ? chartMixedCryptoDark.options : (type === "Rising" ? chartTopAfterHoursDark.options : chartBotAfterHoursDark.options))
                    }
          />
        </Box>
        : <></> }
        </CardContent> 
      </Card>
  );
}
