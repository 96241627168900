import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-etf-holdings.js";
import { useTranslation } from "react-i18next";
import { CardContent } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Period", "Return"];

export default function CardCompanyInfo({stockData, oi}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'StockDetails' });

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("stockInfo")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{"More data"}</Box>
                  <FeatureInfoWithBox description={t("generalInfoTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent style={{marginTop: "-5px"}}>
          {oi == 1 ? <>
          Ticker: <Typography variant="h4" component="h4" display="inline">
             {stockData.ticker}
          </Typography> <br/>
          Name: <Typography variant="h4" component="h4" display="inline">
           {stockData.name}
          </Typography> <br/>
          Sector: <Typography variant="h4" component="h4" display="inline">
            {stockData.sector}
          </Typography> <br/>
          Industry: <Typography variant="h4" component="h4" display="inline">
            {stockData.industry}
          </Typography> <br/>
          Exchange: <Typography variant="h4" component="h4" display="inline">
            {stockData.exchange}
          </Typography> <br/>
          IPO Date: <Typography variant="h4" component="h4" display="inline">
            {stockData.ipoDate ? stockData.ipoDate : "Unclear"}
          </Typography> <br/>
          Website: <Typography variant="h4" component="h4" display="inline" style={{color: theme.palette.bsColors.LogoLightBlue}}>
             <a href={stockData.website} target="_blank">{stockData.website}</a>
          </Typography> <br/>
          </> :
          <> 
          Lorem: <Typography variant="h4" component="h4" display="inline">
             {"Lorem Ipsum is not simply "}
          </Typography> <br/>
          Lorem two: <Typography variant="h4" component="h4" display="inline">
            {"random text dos"}
          </Typography> <br/>
          Lorem three: <Typography variant="h4" component="h4" display="inline">
            {"random text tres"}
          </Typography> <br/>
          Lorem four: <Typography variant="h4" component="h4" display="inline">
            {"random text quatro"}
          </Typography> <br/>
          Lorem cinco: <Typography variant="h4" component="h4" display="inline">
            {"random text cinco"}
          </Typography> <br/>
          Lorem cinco: <Typography variant="h4" component="h4" display="inline">
            {"random text cinco"}
          </Typography> <br/>
          Lorem seis: <Typography variant="h4" component="h4" display="inline">
             {"random text"}
          </Typography> <br/>
          </>}
        </CardContent>
      </Card>
    </>
  );
}
