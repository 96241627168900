import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// @material-ui/lab components
// @material-ui/icons components

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader";
import CardHoldings from "components/Cards/Sortable/CardHoldings";

import componentStyles from "assets/theme/views/admin/sortable.js";
import { useEtoroUser } from "contexts/EtoroUserContext";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(componentStyles);

const Holdings = () => {
  const classes = useStyles();
  const { currentEtoroUserData } = useEtoroUser();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CardHoldings' });

  return (
    <>
      <AlternativeHeader section={t("title")} subsection="Portfolio" />
      {/* Page content */}
      <Helmet>
        <title>Portfolio Holdings | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <CardHoldings currentEtoroUserData={currentEtoroUserData}/>
      </Container>
    </>
  );
};

export default Holdings;
