import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  gridItemRoot: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: "100%",
    paddingLeft: "0!important",
    textAlign: "end",
  },
  gridItemTitle: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "1rem",
    marginBottom: 5,
  },
  mb0: {
    marginBottom: 0,
  },
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  buttonRootUnselected: {
    background: theme.palette.white.main + "!important",
    color: theme.palette.primary.main + "!important",
  },
  boxButtonsHeader:{
    justifyContent:"flex-end",
    display:"flex",
    flexWrap:"wrap",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.8rem",
      justifyContent:"flex-start",
    },
  }
});

export default componentStyles;
