import boxShadows from "assets/theme/box-shadow.js";
import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    backgroundColor: themeColors.white.main,
  },
  listItemRoot: {
    padding: "1.5rem!important",
    borderBottom: "1px solid " + theme.palette.gray[200],
  },
  companyLogo: {
    width: "15px",
    height: "15px",
    marginRight: "10px",
  }
});

export default componentStyles;
