import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

// core components
import componentStyles from "assets/theme/components/card-mini-market-performance.js";
import boxShadows from "assets/theme/box-shadow.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

function CardMiniMarketPerformance({ direction, title, change}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'General' });

  return (
    <>
      <Card classes={{ root: classes["cardRoot"+ direction] }} elevation={0}>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <Grid container component={Box} justifyContent="space-between">
            <Grid item xs="auto">
              <Box
                component={Typography}
                variant="h4"
                color="#ffffff!important"
                marginBottom="0!important"
                marginTop="0!important"
                className={classes.textMarketName}
              >
                {title}
              </Box>
            </Grid>
            <Grid item xs={"auto"}>
              
            </Grid>
          </Grid>
            <Box
              component="p"
              fontSize={theme.typography.h5.fontSize}
              marginTop="1rem"
              marginBottom="0"
              display="flex"
              alignItems="center"
              flexWrap="wrap"
            >
                  <>
                  {change<0 ?
                    <Box
                      component="span"
                      fontSize={theme.typography.h4.fontSize}
                      fontWeight="600"
                      className={classes.textMarketValue}
                      marginRight=".5rem"
                      display="flex"
                      alignItems="center"
                    >
                      <Box
                        component={ArrowDropDown}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        marginLeft="-.25rem"
                      />
                      {" "}
                      {change}%
                    </Box>
                    :
                    <Box
                      component="span"
                      fontSize={theme.typography.h4.fontSize}
                      fontWeight="600"
                      className={classes.textMarketValue}
                      marginRight=".5rem"
                      display="flex"
                      alignItems="center"
                    >
                      <Box
                        component={ArrowDropUp}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        marginLeft="-.25rem"
                      />
                      {" "}
                      {change}%
                    </Box>
                    }
                    <Box component="span" whiteSpace="nowrap" className={classes.textMarketValue}>
                      {t("Today")}
                    </Box>
                  </>
            </Box>
        </CardContent>
      </Card>
    </>
  );
}

CardMiniMarketPerformance.defaultProps = {
  color: "bgPrimaryLight",
};

CardMiniMarketPerformance.propTypes = {
  direction: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  change: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]),
  icon: PropTypes.oneOfType([
    // i.e. an icon name from Nucleo Icons - e.g. ni ni-atom
    // // or an icon name from Font Awesome - e.g. fa fa-heart
    PropTypes.string,
    // i.e. a component from @material-ui/icons
    PropTypes.object,
  ]),
  color: PropTypes.oneOf([
    "bgPrimary",
    "bgPrimaryLight",
    "bgError",
    "bgErrorLight",
    "bgWarning",
    "bgWarningLight",
    "bgInfo",
    "bgInfoLight",
    "bgSuccess",
    "bgSuccessLight",
  ]),
};

export default CardMiniMarketPerformance;
