import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import getTheme from "assets/theme/theme.js";
import themeColors from "assets/theme/colors.js";

import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons components

// core components

import componentStyles from "assets/theme/components/cards/cards/card-article.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import buttonsStyles from "assets/theme/components/button.js";

const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);
const useStylesButtons = makeStyles(buttonsStyles);

const SmallArticleBlogButton = {
  backgroundColor: themeColors.bsColors.LogoLightBlue,
  marginTop:"0.5rem", 
  bottom:"20px", 
  position:"absolute",
  backgroundColor: themeColors.white.main,
  borderColor: themeColors.bsColors.LogoLightBlue,
  color: themeColors.bsColors.LogoLightBlue,
  "&:hover": {
    backgroundColor: themeColors.bsColors.LogoLightBlue,
    borderColor: themeColors.bsColors.LogoLightBlue,
    color: themeColors.white.main,
  },
};

function NormalArticlePreview({ date, image, title, description, source, type, url }) {
  const classes = { ...useStyles(), ...useStylesCardImg(), ...useStylesButtons() };
  const theme = getTheme("light");

  function handleClick(){
    window.open(window.location.href + "/" + url);
  }
  return (
    <>
      <Card className={classes.cardRoot}>
        <img
          alt="..."
          src={image}
          className={classes.cardImgTopArticle}
        />
        <CardContent>
          
            <Box>
                  {type == "eToro" ?
                  <>
                    <img style={{borderRadius:"20px", marginRight:"0.2rem"}} width="30px" src={require("assets/img/brand/eToro-icon-stretch.png").default}/>
                    <b style={{color:themeColors.bsColors.MainBlue}}>eToro Help</b>
                  </>
                  :
                  <>
                    <img style={{borderRadius:"10px", marginRight:"0.2rem"}} width="30px" src={require("assets/img/brand/bs-icon-stretch.png").default}/>
                    <b style={{color:themeColors.bsColors.MainBlue}}>Bullsheet Help</b>
                  </>
                }
              </Box>
          <Box
            component="p"
            fontSize={theme.typography.h5.fontSize}
            fontWeight="400"
            color={themeColors.gray[600]}
          >
            {source} - {date}
          </Box>
          <Box variant="h3" component="h2" marginBottom="0!important">
            {title?.length > 62 ? 
              title.substring(0,60)+"..."
            :
              title
            }
          </Box>
          <Box
            component="h3"
            marginBottom="3.5rem"
            fontWeight="300"
            lineHeight="1.7"
            fontSize={theme.typography.h4.fontSize}
            marginTop="1.2rem"
          >
            {description?.length > 180 ? 
              description.substring(0,180)+"..."
            :
              description
            }
          </Box>
          <Box>
          <Link to={`/auth/resources/${url}`}>
          <Button
            style={SmallArticleBlogButton}
            >
              Read Article
          </Button>
          </Link>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default NormalArticlePreview;
