import React, { useEffect, useRef } from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-etf-holdings.js";
import { useTranslation } from "react-i18next";
import { Button, CardContent } from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import bsIconStretch from "assets/img/brand/bs-icon-stretch.png";
import { useDarkMode } from "contexts/DarkModeContext";

const Styler = styled.text`
  .apexcharts-datalabel {
    color: red !important;
    font-weight: 500 !important;
  }
`;
const useStyles = makeStyles(componentStyles);

const state = {
  series: [{ data: {} }],
  options: {
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return (
          '<span style="padding: 7px;color:#777;">' +
          data.x +
          ": $<b>" +
          moneyFormat(data.y) +
          "</b></span>"
        );
      },
    },
    chart: {
      id: 333332,
      height: 350,
      type: "treemap",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        colors: ["#555", "#777", "#666", "#555", "#444", "#332"],
      },
      formatter: function (ticker, opt) {
        return opt.value > 0.5 && opt.dataPointIndex < 0
          ? ticker + ": " + moneyFormat(opt.value)
          : ticker;
      },
      offsetY: -2,
    },
    colors: ["#4d86c5", "#6930c3", "#5e60ce", "#5390d9", "#4ea8de", "#48bfe3"],
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.5,
        reverseNegativeShade: true,
      },
    },
  },
};

function moneyFormat(value) {
  if (value > 1000000000 || value < -1000000000) {
    return (value / 1000000000).toFixed(2) + "B";
  } else if (value > 1000000 || value < -1000000) {
    return (value / 1000000).toFixed(2) + "M";
  } else if (value > 1000 || value < -1000) {
    return (value / 1000).toFixed(2) + "K";
  } else {
    return value;
  }
}

export default function CardTreeMap({ stockData }) {
  const classes = useStyles();
  const theme = useTheme();
  const treemapRef = useRef();
  const { t, i18n } = useTranslation("translation", { keyPrefix: "StockDetails" });
  const defaultTab = "revenueTTM";
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);
  const { activeTheme } = useDarkMode();
  const data = stockData.peers
    ? stockData.peers.slice(0, 9).map((a) => ({
        x: a.ticker,
        y: a[selectedTab],
        fillColor:
          a.ticker === stockData.ticker ? (activeTheme === "light" ? "#EC449888" : "#fff") : "",
      }))
    : [];
  if (!data.map((entry) => entry.x).includes(stockData.ticker)) {
    const stock = stockData.peers.find((entry) => entry.ticker === stockData.ticker)[selectedTab];
    data.push({
      x: stockData.ticker,
      y: stock ? stock : 0,
      fillColor: activeTheme === "light" ? "#EC449888" : "white",
    });
  }
  const series = [{ data: data }];
  const [chartData, setChartData] = React.useState();
  const [updates, setUpdates] = React.useState(0);

  const Stylerx = styled.rect`
    .apexcharts-treemap-rect {
      //fill: url("#img1");
    }
  `;

  useEffect(() => {
    setChartData((prev) => {
      return {
        ...prev,
        series: series,
        options: {
          ...state.options,
          chart: {
            id: 333333,
            height: 350,
            type: "treemap",
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "12px",
              colors: ["#555", "#777", "#666", "#555", "#444", "#331"],
            },
            formatter: function (ticker, opt) {
              return opt.value > 0.5 && opt.dataPointIndex < 0
                ? ticker + ": " + moneyFormat(opt.value)
                : ticker;
            },
            offsetY: -2,
          },
        },
      };
    });
  }, [stockData, selectedTab]);

  const parentDOM = document.getElementById("apexcharts333333");
  if (parentDOM) {
    const rects = parentDOM.getElementsByClassName("apexcharts-treemap-rect");
    rects[0].fill = "#FF0000";
    if (updates < 3) {
      setUpdates((prev) => prev + 1);
    }
  }

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid container component={Box} alignItems="center" justifyContent="space-between">
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("industry")}
                  </Box>
                </Box>
                <Box component={Typography} variant="h3" marginBottom="0!important">
                  <Box component="span">{t("industryComparison")}</Box>
                  <FeatureInfoWithBox description={t("generalInfoTooltip")} />
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  size="small"
                  classes={
                    selectedTab === "marketCap"
                      ? {
                          root: classes.buttonRootSelected,
                        }
                      : {
                          root: classes.buttonRoot,
                        }
                  }
                  onClick={() => setSelectedTab("marketCap")}
                >
                  {t("marketCap")}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  classes={
                    selectedTab === "revenueTTM"
                      ? {
                          root: classes.buttonRootSelected,
                        }
                      : {
                          root: classes.buttonRoot,
                        }
                  }
                  onClick={() => setSelectedTab("revenueTTM")}
                >
                  {t("revenue")}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  classes={
                    selectedTab === "ebitda"
                      ? {
                          root: classes.buttonRootSelected,
                        }
                      : {
                          root: classes.buttonRoot,
                        }
                  }
                  onClick={() => setSelectedTab("ebitda")}
                >
                  {t("ebitda")}
                </Button>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent style={{ marginTop: "-10px", padding: "10px" }}>
          {chartData && (
            <Styler>
              <Stylerx>
                <ReactApexChart
                  ref={treemapRef}
                  options={chartData.options}
                  series={chartData.series}
                  type="treemap"
                  height={330}
                  style={{ padding: "-40px" }}
                />
              </Stylerx>
            </Styler>
          )}
        </CardContent>
      </Card>
    </>
  );
}
