import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
//import Stack from '@material-ui/core/Stack';
import { Box, Button, Checkbox, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "assets/theme/views/admin/screener.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import themeColors from "assets/theme/colors.js";
import styled from "styled-components";
import bsIconStretch from "assets/img/brand/bs-icon-stretch.png";
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from "@material-ui/core/Chip";
import { useService } from "contexts/ServiceContext";

const AutoStylingWrapper = styled.div`
  .MuiInputBase-input {
    background: fixed;
    color: ${props => props.theme.palette.bsColors.MainBlue};
  };
  .MuiSvgIcon-root {
    fill: black;
    width: 14px;
    height: 14px;
    display: inline-block;
    font-size: 1.7142857142857142rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    fill: ${props => props.theme.palette.bsColors.MainBlue};
  }
  .MuiFormLabel-root {
    color: ${props => props.theme.palette.gray[600]};
    display: inline-block;
    fill: black;
    font-size: .875rem;
    font-family: Open Sans,sans-serif;
    font-weight: 600;
    line-height: 0.3;
    margin-bottom: 2rem;
  };
  .MuiInputLabel-outlined {
    z-index: 1;
    fill: black;
    transform: translate(14px, 18px) scale(1);
    pointer-events: none;
    margin-bottom: 2rem;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    fill: black;
    transform: translate(14px, -6px) scale(0.75);
    margin-bottom: 2rem;
  }
`;
const AutoStylingWrapperNumOrPct = styled.div`
.MuiInputBase-input {
  background: fixed;
}
`;

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

const SubScreener = ({backendFilter, label, short, inputType, options, handleActiveFilters, selectedFilter}) => {
    const classes = { ...useStyles(), ...useStylesCardDeck(), ...useStylesButtons() };
    const { countryCodes } = useService();
    const fromObj = backendFilter[selectedFilter] && backendFilter[selectedFilter][short] ? backendFilter[selectedFilter][short] : null;
    const toObj = backendFilter[selectedFilter] && backendFilter[selectedFilter][short] ? backendFilter[selectedFilter][short] : null ;
    const theme = useTheme();
    function handleKeypress(e) {
      //it triggers by pressing the enter key
      if (e.charCode === 13) {
        // TODO: Send filter to backend on enter press?
      }
    };

    function countryMapper(country) {
      return {logo: country.logo, ticker: countryCodes[country.ticker]};
    }

    function handleSearchInputChange(e, input) {
      if (short === "country") {
        input = input.map(countryMapper);
      }
      e.preventDefault();
      handleActiveFilters(input, short, inputType);
    }

    function handleSearchInputChangeNumOrPct(e, input, type) {
      e.preventDefault();
      handleActiveFilters({[type]: input ? input.value : "", [type + "Type"]: input ? input.type : ""}, short, inputType);
    }

    React.useEffect(() => {
    }, [])


    return (
        <Box 
            //display="flex"
            //justifyContent="center"
            //alignItems="center"
            //width="25rem"
            marginBottom="2rem"
            //className={classes.searchBox}
            >
            { ["multiDual"].includes(inputType) ?
            <Grid container>
            <Grid item xs={10} sm={10} md={5} lg={5}>
              <AutoStylingWrapper theme={theme}>
                    <Autocomplete
                      id="tags-outlined"
                      autoSelect
                      options={options.from}
                      getOptionLabel={(option) => option && option.value ? option.value + option?.type  : ""}
                      filterSelectedOptions
                      defaultValue={fromObj ? {value: fromObj.from, type: fromObj.fromType} : null}
                      onChange={(event, option) => handleSearchInputChangeNumOrPct(event, option, "from")}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, key) => (
                          <Box key={key} marginRight={"10px"}>
                            <Box style={{color: "#1b1b4f"}}>{option.value}{option.type}</Box>
                          </Box>
                        ));
                      }}
                      renderOption={option => {
                        return (
                          <Box style={{color: "#1b1b4f"}}>{option.prefix}{option.value}{option.type}</Box>
                          );
                      }}
                      renderInput={(params) => (
                        <>
                        {label}
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          name='password' autoComplete='off' type='text'
                          variant="outlined"
                          //label={label}
                          decoration={"oi"}
                          placeholder=""
                          inputProps={{
                            ...params.inputProps,
                            name: 'someExoticNameSoThatGoogleDoesntTryToAutofillThisSheet3',
                            autoComplete: 'new-password',
                          }}
                        />
                        </>
                      )}
                    />
                </AutoStylingWrapper>
              </Grid>
              <br/>to
              <Grid item xs={10} sm={10} md={5} lg={5}>
                <AutoStylingWrapper theme={theme}>
                  <Autocomplete
                    id="tags-outlined"
                    options={options.to}
                    getOptionLabel={(option) => option && option.value ? option.value + option?.type  : ""}
                    filterSelectedOptions
                    defaultValue={toObj ? {value: toObj.to, type: toObj.toType} : null}
                    //defaultValue={backendFilter[selectedFilter] && backendFilter[selectedFilter][short] ? backendFilter[selectedFilter][short]["to"] : ""}
                    onChange={(event, option) => handleSearchInputChangeNumOrPct(event, option, "to")}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, key) => (
                        <Box key={key} marginRight={"10px"}>
                          <Box style={{color: "#1b1b4f"}} key={key}>
                                <img style={{width: "25px", marginRight: "5px", borderRadius: "15px"}} src={option.logo}/> 
                                {option.value}{option.type}
                          </Box>
                        </Box>
                      ));
                    }}
                    renderOption={option => {
                      return (
                        <Box style={{color: "#1b1b4f"}}>{option.prefix}{option.value}{option.type}</Box>
                        );
                    }}
                    renderInput={(params) => (
                      <><br/>
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        name='password' autoComplete='off' type='text'
                        variant="outlined"
                        label={""}
                        decoration={"oi"}
                        placeholder=""
                        inputProps={{
                          ...params.inputProps,
                          name: 'someExoticNameSoThatGoogleDoesntTryToAutofillThisSheet3',
                          autoComplete: 'new-password',
                        }}
                      />
                      </>
                    )}
                  />
                </AutoStylingWrapper>
              </Grid>
            </Grid>
            : 
            <AutoStylingWrapper theme={theme}>
              <Autocomplete
                multiple
                freeSolo
                id="tags-outlined"
                options={options}
                getOptionLabel={(option) => option?.ticker}
                defaultValue={backendFilter[selectedFilter] && backendFilter[selectedFilter][short] ? backendFilter[selectedFilter][short].map(a => a) : []}
                filterSelectedOptions
                onChange={(event, option) => handleSearchInputChange(event, option)}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, key) => (
                    <Box key={key} marginRight={"10px"}>
                      <Fragment key={key}>
                        <Box style={{color: theme.palette.bsColors.MainBlue}}>
                              {option?.logo ? 
                                <img style={{width: "20px", marginRight: "5px", borderRadius: "15px"}} src={option.logo}/> : null}
                              {option?.ticker}
                        </Box>
                      </Fragment>
                    </Box>
                  ));
                }}
                renderOption={option => {
                  return (
                      <Fragment>
                        <Box style={{color: "#1b1b4f"}}>
                        {option?.logo ? 
                              <img style={{width: "20px", marginRight: "10px", borderRadius: "15px"}} src={option.logo}/> : null}
                              {option.ticker} {short === "industry" ? " (" + option.count + ") " : null} 
                        </Box>
                      </Fragment>
                    );
                }}
                renderInput={(params) => (
                  <>
                  {label}
                  <TextField
                    {...params}
                    name='password' autoComplete='off' type='text'
                    variant="outlined"
                    //label={label}
                    decoration={"oi"}
                    placeholder=""
                    inputProps={{
                      ...params.inputProps,
                      name: 'someExoticNameSoThatGoogleDoesntTryToAutofillThisSheet3',
                      autoComplete: 'new-password',
                    }}
                  />
                  </>
                )}
              />
            </AutoStylingWrapper>}
        </Box>
  );
};

export default SubScreener;
