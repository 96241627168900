import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRootNormal: {
    //boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    maxWidth: "20rem",
    maxHeight: "12rem",
    backgroundImage: "linear-gradient(135deg, #4e86c5 0%, #1b1b4f 75%)",
    cursor: "pointer",
  },
  cardRootLight: {
    //boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    maxWidth: "20rem",
    maxHeight: "12rem",
    backgroundImage: "linear-gradient(135deg, #4e86c5 0%, #87A6CA 75%)",
    cursor: "pointer",
  },
  cardRootGrey: {
    //boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
    maxWidth: "20rem",
    maxHeight: "12rem",
    backgroundImage: "linear-gradient(135deg, #d1d1d1 0%, #c4c4c4 75%)",
    cursor: "not-allowed",
  },
  listItemRoot: {
    padding: "1.5rem!important",
    borderBottom: "1px solid " + theme.palette.gray[200],
  },
  companyLogo: {
    width: "15px",
    height: "15px",
    marginRight: "10px",
  },
  titleTypography: {
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.h2.fontSize,
    },
  }
});

export default componentStyles;
