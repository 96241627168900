import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "components/Auth/PrivateRoute";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import getTheme from "assets/theme/theme.js";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/plugins/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";

import AuthLayout from "layouts/Auth.js";
import Index from "views/Index.js";
import { AuthProvider } from "contexts/AuthContext";
import { EtoroUserProvider } from "contexts/EtoroUserContext";
import { ServiceProvider } from "contexts/ServiceContext";
import { DarkModeProvider } from "contexts/DarkModeContext";

import TagManager from 'react-gtm-module'
import CookieConsent from "react-cookie-consent";
import { useDarkMode } from "contexts/DarkModeContext";

const tagManagerArgs = {
  gtmId: 'GTM-5KTSV2M'
}

export default function App() {

    return (
    <DarkModeProvider>
        <ThemedApp></ThemedApp>
    </DarkModeProvider>
    );
}

const Maintenance = lazy(() => import('views/Maintenance.js'));

function ThemedApp() { 
    const [analyticsEnabled, setAnalyticsEnabled] = React.useState(true);
    const { activeTheme, toggleTheme } = useDarkMode();

    if (analyticsEnabled) {
        TagManager.initialize(tagManagerArgs)
    }

    return(
    <ThemeProvider theme={getTheme(activeTheme, toggleTheme)}>
        <CookieConsent
        enableDeclineButton
        onDecline={() => {
            window.location.href = "/auth/privacy-policy";

        }}
        location="bottom"
        buttonText="Accept"
        cookieName="bsCookie"
        style={{ background: "#1b1b4f", zIndex: "1201" }}
        buttonStyle={{ color: "white", background: "#74B82F", borderRadius: "5px", marginRight: "1.5rem"}}
        declineButtonStyle={{ borderRadius: "5px", background: "#1b1b4f", border: "1px solid white" }}
        declineButtonText="Manage"
        expires={150}
        >
        This website uses cookies to feed the bulls and enhance the user experience.{" "}
        </CookieConsent>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <BrowserRouter>
        <AuthProvider>
            <ServiceProvider>
            <EtoroUserProvider>
                <Switch>
                    <Route exact path="/" render={(props) => <Index {...props} />} />
                    <PrivateRoute path="/app" />
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Route exact path="/maintenance" 
                        render={(props) => 
                            <Suspense fallback={<></>}>
                                <Maintenance {...props} />
                            </Suspense>} />
                    <Redirect from="*" to="/app/dashboard" />
                </Switch>
            </EtoroUserProvider>
            </ServiceProvider>
        </AuthProvider>
        </BrowserRouter>
    </ThemeProvider>
    )
}
