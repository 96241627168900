import React, { useEffect } from "react";
// javascipt plugin for creating charts
// react plugin used to create charts
import { HorizontalBar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";

// @material-ui/icons components

// core components
import { chartETFSectorBreakdownHorizontal, chartETFSectorBreakdownHorizontalDark } from "variables/charts.js";

import componentStylesButtons from "assets/theme/components/button.js";
import componentStyles from "assets/theme/components/cards/charts/card-etf-sectors.js";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "contexts/DarkModeContext";

const useStylesButtons = makeStyles(componentStylesButtons);
const useStyles = makeStyles(componentStyles);

function CardETFSectors({sectorWeights}) {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = useTheme();
  const { activeTheme } = useDarkMode();
  const [options, setOptions] = React.useState(chartETFSectorBreakdownHorizontal.options);
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'ETFDetails' });
  const sectorsWithColors = sectorWeights
  const sectorColors = [theme.palette.bsColors.Pie0, theme.palette.bsColors.Pie1, theme.palette.bsColors.Pie2, theme.palette.bsColors.Pie3, theme.palette.bsColors.Pie4, theme.palette.bsColors.Pie5, 
    theme.palette.bsColors.Pie6, theme.palette.bsColors.Pie7, theme.palette.bsColors.Pie8, theme.palette.bsColors.Pie9, theme.palette.bsColors.Pie10, 
    theme.palette.bsColors.Pie11, theme.palette.bsColors.Pie12, "#fde2e4", "#fad2e1", "#c5dedd",
  ];
  sectorsWithColors.datasets[0]["backgroundColor"] = [];
  sectorsWithColors.datasets[0]["backgroundColor"].push.apply(sectorsWithColors.datasets[0]["backgroundColor"],sectorColors);
  useEffect(() => {
    if (sectorWeights) {
      sectorsWithColors.datasets[0]["backgroundColor"] = [];
      sectorsWithColors.datasets[0]["backgroundColor"].push.apply(sectorsWithColors.datasets[0]["backgroundColor"],sectorColors);
      setOptions(activeTheme === "light" ? chartETFSectorBreakdownHorizontal.options : chartETFSectorBreakdownHorizontalDark.options);
    }
  }, [activeTheme])  
  return (
    <>
      {alert}
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("etfDiversification")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("sectorBreakdown")}</Box>
                  <FeatureInfoWithBox description={t("etfSectorBreakdownTooltip")}/>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          <Box position="relative" height="350px">
            <HorizontalBar
              data={sectorsWithColors}
              options={options}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardETFSectors;
