import boxShadows from "assets/theme/box-shadow.js";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  avatarRoot: {
    width: "30px",
    height: "30px",
  },
  searchBox: {
    borderColor: theme.palette.bsColors.MainBlue,
    borderRadius: "2rem",
    height: "2.5rem",
    width: "200px",
    border: "0px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.bsColors.MainBlue) + ",0.9)",
    //backgroundColor: "#F8F9FE",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    color: theme.palette.gray[400],
  },
  etoroBox: {
    borderColor: theme.palette.bsColors.NumberGreen,
    borderRadius: "2rem",
    height: "2.5rem",
    width: "6rem",
    border: "0px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.bsColors.NumberGreen) + ",0.9)",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    color: theme.palette.gray[400],
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchIcon: {
    color: theme.palette.gray[400],
    marginRight: "0.5rem",
    marginLeft: "1rem",
  },
  searchClose: {
    color: theme.palette.gray[400],
    marginRight: "1rem",
    marginLeft: "-.5rem",
  },
  searchInput: {
    color: theme.palette.gray[200],
    width: "9rem",
    backgroundColor: "initial",
    border: 0,
    boxShadow: "none",
    padding: "0",
    marginLeft: "1.5rem",
    height: "2.5rem"
  },
  searchInputButton: {
    border: 0, 
    borderRadius: "2rem", 
    height: "2.5rem",
    width: "2rem",
    backgroundPosition: "center", 
    backgroundSize: "cover",
    marginRight: "0rem",
    marginLeft: "0.5rem",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80px", 
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
    },
    "&:hover": { 
      transform: "scale(1.05)"
    },
  },
  searchInputButtonDisabled: {
    border: 0, 
    borderRadius: "2rem", 
    height: "2.5rem",
    width: "2rem",
    backgroundPosition: "center", 
    backgroundSize: "cover",
    marginRight: "0rem",
    marginLeft: "0.5rem",
    cursor: "pointer",
    backgroundSize: "70px", 
    backgroundRepeat: "no-repeat",
    backgroundImage: require("assets/img/brand/bs-icon-stretch.png").default,
    backgroundColor: "#989898 !important",
    backgroundBlendMode: "color-burn",

    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
    },
  },
  flexColumnFlexXlRow: {
    flexDirection: "column!important",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row!important",
    },
  },
  checkIcon:{
    width:"30px",
    height:"auto",
    color:theme.palette.bsColors.NumberGreen,
    verticalAlign: "text-bottom",
  },
  expandIcon:{
    width:"30px",
    height:"auto",
    color:theme.palette.bsColors.MainBlue,
    verticalAlign: "text-bottom",
  },
  filterButton: {
    marginLeft: "0px",
    marginRight: "10px !important",
    width: "150px",
    height: "50px",
    color: theme.palette.bsColors.MainBlue,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.bsColors.GlobalWhite,
    borderWidth: "0.5px",
    fontSize: theme.typography.h5.fontSize,
    "&:hover": {
      backgroundColor: theme.palette.gray[100],
      borderColor: theme.palette.gray[100],
      color: theme.palette.bsColors.MainBlue,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      marginRight: "0px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "5px",
      marginTop: "10px",
    },
  },
  filterButtonSelected: {
    marginLeft: "0px",
    marginRight: "10px !important",
    width: "150px",
    height: "50px",
    color: theme.palette.white.main,
    backgroundColor: theme.palette.bsColors.MainBlue,
    borderColor: theme.palette.bsColors.MainBlue,
    fontSize: theme.typography.h5.fontSize,
    "&:hover": {
      backgroundColor: theme.palette.dark.main,
      borderColor: theme.palette.dark.main,
      color: theme.palette.white.main,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      marginRight: "0px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "5px",
      marginTop: "10px",
    },
  },
  clearButton: {
    marginLeft: "0px",
    marginRight: "10px !important",
    width: "140px",
    height: "40px",
    verticalAlign: "bottom",
    backgroundColor: theme.palette.bsColors.NumberRed,
    borderColor: theme.palette.gray[100],
    color: theme.palette.gray[100],
    fontSize: theme.typography.h5.fontSize,
    "&:hover": {
      color: theme.palette.bsColors.NumberRed,
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.bsColors.NumberRed,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      marginRight: "0px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "5px",
      marginTop: "10px",
    },
  },
  boxButtonsHeader:{
    justifyContent:"flex-start",
    display:"flex",
    flexWrap:"wrap",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0.8rem",
      justifyContent:"flex-start",
    },
  },
  buttonRoot: {
    marginLeft: "0px",
    marginRight: "10px",
    color: theme.palette.bsColors.MainBlue,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    fontSize: theme.typography.h5.fontSize,
    "&:hover": {
      backgroundColor: theme.palette.gray[100],
      borderColor: theme.palette.gray[100],
      color: theme.palette.dark.main,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      marginRight: "0px",
    },
  },
  buttonRootSelected: {
    marginLeft: "0px",
    marginRight: "10px",
    color: theme.palette.white.main,
    backgroundColor: theme.palette.bsColors.MainBlue,
    borderColor: theme.palette.bsColors.MainBlue,
    fontSize: theme.typography.h5.fontSize,
    "&:hover": {
      backgroundColor: theme.palette.dark.main,
      borderColor: theme.palette.dark.main,
      color: theme.palette.white.main,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      marginRight: "0px",
    },
  },
});

export default componentStyles;
