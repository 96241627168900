import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  header: {
    background:
      "linear-gradient(117deg," + themeColors.background.default + ",#F1f1f1)",
    [theme.breakpoints.up("md")]: {
      paddingTop: "10rem",
    },
  },
});

export default componentStyles;
