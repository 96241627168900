import themeColors from "assets/theme/colors.js";

const componentStyles = (theme) => ({
  bgDefault: {
    backgroundColor: themeColors.bsColors.MainBlue,
  },
  svgLoader: {
        animation: "spin 1s linear infinite",
        margin: "auto"
  },
  divLoader: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

export default componentStyles;
