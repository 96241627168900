import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import bsIconStretch from "assets/img/brand/bs-icon-stretch.png";
import Helmet from "react-helmet";
import CircleLoader from "react-spinners/CircleLoader";

// @material-ui/lab components
// @material-ui/icons components
import GraphicEq from "@material-ui/icons/GraphicEq";
import SearchIcon from "@material-ui/icons/Search";

// core components
import { IconButton, TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Globe from "@material-ui/icons/Public";
import Autocomplete from "@material-ui/lab/Autocomplete";
import themeColors from "assets/theme/colors.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import componentStyles from "assets/theme/views/admin/etf-screener.js";
import CardCompanyInfo from "components/Cards/Sortable/CardCompanyInfo";
import CardPriceHeatMap from "components/Cards/Sortable/CardPriceHeatmap";
import CardIndustryComparison from "components/Cards/Sortable/CardIndustryComparison";
import CardStockPrice from "components/Cards/Sortable/CardStockPrice";
import CardTreeMap from "components/Cards/Sortable/CardTreeMap";
import CardQuickInfo from "components/Cards/Widgets/CardQuickInfo";
import CardSimpleRectangle from "components/Cards/Widgets/CardSimpleRectangle";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import { useAuth } from "contexts/AuthContext";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FeatETFDetails from "views/auth/Features/FeatETFDetails";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { useService } from "../../../contexts/ServiceContext";
import CardStockInsiderTrades from "components/Cards/Sortable/CardStockInsiderTrades";
import CardRatiosOverTime from "components/Cards/Sortable/CardRatiosOverTime";
import CardNews from "components/Cards/Cards/CardNews";
import CardNewsSmall from "components/Cards/Cards/CardNewsSmall";

const AutoStylingWrapper = styled.div`
  .MuiInputBase-input {
    background: fixed;
    //background-clip: padding-box;
    border-radius: 0.375rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${(props) => props.theme.palette.white.main};
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    font-size: 0.875rem;
    box-sizing: border-box;
  }
  .MuiSvgIcon-root {
    fill: white;
    width: 14px;
    height: 14px;
    display: inline-block;
    font-size: 1.7142857142857142rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.palette.inputText.main};
    display: inline-block;
    padding: 0;
    font-size: 0.875rem;
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    line-height: 0.3;
    margin-bottom: 1rem;
  }
  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 18px) scale(1);
    pointer-events: none;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

const StockDetails = () => {
  const classes = { ...useStyles(), ...useStylesCardDeck(), ...useStylesButtons() };
  const { getAllStocks, getStock, getNewsForInstrument } = useService();
  const { notify } = useEtoroUser();
  const { currentUser } = useAuth();
  const theme = useTheme();
  const stockRef = React.useRef();
  const [searchButtonEnabled, setSearchButtonEnabled] = React.useState(false);
  const [stocks, setStocks] = React.useState();
  const [fetchedStock, setFetchedStock] = React.useState();
  const [loadingStock, setLoadingStock] = React.useState(false);
  const [updatedNews, setUpdatedNews] = React.useState();
  const [loadingNews, setLoadingNews] = React.useState(false);
  const { t, i18n } = useTranslation("translation");

  function handleSubmit(e) {
    e.preventDefault();
    const instrument = stocks.find((stock) => stock.ticker === stockRef?.current?.value);
    if (instrument) {
      setLoadingStock(true);
      getStock(instrument.instrumentId)
        .then((result) => {
          setFetchedStock(result.data);
          //getNewsForStock(result.data);
          setLoadingStock(false);
        })
        .catch((err) => {
          console.log(err, "errerrerr");
          notify("stock_error");
        });
    }
  }

  function handleKeypress(e, ticker) {
    //it triggers by pressing the enter key
    if (e.charCode === 13 && ticker) {
      handleSubmit(e);
    }
  }

  function handleSearchInput(e, ticker) {
    e.preventDefault();
    if (ticker || stockRef?.current?.value) {
      setSearchButtonEnabled(true);
    } else {
      setSearchButtonEnabled(false);
    }
  }
  function handleSuggestionClick(e, ticker) {
    e.preventDefault();
    if (ticker && stocks) {
      setLoadingStock(true);
      stockRef.current.value = ticker;
      setSearchButtonEnabled(true);
      handleSubmit(e);
    }
  }
  function handleEtoroSearch(e, ticker) {
    e.preventDefault();
    const url = "https://www.etoro.com/markets/";
    window.open(url + ticker, "_blank");
  }

  React.useEffect(() => {
    if (fetchedStock) {
      const instrumentsToSend = {
        instrumentId: fetchedStock.instrumentId,
        ticker: fetchedStock.ticker,
      };
      setLoadingNews(true);
      getNewsForInstrument(instrumentsToSend)
        .then((response) => {
          if (response) {
            setUpdatedNews(response.data);
          }
          setLoadingNews(false);
        })
        .catch(() => {
          setLoadingNews(false);
        });
      return () => {
        setUpdatedNews({});
      };
    }
  }, [fetchedStock]);

  React.useEffect(() => {
    if (currentUser.subscription === "premium") {
      getAllStocks().then((result) => {
        setStocks(result.data);
      });
    }
  }, []);

  return (
    <>
      <AlternativeHeader section={t("StockDetails.header")} subsection="Portfolio" />
      {/* Page content */}
      <Helmet>
        <title>Stock Details | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        {
          /* TODO uncomment when feature is live!*/ currentUser.subscription !== "freeTier" ? (
            <>
              <Grid container>
                <Grid item xs={8} lg={6}>
                  <Box
                    display="flex"
                    //justifyContent="center"
                    alignItems="center"
                    width="15rem"
                    marginBottom="2rem"
                    className={classes.searchBox}
                  >
                    <AutoStylingWrapper theme={theme}>
                      <Autocomplete
                        id="combo-box-demo"
                        disabled={!stocks}
                        options={stocks}
                        filterOptions={(x, state) =>
                          state.inputValue.length > 0 &&
                          x &&
                          x.filter((entry) =>
                            [
                              entry.ticker.slice(0, state.inputValue.length),
                              entry.name.slice(0, state.inputValue.length).toUpperCase(),
                            ].includes(state.inputValue.toUpperCase())
                          )
                        }
                        getOptionLabel={(option) => option.ticker}
                        onChange={(event, value) => handleSearchInput(event, value)}
                        renderOption={(option) => {
                          return (
                            <Fragment>
                              <img
                                style={{ width: "25px", marginRight: "10px", borderRadius: "15px" }}
                                src={option.logo}
                              />
                              <Box style={{ color: "#1b1b4f" }}>{option.ticker}</Box>
                            </Fragment>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputRef={stockRef}
                            label={t("StockDetails.searchStock")}
                            variant="outlined"
                            style={{ border: "none" }}
                          />
                        )}
                      />
                    </AutoStylingWrapper>
                    <Button
                      disabled={!searchButtonEnabled || !stocks}
                      style={{
                        backgroundColor: "#1b1b4f",
                        backgroundImage: `url(${bsIconStretch})`,
                        cursor: "pointer",
                      }}
                      onClick={handleSubmit}
                      className={
                        searchButtonEnabled
                          ? classes.searchInputButton
                          : classes.searchInputButtonDisabled
                      }
                    ></Button>
                  </Box>
                </Grid>
              </Grid>
              {fetchedStock && !loadingStock ? (
                <>
                  <Box display="flex" marginBottom={"0.5rem"} justifyContent="space-between">
                    <Box fontSize="0.875rem">
                      {fetchedStock.sector + " / " + fetchedStock.industry}
                    </Box>
                    <Box fontSize="0.875rem">
                      {fetchedStock.exchange +
                        (fetchedStock.ipoDate ? ", IPO: " + fetchedStock.ipoDate : "")}
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent={"space-between"}>
                    <Box display="flex">
                      <Box
                        component={fetchedStock ? Avatar : Globe}
                        classes={{ root: classes.avatarRoot }}
                        marginRight="0.875rem"
                        alt="..."
                        src={
                          fetchedStock
                            ? stocks.find((stock) => stock.ticker === fetchedStock.ticker)?.logo
                            : ""
                        }
                      />
                      <Typography variant="h3" style={{ marginBottom: "1.5rem" }}>
                        {fetchedStock
                          ? fetchedStock.name + " (" + fetchedStock.ticker + ")"
                          : t("StockDetails.selectStock")}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="auto"
                      marginBottom="1rem"
                      className={classes.etoroBox}
                    >
                      <IconButton
                        onClick={(event) => handleEtoroSearch(event, fetchedStock?.ticker)}
                      >
                        <Box
                          component={SearchIcon}
                          width="1.5rem!important"
                          height="1.5rem!important"
                          style={{ color: "white" }}
                        />
                        <Typography
                          variant="h6"
                          style={{
                            color: "#f1f1f1",
                            marginLeft: "0.2rem",
                            marginTop: "0.2rem",
                            paddingRight: "0.3rem",
                            textAlign: "left",
                            lineHeight: "0.8rem",
                          }}
                        >
                          Check <br />
                          on eToro
                        </Typography>
                      </IconButton>
                    </Box>
                  </Box>
                  <Grid container>
                    <Grid item xl={12} md={12} xs={12}>
                      <CardPriceHeatMap stockData={fetchedStock} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xl={6} md={6} xs={12}>
                      <CardStockPrice stockData={fetchedStock} />
                    </Grid>
                    <Grid item xl={6} md={6} xs={12}>
                      <CardRatiosOverTime stockData={fetchedStock} />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xl={5} md={6} xs={12}>
                      <CardTreeMap stockData={fetchedStock} />
                    </Grid>
                    {/* <Grid item xl={7} md={6} xs={12}>
                      <CardIndustryComparison stockData={fetchedStock} />
                    </Grid> */}
                    <Grid item xl={6} md={6} xs={12}>
                      <CardStockInsiderTrades stockData={fetchedStock} />
                      <Box
                        container
                        className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}
                      >
                        <CardQuickInfo
                          subtitle={t("ETFDetails.divYield")}
                          title={(fetchedStock.divYield * 100).toFixed(2) + "%"}
                          icon={GraphicEq}
                          color="bgGradientDefault"
                        />
                        <CardQuickInfo
                          subtitle={t("StockDetails.website")}
                          title={fetchedStock.website?.split("//")[1]}
                          icon={GraphicEq}
                          color="bgGradientDefault"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {/* <Grid container>
                    <Grid item xl={6} md={6} xs={12}>
                      <CardStockInsiderTrades stockData={fetchedStock} />
                    </Grid>
                    <Grid item xl={6} md={6} xs={12}>
                      <CardStockInsiderTrades stockData={fetchedStock} />
                    </Grid>
                  </Grid> */}

                  {/* <Box container className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
                    <CardQuickInfo
                      subtitle={t("ETFDetails.nrOfHoldings")}
                      title={fetchedStock.name}
                      icon={GraphicEq}
                      color="bgGradientDefault"
                    />
                    <CardQuickInfo
                      subtitle={t("ETFDetails.top10Holdings%")}
                      title={fetchedStock.name}
                      icon={GraphicEq}
                      color="bgGradientDefault"
                    />
                  </Box> */}
                  <Box container className={clsx(classes.cardDeck, classes.flexColumnFlexXlRow)}>
                    {updatedNews && updatedNews.length > 0 ? (
                      <Grid container>
                        {updatedNews.slice(0, 3).map((news, key) => (
                          <Grid item xs={12} md={4} xl={4} key={key}>
                            <CardNewsSmall
                              ticker={news.etoroTicker}
                              date={news.publishTime + " UTC"}
                              image={news.image}
                              title={news.title}
                              description={news.description}
                              source={news.source}
                              url={news.url}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Box>
                </>
              ) : (
                <Grid container style={{ marginTop: "1rem" }}>
                  {loadingStock ? (
                    <>
                      <CircleLoader
                        color={theme.palette.bsColors.LogoLightBlue}
                        loading={loadingStock}
                        size={30}
                        style={{ marginLeft: "50px" }}
                      />
                    </>
                  ) : (
                    <>
                      <Grid item xl={4} md={4} xs={6}>
                        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                          {t("StockDetails.usStocks")}
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "AAPL")}
                        >
                          <CardSimpleRectangle
                            ticker="AAPL"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1001/150x150.png"
                            title="Apple Inc"
                            color="bgGradientDefault"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "MSFT")}
                        >
                          <CardSimpleRectangle
                            ticker="MSFT"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1004/150x150.png"
                            title="Microsoft Corporation"
                            color="bgGradientWarning"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "AMZN")}
                        >
                          <CardSimpleRectangle
                            ticker="AMZN"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1005/150x150.png"
                            title="Amazon"
                            color="bgGradientSuccess"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "GOOG")}
                        >
                          <CardSimpleRectangle
                            ticker="GOOG"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1002/150x150.png"
                            title="Alphabet Inc Class C"
                            color="bgGradientError"
                          />
                        </Box>
                      </Grid>
                      <Grid item xl={4} md={4} xs={6}>
                        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                          {t("StockDetails.popularStocks")}
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "TSLA")}
                        >
                          <CardSimpleRectangle
                            ticker="TSLA"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1111/150x150.png"
                            title="Tesla Motors Inc"
                            color="bgGradientError"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "NIO")}
                        >
                          <CardSimpleRectangle
                            ticker="NIO"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/4489/150x150.png"
                            title="NIO Inc"
                            color="bgGradientSuccess"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "PLTR")}
                        >
                          <CardSimpleRectangle
                            ticker="PLTR"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/7991/150x150.png"
                            title="Palantir Technologies Inc"
                            color="bgGradientDefault"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "GME")}
                        >
                          <CardSimpleRectangle
                            ticker="GME"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1699/150x150.png"
                            title="Gamestop Corp."
                            color="bgGradientWarning"
                          />
                        </Box>
                      </Grid>
                      <Grid item xl={4} md={4} xs={6}>
                        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                          {t("StockDetails.internationalStocks")}
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "ULVR.L")}
                        >
                          <CardSimpleRectangle
                            ticker="ULVR.L"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/2093/150x150.png"
                            title="Unilever"
                            color="bgGradientError"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "VOW3.DE")}
                        >
                          <CardSimpleRectangle
                            ticker="VOW3.DE"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1210/150x150.png"
                            title="Volkswagen AG"
                            color="bgGradientSuccess"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "MC.PA")}
                        >
                          <CardSimpleRectangle
                            ticker="MC.PA"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1253/150x150.png"
                            title="LVMH Moet Hennessy L.V. SA"
                            color="bgGradientError"
                          />
                        </Box>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={(event) => handleSuggestionClick(event, "ROG.ZU")}
                        >
                          <CardSimpleRectangle
                            ticker="ROG.ZU"
                            imageUrl="https://etoro-cdn.etorostatic.com/market-avatars/1402/150x150.png"
                            title="Roche Holding AG"
                            color="bgGradientSuccess"
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </>
          ) : (
            <Box component="section" paddingTop="1.5rem">
              <Box textAlign="center" paddingBottom="1.5rem">
                <Typography
                  variant="h1"
                  component="h1"
                  className={classes.typographyH1Center}
                  style={{
                    backgroundImage:
                      "linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(126, 38%, 48%, 1) 75%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    MozBackgroundClip: "text",
                    MozTextFillColor: "transparent",
                  }}
                >
                  <b>{"Sponsor Beta"}</b>
                </Typography>
                <Box marginTop="2rem" textAlign="center">
                  <Button
                    component={Link}
                    variant="contained"
                    to="/auth/pricing"
                    classes={{ root: classes.buttonCTANormal }}
                  >
                    {t("PremiumContent.premiumCtA")}
                  </Button>
                </Box>
              </Box>
            </Box>
          )
        }
      </Container>
    </>
  );
};

export default StockDetails;
