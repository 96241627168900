import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  tableRoot:{
    maxHeight: "27rem",
  },
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    borderTop: "0",
    fontSize: theme.typography.h5.fontSize,
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.gray[600],
    fontSize: theme.typography.h6.fontSize,
  },
  tableCellRootBodyHead: {
    textTransform: "unset!important",
    fontSize: ".8125rem",
  },
  borderBottomUnset: {
    borderBottom: "0!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  avatarRoot: {
    width: "30px",
    height: "30px",
  },
  avatarRootRisk0: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk0,
  },
  avatarRootRisk1: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk1,
  },
  avatarRootRisk2: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk2,
  },
  avatarRootRisk3: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk3,
  },
  avatarRootRisk4: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk4,
  },
  avatarRootRisk5: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk5,
  },
  avatarRootRisk6: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk6,
  },
  avatarRootRisk7: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk7,
  },
  avatarRootRisk8: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk8,
  },
  avatarRootRisk9: {
    width: "30px",
    height: "30px",
    fontWeight: "700",
    fontSize: theme.typography.h5.fontSize,
    backgroundColor: theme.palette.bsColors.Risk9,
  },
  avatarCompanyRoot: {
    width: "20px",
    height: "20px",
  },
});

export default componentStyles;
