import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-etf-holdings.js";
import { useTranslation } from "react-i18next";
import { CardContent } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { useDarkMode } from "contexts/DarkModeContext";

const useStyles = makeStyles(componentStyles);

function moneyFormatter(label, index, labels) {
  if (label > 1000000000 || label < -1000000000) {
    return label / 1000000000 + "B";
  } else if (label > 1000000 || label < -1000000) {
    return label / 1000000 + "M";
  } else if (label > 1000 || label < -1000) {
    return label / 1000 + "K";
  } else {
    return label;
  }
}

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let label = data.labels[tooltipItem.index];
        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return moneyFormatter(value);
      },
    },
  },
  plugins: {
    title: {
      display: true,
      text: "Chart.js Line Chart - Multi Axis",
    },
    legend: {
      labels: {
        // This more specific font property overrides the global property
        color: "red",
      },
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        type: "time",
        time: {
          parser: "MM/YYYY",
          tooltipFormat: "MM/YYYY",
          unit: "quarter",
          unitStepSize: 2,
          displayFormats: {
            quarter: "MM/YY",
          },
        },
        gridLines: {
          color: "#bbb",
        },
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          callback: function (label, index, labels) {
            return label;
          },
        },
        id: "y1",
        gridLines: {
          color: "#bbb",
        },
      },
      {
        type: "linear",
        display: true,
        ticks: {
          callback: moneyFormatter,
        },
        position: "right",
        id: "y2",
        gridLines: {
          color: "#bbb",
        },
      },
    ],
  },
};

export default function CardStockPrice({ stockData }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation("translation", { keyPrefix: "StockDetails" });
  const [chartData, setChartData] = React.useState();
  const { activeTheme } = useDarkMode();

  const data = {
    labels: [
      "Jan 2022",
      "Feb 2022",
      "Mar 2022",
      "Apr 2022",
      "May 2022",
      "Jun 2022",
      "Jul 2022",
      "Aug 2022",
      "Sep 2022",
      "Oct 2022",
      "Nov 2022",
      "Dec 2022",
    ],
    datasets: [
      {
        label: "Stock Price",
        data: [33, 53, 85, 41, 44, 65, 77, 70, 56, 40, 30, 35],
        fill: false,
        backgroundColor: "#3FB923",
        borderColor: "#3FB923",
        yAxisID: "y1",
      },
      {
        label: "Revenue",
        data: [33, 25, 35, 51, 54, 46, 53, 60, 41, 44, 45, 60],
        fill: true,
        backgroundColor: activeTheme === "light" ? "#1B1B4F" : "#b879e8",
        type: "bar",
        yAxisID: "y2",
      },
      {
        label: "Earnings",
        data: [60, 25, 35, 51, 54, 46, 53, 60, 41, 44, 45, 60],
        fill: true,
        backgroundColor: "#4D86C5",
        type: "bar",
        yAxisID: "y2",
      },
    ],
  };

  React.useEffect(() => {
    if (stockData) {
      data.labels = stockData.earningsData
        ? stockData.earningsData.map((a) =>
            a.filing_date
              ? a.filing_date.substring(5, 7) + "/" + a.filing_date.substring(0, 4)
              : a.date.substring(5, 7) + "/" + a.date.substring(0, 4)
          )
        : [];
      data.datasets[0].label = t("stockPrice");
      data.datasets[1].label = t("revenue");
      data.datasets[2].label = t("earnings");
      const dataPointsDif = stockData.earningsData.length - stockData.priceData.length;
      if (dataPointsDif > 0) {
        // there is somehow older earnings data than price data
        stockData.earningsData = stockData.earningsData.slice(dataPointsDif);
        data.labels = data.labels.slice(dataPointsDif);
      }
      data.datasets[0].data = stockData.priceData
        ? stockData.priceData.map((a) => a.adjusted_close)
        : [];
      data.datasets[1].data = stockData.earningsData
        ? stockData.earningsData.map((a) => a.totalRevenue)
        : [];
      data.datasets[2].data = stockData.earningsData
        ? stockData.earningsData.map((a) => a.netIncome)
        : [];

      setChartData({ ...data });
    }
  }, [stockData]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid container component={Box} alignItems="center" justifyContent="space-between">
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("stockPerformance")}
                  </Box>
                </Box>
                <Box component={Typography} variant="h3" marginBottom="0!important">
                  <Box component="span">{t("priceVsFinancials")}</Box>
                  <FeatureInfoWithBox description={t("generalInfoTooltip")} />
                </Box>
              </Grid>
              <Grid item xs="auto"></Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent style={{ marginTop: "-5px" }}>
          {chartData && <Line options={options} data={chartData} />}
        </CardContent>
      </Card>
    </>
  );
}
