import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-etf-holdings.js";
import { useTranslation } from "react-i18next";
import { CardContent } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { useDarkMode } from "contexts/DarkModeContext";

const useStyles = makeStyles(componentStyles);

const annotation = {
  type: "line",
  borderColor: "black",
  borderDash: [6, 6],
  borderDashOffset: 0,
  borderWidth: 3,
  label: {
    enabled: true,
    content: (ctx) => "Average: " + average(ctx).toFixed(2),
    position: "end",
  },
  scaleID: "y",
  value: (ctx) => average(ctx),
};

function average(ctx) {
  const values = ctx.chart.data.datasets[0].data;
  return values.reduce((a, b) => a + b, 0) / values.length;
}

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "Chart.js Line Chart - Multi Axis",
    },
    annotation: {
      annotations: {
        annotation,
      },
    },
  },
  scales: {
    xAxes: [
      {
        display: true,
        type: "time",
        time: {
          parser: "MM/YYYY",
          tooltipFormat: "MM/YYYY",
          unit: "quarter",
          unitStepSize: 2,
          displayFormats: {
            quarter: "MM/YY",
          },
        },
        gridLines: {
          color: "#bbb",
        },
      },
    ],
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          callback: function (label, index, labels) {
            return label;
          },
        },
        id: "y1",
        gridLines: {
          color: "#bbb",
        },
      },
    ],
  },
};

export default function CardRatiosOverTime({ stockData }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation("translation", { keyPrefix: "StockDetails" });
  const { activeTheme } = useDarkMode();

  const data = {
    labels: [
      "Jan 2022",
      "Feb 2022",
      "Mar 2022",
      "Apr 2022",
      "May 2022",
      "Jun 2022",
      "Jul 2022",
      "Aug 2022",
      "Sep 2022",
      "Oct 2022",
      "Nov 2022",
      "Dec 2022",
    ],
    datasets: [
      {
        label: "P/E",
        data: [33, 53, 85, 41, 44, 65, 77, 70, 56, 40, 30, 35],
        fill: false,
        backgroundColor: "#3FB923",
        borderColor: "#3FB923",
        yAxisID: "y1",
      },
      {
        label: "P/S",
        data: [33, 25, 35, 51, 54, 46, 53, 60, 41, 44, 45, 60],
        fill: false,
        backgroundColor: activeTheme === "light" ? "#1B1B4F" : "#b879e8",
        borderColor: activeTheme === "light" ? "#1B1B4F" : "#b879e8",
        yAxisID: "y1",
      },
    ],
  };
  const [chartData, setChartData] = React.useState(data);

  React.useEffect(() => {
    if (stockData) {
      const minIndex = stockData.earningsData.length - stockData.priceData.length; // some stocks don't have enough price data to fill all slots
      data.labels = stockData.earningsData
        ? stockData.earningsData
            .slice(minIndex, -4)
            .map(
              (a) =>
                a[a.filing_date ? "filing_date" : "date"].substring(5, 7) +
                "/" +
                a[a.filing_date ? "filing_date" : "date"].substring(0, 4)
            )
        : [];
      data.datasets[0].data = stockData.priceData
        ? stockData.earningsData
            .slice(minIndex, -4)
            .map((a, index) =>
              (
                (stockData.balanceSheet[index].commonStockSharesOutstanding *
                  stockData.priceData[index].adjusted_close) /
                (Number(a.netIncome) +
                  Number(stockData.earningsData[index + 1].netIncome) +
                  Number(stockData.earningsData[index + 2].netIncome) +
                  Number(stockData.earningsData[index + 3].netIncome))
              ).toFixed(2)
            )
        : [];
      data.datasets[1].data = stockData.earningsData
        ? stockData.earningsData
            .slice(minIndex, -4)
            .map((a, index) =>
              (
                (stockData.balanceSheet[index].commonStockSharesOutstanding *
                  stockData.priceData[index].adjusted_close) /
                (Number(a.totalRevenue) +
                  Number(stockData.earningsData[index + 1].totalRevenue) +
                  Number(stockData.earningsData[index + 2].totalRevenue) +
                  Number(stockData.earningsData[index + 3].totalRevenue))
              ).toFixed(2)
            )
        : [];
      setChartData({ ...data });
    }
  }, [stockData]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid container component={Box} alignItems="center" justifyContent="space-between">
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("stockPerformance")}
                  </Box>
                </Box>
                <Box component={Typography} variant="h3" marginBottom="0!important">
                  <Box component="span">{t("ratiosOverTime")}</Box>
                  <FeatureInfoWithBox description={t("generalInfoTooltip")} />
                </Box>
              </Grid>
              <Grid item xs="auto"></Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent style={{ marginTop: "-5px" }}>
          <Line options={options} data={chartData} />
        </CardContent>
      </Card>
    </>
  );
}
