import React from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/headers/features-header.js";

const useStyles = makeStyles(componentStyles);

const FeaturesHeader = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Box
        className={classes.header}
        position="relative"
        paddingTop="8rem"
        paddingBottom="6rem"
      >
        <Container maxWidth="xl">
          <Box>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={6}>
              <Box textAlign="left">
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.typographyH3}
                    >
                      Works with:{"      "}
                    <img
                      alt="eToro"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Etoro_logo.svg/1200px-Etoro_logo.svg.png"
                      width="70rem" 
                      style={{marginLeft:"0.5rem", verticalAlign:"text-top", marginBottom:"0rem"}}

                    />
                    </Typography>

                  </Box>
                <Box paddingRight="2rem" marginTop="1rem">
                  <Typography
                    variant="h1"
                    component="h1"
                    className={classes.typographyH1}
                  >
                    Bullsheet Features to <br/>
                  </Typography>
                  <Typography
                    variant="h1"
                    component="h1"
                    className={classes.typographyH1}
                    style={{backgroundImage:"linear-gradient(135deg, hsla(212, 51%, 54%, 1) 0%, hsla(126, 38%, 48%, 1) 75%)", 
                      "WebkitBackgroundClip": "text",
                      "WebkitTextFillColor": "transparent",
                      "MozBackgroundClip": "text",
                      "MozTextFillColor": "transparent",}} 
                  >
                    track your investments
                  </Typography>
                  <Box marginTop="1.5rem">
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.typographyH3}
                    >
                      Save hours of manual work by automatically importing your stocks. Analyse your portfolio's diversification, follow your stocks' news and extended hours, and much more.
                    </Typography>
                  </Box>
                  <Box marginTop="1.5rem">
                    <Button
                      component={Link}
                      variant="contained"
                      to="/auth/register"
                      classes={{ root: classes.buttonRoot }}
                    >
                      Sign Up for Free
                    </Button>
                  </Box>
                  
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Box
                    component="img"
                    alt="Automate your Etoro Portfolio"
                    height="auto"
                    className={classes.imgBoxHeader}
                    src={require("assets/img/theme/features_header.png").default}
                  ></Box>
                  </Grid>
                </Grid>    
              </Grid>
           
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FeaturesHeader;
