const componentStyles = (theme) => ({
    cardRoot: {
      color: theme.palette.dark.main,
      background:
      "linear-gradient(180deg, hsla(80, 100%, 35%, 1) 35%, hsla(89, 34%, 38%, 1) 100%)!important",
    },
    cardRootDark: {
      color: theme.palette.white.main,
      borderRadius: "15px",

      background:
      "linear-gradient(270deg, hsla(0, 0%, 33%, 1) 15%, hsla(0, 0%, 16%, 1) 100%)!important",
    },
    cardRootBTC: {
        color: theme.palette.white.main,
        borderRadius: "15px",
        background:
        "linear-gradient(90deg, hsla(40, 100%, 58%, 1) 45%, hsla(40, 96%, 38%, 1) 100%)!important",
      },
    cardRootETH: {
        color: theme.palette.white.main,
        borderRadius: "15px",
        background:
        "linear-gradient(90deg, hsla(0, 0%, 33%, 1) 45%, hsla(0, 0%, 16%, 1) 100%)!important",
      },
      
      cardRootADA: {
        color: theme.palette.white.main,
        borderRadius: "15px",
        background:
        "linear-gradient(90deg, hsla(219, 99%, 69%, 1) 45%, hsla(219, 55%, 43%, 1) 100%)!important",
      },
      cardRootDOGE: {
        color: theme.palette.white.main,
        borderRadius: "15px",
        background:
        "linear-gradient(90deg, hsla(48, 56%, 47%, 1) 45%, hsla(48, 69%, 29%, 1) 100%)!important",
      },
      cardRootBNB: {
        color: theme.palette.white.main,
        borderRadius: "15px",
        background:
        "linear-gradient(90deg, hsla(42, 89%, 57%, 1) 0%, hsla(42, 89%, 57%, 1) 40%, hsla(42, 79%, 48%, 1) 100%)!important",
      },
    cardContentRoot: {
        flex: "1 1 auto",
        maxHeight: "5rem",
        padding: "0rem",
        minHeight: "1px",
        marginBottom: "0rem",
        "&:last-child": {
            paddingBottom: 0,
          },
      },
    typographyH2White: {
      marginBottom: ".5rem !important",
      color: theme.palette.bsColors.GlobalWhite,
      fontWeight: "600!important",
      fontSize: theme.typography.h2.fontSize,
    },
    typographyH3White: {
      color: theme.palette.bsColors.GlobalWhite,
      fontWeight: "700!important",
      marginBottom: "0!important",
      fontSize: theme.typography.h3.fontSize,
      textAlign: "left",
      lineHeight: 1.5,
    },
    typographyH4White: {
      color: theme.palette.bsColors.GlobalWhite,
      fontWeight: "600!important",
      marginBottom: "0!important",
      fontSize: theme.typography.h4.fontSize,
      textAlign: "left",
      lineHeight: 1.5,
    },
    typographyH5White: {
      color: theme.palette.bsColors.GlobalWhite,
      fontWeight: "600!important",
      fontSize: theme.typography.h5.fontSize,
      textAlign: "left",
      lineHeight: 1.5,
    },
    tableCellRootBuy: {
      color: theme.palette.bsColors.NumberGreen,
      fontWeight: "600!important",
      fontSize: theme.typography.h5.fontSize,
      textAlign: "left",
      lineHeight: 1.5,
    },
    tableCellRootSell: {
      color: theme.palette.bsColors.NumberRed,
      fontWeight: "600!important",
      fontSize: theme.typography.h5.fontSize,
      textAlign: "left",
      lineHeight: 1.5,
    },
    logoStyle: {
        borderRadius:"50%",
        //border:"0.5px solid #1f1f1f"
    }
  });
  
  export default componentStyles;
  