import boxShadows from "assets/theme/box-shadow.js";
import hexToRgb from "assets/theme/hex-to-rgb.js";

const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  avatarRoot: {
    width: "30px",
    height: "30px",
  },
  searchBox: {
    borderColor: theme.palette.bsColors.MainBlue,
    borderRadius: "0.75rem",
    height: "2.5rem",
    width: "200px",
    border: "0px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.bsColors.MainBlue) + ",0.9)",
    //backgroundColor: "#F8F9FE",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    color: theme.palette.gray[400],
  },
  etoroBox: {
    borderColor: theme.palette.bsColors.NumberGreen,
    borderRadius: "0.75rem",
    height: "2.5rem",
    width: "6rem",
    border: "0px solid",
    backgroundColor: "rgba(" + hexToRgb(theme.palette.bsColors.NumberGreen) + ",0.9)",
    boxShadow: boxShadows.inputBoxShadow,
    transition: "box-shadow .15s ease",
    color: theme.palette.gray[400],
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchIcon: {
    color: theme.palette.gray[400],
    marginRight: "0.5rem",
    marginLeft: "1rem",
  },
  searchClose: {
    color: theme.palette.gray[400],
    marginRight: "1rem",
    marginLeft: "-.5rem",
  },
  searchInput: {
    color: theme.palette.gray[200],
    width: "9rem",
    backgroundColor: "initial",
    border: 0,
    boxShadow: "none",
    padding: "0",
    marginLeft: "1.5rem",
    height: "2.5rem"
  },
  searchInputButton: {
    border: 0, 
    borderRadius: "0.75rem",
    height: "2.5rem",
    width: "2rem",
    backgroundPosition: "center", 
    backgroundSize: "cover",
    marginRight: "0rem",
    marginLeft: "0.5rem",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80px", 
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
    },
    "&:hover": { 
      transform: "scale(1.05)"
    },
  },
  searchInputButtonDisabled: {
    border: 0, 
    borderRadius: "0.75rem",
    height: "2.5rem",
    width: "2rem",
    backgroundPosition: "center", 
    backgroundSize: "cover",
    marginRight: "0rem",
    marginLeft: "0.5rem",
    cursor: "pointer",
    backgroundSize: "70px", 
    backgroundRepeat: "no-repeat",
    backgroundImage: require("assets/img/brand/bs-icon-stretch.png").default,
    backgroundColor: "#989898 !important",
    backgroundBlendMode: "color-burn",

    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
    },
  },
  flexColumnFlexXlRow: {
    flexDirection: "column!important",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row!important",
    },
  },
});

export default componentStyles;
