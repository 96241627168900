import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// @material-ui/lab components
// @material-ui/icons components

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader";
import componentStyles from "assets/theme/views/admin/export.js";
import componentStylesButtons from "assets/theme/components/button.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import CardTimeline from "components/Cards/Cards/CardTimeline";
import CardFeedbackForm from "../Forms/CardFeedbackForm";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);


const FeedbackPage = () => {
  const classes = { ...useStyles(), ...useStylesButtons(), ...useStylesCardDeck() };
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Contact' });

  return (
    <>
      <AlternativeHeader section={t("header")} subsection="Portfolio" />
      {/* Page content */}
      <Helmet>
        <title>Give Feedback | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          <Grid item xs={12} lg={6}>
            <CardFeedbackForm/>
          </Grid>
          <Grid item xs={12} lg={6}>
            <CardTimeline/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FeedbackPage;
