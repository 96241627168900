import React from "react";
// javascript plugin that creates a sortable object from a dom object
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/sortable/card-etf-holdings.js";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const tableHead = ["Name", "Ratio"];

export default function CardETFValuations({valuations}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'ETFDetails' });

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("etfInfo")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("valuationRatios")}</Box>
                  <FeatureInfoWithBox description={t("etfValuationRatiosTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto">
                
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer classes={{ root: classes.tableRoot }}>
          <Box alignItems="center" marginBottom="0!important">
          <Table stickyHeader aria-label="sticky table">
            <TableHead classes={{ root: classes.tableCellRootHead }}>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              { valuations.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    //variant="head"
                    scope="row"
                    zindex={1000}
                  >
                    <Box alignItems="center" display="flex">
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker1"
                        >
                          {prop[0]}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                  >                  
                    <Box
                        component="span"
                        marginRight=".5rem"
                        className="invested"
                      >
                      {prop[1] ? parseFloat(prop[1]).toFixed(2): "N/A"}
                    </Box>
                  </TableCell>
                  
                  </TableRow>
               ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}
