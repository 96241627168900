import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Helmet from "react-helmet";
// @material-ui/lab components
// @material-ui/icons components

// core components
import NoBreadcrumbsHeader from "components/Headers/NoBreadcrumbsHeader.js";
import CardHoldings from "components/Cards/Sortable/CardHoldings";
import AlternativeHeader from "components/Headers/AlternativeHeader";

import componentStyles from "assets/theme/views/admin/sortable.js";
import CardPITRades from "components/Cards/Sortable/CardPITrades";
import CardInsiderTrades from "components/Cards/Sortable/CardInsiderTrades";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);

const RealTimeTrades = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'RealTimeTrades' });

  return (
    <>
      <AlternativeHeader section={t("header")}/>
      {/* Page content */}
      <Helmet>
        <title>Real Time Trades | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <CardPITRades />
        <CardInsiderTrades />
      </Container>
    </>
  );
};

export default RealTimeTrades;
