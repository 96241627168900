import React, { useEffect } from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Doughnut } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import styled, { ThemeProvider } from "styled-components";
import themeColors from "assets/theme/colors";

import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
import strings from "../../../strings/en.js";
// @material-ui/icons components

// core components
import { chartOptions, parseOptions, chartCryptoPortfolioBreakdown } from "variables/charts.js";
import componentStyles from "assets/theme/components/cards/charts/card-crypto-portfolio-breakdown.js";
import componentStylesButtons from "assets/theme/components/button.js";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import { useTranslation } from "react-i18next";

const useStylesButtons = makeStyles(componentStylesButtons);
const useStyles = makeStyles(componentStyles);

const ChartWrapper = styled.div`
  height: 250px;
  position: relative;
  margin-top: 0px;
  #myChart {
    position: relative;
    z-index: 10;
  }
  #chartjs-tooltip {
    left: 50%;
    background: transparent;
    top: 0%;
    font-family:  Open Sans, sans-serif;
    font-style: normal;
    line-height: 2rem;
    right: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 0;
    height: 100%;
    padding: 0;
    opacity: 1 !important;
    align-items: center;
    color: ${props => props.theme.palette.bsColors.MainBlue};
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    text-align: center;
    vertical-align: middle;
  }
`;

function CardCryptoPortfolioBreakdown({currentEtoroUserData}) {
  const classes = { ...useStyles(), ...useStylesButtons() };
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CryptoDashboard' });

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }, [t])
  
  //This can be replaced with a cryptopositionsarray from backend
  const backgroundColor = [themeColors.bsColors.Pie0, themeColors.bsColors.Pie1, themeColors.bsColors.Pie2, themeColors.bsColors.Pie3,
    themeColors.bsColors.Pie4, themeColors.bsColors.Pie5, themeColors.bsColors.Pie6, themeColors.bsColors.Pie7, 
    themeColors.bsColors.Pie8, themeColors.bsColors.Pie9, themeColors.bsColors.Pie10, themeColors.bsColors.Pie11, themeColors.bsColors.Yellow, "#70F", "#000", "#70F", "#000", "#70F", "#000", "#70F", "#000",
  ];
  const cryptoOnlyArray = [
    {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: backgroundColor,
          label: "Crypto Positions"
        }
      ],
    }
  ]
  currentEtoroUserData?.currentPortfolio.summary.positions.sort((a, b) => b.valuePctUnrealized - a.valuePctUnrealized)
  for (let i=0; i<currentEtoroUserData?.currentPortfolio.summary.positions.length; i++){
    if(currentEtoroUserData?.currentPortfolio.summary.positions[i].instrumentId >= 10000){
      cryptoOnlyArray[0].datasets[0].data.push(currentEtoroUserData?.currentPortfolio.summary.positions[i].valuePctUnrealized)
      cryptoOnlyArray[0].labels.push(getTickerFromId(currentEtoroUserData?.currentPortfolio.summary.positions[i].instrumentId))
    }
  }
  function getTickerFromId(instrumentId) {
    const asset = currentEtoroUserData.userInstruments.instruments.find(entry => entry.instrumentId === instrumentId);  
    if(!asset) {
      return "Other"
    }
    return asset.ticker  // or undefined
  }
  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Crypto Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">Crypto Breakdown</Box>
                  <FeatureInfoWithBox description={t("cryptoBreakdownTooltip")}/>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          <Box position="relative" height="250px">
            { currentEtoroUserData?.portfolioBreakdown ? 
            <ThemeProvider theme={theme}>
              <ChartWrapper>
                <Doughnut
                  //id="myChart"
                  data={cryptoOnlyArray[0]}
                  options={chartCryptoPortfolioBreakdown.options}
                  getDatasetAtEvent={(e) => console.log(e)}
                />
                <div id="chartjs-tooltip"><span>{cryptoOnlyArray[0]?.datasets[0]?.data?.length} <br/>cryptos <br/><small><small><b>{cryptoOnlyArray[0]?.datasets[0]?.data?.reduce((a,b)=> a+b , 0).toFixed(1)}%</b> <small>{t('ofPortfolio')}</small></small></small></span></div>
              </ChartWrapper>
            </ThemeProvider>
               : <></> }
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardCryptoPortfolioBreakdown;
