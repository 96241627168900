import React, { useEffect } from "react";
// javascipt plugin for creating charts
// react plugin used to create charts
import { HorizontalBar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/icons components

// core components
import { chartSectorVsMarket } from "variables/charts.js";

import componentStyles from "assets/theme/components/cards/charts/card-portfolio-ratios";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "contexts/DarkModeContext";
import { useEtoroUser } from "contexts/EtoroUserContext";

const useStyles = makeStyles(componentStyles);

// quick find: Sector Diversification
function CardSectorVsMarket({currentSectorVsMarketBreakdown}) {
  const classes = useStyles();
  const theme = useTheme();
  const { currentEtoroUserData } = useEtoroUser();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Analysis' });
  const { activeTheme } = useDarkMode();
  let [chartStyling, setChartStyling] = React.useState(chartSectorVsMarket.options);

  const backgroundColorPortfolio = [theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, 
    theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue,
    theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue, theme.palette.bsColors.MainBlue,
  ];

  const backgroundColorMarket = [theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, 
    theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue,
    theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue, theme.palette.bsColors.LogoLightBlue,
  ];

  useEffect(() => {
    if (currentEtoroUserData?.currentSectorVsMarketBreakdown) {
      currentEtoroUserData.currentSectorVsMarketBreakdown.datasets[0].backgroundColor = backgroundColorPortfolio;
      currentEtoroUserData.currentSectorVsMarketBreakdown.datasets[1].backgroundColor = backgroundColorMarket;
      chartStyling = activeTheme === "light" ? chartSectorVsMarket.options : chartSectorVsMarket.options
      chartStyling.scales.xAxes[0].gridLines.color = theme.palette.bsColors.ChartLines;
      setChartStyling(chartStyling);
    }
  }, [activeTheme])

  return (
    <>
      <Card
        classes={{
          root: classes.cardRoot,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    Portfolio
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("sectorDiversification")}</Box>
                  <FeatureInfoWithBox description={t("sectorDiversificationTooltip")}/>
                </Box>
              </Grid>
              <Grid item xs="auto" className={classes.gridItemRoot}>
                <Box
                  color={theme.palette.bsColors.MainBlue}
                  component="span"
                  marginRight=".25rem"
                  >
                  ●
                </Box>
                <Box color={theme.palette.bsColors.MainBlue} fontSize={theme.typography.h6.fontSize} fontWeight="400" component="small">
                  Portfolio
                </Box>
                <Box
                  color={theme.palette.bsColors.LogoLightBlue}
                  component="span"
                  marginRight=".25rem"
                  marginLeft="1rem"
                  >
                  ●
                </Box>
                <Box color={theme.palette.bsColors.MainBlue} fontSize={theme.typography.h6.fontSize} fontWeight="400" component="small">
                  S&P500
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          
              <Box position="relative" height="350px">
                  <HorizontalBar
                    data={currentSectorVsMarketBreakdown ? currentSectorVsMarketBreakdown : {}}
                    options={chartStyling}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </Box>
            
        </CardContent>
      </Card>
    </>
  );
}

export default CardSectorVsMarket;
