import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  gridItemRoot: {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: "100%",
  },
  listItemRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    padding:8,
    "&:not(:last-child)": {
      borderBottom: "1px solid " + theme.palette.background.default,
    },
  },
  img: {
    verticalAlign: "middle",
    borderStyle: "none",
    maxWidth: "1.5rem",
    borderRadius: "50%",
    border: "1px solid black"
  },
  mb0: {
    marginBottom: "0!important",
  },
  dividendPercentageBox: {
    textAlign: "center",
    color: theme.palette.bsColors.LogoLightBlue,
    marginTop: "-15px!important",
  },
  dividendValueBox: {
    textAlign: "center",
    color: theme.palette.gray[600],
  },
  buttonRootDefaultSmall: {
    padding: "2px",
    color: theme.palette.bsColors.MainBlue,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.gray[100],
      borderColor: theme.palette.gray[100],
      color: theme.palette.bsColors.MainBlue,
    },
  },
});

export default componentStyles;
