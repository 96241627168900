import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";

import componentStyles from "assets/theme/components/cards/cards/card-coin-basic-info.js";
import componentStylesCardImg from "assets/theme/components/card-img.js";
import { Typography } from "@material-ui/core";
import { useEtoroUser } from "contexts/EtoroUserContext";

const useStyles = makeStyles(componentStyles);
const useStylesCardImg = makeStyles(componentStylesCardImg);

function CardCoinBasicInfo({coin, logo, price, change}) {
  const classes = { ...useStyles(), ...useStylesCardImg() };
  const theme = useTheme();
  const coinsWithStyles = ["BTC", "ADA", "ETH", "BNB", "DOGE"]
  const { currentEtoroUserData } = useEtoroUser();
  let logoUrl = ""
  let logoBg ="red"
  // function getLogo(ticker) {
  //   const asset = currentEtoroUserData?.userInstruments?.instruments?.find(entry => entry.ticker === ticker);  
  //   if (typeof asset == "undefined"){
  //     return
  //   }
  //   else {
  //     return asset?.media.at(-1).uri  // or undefined
  //   }
  // }
  // if (getLogo(coin)){
  //   logoUrl = getLogo(coin)
  //   logoBg = "#" + logoUrl.substr(logoUrl.indexOf("_"),6)
  // }
  // else {
  //   logoUrl = logo
  //   logoBg = "transparent"
  // }
  return (
    <>
      <Card className={classes["cardRoot" + (coinsWithStyles.includes(coin) ? coin : "Dark")]}>

        <CardContent className={classes.cardContentRoot}>
            <Box display="flex">
            <img className={classes.logoStyle} src={logo} height="80vh"/>
                <Box alignSelf="center" style={{paddingLeft: "10px"}}>
                  <Typography className={classes.typographyH4White}>{coin}</Typography>
                  <Typography className={classes.typographyH5White}>
                    ${price}
                  </Typography>
                  <Typography className={classes.typographyH5White}>
                    {change}%
                  </Typography>
                </Box>
                </Box>
        </CardContent>
      </Card>
    </>
  );
}

export default CardCoinBasicInfo;