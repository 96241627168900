import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components


// core components

import componentStyles from "assets/theme/views/admin/alternative-dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import CardCopySync from "components/Cards/Charts/CardCopySync";
import CardEffectivePortfolio from "components/Cards/Sortable/CardEffectivePortfolio";
import { useEtoroUser } from "../../../contexts/EtoroUserContext";
import CardParentTrades from "components/Cards/Sortable/CardParentTrades";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

// quick find: Copy Sync
function CopyPeople() {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  const { currentEtoroUserData } = useEtoroUser();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'CopySync' });

  return (
    <>
      <AlternativeHeader section={t("header")}/>  
      {/* Page content */}
      <Helmet>
        <title>Copy People | Bullsheet</title>
      </Helmet>
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-4.5rem"
        classes={{ root: classes.containerRoot }}
      >
        
        <Grid container>
          { currentEtoroUserData?.copySyncData?.copySync?.effectivePortfolio ?
          <Grid item xs={12} xl={4}>
            <CardEffectivePortfolio effectivePortfolio={currentEtoroUserData.copySyncData.copySync.effectivePortfolio}/>
            </Grid>
          :
          <Grid item xs={12} xl={4}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                className={classes.cardHeader}
                title={t("header")}
                titleTypographyProps={{
                  component: Box,
                  marginBottom: "0!important",
                  variant: "h3",
                }}
              ></CardHeader>
              <CardContent>
              <Typography
                variant="h4"
                component="h4"
                classes={{ root: classes.menuTitle }}
              >
                {t("noCopy")}
              </Typography>
                {t("noCopyDescription")}
              </CardContent>
            </Card> 
          </Grid> }
          { currentEtoroUserData?.copySyncData?.latestParentTrades && currentEtoroUserData?.copySyncData?.latestParentTrades.length > 0 ?
          <>
            <Grid item xs={12} xl={8}>
              <CardParentTrades/>
            </Grid> 
            <Grid item xs={12} xl={12} style={{marginBottom: "0.5rem"}}>
              <i>Copy sync is being remodeled. Meanwhile, we'll display following data:</i>
            </Grid>
          </>
          : <></> }

          {currentEtoroUserData?.copySyncData ? currentEtoroUserData.copySyncData.copySync?.parentSyncs?.map((copy, key) => (
          <Grid key={key} item xs={12} md={6} xl={6}>
              <CardCopySync parentUsername={copy.parentUsername} latestTrades={copy.latestTrades} parentRisk={currentEtoroUserData?.userInstruments?.copyParentUsernames[copy.parentUsername]?.parentRiskScore} 
                            biggestPosition={copy.biggestParentPosition} parentCash={copy.parentCash}/>
          </Grid> )): <></> }
        </Grid> 
      </Container>
    </>
  );
}

export default CopyPeople;
