import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PerfectScrollbar from "react-perfect-scrollbar";
import FeatureInfoWithBox from "components/Custom/FeatureInfoWithBox";
// @material-ui/lab components
// @material-ui/icons components
// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-risk-line.js";

import { chartOptions, parseOptions, chartRiskClusters, chartRiskClustersDark } from "variables/charts.js";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "contexts/DarkModeContext";


const useStyles = makeStyles(componentStyles);

const tableHead = ["Risk", "Ticker", "Invested", "Risk Contrib."];

// quick find: Asset Risk
export default function CardRiskLine({instrumentRisksArray, riskClusters}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'Analysis' });
  const { activeTheme } = useDarkMode();

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const tableConatinerRef = React.useRef(null);
  React.useEffect(() => {
    new List(tableConatinerRef.current, {
      valueNames: ["risk", "ticker", "invested", {name: "risk contrib.", attr:"risk-val"}],
      listClass: "list",
    });
  }, []);
return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h6"
                  letterSpacing="2px"
                  marginBottom="0!important"
                  className={classes.textUppercase}
                >
                  <Box component="span" color={theme.palette.gray[600]}>
                    {t("analytics")}
                  </Box>
                </Box>
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  <Box component="span">{t("assetRisk")}</Box>
                  <FeatureInfoWithBox description={t("assetRiskTooltip")}/>
                </Box>
                <Box
                  component={Typography}
                  variant="h6"
                  marginBottom="0!important"
                  style={{fontStyle: 'italic', fontWeight: 400}}
                >
                  {t("riskDisclaimer")}
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
          <Grid container>
          <Grid item lg={6} xs={12}>
          <PerfectScrollbar>
        { instrumentRisksArray && instrumentRisksArray.length > 0? 
        <TableContainer ref={tableConatinerRef} classes={{ root: classes.tableRoot }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    {...{
                      "data-sort":
                        prop !== "" 
                          ? prop.toLowerCase()
                          : undefined,
                    }}
                    scope="col"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " sort " +
                        classes.tableCellRootHead,
                    }}
                  >
                    {t(prop)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {instrumentRisksArray ? instrumentRisksArray.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="body"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Avatar variant="rounded" className="risk" classes={{ root: classes["avatarRootRisk" + prop.risk] }}>
                        {prop.risk}
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootBodyHead,
                    }}
                    component="th"
                    variant="body"
                    scope="row"
                  >
                    <Box alignItems="center" display="flex">
                      <Box
                        component={Avatar}
                        classes={{ root: classes.avatarCompanyRoot}}
                        marginRight="0.5rem"
                        alt="..."
                        src={prop.tickerLogo}
                      />
                      <Box display="flex" alignItems="flex-start">
                        <Box
                          fontSize={theme.typography.h5.fontSize}
                          component="span"
                          className="ticker"
                          fontWeight= "600"
                          color={theme.palette.bsColors.MainBlue}
                        >
                          {prop.tickerName}
                        </Box>
                    </Box>
                    </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot + " budget" }}>
                        <Box
                            component="span"
                            marginRight=".5rem"
                            className="invested"
                          >
                          {prop.invested}%
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Box paddingTop=".35rem" paddingBottom=".35rem" className="risk contrib." risk-val={prop.riskContribution + 1000}>
                          {prop.riskContribution}%
                        </Box>
                      </TableCell>
                    </TableRow>
                  )) : <></>}
                </TableBody>
              </Table>
            </TableContainer>
            : <></> }
            </PerfectScrollbar>
          </Grid>
          <Grid item lg={5} xs={11}>
              <Box position="relative" height="20rem" marginTop="2rem" marginLeft="1rem">
                { riskClusters ? 
                  <Bar
                    data={riskClusters}
                    options={activeTheme === "light" ? chartRiskClusters.options : chartRiskClustersDark.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  /> : <></> }
              </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
