import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    //boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  cardHeaderRoot: {
    backgroundColor: "initial!important",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
  buttonRootUnselected: {
    background: theme.palette.white.main + "!important",
    color: theme.palette.primary.main + "!important",
  },
  buttonRoot: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.white.main,
    borderColor: theme.palette.white.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  buttonRootSelected: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.dark.main,
    borderColor: theme.palette.dark.main,
    "&:hover": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      color: theme.palette.dark.main,
    },
  },
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderTop: "0",
  },
  tableBodyRoot: {
    fontSize: theme.typography.h6.fontSize,
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.gray[100],
    color: theme.palette.gray[600],
  },
  tableCellRootBodyHead: {
    textTransform: "unset!important",
    fontSize: ".8125rem",
  },
  tableRoot:{
    maxHeight: "25rem",
    marginBottom: "0!important",
  },
  avatarRoot: {
    height: "40px",
    width: "40px",
  },
  logoRoot: {
    height: "30px",
    width: "30px",
  }
});

export default componentStyles;
